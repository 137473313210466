/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { jsx } from "@emotion/core";
import { VolumeIcon, MuteIcon } from '../../Components/LandingHeader/Feedback/icons/Icons'
import {playSound, pauseSound} from '../../redux/slices/timer';

const TimerSoundsDropdown = ({theme, handleChange, settings, colorPicker}) => {
    const [show, setShow] = useState(false);
    const dropdown = useRef('');
    const timerCompleteSound = [
        {value: 'Kung Pow!.wav', label: 'Kung Pow!'},
        {value: 'Airy Melody.wav', label: 'Airy Melody'},
        {value: 'Bright Percussion.wav', label: 'Bright Percussion'},
        {value: 'Brriinnng!.mp3', label: 'Brriinnng!'},
        {value: 'Canary.wav', label: 'Canary'},
        {value: 'Deep Himalayan Gong.wav', label: 'Deep Himalayan Gong'},
        {value: 'Digital Alarm Clock Beeping.wav', label: 'Digital Alarm Clock Beeping'},
        {value: 'Digital Blips.wav', label: 'Digital Blips'},
        {value: 'Digital Progression.wav', label: 'Digital Progression'},
        {value: 'Energetic and Cinematic Clapping.wav', label: 'Energetic and Cinematic Clapping'},
        {value: 'Flute riff.wav', label: 'Flute riff'},
        {value: 'Funky Celebration.wav', label: 'Funky Celebration'},
        {value: 'Japanese Gong.wav', label: 'Japanese Gong'},
        {value: 'Light Thai Gong.wav', label: 'Light Thai Gong'},
        {value: 'Minimal Reverse Chime.wav', label: 'Minimal Reverse Chime'},
        {value: 'Mystical Mission Complete.wav', label: 'Mystical Mission Complete'},
        {value: 'Single Bell.mp3', label: 'Single Bell'},
        {value: 'Twinkle twinkle.wav', label: 'Twinkle twinkle'},
        {value: 'Twinkly Tones.wav', label: 'Twinkly Tones'},
        {value: 'Uplifting Orchestra.wav', label: 'Uplifting Orchestra'},
        {value: 'Victory Trumpets.wav', label: 'Victory Trumpets'},
        {value: 'Wind Chimes.mp3', label: 'Wind Chimes'}
    ];

    const [option, setOption] = useState(settings?.timerSound ?? timerCompleteSound[0].value);

    useEffect(() => {
        document.addEventListener('click', toggleDropdown);

        return () => {
            pauseSound();
            document.removeEventListener('click', toggleDropdown);
        }
    }, []);

    useEffect(() => {
        setShow(false);
        handleChange({value: option}, 'timerSound');
    }, [option]);

    const toggleDropdown = (e) => {
        !e.target.closest('.slectFocusOpt') && setShow(false)
    }

    return (
        <div className="field-block mt-30">
            <label className="field-label" style={{ color: theme == "dark" ? "white" : "" }}>Timer Complete Sound</label>
            <div className="field-data">
                <div className="filed-data-block sounds-dropdown">
                    <div>
                        <div ref={dropdown} className={`slectFocusOpt ${show ? 'active' : ''}`}>
                            <input readOnly value={(settings?.timerSound ?? timerCompleteSound[0].value).replace(/\.(.*)/, '')} type="text" className='input-field filed-data-block testing-code-field'  onClick={e => setShow(true)}/>

                            <div className='FocusOptn-col'> 
                                <ul className='optionUl'>
                                    {
                                        timerCompleteSound.map(sound => {
                                            return (
                                                <li className='optionLi'><a href='javascript: void(0);' className={`optionLi-link ${sound.value == settings?.timerSound ? `active ${theme}` : ''}`} css={{
                                                    '&.active':{
                                                        background: colorPicker,
                                                        color: '#fff'
                                                    }
                                                }}><span style={{marginTop: '5px', marginRight: '5px'}} href="#" onClick={e => {
                                                    pauseSound();
                                                    playSound(sound.value, false);
                                                }}><VolumeIcon /></span> <span onClick={e => setOption(sound.value)}>{sound.label}  {sound.value == settings?.timerSound && <img style={{marginTop: '5px'}} src="../../../images/checkMark.svg" alt="" className='checkMark' />}</span></a></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default TimerSoundsDropdown