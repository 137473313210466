/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { pushToSetting } from '../../../redux/slices/SettingSclice';
import { fetchGlobalSetting, setSetting } from '../../../Components/action/common';

const FeatureUpdateModal = () => {
    const { theme, colorPicker } = useSelector(state => state.theme);
    const {settings} = useSelector(state => state.settings);
    const [notification, setNotification] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchGlobalSetting({settings: 'newFeatureText,newFeatureVersion'}).then(async(res) => {
            let newFeatureText = null;
            let newFeatureVersion = null;
            res?.data?.responseData?.map(setting => {
                newFeatureText = setting?.settingName == "newFeatureText" ? setting?.settingValue : newFeatureText;
                newFeatureVersion = setting?.settingName == "newFeatureVersion" ? setting?.settingValue : newFeatureVersion;
            })
            
            if(newFeatureVersion != settings?.appVersion && newFeatureVersion == process.env.REACT_APP_VERSION){
                setNotification(newFeatureText);
                // Update settings
                const newSettings = { ...settings, appVersion: newFeatureVersion };
                await setSetting({ "settings": newSettings })
                dispatch(pushToSetting(newSettings));
            }
        });
    }, []);

    return (
        notification !== null &&
        <Modal show={true} className="task-modal file-upload-modal transform none absolute top-per-10"  css={{
            '.darkPrimaryColor': {
                background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker} !important`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
            },
            '.notification-text':{
                a: {
                    color: `${colorPicker} !important`
                }
            }
        }}>
            <Modal.Body>
                <div className="full-model md-model-updates" style={{ display: 'block' }}>
                    <div className="full-model-body notification-text" style={{background : theme == "dark" ? "black" :""}}>
                        <h3 className="darkPrimaryColor">What’s New</h3>
                        <div className="max height-60vh" style={{overflowY: 'auto'}}>
                            <div className="body-data mt-20" dangerouslySetInnerHTML={{ __html: notification }} />
                        </div>
                        <div className="mt-40 d-flex justify-end align-end">
                            <button className="button light-btn "
                                onClick={() => setNotification(null)}
                                css={{
                                    background: 'none !important',
                                    color: `${colorPicker} !important`
                            }}
                            >Close</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FeatureUpdateModal