import React from 'react'
import {useSelector} from 'react-redux'

function DragIcon({color = null}) {
  const colorPicker = useSelector(state=> color ? color : state.theme.colorPicker)
  
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M7.49984 10.8334C7.96007 10.8334 8.33317 10.4603 8.33317 10.0001C8.33317 9.53984 7.96007 9.16675 7.49984 9.16675C7.0396 9.16675 6.6665 9.53984 6.6665 10.0001C6.6665 10.4603 7.0396 10.8334 7.49984 10.8334Z" stroke={colorPicker} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.49984 4.99992C7.96007 4.99992 8.33317 4.62682 8.33317 4.16659C8.33317 3.70635 7.96007 3.33325 7.49984 3.33325C7.0396 3.33325 6.6665 3.70635 6.6665 4.16659C6.6665 4.62682 7.0396 4.99992 7.49984 4.99992Z" stroke={colorPicker} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.49984 16.6667C7.96007 16.6667 8.33317 16.2936 8.33317 15.8333C8.33317 15.3731 7.96007 15 7.49984 15C7.0396 15 6.6665 15.3731 6.6665 15.8333C6.6665 16.2936 7.0396 16.6667 7.49984 16.6667Z" stroke={colorPicker} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.4998 10.8334C12.9601 10.8334 13.3332 10.4603 13.3332 10.0001C13.3332 9.53984 12.9601 9.16675 12.4998 9.16675C12.0396 9.16675 11.6665 9.53984 11.6665 10.0001C11.6665 10.4603 12.0396 10.8334 12.4998 10.8334Z" stroke={colorPicker} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.4998 4.99992C12.9601 4.99992 13.3332 4.62682 13.3332 4.16659C13.3332 3.70635 12.9601 3.33325 12.4998 3.33325C12.0396 3.33325 11.6665 3.70635 11.6665 4.16659C11.6665 4.62682 12.0396 4.99992 12.4998 4.99992Z" stroke={colorPicker} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.4998 16.6667C12.9601 16.6667 13.3332 16.2936 13.3332 15.8333C13.3332 15.3731 12.9601 15 12.4998 15C12.0396 15 11.6665 15.3731 11.6665 15.8333C11.6665 16.2936 12.0396 16.6667 12.4998 16.6667Z" stroke={colorPicker} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export default DragIcon