/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setAllTaskList, setGroupedList, setHeaderName, setNoDateTaskList, setOverDueTaskList, setTotalCount, setTrashTaskList } from '../../redux/slices/TaskSlice'
import { useEffect } from 'react'
import { totalTaskCount } from '../action/common'
import moment from 'moment'
import { jsx } from "@emotion/core";


function TaskSidebar(props) {
  const dispatch = useDispatch()
  const [activeHeaderLink, setSelectedActiveHeaderLink] = useState('Upcoming')
  const [isSorting, setIssorting] = useState(false)
  const [sortBy, setSortBy] = useState('Time');
  const [sortDirection, setSortDirection] = useState('ascending')
  const [arrowActive, setArrowActive] = useState(false)
  const [isActiveComponent, setIsActiveComponent] = useState('Upcoming')
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes));
  const { colorPicker, theme } = useSelector(state => state.theme)
  // const {settings} =useSelector(state=>state.settings)
  // const timeFormat = settings?.timeFormat ? settings?.timeFormat : "LT"


  const { totalCount, taskGroupedList, allTaskList, overDueTaskList, noDataTaskList, completedTaskList, trashTaskList } = useSelector(state => state.task)

  function setActiveHeaderLink(header) {

    setSelectedActiveHeaderLink(header)
    dispatch(setHeaderName(header))
  }

  const sortTaskList = () => {

    let sortedItems = [...taskGroupedList]
    let allTaskSort = [...allTaskList]
    let overDueTask = [...overDueTaskList]
    let noDate = [...noDataTaskList]
    let completedTask = [...completedTaskList]
    let trashTask = [...trashTaskList]

    setIssorting(false);

    if (sortBy !== null) {
      sortedItems.sort((a, b) => {

        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() < b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? -1 : 1;
        }
        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() > b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    if (sortBy !== null) {
      allTaskSort.sort((a, b) => {

        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() < b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? -1 : 1;
        }
        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() > b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    if (sortBy !== null) {
      overDueTask.sort((a, b) => {

        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() < b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? -1 : 1;
        }
        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() > b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    if (sortBy !== null) {
      noDate.sort((a, b) => {

        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() < b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? -1 : 1;
        }
        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() > b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    if (sortBy !== null) {
      noDate.sort((a, b) => {

        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() < b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? -1 : 1;
        }
        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() > b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    if (sortBy !== null) {
      completedTask.sort((a, b) => {

        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() < b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? -1 : 1;
        }
        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() > b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    if (sortBy !== null) {
      trashTask.sort((a, b) => {

        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() < b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? -1 : 1;
        }
        if ((a[sortBy] && b[sortBy]) && (a[sortBy].toLowerCase() > b[sortBy].toLowerCase())) {
          return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    dispatch(setGroupedList(sortedItems));
    dispatch(setAllTaskList(allTaskSort))
    dispatch(setOverDueTaskList(overDueTask))
    dispatch(setOverDueTaskList(overDueTask))
    dispatch(setNoDateTaskList(noDate))
 //   dispatch(setCompletedTaskList(completedTask))
 //   dispatch(setTrashTaskList(trashTask))

  }

  const onSortArrow = () => {
    setArrowActive(!arrowActive)
    if (sortDirection == 'ascending') {
      setSortDirection('descending')
    } else {
      setSortDirection('ascending')
    }
  }

  const fetchTotalCount = async () => {
    try {
      let response = await totalTaskCount({ "date": currentTime.toISOString() })
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({ response }) {

    }
  }


  useEffect(() => {
    sortTaskList()
  }, [sortDirection, sortBy])

  useEffect(() => {
    fetchTotalCount()
  }, [])


  return (

    <div className="task-menus" style={{background:theme == "dark" ? "black" :"white"}}>
        <ul className="ul-menus">
            <li className="li-menus">
                <NavLink to='/tasks'
                activeClass={activeHeaderLink == 'Upcoming' ? 'active' : 'inactive'}
                className="menus-link"
                css={{
                    '&.active': {
                    background: `${colorPicker} !important`,
                    }
                }}
                style={{ color: theme == "dark" ? "white" : "gray"}}
                onClick={() => { setActiveHeaderLink('Upcoming'); setIsActiveComponent('Upcoming') }} end
                >Upcoming <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "gray" }}>{totalCount?.upcomingCount}</span></NavLink>
            </li>

            <li className="li-menus">
                <NavLink to="/tasks/overdue" style={{ color: theme == "dark" ? "white" : "gray"  }} activeClass={activeHeaderLink == 'Overdue' ? 'active' : 'inactive'} className="menus-link "
                css={{
                    '&.active': {
                    background: `${colorPicker} !important`,
                    }
                }}
                onClick={() => setActiveHeaderLink('Overdue')}>Overdue <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "gray" }}>{totalCount?.overdueCount}</span></NavLink>
            </li>

            <li className="li-menus">
                <NavLink to="/tasks/inbox" style={{ color: theme == "dark" ? "white" : "gray"}} activeClass={activeHeaderLink == 'All' ? 'active' : 'inactive'} className="menus-link"
                css={{
                    '&.active': {
                    background: `${colorPicker} !important`,
                    }
                }}
                onClick={() => setActiveHeaderLink('All')}>Inbox <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "gray" }}>{totalCount?.totalCount}</span></NavLink>
            </li>
            <li className="li-menus">
                <NavLink to="/tasks/nodate" style={{ color: theme == "dark" ? "white" : "gray"}} activeClass={activeHeaderLink == 'NoDate' ? 'active' : 'inactive'} className="menus-link"
                css={{
                    '&.active': {
                    background: `${colorPicker} !important`,
                    }
                }}
                onClick={() => setActiveHeaderLink('NoDate')}>No Date <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "gray" }}>{totalCount?.noDateCount}</span></NavLink>
            </li>
            {/* <li className="li-menus">
                <NavLink to="/tasks/complete" style={{ color: theme == "dark" ? "white" : "gray" , opacity:activeHeaderLink !== "Completed" && 0.5}} activeClass={activeHeaderLink == 'Completed' ? 'active' : 'inactive'} className="menus-link"
                css={{
                    '::before': {
                    background: `${colorPicker} !important`,
                    }
                }}
                onClick={() => setActiveHeaderLink('Completed')}>Completed <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "" }}>{totalCount?.completedCount}</span></NavLink>
            </li>
            <li className="li-menus">
                <NavLink to="/tasks/trash" style={{ color: theme == "dark" ? "white" : "gray" , opacity:activeHeaderLink !== "Trash" && 0.5 }} activeClass={activeHeaderLink == 'Trash' ? 'active' : 'inactive'} className="menus-link"
                css={{
                    '::before': {
                    background: `${colorPicker} !important`,
                    }
                }}
                onClick={() => setActiveHeaderLink('Trash')}>Trash <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "" }}>{totalCount?.deletedCount}</span></NavLink>
            </li> */}
        </ul>
    </div>
  )
}

export default TaskSidebar