/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';
import { jsx } from "@emotion/core";
import './style.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setTimer, decreaseTimer, increaseTimer } from '../../redux/slices/timer';
import { focusTimerFormat, focusTimerFormatStatic, setFocusOnTimer } from '../action/utilities';
import ArrowUp from '../../pages/Quotes/ArrowUp';
import { Add, CollapseIcon, FullscreenIcon, Minus, SettingsIcon } from '../LandingHeader/Feedback/icons/Icons';
import TimerOptions from './TimerOptions';
import { DragHandler } from '../../pages/Tasks/DragHandler';
import { useNavigate } from 'react-router-dom';

function FocusTimer({defaultOption = 'focusModeSettings', timeSetting = true, timerOptions = true, showTimeTabs = false}) {
  const [collapse, setCollapse] = useState(false);
  const { timer, start } = useSelector(state => state.focusTimer);
  const { theme, colorPicker } = useSelector(state => state.theme);
  const { settings } = useSelector(state => state.settings);
  const dispatch = useDispatch();
  const defaultTimePeriodFocus = settings?.defaultTimePeriodFocus?.replace(/\s/, '') !== '' ? parseInt(settings.defaultTimePeriodFocus) : 25;
  const timeTabs = [[5, "5 min"], [15, "15 min"], [30, "30 min"], [60, "1 hr"], [90, "90 min"], [120, "2 hrs"], [150, "150 mins"]];
  const [hrMinSecValue, setHrMinSec] = useState(["", "", ""])
  const [selectInputFocus, setselectInputFocus] = useState(false);
  const customTimeInput = useRef('');
  const timerSpan = settings?.timerNudgeAmount ?? 300; // secs
  const refOne = useRef(null)
  const navigate = useNavigate();
  const [quotecollapse, setquotecollapse] = useState(true);

  useEffect(() => {
    !start && setHrMinSec(["", "", ""]);
  }, [start])

  useEffect(() => {
    if(selectInputFocus === false && customTimeInput?.current){
      customTimeInput.current.value = '';
    }
      
  }, [selectInputFocus])

  const setCustomTimeHandler = (e) => {
    setselectInputFocus(true);
    customTimeInput.current.focus();
    setHrMinSec(["","",""])
    const element = document.querySelector('.set-time');
      element.style.borderRight = '1px solid rgb(0, 0, 0)';
  }

  const setCustomTime = (e) => {
    if(isNaN(e.target.value))
      return;

    let time = e.target.value.substr(-6, 6);
    customTimeInput.current.value = time;
    time = time.length % 2 !== 0 ? time.padStart(time.length + 1, "0") : time;
    setHrMinSec([time.length > 4 ? time.substr(-6, 2) : '', time.length > 2 ? time.substr(-4, 2) : '', time.length > 0 ? time.substr(-2, 2) : '']);
  }

  useEffect(() => {
    if(defaultOption === 'focusMode' && !selectInputFocus)
      return;

    let focusTime = ((hrMinSecValue?.[0]?.length ? hrMinSecValue[0] * 3600 : 0) + (hrMinSecValue?.[1] * 60) + (hrMinSecValue?.[2] * 1))
    if(!focusTime){
      const defaultTimePeriod = settings?.defaultTimePeriodFocus ?? '25';
      focusTime = defaultTimePeriod?.replace(/\s/, '') !== '' ? parseInt(defaultTimePeriod) * 60 : 0;
    }
    dispatch(setTimer(focusTime));
  }, [hrMinSecValue])
  
  const setDefaultTimePeriod = (timerType, timerValue = null) => {
    let defaultTimePeriod = timerValue !== null ? `${timerValue}` : (timerType == 'focus' || timerType == 'focusModeSettings' ? (settings?.defaultTimePeriodFocus ?? '25') : (settings?.defaultTimePeriodBreak ?? '5'));
    setselectInputFocus(false);
    if(defaultTimePeriod){
      const focusTime = defaultTimePeriod?.replace(/\s/, '') !== '' ? parseInt(defaultTimePeriod) * 60 : 0;
      dispatch(setTimer(focusTime));
    }
  }

  const increaseDecreaseTimer = (timerSpan, type) => {
    setselectInputFocus(false);
    type == 'decrease' ? dispatch(decreaseTimer(timerSpan)) : dispatch(increaseTimer(timerSpan));
    setFocusOnTimer();
  }

  const handleClickOutside = (e) => {
    if(defaultOption !== 'focusMode' && !document.getElementById("quote-sec")?.contains(e.target) && !document.getElementById("meeting-form")?.contains(e.target)){
      setHrMinSec(["", "25", "00"])
      const element = document.querySelector('.set-time');
      element && (element.style.border = 'none');
      customTimeInput.current.value = '';
     }
  }

  return (
    <>
    <div className='bar widgetWrapper'>
      {
        quotecollapse ?
      
        <div className={`dailyQuote quoteInner focus-timer ${theme ?? ''}`}   ref={refOne} >
          <div className={`quoteHeader ${collapse ? 'head-option' : ''} `} style={{ background: theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000" : "" }} onClick={() => setCollapse(!collapse)}>
            <DragHandler />
            <span className={`quotetitle ${theme == "dark" ? "quotesTitleDarkMode" : ""}`}>TIMER</span>
            <span className={`header-arrow ${collapse ? 'active' : ''}`} onClick={() => setCollapse(!collapse)}>
              {
                theme == "dark" ? <ArrowUp /> : <img src='../../../images/arrow-up-dn.svg' alt='' />
              }
            </span>
          </div>
          {
              !start
              ?
              <>
                <div className="quoteContainer focus-timer-container" style={{ display: collapse ? "none" : '' }}>
                  <DragHandler />
                  {
                    timeSetting
                    && 
                    <div className='timeColthree focusTimer'>
                      <a href="javascript: void(0);" className='desc actBtns' onClick={e => increaseDecreaseTimer(timerSpan, 'decrease')}>
                        <Minus color={theme == "dark" ? '#fff' : '#000'} />
                      </a>

                      <div style={{borderRightColor: selectInputFocus ? (theme == "dark" ? '#fff' : '#000') : 'transparent'}} className='set-time' id="uu" onClick={setCustomTimeHandler} dangerouslySetInnerHTML={{ __html: selectInputFocus ? focusTimerFormatStatic(hrMinSecValue, theme == "dark" ? '#fff' : '#000') : focusTimerFormat(timer, theme == "dark" ? '#fff' : '#000') }}></div>
                      
                      <input className="timer-custom-time" ref={customTimeInput} onInput={setCustomTime} />

                      <a href="javascript: void(0);" className='ased actBtns' onClick={e => increaseDecreaseTimer(timerSpan, 'increase')}>
                          <Add color={theme == "dark" ? '#fff' : '#000'} />
                      </a>
                    </div>
                  }
                  {
                    timerOptions && <TimerOptions defaultOption={defaultOption} onChange={setDefaultTimePeriod} timer={timer} colorPicker={colorPicker} theme={theme} />
                  }
                </div> 
                {
                  showTimeTabs
                  &&
                  <div className='timer-time-tabs'>
                    {
                      timeTabs.map((tab, index) =>
                        <button className={`time-tab ${timer / 60 == tab[0] ? 'active' : ''}`} key={index} onClick={e => setDefaultTimePeriod(null, tab[0])} css={{
                          '&.active': {opacity: 1, borderColor: `${colorPicker} !important`, background: `${colorPicker} !important`, color: `${theme == 'dark' ? '#000' : '#fff'} !important`},
                        }}>
                        {tab[1]}
                        </button>
                    )}
                  </div>
                }
              </>
              :
              <div className="quoteContainer focus-timer-container"><span className='in-progress'>In Progress</span><img src='../../../images/timer.svg' /> </div>
          }

            {/* <div className='onHoverShorts foCusTimer'>
              <div className='actOnhove'>
                  <div className='hovAct ht-collapse' onClick={() => setquotecollapse(false)}> <CollapseIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                  <div className="hovAct ht-scale"> <FullscreenIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                  <div className='hovAct ht-settings' onClick={() => navigate(`/settings#cards:focus`) }> <SettingsIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
              </div>
            </div> */}
          </div>
        : 
          <div className='barContent'  onClick={() => setquotecollapse(true)}>
              <div className='barContentHeading'>
                  <h4 className='barHead'>Timer</h4>
                  <span className='headBrk'></span>
              </div>
          </div>
        }
    </div>
    </>
  )
}

export default FocusTimer