import React from 'react'
import { useSelector } from 'react-redux';
import RunningFocusTimerV2 from './RunningFocusTimerV2';
import FocusTimerWidget from './FocusTimerWidget';

const TimerWidget = () => {
    const { activeTimer } = useSelector(state => state.focusTimer);

    return (
        activeTimer !== null ? <RunningFocusTimerV2 /> : <FocusTimerWidget />
    )
}

export default TimerWidget