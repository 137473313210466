/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import _ from 'lodash'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import FeedbackButton from "./Feedback/FeedbackButton"
import { AuthContext } from "../../AuthProvider"
import { Link } from "react-router-dom"

function LandingHeader() {
  const { subscription } = useContext(AuthContext);
  const { colorPicker, theme } = useSelector(state => state.theme);
  
  return (
    <header className="header" id="header-id">
      <div className="d-flex header-inner">
        <div className="header-left">
          
        </div>
        <div className="heder-midle">
        </div>

         <div className="header-right">
          {/*<div className="head-temp">

            <figure> <CloudImg /> </figure>

            <span className="temperature" style={{ color: theme == "dark" ? "white" : "" , opacity : theme == "dark" ? 0.3 :"" }}>{
              loader ? <span className="loaderTemp"></span> : (isNaN(temperature) ? null : <span>{parseInt(temperature)}{' C'}</span>)
            }</span>
            <span className="weatherType">Cloudy</span>
          </div>*/}
          {/* <GroupByDropdown /> */}
          {
            subscription?.isFreeTrial 
            && 
            <Link className="btn upgrade-plan-btn" to="/subscription-plan" css={{
              background: `linear-gradient(0deg,rgba(255,255,255,0.6),rgba(255,255,255,0.6)),linear-gradient(0deg,${colorPicker},${colorPicker}),linear-gradient(0deg,rgba(0,0,0,0.05),rgba(0,0,0,0.05)),#FFFFFF`
            }}>
              <span css={{
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${colorPicker}`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
              }}>Upgrade</span> {subscription?.daysLeft} {subscription?.daysLeft > 1 ? 'days' : 'day'} left
            </Link>
          }
          <FeedbackButton />
        </div> 
      </div>
    </header>
  )
}

export default LandingHeader
