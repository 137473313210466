/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useRef } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setHeaderName, setTotalCount } from '../../redux/slices/TaskSlice'
import { useEffect } from 'react'
import { taskProjectPosition, totalTaskCount } from '../action/common'
import moment from 'moment'
import { jsx } from "@emotion/core";
import { CheckIcon, InboxIcon, OverDueIcon, NoDateIcon, Trash, UpcomingTasksIcon, FolderIcon, EditIcon2, FolderPlusIcon } from '../LandingHeader/Feedback/icons/Icons'
import { fetchProjectList } from '../action/tasks'
import { setProjectsListWithTasksCount } from '../../redux/slices/ProjectSlice'
import DeleteProjectModal from '../../pages/Settings/Modal/DeleteProjectModal'
import RenameProjectModal from '../../pages/Settings/Modal/RenameProjectModal'
import TaskSourceIcon from '../../pages/Tasks/TaskSourceIcon'
import ActionsDropdown from '../common/ActionsDropdown'
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import DragIcon from '../../pages/Tasks/DragIcon'
import { arrayMoveImmutable } from 'array-move'

const DragHandle = SortableHandle(() => <span className="dragdrop-option"><DragIcon /></span>);

function SiteNav(props) {
    const dispatch = useDispatch()
    const [activeHeaderLink, setSelectedActiveHeaderLink] = useState('Upcoming')
    const currentTimes = moment().startOf('date')
    const [currentTime, setCurrentTime] = useState(new Date(currentTimes));
    const { colorPicker, theme } = useSelector(state => state.theme)
    const totalCount = useSelector(state => state.task.totalCount)
    const projectsListWithTasksCount = useSelector(state => state.project.projectsListWithTasksCount)
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(null)
    const [showEditProjectModal, setShowEditProjectModal] = useState(null)
    const [newProject, setNewProject] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();

  function setActiveHeaderLink(header) {
    setSelectedActiveHeaderLink(header)
    dispatch(setHeaderName(header))
  }

  const fetchTotalCount = async () => {
    try {
      let response = await totalTaskCount({ "date": currentTime.toISOString() })
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({ response }) {}
  }

  useEffect(() => {
    fetchTotalCount();
    fetchProjectListWithTasksCount();
  }, [])

  const openDeleteProjectModal = (e, project) => {
    e.preventDefault()
    e.stopPropagation()
    setShowDeleteProjectModal(project)
  }

  const openEditProjectModal = (e, project) => {
    e.preventDefault()
    e.stopPropagation()
    setNewProject(false);
    setShowEditProjectModal(project)
  }

  const hideProjectModal = (fetchProjectList = false, deletedId = null, renameId = null, renameTitle = null, renameSource = null) => {
    setShowDeleteProjectModal(null)
    setShowEditProjectModal(null)
    setNewProject(false);

    const id = (location.pathname?.split('/')?.[4] ?? '').split('_')[0];

    if(deletedId == id){
        navigate(`/tasks/projects/untitled/-1`)
    }
    if(renameId == id){
        navigate(`/tasks/projects/${renameTitle}/${renameId}${renameSource ? `_${renameSource}` : ''}`)
    }
    if(fetchProjectList){
      fetchProjectListWithTasksCount();
    }
  }

  const fetchProjectListWithTasksCount = () => {
    fetchProjectList({includeTasksCount: true, ordering: '1'}).then((res) => {
      dispatch(setProjectsListWithTasksCount(res?.data?.responseData?.data ?? []))
    })
  }

  const createNewProject = (e) => {
    e.preventDefault()
    e.stopPropagation()
    
    setNewProject(true);
  }

  const SortableItem = sortableElement(({project}) => {
    const title = project.title//.charAt(0).toUpperCase() + project.title.slice(1);
    const renameActive = showEditProjectModal?.project_id == project.project_id;

    return (
        <li className="li-menus border radius-15 project-list-item" onClick={(e) => {
            e.preventDefault()    
        }} css={{
            '&:hover': {
                position: 'relative',
                background: `radial-gradient(50% 100% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #FFF`,
                '.action-btn-wrapper': {
                    display: 'flex !important'
                },
                '.dragdrop-option': {
                    opacity: '1 !important',
                    left: '-4px',
                    top: '11px'
                }
            }
        }}>
            <>
                <NavLink to={`/tasks/projects/${project.title}/${project.project_id}${project.projectSource ? `_${project.projectSource}` : ''}`}
                className={`opacity d10 align-start justify-start gap-2 fd-col relative ${ renameActive ? 'active' : ''}`}
                onClick={() => { setActiveHeaderLink(`Project - ${title}`) }}
                end
                >
                    <div className='d-flex align-center justify-start gap-10 width-100 wrap'>
                        <DragHandle />
                        <FolderIcon width={18} height={18} color={activeHeaderLink == `Project - ${title}` ? colorPicker : "black"} fill={activeHeaderLink == `Project - ${title}` ? colorPicker : "none"} />
                        {
                            renameActive
                            ?
                            <RenameProjectModal project={showEditProjectModal} onHide={hideProjectModal} />
                            :
                            <>
                                {title}
                                <TaskSourceIcon task={{id: project.project_id, taskSource: project.projectSource}} colorPicker={colorPicker} />
                                <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                <span className='font size-14 opacity d5 color-black'>{project.pendingTasksCount}</span>
                            </>
                        }
                    </div>
                </NavLink>
                {
                    !renameActive &&
                    <div className='action-btn-wrapper bg-white border radius-20 d-flex align-center justify-center gap-15 pad-8-15  position absolute right-15 top-5 d-none' style={{border: '1px solid rgba(0, 0, 0, 0.05)'}}>
                        <a href="#" className='d-flex align-center justify-center opacity d10' onClick={e => openDeleteProjectModal(e, project)}><Trash /></a>
                        <a href="#" className='d-flex align-center justify-center opacity d10' onClick={e => openEditProjectModal(e, project)}><EditIcon2 /></a>
                    </div>
                }
            </>
        </li>
    )
  })

  const SortableContainer = sortableContainer(() => {
    return (
        <ul className="ul-menus mt-20" css={{
            overflowY: 'auto',
            'a.active': {
                background: `radial-gradient(50% 100% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker} !important`
            }
        }}>
            {
                newProject &&
                <li className="li-menus border radius-15 project-list-item">
                    <a className={`opacity d10 align-start justify-start fd-col relative active`}>
                        <div className='d-flex align-center justify-start gap-10 width-100 wrap'>
                            <FolderIcon width={18} height={18} color="black" fill="none" />
                            <RenameProjectModal project={showEditProjectModal} onHide={hideProjectModal} />
                        </div>
                    </a>
                </li>
            }
            {
                projectsListWithTasksCount.map((project, index) => {
                    return <SortableItem key={project.project_id} project={project} index={index} disabled={project.project_id === null} />
                })
            }
        </ul>
    )
  })

  const onSortEnd = async ({oldIndex, newIndex}) => {
    if(oldIndex == newIndex)
        return;

    const groupKeys = projectsListWithTasksCount.map(t => t.title);
    const newList = arrayMoveImmutable(groupKeys, oldIndex, newIndex);
    
    dispatch(setProjectsListWithTasksCount(newList.map(t => projectsListWithTasksCount.find(p => p.title == t))))

    try{
      await taskProjectPosition({ "positions": newList })
    }catch(e){
      console.log(e)  
    }
  }

  return (
    <>
        <div className='task-menus'>
            <ul className="ul-menus" css={{
                'li': {
                    '&:hover': {
                        borderRadius: '15px',
                        background: `radial-gradient(50% 100% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #FFF !important`
                    }
                },
                'a.active': {
                    background: `radial-gradient(50% 100% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker} !important`
                }
            }}>
                <li className="li-menus" onClick={(e) => {
                    e.preventDefault()    
                }}>
                    <NavLink to='/tasks'
                    activeClass={activeHeaderLink == 'Upcoming' ? 'active' : 'inactive'} onClick={() => { setActiveHeaderLink('Upcoming') }} end
                    ><UpcomingTasksIcon color={activeHeaderLink == 'Upcoming' ? colorPicker : "black"} fill={activeHeaderLink == 'Upcoming' ? colorPicker : "none"}/> Upcoming <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "" }}></span></NavLink>
                </li>

                <li className="li-menus" onClick={(e) => {
                    e.preventDefault()    
                }}>
                    <NavLink to="/tasks/overdue" activeClass={activeHeaderLink == 'Overdue' ? 'active' : 'inactive'} onClick={() => setActiveHeaderLink('Overdue')}><OverDueIcon color={activeHeaderLink == 'Overdue' ? colorPicker : "black"}/> Overdue <span className="mn-count overdue-count" style={{ color: theme == "dark" ? "gray" : "" }}></span></NavLink>
                </li>

                <li className="li-menus" onClick={(e) => {
                    e.preventDefault()    
                }}>
                    <NavLink to="/tasks/inbox" activeClass={activeHeaderLink == 'All' ? 'active' : 'inactive'} onClick={() => setActiveHeaderLink('All')}><InboxIcon color={activeHeaderLink == 'All' ? colorPicker : "black"}/> Inbox <span className="mn-count inbox-count" style={{ color: theme == "dark" ? "gray" : "" }}>{totalCount?.totalCount ?? 0}</span></NavLink>
                </li>
                <li className="li-menus" onClick={(e) => {
                    e.preventDefault()    
                }}>
                    <NavLink to="/tasks/nodate" activeClass={activeHeaderLink == 'NoDate' ? 'active' : 'inactive'} onClick={() => setActiveHeaderLink('NoDate')}><NoDateIcon color={activeHeaderLink == 'NoDate' ? colorPicker : "black"}/> No Date <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "" }}>{totalCount?.noDateCount ?? 0}</span></NavLink>
                </li>
                <li className="li-menus" onClick={(e) => {
                    e.preventDefault()    
                }}>
                    <NavLink to="/tasks/complete" activeClass={activeHeaderLink == 'Completed' ? 'active' : 'inactive'} onClick={() => setActiveHeaderLink('Completed')}><CheckIcon color={activeHeaderLink == 'Completed' ? colorPicker : "black"}/> Completed <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "" }}></span></NavLink>
                </li>
                <li className="li-menus" onClick={(e) => {
                    e.preventDefault()    
                }}>
                    <NavLink to="/tasks/trash" activeClass={activeHeaderLink == 'Trash' ? 'active' : 'inactive'} onClick={() => setActiveHeaderLink('Trash')}><Trash color={activeHeaderLink == 'Trash' ? colorPicker : "black"}/> Trash <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "" }}></span></NavLink>
                </li> 
            </ul>
            <div className='hr-line mt-20 mb-20' css={{'&:after': {left: '0px !important'}}}></div>
            <div className='d-flex align-center justify-between width-100 project-list-nav'>
                <h3 className='font-family inter font size-18 weight-600 opacity d5' style={{color: colorPicker}}>Projects</h3>
                <ActionsDropdown colorPicker={colorPicker} actions={[{
                    icon: <FolderPlusIcon />,
                    title: 'Create New Project',
                    action: (e) => createNewProject(e)
                }]} />
            </div>
            <SortableContainer hideSortableGhost={true} useDragHandle={true} helperClass="sortable-helper" axis="y" lockAxis="y" onSortEnd={onSortEnd}></SortableContainer>
        </div>
        {showDeleteProjectModal && <DeleteProjectModal project={showDeleteProjectModal} theme={theme} colorPicker={colorPicker} onHide={hideProjectModal} />}
    </>
  )
}

export default SiteNav