import moment from 'moment'
import React, { useCallback, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { fetchTaskList, totalTaskCount } from '../../Components/action/common'
import AddTask from '../AddTasks/AddTask'
import TaskListDetails from './TaskListDetails'
import { useDispatch, useSelector } from 'react-redux'
import { setDateChange, setOverDueTaskList, setTaskProjects, setTotalCount } from '../../redux/slices/TaskSlice'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'
import { Accordion } from 'react-accessible-accordion'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { verifyRepeatedTask } from '../../Components/action/utilities'

function OverDue() {
  // dispatch
  const dispatch = useDispatch()
  // global state
  const {theme} = useSelector(state => state.theme)
  const overDueTaskList = useSelector(state => state.task.overDueTaskList)
  const currentTimes = moment().startOf('date')
  const [list, setList] = useState([])
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes))
  const [isLoader, setIsLoader] = useState(true)
  const wrapperRef = useRef(null)
  const fetchTotalCount = async () => {
    try {
      let response = await totalTaskCount({ "date": currentTime.toISOString() })
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({ response }) {}
  }

  const fetchAddTaskData = useCallback(async () => {
    setIsLoader(true)
    let requestBody = {
      "status": 0,
      "type": 2,
      "date": currentTime.toISOString()
    }
    try {
      const response = await fetchTaskList(requestBody)

      const totalOverDueTasks = _.orderBy(response?.data?.responseData.map(item => {
        if(item?.repeat?.rule){
          const result = verifyRepeatedTask(item, null, [], true);
          return result?.length > 0 ? {...item, date: new Date(result[0]).toISOString()} : false;
        }else{
          const result = item.status == 0 && item.date !== null && item.date < currentTime.toISOString();
          return result ? item : false;
        }

      }).filter(item => item !== false), ['date'], ['desc']);

      const overDueTaskList = _.chunk(totalOverDueTasks, 20);

      document.querySelector('.overdue-count').textContent = totalOverDueTasks?.length

      const chunk = overDueTaskList.splice(0, 1);
      setList(overDueTaskList)
      dispatch(setTaskProjects(response?.data?.projects ?? []))
      dispatch(setOverDueTaskList(chunk?.[0] ?? []))
      setIsLoader(false)
    } catch ({ response }) {
      setIsLoader(false)
    }
  }, [currentTime, dispatch])

  useEffect(() => {
    dispatch(setDateChange((new Date()).toISOString()))
    fetchAddTaskData()
  }, [])

  useEffect(() => {
    if(isLoader) return;

    const bound = wrapperRef.current.getBoundingClientRect()
    wrapperRef.current.style.height = window.innerHeight - bound.top + 'px'
  }, [isLoader])

  const fetchMoreData = () => {
    const chunk = list.splice(0, 1);
    if(chunk.length > 0){
      setList(list)
      dispatch(setOverDueTaskList([...overDueTaskList, ...chunk[0]]))
    }
  }

  return (
    <>
      {
        isLoader ? <AllLoader/> : <>
        <div id="tasks-list-wrapper" ref={wrapperRef} className="overdue-form task-list-page common-form border radius-20" style={{background:theme == "dark" ? "black" :"white"}}>
          <div className="task-list">
            <div className="task-list-data">
              <div className="ul-task-list">
                <div className="task-list">
                  <InfiniteScroll
                    dataLength={overDueTaskList?.length ?? 0}
                    next={fetchMoreData}
                    hasMore={list.length > 0}
                    loader={<AllLoader  />}
                    scrollableTarget="tasks-list-wrapper"
                  >
                    <Accordion preExpanded={['accitem0']}>
                      <TaskListDetails 
                      fetchTotalCount={fetchTotalCount}
                      currentPage="overDue"
                      />
                    </Accordion>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      }
    </>
  )
}

export default OverDue