import { useSelector } from "react-redux";
import RunningFocusTimer from "./RunningFocusTimer";

function DaemonTimer(){
    const {activeTimer} = useSelector((state) => state.focusTimer);

    return (
        activeTimer !== null && <RunningFocusTimer defaultMaximizTimer={false} focusMode={true} display={false} />
    )
}

export default DaemonTimer;