/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from 'react'
import LandingHeader from '../LandingHeader/LandingHeader'
import SideBar from '../SideBar/SideBar'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { jsx } from "@emotion/core";
import HomePage from '../../pages/HomePage/HomePage'
import Tasks from '../../pages/Tasks/Tasks'
import OverDue from '../../pages/Tasks/OverDue'
import All from '../../pages/Tasks/All'
import Completed from '../../pages/Tasks/Completed'
import Trash from '../../pages/Tasks/Trash'
import NoDate from '../../pages/Tasks/NoDate'
import Settings from '../../pages/Settings/Settings'
import Sidebar from './Sidebar'
import AIAssistantFullScreen from '../ChatGPT/Fullscreen'
import { clearChat } from '../../redux/slices/AiAssistant'
import TasksLayout from '../../pages/Tasks/TasksLayout'
import FocusModeSettings from '../FocusTimer/FocusModeSettings'
import Admin from '../Admin/Admin'
import SubscriptionPlan from '../Outseta/SubscriptionPlan'
import { AuthContext } from '../../AuthProvider'
import DaemonTimer from '../FocusTimer/DaemonTimer'
import AdminSettings from '../Admin/Settings'
import TodayTopBar from '../LandingHeader/TodayTopBar'
import General from '../../pages/Settings/Genral'
import SettingsLayout from '../../pages/Settings/SettingsLayout'
import ConfirmLogout from '../../pages/Settings/ConfirmLogout'
import ProjectTasks from '../../pages/Tasks/ProjectTasks'
import TasksSearchResults from '../../pages/Tasks/TasksSearchResults'
import FeatureUpdateModal from '../../pages/Settings/Modal/FeatureUpdateModal'

function DashboardLayout(props) {
  const dispatch = useDispatch()
  //const { search } = useLocation()
  const urlPath = window.location.pathname
  //let queries = queryString.parse(search);
  // const navigate = useNavigate();
  // let access_token = localStorage.getItem("token")
  const { settings, settingsFetched } = useSelector(state => state.settings)
  const { themeColor, theme, colorPicker } = useSelector(state => state.theme)
  //const { istodayQuotes, isFoucsActive, quicksLinksActive } = useSelector(state => state.card)
  const innerPages = urlPath?.includes("tasks") || (urlPath?.includes("settings") && !urlPath?.includes("focus-mode-settings")) || urlPath?.includes("ai-assistant") || urlPath?.includes("admin") || urlPath?.includes("subscription-plan");
  //console.log(window.location.pathname,"path:::")
  const { subscription } = useContext(AuthContext);

  const isSubscriptionExpired = subscription?.isFreeTrial && subscription?.accountStage > 3;

  // const fetchSettinValue = async () => {
  //   let settings = {};
  //   try {
  //     let response = await fetchSetting();
  //     settings = response?.data?.responseData?.settings ?? {};
  //     dispatch(setSettings(settings));
  //   } catch ({ response }) {}

  //   return settings;
  // }

  // const setBackgroundImage = async (id) => {
  //   // document.body.style.setProperty('background', `linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.96) 6.22%, rgba(255, 255, 255, 0.70) 100%), ${colorPicker}`);
  //   const bodyStyle = document.body.style;
  //   const bgImage = await getData(id);
  //   bodyStyle.backgroundImage = `
  //     url(${bgImage?.location}),
  //     linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%),
  //     linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.96) 6.22%, rgba(255, 255, 255, 0.70) 100%)
  //   `;
  //   bodyStyle.backgroundColor = '#889427';
  //   bodyStyle.backgroundPosition = '50%';
  //   bodyStyle.backgroundSize = 'cover';
  //   bodyStyle.backgroundRepeat = 'no-repeat';
  // }

  // useEffect(() => {
  //   if(!backgroundImageId) return;

  //   setBackgroundImage(backgroundImageId);
  // }, [backgroundImageId])

  useEffect(() => {
    (urlPath?.includes("tasks") || (urlPath?.includes("settings") && !urlPath?.includes("focus-mode-settings"))) && dispatch(clearChat());
  }, [urlPath])

  // useEffect(() => {
  //   fetchSettinValue().then(settings => {
  //     fetchBackgroundImages(false).then(async (images) => {
  //       let defaultImage = null;
  //       // Local background image
  //       if(settings?.backgroundImageId){
  //         defaultImage = images.find(img => img.id == settings?.backgroundImageId)?.id ?? null;
  //       }
  //       // If local not found then apply global default background image
  //       if(!defaultImage){
  //         defaultImage = (images.find(img => img.isDefault))?.id ?? null;
  //       }

  //       defaultImage && dispatch(setThemeDefaultImage(defaultImage));
  //       await fetchBackgroundImages();
  //     });
  //   });
  // }, [])

  useEffect(() => {
    document.addEventListener('click', closeInputBox);
    return () => {
      document.removeEventListener('click', closeInputBox);
    }
  }, [])

  const closeInputBox = (e) => {
    // If click next/prev/today month arrow button in datepicker
    if (e.target?.parentNode?.classList?.contains('datepicker-month-nav') || e.target?.closest('.add-task-static') || e.target?.closest('.datepicker-month-nav') || e.target?.closest('.suggestions-list')) {
      return;
    }

    const inputElem = e.target?.classList?.contains('add-task-input') ?? null;

    if (inputElem) {
      document.querySelectorAll('.repetions-block').forEach(elem => {
        elem.style.display = 'none'
        elem.closest('.type-header') !== null && elem.closest('.type-header')?.classList?.remove('bg-light-addTask');
      });
      if(e.target.closest('.type-header')?.querySelector('.repetions-block')){
        e.target.closest('.type-header').querySelector('.repetions-block').style.display = 'block'
        e.target.closest('.type-header').classList.add('bg-light-addTask');
      }
    }

    document.querySelectorAll('.add-task-input').forEach(el => {
      el?.value?.length === 0 && el.classList.remove('focus-input')
    });

    if (e.target.closest('.filed-type')) {
      const inputElem = e.target?.classList?.contains('add-task-input') ?? null;
      if (inputElem) {
        e.target.closest('.add-task-input').classList.add('focus-input')
      }
    }

    // cancel-btn

    if (!e.target.closest('.advancedDatePicker') && !e.target.closest('.navBtn') && !e.target.closest('.datePickerButton')) {
      document.querySelectorAll('.advancedDatePicker.active').forEach(el => {
        el.classList.remove('active');
      })
    }
  }
  
  return (
      urlPath.match(/\/(signup|login|welcome|focus-mode-completed)$/) !== null
      ?
      <Navigate to={process.env.REACT_APP_ROOT} />
      :
      (
        isSubscriptionExpired && urlPath.match(/\/subscription\-plan$/) === null
        ?
        <Navigate to="/subscription-plan" />
        :
        <div className={`main-body-wrap ${theme == "dark" ? "dark-mode" : ""} ${settings?.focus === false && settings?.quotes === false && settings?.quickLink === false && settings?.aiAssistant === false ? 'no-sidebar' : ''} ${urlPath?.includes("subscription-plan") ? `subscription-plan-page ${isSubscriptionExpired ? 'post-expired' : ''}` : ''}`}>
        {
          urlPath?.includes("tasks") || urlPath?.includes("ai-assistant") ? null : ((urlPath?.includes("settings") && !urlPath?.includes("focus-mode-settings")) || urlPath?.includes("admin") || urlPath?.includes("subscription-plan") ? null : <LandingHeader />)
        }
        <div className={`page-wrapper ${'pages' } `}>
        {/* <div className="page-wrapper" > */}
          <div className={`page-container ${innerPages && !(urlPath?.includes("settings") && !urlPath?.includes("focus-mode-settings")) ? 'inner-page-wrapper' : 'home-page-wrapper'}`}>
            <SideBar innerPages={urlPath} />
            {!innerPages && <TodayTopBar />}
            <div className={`contentquoteSection mainHomepage ${innerPages ? 'inner-page' : 'home-page'} ${urlPath?.includes("ai-assistant") ? 'ai-assistant-page-content' : ''}`}
              css={{
                '&::before': {
                  background: theme == "dark" ? `${colorPicker} !important` : ""
                }
              }} id="uniqueClose">
              <Routes>
                {
                  !isSubscriptionExpired
                  &&
                  <>
                    <Route path={process.env.REACT_APP_ROOT} element={<HomePage />} />
                    <Route path='/focus-mode-settings' element={<HomePage />}/>
                    <Route element={<TasksLayout />}>
                      <Route path="/tasks" element={<Tasks />} />
                      <Route path="/tasks/search" element={<TasksSearchResults />} />
                      <Route path='/tasks/upcoming' element={<Tasks />} />
                      <Route path="/tasks/inbox" element={<All />} />
                      <Route path="/tasks/projects/:projectTitle/:id" element={<ProjectTasks />} />
                      <Route path='/tasks/nodate' element={<NoDate />} />
                      <Route path="/tasks/overdue" element={<OverDue />} />
                      <Route path='/tasks/complete' element={<Completed />} />
                      <Route path='/tasks/trash' element={<Trash />} />
                    </Route>
                    <Route path='/settings' element={<Settings/>} />
                    {/* <Route path='/settings' element={<SettingsLayout/>}>
                      <Route path='/settings/general' element={<General/>}/>
                      <Route path='/settings/confirm-logout' element={<ConfirmLogout/>}/>
                    </Route> */}
                    <Route path='/ai-assistant' element={<AIAssistantFullScreen/>}/>
                    <Route path='/admin/settings' element={<AdminSettings />}/>
                    <Route path='/admin' element={<Admin/>}/>
                  </>
                }
                <Route path='/subscription-plan' element={<SubscriptionPlan/>}/>
                {/* <Route path='/payment' element={<StripeContainer/>}/> */}
              </Routes>
              {
                innerPages
                ? 
                <>
                </> 
                : 
                (settingsFetched ? <Sidebar systemSettings={settings} /> : null)
              }
            </div>
          </div>
        </div>
        {/* <div className='backgroundBottom'>
          <BackgroundImage />
        </div> */}
        <DaemonTimer />
        {Object.keys(settings)?.length > 0 && <FeatureUpdateModal />}
        </div>
      )
      
  )
}

export default DashboardLayout