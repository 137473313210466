import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fectchJournalToday, updateJournalToday } from '../../Components/action/common'
import {useDispatch} from 'react-redux'
import { setJournalList, updateJournalList } from '../../redux/slices/TaskSlice'

function JournalSection() {

  const dispatch=useDispatch()
  const {  dateChange  , journalList } = useSelector(state => state.task)
  const { theme} = useSelector(state => state.theme)
  const [gratitude,setGratitude]=useState({})

  function auto_grow(e, target) {
    const element = target ?? e.target;
    element.style.height = "5px";
    element.style.height = (element.scrollHeight) + "px";
  }


  const fetchJournalData = async () => {
    try {
      let response = await fectchJournalToday({ "date": moment(dateChange).startOf('date').toISOString() })
      dispatch(setJournalList(response?.data?.responseData ?? []));
      setGratitude(response?.data?.responseData?.[1] ?? {})
      setTimeout(() => document.querySelectorAll('.textarea-lines').forEach(element => auto_grow(null, element)), 1)
    } catch ({ response }) {
    }
  }

  const updateInCache = (e) => {
    const {name, value} = e.target;
    let requestBody = {
      [name]: value,
      "date": moment(dateChange).startOf('date').toISOString()
    }
    dispatch(updateJournalList(requestBody ?? {}));
  }


  const updateJournal = async (e) => {
    let gratitude = e?.target?.value
    let requestBody = {
      "gratitude": gratitude,
      "date": moment(dateChange).startOf('date').toISOString()
    }

    setGratitude(g => ({...g, gratitude}));

    try {
      let response = await updateJournalToday(requestBody);
      
    } catch ({ response }) {
    }
  }

  const updateJournalNotes = async (e) => {
    let notesData = e?.target?.value
    let requestBody = {
      "notes": notesData,
      "date": moment(dateChange).startOf('date').toISOString()
    }
    setGratitude(g => ({...g, notes: notesData}));
    
    try {
      let response = await updateJournalToday(requestBody)
    } catch ({ response }) {

    }
  }

  useEffect(() => {
    let x=journalList.find(o => o.date ===  moment(dateChange).startOf('date').toISOString())
    if(x == undefined){
      fetchJournalData()
    }else{
      setGratitude(x ?? {})
    }
    
    setTimeout(() => document.querySelectorAll('.textarea-lines').forEach(element => auto_grow(null, element)), 1)
  }, [ dateChange])

  useEffect(() => {
    let x=journalList.find(o => o.date ===  moment(dateChange).startOf('date').toISOString())
    setGratitude(x ?? {})
    
  }, [])


  return (
    <div className="task-list todayJournal" style={{background:theme == "dark" ? "black" : "#FFF"}}>
      
    <div className="list-tag-name">
      <label className="task-tag"><b>Journal</b> <span style={{color:theme == "dark" ? "white" : "" , opacity: theme == "dark" ? 0.3 :""}}>Reflect on your day</span></label>
    </div>
    <div className="list-tag-name textGratitude">
      <label className="task-tag sub-task-tag" style={{color:theme == "dark" ? "white" : ""}}>Gratitude</label>
      <div>
        <textarea name="gratitude" value={  gratitude?.gratitude ?? ''} style={{ minHeight: 'auto' ,color: theme == "dark" ? "white" :"black"}} placeholder='What are you grateful for today?' className={`journal-input gratitude textarea-lines ${theme == "dark" ? "textarea-lines-darkmode" :""}`} onInput={auto_grow}  onChange={(e) => { updateJournal(e); }} onBlur={updateInCache} ></textarea>
      </div>
    </div>
    <div className="list-tag-name textNotes">
      <label className="task-tag sub-task-tag" style={{color:theme == "dark" ? "white" : ""}}>Notes</label>
      <div>
        <textarea name="notes" placeholder='Any notes for the day?' style={{color: theme == "dark" ? "white" :"black"}} value={ gratitude?.notes ?? ''} className={`journal-input gratitude textarea-lines ${theme == "dark" ? "textarea-lines-darkmode" :""}`} onInput={auto_grow}  onChange={(e) => { updateJournalNotes(e); }} onBlur={updateInCache}></textarea>
      </div>
    </div>
  </div>
  )
}

export default JournalSection