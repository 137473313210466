import React, { useEffect, useState } from 'react'
import ChatGPTModal from './ChatGPTModal'

const AiAssistantModal = ({fullscreenButton = true}) => {
    const [chatGPTModal, setChatGPTModal] = useState(false);
    const isMac = navigator?.userAgent?.includes('Macintosh') ?? false;

    useEffect(() => {
        document.addEventListener('keydown', onPressCmd, false);
    
        return () => {
          document.removeEventListener('keydown', onPressCmd, false);
        }
    }, []);

    const onPressCmd = (e) => {
        // Check if either meta (Cmd) or ctrl is pressed, and the 'K' key is pressed
        if (((isMac && e.metaKey) || (!isMac && e.ctrlKey)) && e.key === 'k') {
            e.preventDefault();  // Prevent default browser behavior
            e.stopPropagation();  // Stop event propagation to prevent focusing the address bar
            setChatGPTModal(true);  // Open the popup/modal
        }
    }

    return (
        chatGPTModal && <ChatGPTModal fullscreenButton={fullscreenButton} onHide={() => setChatGPTModal(false)} />
    )
}

export default AiAssistantModal