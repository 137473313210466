import React from 'react'

function CalendarIcon({color, width = 16, height = 17}) {
  
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6667 3.16669H3.33333C2.59695 3.16669 2 3.76364 2 4.50002V13.8334C2 14.5697 2.59695 15.1667 3.33333 15.1667H12.6667C13.403 15.1667 14 14.5697 14 13.8334V4.50002C14 3.76364 13.403 3.16669 12.6667 3.16669Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.667 1.83331V4.49998" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.33301 1.83331V4.49998" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 7.16669H14" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CalendarIcon
