import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchSetting, setSetting } from '../../Components/action/common';
import { pushToSetting } from '../../redux/slices/SettingSclice';
import _ from 'lodash';

const QuickLinks = () => {
    const dispatch = useDispatch();
    const [links, setLinks] = useState([{link: 'https://meet.google.com/new'}, {link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'}, {link: 'https://mail.google.com/mail'}, {link: 'https://chat.openai.com/'}, {link: ''}, {link: ''}, {link: ''}, {link: ''}, {link: ''}, {link: ''}]);
    const { settings } = useSelector(state => state.settings)
    const { theme } = useSelector(state => state.theme)

    useEffect(() => {
       settings?.quickLinks && setLinks(settings.quickLinks);
    }, [])
    
    useEffect( () => {
        const newSettings = { ...settings, quickLinks: links };
        dispatch(pushToSetting(newSettings));
        setSetting({ "settings": newSettings })
    }, [links])

    const onLinkChange = (value, index) => {
        let ls = _.cloneDeep(links);
        ls[index].link = value;
        setLinks(ls);
    }
    
    return (
        <div className="inner-form">
            <div className='formProfile'>
                <form className='formProfile'>
                    {
                        links.map((link, index) => {
                            return (
                                <div key={index} className='field-block mt-30'>
                                    <label className="field-label" style={{ color: theme == "dark" ? "white" : "black" }}>Link {index + 1}</label>
                                    <div className='field-data'>
                                        <div className='filed-data-block'>
                                            <input type="text" class="input-field" name="name" onInput={(e) => onLinkChange(e?.target?.value, index)} value={link.link} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </form>
            </div>
        </div>
    )
}

export default QuickLinks