/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx } from "@emotion/core";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { setSetting } from '../../Components/action/common';
import { pushToSetting } from '../../redux/slices/SettingSclice';
import TimerSoundsDropdown from './TimerSoundsDropdown';

const FocusTimerSettings = ({theme, colorPicker}) => {
    const { settings } = useSelector(state => state.settings);
    const dispatch = useDispatch();

    // In secs
    const timerNudgeAmount = [
        { value: '300', label: '5 mins' },
        { value: '600', label: '10 mins' },
        { value: '900', label: '15 mins' },
        { value: '1200', label: '20 mins' },
    ];

    const handleChange = async (value, name) => {
        const newSettings = { ...settings, [name]: value?.value };
        dispatch(pushToSetting(newSettings));

        try {
          await setSetting({ "settings": newSettings })
        } catch ({ response }) { }
    }

    return (
        <div className='card-body' style={{ background: theme == "dark" ? "#1a1616" : "white" }} css={{
            '.general-setting__option--is-selected': {
                background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
            }
            }}>
            <div className="field-block">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "" }}>Focus timer default duration</label>
                <div className="field-data default-setting">
                  <input defaultValue={settings?.defaultTimePeriodFocus ?? 25} type="text" onInput={(e) => handleChange(e.target, "defaultTimePeriodFocus")} className='input-field filed-data-block testing-code-field' />
                </div>
            </div>
            <div className="field-block mt-30">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "" }}>Timer increment amount</label>
                <div className="field-data">
                    <div className="filed-data-block">
                    <Select
                        onChange={(value) => handleChange(value, "timerNudgeAmount")}
                        options={timerNudgeAmount}
                        value={
                            settings?.timerNudgeAmount ? timerNudgeAmount.filter(option =>
                            option.value === settings?.timerNudgeAmount) : timerNudgeAmount[0]
                        }
                        className="react-select"
                        constainerClass=""
                        classNamePrefix={"general-setting" || ""}
                    />
                    </div>
                </div>
            </div> 
            
            {/* <div className="field-block mt-30">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Break timer default duration</label>
                <div className="field-data default-setting">
                  <input defaultValue={settings?.defaultTimePeriodBreak ?? 5} type="text" onInput={(e) => handleChange(e.target, "defaultTimePeriodBreak")} className='input-field filed-data-block testing-code-field' />
                </div>
            </div> */}
            <TimerSoundsDropdown colorPicker={colorPicker} theme={theme} handleChange={handleChange} settings={settings} />
        </div>
    )
}

export default FocusTimerSettings