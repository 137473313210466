/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { useSelector } from 'react-redux'
import { jsx } from "@emotion/core";

function CalendarIcon() {
  const {theme}=useSelector(state=>state.theme)
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M11.0833 2.33325H2.91667C2.27233 2.33325 1.75 2.85559 1.75 3.49992V11.6666C1.75 12.3109 2.27233 12.8333 2.91667 12.8333H11.0833C11.7277 12.8333 12.25 12.3109 12.25 11.6666V3.49992C12.25 2.85559 11.7277 2.33325 11.0833 2.33325Z" stroke={`${theme == "dark" ? "white" : "black"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.3335 1.16675V3.50008"  stroke={`${(theme == "dark") ? "white" : "black"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.6665 1.16675V3.50008" stroke={`${theme == "dark" ? "white" : "black"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.75 5.83325H12.25" stroke={`${theme == "dark" ? "white" : "black"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export default CalendarIcon