import React, { useEffect } from 'react'
import { SITE_BASE_URL } from '../../Components/common/constants'

const Welcome = () => {

  useEffect(() => {
    document?.title && (document.title = `LifeTab`);
  }, [])

  return (
    <div className="main-body-wrap welcome-page boddyInner bg-white">
        <div className="page-wrapper">
            <div className="page-container">
                <div className='welcome-body'>
                    <h1>Welcome to LifeTab</h1>
                    <p>Your new life dashboard will appear in just a moment. We first need to get you signed up.</p>
                    <a href={`${SITE_BASE_URL}/signup`} className='btn'>Sign Up</a>
                    <a href={`${SITE_BASE_URL}/login`} className='link'>Already have an account? Click here to login.</a>
                </div>
            </div>
        </div>
        <img className="shadow" src='/images/welcome_shade.svg' />
    </div>
  )
}

export default Welcome