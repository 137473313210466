import React, { useState } from 'react'
import { setChat } from '../../redux/slices/AiAssistant';
import { EditIcon2, Trash } from '../LandingHeader/Feedback/icons/Icons';
import DeletedChat from './DeletedChat';
import moment from 'moment';
import EditChatTitle from './EditChatTitle';
import { useDispatch } from 'react-redux';
import ActionsDropdown from '../common/ActionsDropdown';

const HistoryItem = ({c, id, theme, colorPicker, deleteChat, disappearChat, undoChat}) => {
    const content = JSON.parse(c.conversation);
    const [editChatTitleId, setEditChatTitleId] = useState(null);
    const dispatch = useDispatch();

    const chatContent = (c, data) => {
        return c?.title ?? (data?.[data.length - 1].content ?? '');
    }

    const chatPersona = (data) => {
        return data?.[data.length - 1].sender ?? 'Default';
    }

    const changeConversation = (e, c) => {
        e.preventDefault();
        if(!e.target.closest('.actions-dropdown-container') && c.deletedAt === null && c.id != editChatTitleId){
            dispatch(setChat({data: JSON.parse(c.conversation), title: c.title, id: c.id, changed: true}));
            document.querySelector('.chatTextarea').focus();
        }
    }

    const editChatTitle = (e, id) => {
        e.preventDefault();

        setEditChatTitleId(id);
    }

    const hideEditChatTitle = (c) => {
        setEditChatTitleId(null);
        dispatch(setChat({data: JSON.parse(c.conversation), title: c.title, id: c.id}));
    } 

    return (
        <li key={c.id} className={`li-menus ${c.deletedAt === null ? '' : 'deleted-task'} ${editChatTitleId !== null && c.id == editChatTitleId ? 'editing' : ''}`} onClick={e => changeConversation(e, c)}>
            {
                c.deletedAt !== null
                ?
                <DeletedChat colorPicker={colorPicker} trigger={disappearChat} undoChat={undoChat} c={c} />
                :
                (
                    editChatTitleId !== null && c.id == editChatTitleId
                    ?
                    <EditChatTitle theme={theme} colorPicker={colorPicker} trigger={hideEditChatTitle} cancelAction={setEditChatTitleId} c={c} />
                    :
                    <a href="#" className={`${c.id == id ? 'active' : ''}`}>
                        <span className={`histry-title-name ${c.id == id ? 'darkPrimaryColor' : ''}`}>{chatContent(c, content)}</span>
                        <span className='date'>{moment(c.createdAt).fromNow()}</span>
                        <ActionsDropdown colorPicker={colorPicker} actions={[{
                            icon: <EditIcon2 />,
                            title: 'Rename Chat',
                            action: (e) => editChatTitle(e, c.id)
                        },{
                            icon: <Trash />,
                            title: 'Delete Chat',
                            action: (e) => deleteChat(e, c.id)
                        }]} />
                    </a>
                )
            }
        </li>
    )
}

export default HistoryItem