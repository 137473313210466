import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";


const initialState = {
  taskList: [],
  taskGroupedList: [],
  overDueTaskList: [],
  allTaskList: [],
  completedTaskList: [],
  trashTaskList: [],
  noDataTaskList: [],
  selectedDate: null,
  currentTask: 0,
  currentTaskData: {},
  dateChange: null,
  taskDaySelected: 'Today',
  headerName: null,
  totalCount: null,
  signUpEmail: null,
  signUpPassword: null,
  signUpName: null,
  isEmmailAlreadyExist: false,
  gratitude: null,
  notes: null,
  activeTask: null,
  editTaskSelected: 'Today',
  settings: {},
  quotesList: [],
  journalList: [],
  focusTasksList: [],
  tasksGroupBy: 'none',
  taskProjects: []
}

const TaskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTaskProjects(state, action){
      state.taskProjects = action.payload
    },
    setFocusTasksList(state, action){
      state.focusTasksList = action.payload
    },
    setJournalList(state, action) {
      state.journalList = action.payload
    },
    updateJournalList(state, action) {
      state.journalList = [...state.journalList].map(j => j?.date === action.payload?.date ? { ...j, ...action.payload} : j)
    },
    setQuotesList(state, action) {
      state.quotesList = action.payload
    },
    // setSettings(state, action) {
    //   state.settings = action.payload
    // },

    // pushToSetting(state, action) {
    //   state.settings = { ...state.settings, ...action.payload }
    // },

    setActiveTask(state, action) {

      state.activeTask = action.payload
    },
    setTaskList(state, action) {

      state.taskList = action.payload
    },
    setCompletedTasks(state, action) {
      state.taskList = [...(state.taskList.filter(task => task.status != 1)), ...action.payload]
    },
    pushSetTaskList(state, action) {
      if ((action.payload?.date !== null)) {
        state.taskList = [...state.taskList, action.payload]
        state.focusTasksList = [...state.focusTasksList].map(task => task.id == action.payload?.orgRepeatId ? action.payload : task);
      }
    },

    undoTaskSlice(state, action) {
      state.taskList = [...state.taskList].map(task => task?.id === action.payload?.id ? { ...task, deletedAt: null } : task)
    },

    undoUpcomingTaskSlice(state, action) {
      state.taskGroupedList = [...state.taskGroupedList].map(task => task?.id === action.payload?.id ? { ...task, deletedAt: null } : task)
    },

    undoOverDueTaskSlice(state, action) {
      state.overDueTaskList = [...state.overDueTaskList].map(task => task?.id === action.payload?.id ? { ...task, deletedAt: null } : task)
    },
    undoAllTaskSlice(state, action) {
      state.allTaskList = [...state.allTaskList].map(task => task?.id === action.payload?.id ? { ...task, deletedAt: null } : task)
    },
    undoNoDataTaskSlice(state, action) {
      state.noDataTaskList = [...state.noDataTaskList].map(task => task?.id === action.payload?.id ? { ...task, deletedAt: null } : task)
    },

    updateSetTaskList(state, action) {
      state.taskList = [...state.taskList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : task)
      state.focusTasksList =  [...state.focusTasksList].map(task => task.id == action.payload?.id ? { ...task, ...action.payload } : task);
      // const date = moment(action.payload?.date).format('YYYY-MM-DD')
      // const curentDate = moment(date).isBefore(moment().format('YYYY-MM-DD'))

      // // if ((action.payload) && (date == curentDate)) {
      //   if ((action.payload)) {

      //   const array = [...state.taskList];
      //   const index = array.findIndex((item) => item?.id === action.payload?.id);
      //   const obj = array.find((item) => item?.id === action.payload.id);
      //   const newObj = { ...obj, title: action?.payload?.title }
      //   array.splice(index, 1, newObj);
      //   state.taskList = array;
      // }
    },
    deleteSetTaskList(state, action) {
      if (action.payload) {
        state.taskList = [...state.taskList].map(task => task?.id === action.payload?.id || (action.payload?.recurring && action.payload?.recurring == 3 && action.payload?.orgRepeatId && task?.orgRepeatId === action.payload?.orgRepeatId) ? { ...task, deletedAt: (new Date()).toISOString() } : task)
        
        // const array = [...state.taskList];
        // const index = array.findIndex((item) => item?.id === action?.payload.id);
        // array.splice(index, 1);
        // state.taskList = [...array]
      }

    },
    updateStatus(state, action) {
      if (action.payload) {
        state.taskList = [...state.taskList].map(task => task?.id == action.payload?.id ? { ...task, status: action?.payload?.status, completedAt: action?.payload?.status == '1' ? (new Date()).toISOString() : task.completedAt } : task)
        state.focusTasksList =  [...state.focusTasksList].map(t => t.id == action.payload?.id ? { ...t, status: action?.payload?.status, completedAt: action?.payload?.status == '1' ? (new Date()).toISOString() : t.completedAt } : t);
      }
    },
    updateFocusTasksWithRecurringTask(state, action) {
      state.focusTasksList =  [...state.focusTasksList].map(t => t.id == action.payload.oldTask?.id ? action.payload.newTask : t);
    },

    updateMostImportantFlag(state, action) {
      if (action.payload) {

        state.taskList = [...state.taskList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : {...task, isMostImportant: action.payload.isMostImportant == '1' && action.payload.date == task.date ? 0 : task.isMostImportant});

        state.allTaskList = [...state.allTaskList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : {...task, isMostImportant: action.payload.isMostImportant == '1' && action.payload.date == task.date ? 0 : task.isMostImportant});


        state.overDueTaskList = [...state.overDueTaskList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : {...task, isMostImportant: action.payload.isMostImportant == '1' && action.payload.date == task.date ? 0 : task.isMostImportant});


        state.taskGroupedList = [...state.taskGroupedList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : {...task, isMostImportant: action.payload.isMostImportant == '1' && action.payload.date == task.date ? 0 : task.isMostImportant});

      }
    },

    // upcoming tasklist

    setGroupedList(state, action) {
      state.taskGroupedList = action?.payload ?? []
    },
    pushGroupedList(state, action) {
      state.taskGroupedList = [action.payload, ...state.taskGroupedList]
    },
    updateGroupedList(state, action) {
      if (action.payload) {
        state.taskGroupedList = [...state.taskGroupedList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : task)
        state.focusTasksList =  [...state.focusTasksList].map(task => task.id == action.payload?.id ? { ...task, ...action.payload } : task);
        // const array = [...state.taskGroupedList];
        // const index = array.findIndex((item) => item?.id === action.payload?.id);
        // const obj = array.find((item) => item?.id === action.payload.id);
        // const newObj = { ...obj, title: action?.payload?.title , date:action?.payload?.date}
        // array.splice(index, 1, newObj);
        // state.taskGroupedList = array;
      }
    },


    deleteGroupedList(state, action) {
      if (action.payload) {
        state.taskGroupedList = [...state.taskGroupedList].map(task => task?.id === action.payload?.id || (action.payload?.recurring && action.payload?.recurring == 3 && action.payload?.orgRepeatId && task?.orgRepeatId === action.payload?.orgRepeatId) ? { ...task, deletedAt: (new Date()).toISOString() } : task)
        // const array = [...state.taskGroupedList];
        // const index = array.findIndex((item) => item?.id === action?.payload.id);
        // array.splice(index, 1);
        // state.taskGroupedList = [...array]
      }
    },

    updateGroupedStatus(state, action) {
      if (action.payload) {

        // const array = state.taskGroupedList[action.payload?.groupDate]

        // const index = array.findIndex((item) => item?.id === action.payload?.id);
        // const obj = array.find((item) => item?.id === action.payload.id);
        // const newObj = { ...obj, status: action?.payload?.status }
        // array.splice(index, 1,);
        // state.taskList = [...array, newObj];

        const array = [...state.taskGroupedList];
        const index = array.findIndex((item) => item?.id === action.payload?.id);
        const obj = array.find((item) => item?.id === action.payload.id);
        const newObj = { ...obj, status: action?.payload?.status }
        array.splice(index, 1,);
        if (action.payload?.status == 1) {
          state.taskGroupedList = [...array, newObj];
        }

        if (action.payload?.status == 0) {
          state.taskGroupedList = [newObj, ...array];
        }

        state.focusTasksList =  [...state.focusTasksList].map(t => t.id == action.payload.id ? { ...t, status: action?.payload?.status, completedAt: action?.payload?.status == 1 ? (new Date()).toISOString() : t.completedAt } : t);
      }
    },

    // overdue task list
    setOverDueTaskList(state, action) {
      state.overDueTaskList = action?.payload ?? []
    },

    pushOverDueTaskList(state, action) {
      const date = moment(action.payload?.date).format('YYYY-MM-DD')
      const curentDate = moment(date).isBefore(moment().format('YYYY-MM-DD'))
      if (curentDate === true) {
        state.overDueTaskList = [action.payload, ...state.overDueTaskList]
      }

    },
    updateOverDueTaskList(state, action) {
      if (action.payload) {
        state.overDueTaskList = [...state.overDueTaskList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : task)

        // const array = [...state.overDueTaskList];
        // const index = array.findIndex((item) => item?.id === action.payload?.id);
        // const obj = array.find((item) => item?.id === action.payload.id);
        // const newObj = { ...obj, title: action?.payload?.title }
        // array.splice(index, 1, newObj);
        // state.overDueTaskList = array;
      }
    },
    updateOverDueTaskStatus(state, action) {
      if (action.payload) {
        const array = [...state.overDueTaskList];
        const index = array.findIndex((item) => item?.id === action.payload?.id);
        const obj = array.find((item) => item?.id === action.payload.id);
        const newObj = { ...obj, status: action?.payload?.status }
        array.splice(index, 1,);
        state.overDueTaskList = [...array];
      }
    },

    deleteOverDueTaskList(state, action) {
      if (action.payload) {
        state.overDueTaskList = [...state.overDueTaskList].map(task => task?.id === action.payload?.id || (action.payload?.recurring && action.payload?.recurring == 3 && action.payload?.orgRepeatId && task?.orgRepeatId === action.payload?.orgRepeatId) ? { ...task, deletedAt: (new Date()).toISOString() } : task)
        // const array = [...state.overDueTaskList];
        // const index = array.findIndex((item) => item?.id === action?.payload.id);
        // array.splice(index, 1);
        // state.overDueTaskList = [...array]
      }
    },

    // all task list
    setAllTaskList(state, action) {
      state.allTaskList = action?.payload ?? []
    },

    pushAllTaskList(state, action) {
      state.allTaskList = [action.payload, ...state.allTaskList]
    },
    updateAllTaskList(state, action) {

      if (action.payload) {
        state.allTaskList = [...state.allTaskList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : task)
        // const array = [...state.allTaskList];
        // const index = array.findIndex((item) => item?.id === action.payload?.id);
        // const obj = array.find((item) => item?.id === action.payload.id);
        // const newObj = { ...obj, title: action?.payload?.title }
        // array.splice(index, 1, newObj);
        // state.allTaskList = array;
      }
    },
    deleteAllTaskList(state, action) {
      if (action.payload) {
        state.allTaskList = [...state.allTaskList].map(task => task?.id === action.payload?.id || (action.payload?.recurring && action.payload?.recurring == 3 && action.payload?.orgRepeatId && task?.orgRepeatId === action.payload?.orgRepeatId) ? { ...task, deletedAt: (new Date()).toISOString() } : task)
        // const array = [...state.allTaskList];
        // const index = array.findIndex((item) => item?.id === action?.payload.id);
        // array.splice(index, 1);
        // state.allTaskList = [...array]
      }
    },
    updateAllTaskStatus(state, action) {
      if (action.payload) {
        const array = [...state.allTaskList];
        const index = array.findIndex((item) => item?.id === action.payload?.id);
        const obj = array.find((item) => item?.id === action.payload.id);
        const newObj = { ...obj, status: action?.payload?.status }
        array.splice(index, 1,);
        state.allTaskList = [...array, newObj];
        state.focusTasksList =  [...state.focusTasksList].map(t => t.id == action.payload.id ? { ...t, status: action?.payload?.status, completedAt: action?.payload?.status == 1 ? (new Date()).toISOString() : t.completedAt } : t);
      }
    },

    // completed task list
    setCompletedTaskList(state, action) {
      state.completedTaskList = action.payload
    },
    pushCompletedTaskList(state, action) {
      state.completedTaskList = [action.payload, ...state.completedTaskList]
    },
    updateCompleteTaskStatus(state, action) {

      if (action.payload) {
        // state.completedTaskList = [...state.completedTaskList].map(task => task?.id === action.payload?.id ? {...task, status: action?.payload?.status} : task)

        const array = [...state.completedTaskList];
        const index = array.findIndex((item) => item?.id === action.payload?.id);
        const obj = array.find((item) => item?.id === action.payload.id);
        const newObj = { ...obj, status: action?.payload?.status }
        array.splice(index, 1,);
        state.completedTaskList = [...array];
      }
    },

    restoreCompletedTaskSlice(state, action) {

      state.completedTaskList = [...state.completedTaskList].map(task => task?.id === action.payload?.id ? { ...task, status: action?.payload?.status } : task)

    },

    updateCompletedTaskList(state, action) {

      if (action.payload) {
        const array = [...state.completedTaskList];
        const index = array.findIndex((item) => item?.id === action.payload?.id);
        const obj = array.find((item) => item?.id === action.payload.id);
        const newObj = { ...obj, title: action?.payload?.title }
        array.splice(index, 1, newObj);
        state.completedTaskList = array;
      }
    },


    // trash task list
    setTrashTaskList(state, action) {
      state.trashTaskList = action.payload
    },

    undoTrashSlice(state,action){
      state.trashTaskList = [...state.trashTaskList , action.payload]
    },

    updateTrashTaskStatus(state, action) {
      if (action.payload) {
        const array = [...state.trashTaskList];
        const index = array.findIndex((item) => item?.id === action.payload?.id);
        const obj = array.find((item) => item?.id === action.payload.id);
        const newObj = { ...obj, status: action?.payload?.status }
        array.splice(index, 1);
        state.trashTaskList = [...array];
      }
    },


    // no data task list
    setNoDateTaskList(state, action) {
      if (action.payload?.date == null) {
        state.noDataTaskList = action?.payload ?? []
      }
    },
    pushNoDateTaskList(state, action) {
      if (action.payload?.date == null) {
        state.noDataTaskList = [action.payload, ...state.noDataTaskList]
      }
    },

    updateNoDateTaskList(state, action) {
      if (action.payload) {
        state.noDataTaskList = [...state.noDataTaskList].map(task => task?.id == action.payload?.id ? { ...task, ...action.payload } : task)
        // const array = [...state.noDataTaskList];
        // const index = array.findIndex((item) => item?.id === action.payload?.id);
        // const obj = array.find((item) => item?.id === action.payload.id);
        // const newObj = { ...obj, title: action?.payload?.title , date:action?.payload?.date }
        // array.splice(index, 1, newObj);
        // state.noDataTaskList = array;
      }
    },

    deleteNoDateTaskList(state, action) {
      if (action.payload) {
        state.noDataTaskList = [...state.noDataTaskList].map(task => task?.id === action.payload?.id || (action.payload?.recurring && action.payload?.recurring == 3 && action.payload?.orgRepeatId && task?.orgRepeatId === action.payload?.orgRepeatId) ? { ...task, deletedAt: (new Date()).toISOString() } : task)
        // const array = [...state.noDataTaskList];
        // const index = array.findIndex((item) => item?.id === action?.payload.id);
        // array.splice(index, 1);
        // state.noDataTaskList = [...array]
      }
    },
    updateNoDateTaskStatus(state, action) {
      if (action.payload) {
        const array = [...state.noDataTaskList];
        const index = array.findIndex((item) => item?.id === action.payload?.id);
        const obj = array.find((item) => item?.id === action.payload.id);
        const newObj = { ...obj, status: action?.payload?.status }
        array.splice(index, 1,);
        state.noDataTaskList = [...array, newObj];
      }
    },



    setSelectedDate(state, action) {
      state.selectedDate = action.payload
    },

    setCurrentTask(state, action) {
      state.currentTask = action.payload
    },

    setCurrentTaskData(state, action) {
      state.currentTaskData = action.payload
    },

    updateCurrentTaskStatus(state, action) {
      if (action.payload) {
        const array = [...state.currentTaskData];
        const index = array.findIndex((item) => item?.id === action.payload?.id);
        const obj = array.find((item) => item?.id === action.payload.id);
        const newObj = { ...obj, status: action?.payload?.status }


        array.splice(index, 1,);
        state.currentTaskData = [...array, newObj];
      }
    },

    setDateChange(state, action) {
      state.dateChange = action.payload
    },

    setHeaderName(state, action) {
      state.headerName = action.payload
    },

    setTotalCount(state, action) {
      state.totalCount = action.payload
    },

    setTaskDaySelected(state, action) {
      state.taskDaySelected = action.payload
    },

    setSignUpEmail(state, action) {
      state.signUpEmail = action.payload
    },
    setSignUpPassword(state, action) {
      state.signUpPassword = action.payload
    },
    setSignUpName(state, action) {
      state.signUpName = action.payload
    },


    setIsEmmailAlreadyExist(state, action) {
      state.isEmmailAlreadyExist = action.payload
    },

    setGratitudeData(state, action) {
      state.gratitude = action.payload
    },
    setNotesData(state, action) {
      state.notes = action.payload
    },


    setEditTaskSelected(state, action) {
      state.editTaskSelected = action.payload
    },

    setTasksGroupBy(state, action) {
      state.tasksGroupBy = action.payload
    }
  }
})


// reducers exports
export const {
  updateJournalList,
  setJournalList,
  setQuotesList,
  restoreCompletedTaskSlice,

  // setSettings,
  // pushToSetting,

  updateCompletedTaskList,
  undoTaskSlice,
  undoOverDueTaskSlice,
  undoUpcomingTaskSlice,
  undoAllTaskSlice,
  undoNoDataTaskSlice,

  setTaskList,
  pushSetTaskList,
  updateSetTaskList,
  deleteSetTaskList,
  updateStatus,

  setGroupedList,
  updateGroupedStatus,
  pushGroupedList,
  updateGroupedList,
  deleteGroupedList,

  setOverDueTaskList,
  pushOverDueTaskList,
  updateOverDueTaskList,
  updateOverDueTaskStatus,
  deleteOverDueTaskList,

  setAllTaskList,
  pushAllTaskList,
  updateAllTaskList,
  deleteAllTaskList,
  updateAllTaskStatus,

  setCompletedTaskList,
  pushCompletedTaskList,
  updateCompleteTaskStatus,

  setTrashTaskList,
  updateTrashTaskStatus,

  setNoDateTaskList,
  pushNoDateTaskList,
  updateNoDateTaskList,
  deleteNoDateTaskList,
  updateNoDateTaskStatus,

  setSelectedDate,

  undoTrashSlice,

  setCurrentTask,
  setCurrentTaskData,
  updateCurrentTaskStatus,

  setDateChange,

  setHeaderName,

  setTotalCount,

  setTaskDaySelected,


  setSignUpEmail,
  setSignUpPassword,
  setSignUpName,


  setIsEmmailAlreadyExist,

  setGratitudeData,
  setNotesData,
  setActiveTask,

  setEditTaskSelected,
  setColorPickerMode,

  updateMostImportantFlag,

  setFocusTasksList,

  setTasksGroupBy,

  updateFocusTasksWithRecurringTask,

  setCompletedTasks,

  setTaskProjects

} = TaskSlice.actions;

export default TaskSlice.reducer;