import React from 'react'
import { useSelector } from 'react-redux'

function Check() {
  const {colorPicker} =useSelector(state=>state.theme)
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3332 1L4.99984 8.33333L1.6665 5" stroke={colorPicker} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Check