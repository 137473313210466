/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useRef, useState } from 'react';
import { EyeDropperTinyIcon } from '../LandingHeader/Feedback/icons/Icons';
import BackgroundImageCard from "../common/BackgroundImageCard";
import { ChromePicker } from "react-color";
import { setColorPicker } from "../../redux/slices/ThemeSlice";
import { useDispatch } from "react-redux";

const EyeDropperColorPicker = ({onChange, imageSrc, color}) => {
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const contextRef = useRef(null);
  const [showImage, setShowImage] = useState(false);
  const [hoverColor, setHoverColor] = useState(color);
  const [colorPickerPopup, setColorPickerPopup] = useState(false);
  const dispatch = useDispatch();

  const handleMouseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowImage(true);
  };

  const selectColor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowImage(false);
    onChange(e, hoverColor);
    closeEyeDropper();
  }

  const pickColor = (e) => {
    // Get the mouse coordinates relative to the image
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const imageData = contextRef.current.getImageData(x, y, 1, 1).data;
    const color = `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`;
  
    setHoverColor(color);
  }

  const onPressEscape = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === 'Escape' || event.keyCode === 27) {
      setShowImage(false);
    }
  }

  const openEyeDropper = () => {
    document.body.classList.add('pick-color-eyedropper-open');
    window.addEventListener('mousemove', pickColor);
    window.addEventListener('keyup', onPressEscape);
  }

  const closeEyeDropper = () => {
    document.body.classList.remove('pick-color-eyedropper-open');
    window.removeEventListener('mousemove', pickColor);
    window.removeEventListener('keyup', onPressEscape);
    setColorPickerPopup(false)
  }

  const openColorPicker = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setColorPickerPopup(true)
  }

  const closeColorPicker = (e) => {
    if(!e.target.closest('.color-picker-popup')){
      setColorPickerPopup(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeColorPicker);
    return () => {
      document.removeEventListener('click', closeColorPicker);
    }
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current;

    if(showImage){
      const image = imageRef.current;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      contextRef.current = canvas.getContext('2d', { willReadFrequently: true });

      // Get the dimensions of the image
      const imgWidth = image.width;
      const imgHeight = image.height;
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate aspect ratios
      const imgAspectRatio = imgWidth / imgHeight;
      const canvasAspectRatio = canvasWidth / canvasHeight;

      let drawWidth, drawHeight;

      // Determine dimensions to cover the whole canvas while maintaining aspect ratio
      if (canvasAspectRatio > imgAspectRatio) {
        // Canvas is wider than image
        drawWidth = canvasWidth;
        drawHeight = drawWidth / imgAspectRatio;
      } else {
        // Canvas is taller than image
        drawHeight = canvasHeight;
        drawWidth = drawHeight * imgAspectRatio;
      }

      // Calculate position to center the image
      const xOffset = (canvasWidth - drawWidth) / 2;
      const yOffset = (canvasHeight - drawHeight) / 2;

      // Draw the image
      contextRef.current.drawImage(image, xOffset, yOffset, drawWidth, drawHeight);
      openEyeDropper();
    }else{
      closeEyeDropper();
    }

    return () => {
      closeEyeDropper();
    }
  }, [showImage])

  return (
    <>
      <a href="#" className="pick-color-eyedropper" onClick={openColorPicker} title="Pick Color"><EyeDropperTinyIcon /></a>
      <img ref={imageRef} src={imageSrc} crossOrigin="anonymous" style={{display: 'none'}} />
      {
        showImage
        ?
        <>
          <BackgroundImageCard color={hoverColor} showColorPalette={false} />
          <canvas ref={canvasRef} onClick={selectColor} css={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            width: '100%',
            height: '100%'
          }} />
        </> 
        :
        <div className='color-picker-popup' style={{display: colorPickerPopup ? 'block' : 'none'}}>
            <ChromePicker color={color} onChange={color => dispatch(setColorPicker(color?.hex))} onChangeComplete={color => onChange(null, color?.hex)} />
            <a href="#" className="pick-color-eyedropper" onClick={handleMouseClick} title="Pick Color"><EyeDropperTinyIcon /></a>
        </div>
      }
    </>
  );
};

export default EyeDropperColorPicker;
