import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { updatePassword, verifyCode } from '../Components/action/common'
import ResetPasswordNavigation from './ResetPasswordNavigation'

function VerificationCode() {
  const navigate = useNavigate()
  const [isLoader, setIsLoader] = useState(false)
  const { register, handleSubmit } = useForm()
  const [errorMsg, setErrorMsg] = useState("")

  const onSubmitCode = async (data) => {
    localStorage.setItem("code", data?.code)
    try {
      setIsLoader(true)
      let response = await verifyCode({ "code": data?.code })
      setIsLoader(false)
      navigate('/password-reset')
    } catch ({ response }) {
      setErrorMsg("That was the wrong code. Try again.")
      setIsLoader(false)
    }
  }
  return (
    <>
      <div className="main-body-wrap task-wrap inner-pages-wrap login-wrap password-reset">
        <header className="header">
          <div className="d-flex header-inner">
            <div className="site-logo">
              <a href="#" className="logo">LifeTab</a>
            </div>
            <div className="heder-midle">

            </div>
            <div className="header-right">

            </div>
          </div>
        </header>

        <div className="page-wrappers login">
          <div className="page-container">
            <div className="contentquoteSection">
              <div className="form-content">
                <div className="login-form">
                  <div className="slide-form">


                    <div className="slide-fields name-slide active">
                      <div className="login-block">
                        <form onSubmit={handleSubmit(onSubmitCode)}>
                          <span className="log-name text-uppercase color-green">Code</span>
                          <div className="input-block">
                            <label className="form-label">Now, enter your verification code. </label>
                            <input type="text" placeholder="Type your code here" autoFocus className="field-input" name="code" {...register("code", {
                              required: {
                                value: true,
                                message: "Code is required"
                              }
                            })} />
                          </div>
                          <div className="action-button">
                            <div className="error"><p>{errorMsg}
                            </p></div>
                          </div>
                         

                          <div className="action-button">
                            <button type="submit" className="submit-btn sb-btn" >{isLoader == true ? <span className="loaderTemp" style={{ borderColor: "white white transparent" }}></span> : <>Submit</>}</button>
                          </div>
                        </form>

                      </div>
                    </div>


                  </div>

                  <div className="form-navigation">

                    <div className="back-action" onClick={() => navigate('/email-sent')} ><a className="back-link" ><img src="./images/arrow-left.svg" alt='' /></a></div>


                  </div>
                  {/* <ResetPasswordNavigation isVerificationCode={true}/> */}


                </div>
              </div>
            </div>
            {/* End Quote block */}
          </div>
        </div>
      </div>



    </>
  )
}

export default VerificationCode