/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from 'react'
import { Add } from '../LandingHeader/Feedback/icons/Icons';

const TimeDropdown = ({theme, colorPicker, increaseDecreaseTimer}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const timeTabs = [[5, "5 mins"], [10, "10 mins"], [15, "15 mins"], [20, "20 mins"], [30, "30 mins"], [45, "45 mins"], [60, "1 hr"]];

    useEffect(()=>{
        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        }
    }, [])

    const closeDropdown = (e) => {
        if(!e.target?.closest('.time-dropdown-wrapper')){
            setShowDropdown(false);
        }
    }

    const toggleDropdown = (e) => {
        e.preventDefault();

        setShowDropdown(value => !value);
    }

    const toggleTimerTime = (e, tab) => {
        e.preventDefault();

        increaseDecreaseTimer(parseInt(tab[0]) * 60, 'increase', true)
    }

    return (
        <div className={`timer-calc-actions time-dropdown-wrapper ${theme}`} style={{position: 'relative'}}>
            <a href="#" className='ased actBtns' onClick={toggleDropdown}>
                <Add color={theme == "dark" ? '#fff' : '#000'} border={false} />
            </a>
            {
                showDropdown
                &&
                <div className="time-dropdown">
                    <ul>
                        {
                            timeTabs.map((tab, index) => {
                                return <li key={index}><a href="#" onClick={e => toggleTimerTime(e, tab)}  css={{
                                        '&:hover': {
                                            '&:before': {
                                                background: colorPicker,
                                                opacity: 0.3
                                            }
                                        }
                                    }}>
                                        {tab[1]}
                                    </a>
                                </li>
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

export default TimeDropdown