import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const initialState = {
  backgroundImages: []
}

const AdminSettingSlice = createSlice({
  name: 'admin-settings',
  initialState,
  reducers: {
    setBackgroundImages(state, action) {
      state.backgroundImages = action.payload
    },

    setDeleteImage(state, action) {
      state.backgroundImages = [...state.backgroundImages].filter(img => img.id != action.payload)
    },

    setDefaultImage(state, action) {
      state.backgroundImages = [...state.backgroundImages].map(img => img.id == action.payload ? {...img, isDefault: 1} : {...img, isDefault: 0})
    }
  }
})


// reducers exports
export const {setBackgroundImages, setDeleteImage, setDefaultImage} = AdminSettingSlice.actions;
export default AdminSettingSlice.reducer;
