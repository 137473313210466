import React, { useContext, useEffect, createContext } from 'react'
import { useLocation } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

// Create context
const PosthogContext = createContext();

// Create hook for consuming context
export function usePosthog() {
    const context = useContext(PosthogContext);
    if (!context) {
        throw new Error('usePosthog must be used within a PosthogProvider');
    }
    return context;
}

// Rename component to Provider
export function PosthogProvider({ 
    children,
    apiKey, 
    host = 'https://app.posthog.com'
}) {
    const location = useLocation();
    const { userProfile } = useContext(AuthContext);
    const distinctId = userProfile?.email || 'anonymous'
    const isExtension = window?.chrome?.storage ? true : false

    const getOSInfo = () => {
        const userAgent = navigator.userAgent;
        let os = 'unknown';
        let version = 'unknown';

        if (userAgent.indexOf("Win") !== -1) {
            os = "Windows";
            const matches = userAgent.match(/Windows NT (\d+\.\d+)/);
            version = matches ? matches[1] : 'unknown';
        } else if (userAgent.indexOf("Mac") !== -1) {
            os = "MacOS";
            const matches = userAgent.match(/Mac OS X (\d+[._]\d+)/);
            version = matches ? matches[1].replace('_', '.') : 'unknown';
        } else if (userAgent.indexOf("Linux") !== -1) {
            os = "Linux";
        }

        return { os, version };
    };

    const { os, version } = getOSInfo()

    const getBrowserInfo = () => {
        if (isExtension) {
            // Use Chrome extension API for more accurate version
            return {
                browser: 'Chrome',
                version: window?.chrome?.runtime?.getManifest()?.version || '0'
            };
        }

        // Fallback to user agent detection for web
        const userAgent = navigator.userAgent;
        let browser = 'unknown';
        let version = '0';

        if (userAgent.indexOf("Chrome") !== -1) {
            browser = "Chrome";
            const matches = userAgent.match(/Chrome\/(\d+\.\d+)/);
            version = matches ? matches[1] : '0';
        } else if (userAgent.indexOf("Firefox") !== -1) {
            browser = "Firefox";
            const matches = userAgent.match(/Firefox\/(\d+\.\d+)/);
            version = matches ? matches[1] : '0';
        } else if (userAgent.indexOf("Safari") !== -1) {
            browser = "Safari";
            const matches = userAgent.match(/Version\/(\d+\.\d+)/);
            version = matches ? matches[1] : '0';
        } else if (userAgent.indexOf("Edge") !== -1) {
            browser = "Edge";
            const matches = userAgent.match(/Edge\/(\d+\.\d+)/);
            version = matches ? matches[1] : '0';
        }

        return { browser, version };
    };

    const { browser, version: browserVersion } = getBrowserInfo();
    const browserLanguage = navigator.language || navigator.userLanguage || 'unknown';

    const captureClickEvent = (event) => {
        try {
            event.stopPropagation();
            const element = event.target;
            const tagName = element.tagName?.toLowerCase() || 'unknown';
            const elementText = element.textContent?.trim()?.substring(0, 100) || '';

            captureEvent(elementText.length > 0 ? `clicked ${tagName} with text "${elementText}"` : `clicked ${tagName}`);
        } catch (error) {
            console.error('Failed to capture click event:', error);
        }
    };

    const captureEvent = async (type = '$pageleave', properties = {}) => {
        try {
            const eventId = crypto.randomUUID()
            const timestamp = new Date().toISOString()
            const payload = {
                api_key: apiKey,
                event: type,
                properties: {
                    $current_url: window?.location?.href ?? '',
                    $pathname: location?.pathname ?? '',
                    $host: window?.location?.host ?? '',
                    $referrer: document?.referrer ?? '',
                    distinct_id: distinctId,
                    $lib: isExtension ? 'extension' : 'web',
                    $browser: browser,
                    $browser_version: parseInt(browserVersion),
                    $browser_language: browserLanguage,
                    $os: os,
                    $os_version: version,
                    $screen_width: window?.screen?.width || 0,
                    $screen_height: window?.screen?.height || 0,
                    $viewport_width: window?.innerWidth || 0,
                    $viewport_height: window?.innerHeight || 0,
                    ...properties,
                },
                timestamp,
                uuid: eventId,
            }

            if (isExtension) {
                // Use regular fetch for extensions
                await fetch(`${host}/capture/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
            } else {
                // Use sendBeacon for web apps
                const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
                navigator.sendBeacon(`${host}/capture/`, blob);
            }
        } catch (error) {
            console.error('Failed to capture page view:', error)
        }
    }

    useEffect(() => {
        // Capture initial pageview
        captureEvent('$pageview')

        // Capture click events
        //document.addEventListener('click', captureClickEvent);

        if (typeof window !== 'undefined') {
            // For web apps, use beforeunload
            if (!isExtension) {
                window.addEventListener('beforeunload', captureEvent)
                return () => {
                    window.removeEventListener('beforeunload', captureEvent)
                }
            }
            // For extensions, use visibilitychange
            else {
                const handleVisibilityChange = () => {
                    if (document.visibilityState === 'hidden') {
                        captureEvent()
                    }
                }
                document.addEventListener('visibilitychange', handleVisibilityChange)
                return () => {
                    document.removeEventListener('visibilitychange', handleVisibilityChange)
                }
            }
        }
    }, [location])

    // Create value object to share through context
    const value = {
        captureEvent
    };

    return (
        <PosthogContext.Provider value={value}>
            {children}
        </PosthogContext.Provider>
    );
} 