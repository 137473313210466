import React, { useEffect, useState, useRef } from 'react'
import { extractColors } from 'extract-colors'
import EyeDropperColorPicker from '../../ColorEyeDropper/EyeDropperColorPicker';
import { setColorPicker } from '../../../redux/slices/ThemeSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setSetting } from '../../action/common';
import { pushToSetting } from '../../../redux/slices/SettingSclice';
import _ from 'lodash';
import { Tick } from '../../LandingHeader/Feedback/icons/Icons';

const BackgroundImage = ({imageObject = null, location, showColorPalette = true, colorPicker = null, onColorChange = null, isAdmin = false }) => {
    const [colors, setColors] = useState([]);
    const [eyeDropperColor, setEyeDropperColor] = useState(colorPicker);
    const dispatch = useDispatch();
    const { settings } = useSelector(state => state.settings)
    const imgRef = useRef(null);

    useEffect(() => {
        if(location){
            const img = new Image;
            img.src = location;
            img.onload = () => {
                imgRef.current.src = img.src;
                imgRef.current.style.display = 'block';
            }
            if(showColorPalette){
                (async () => {
                    let colors;
                    if(imageObject?.palette){
                        colors = imageObject.palette.replaceAll('"', '').split(',').map(color => ({hex: color}));
                        console.log('colors', colors);
                    }else{
                        colors = await extractColors(location, {crossOrigin: 'anonymous'});
                        console.log('extractColors', colors);
                    }
                    
                    setColors(_.uniqBy([...(colors.slice(0, 5).map(color => ({hex: color.hex}))), {hex: colorPicker}], 'hex'))
                })();
            }
        }
    }, [location, showColorPalette]);

    useEffect(() => {
        if (eyeDropperColor) {
            setColors(colors => _.uniqBy([...(colors.slice(0, 5).map(color => ({hex: color.hex}))), {hex: eyeDropperColor}], 'hex'))
        }
    }, [eyeDropperColor])

    const handleChangeComplete = (e, color, eyeDropper = false) => {
        e?.stopPropagation();
        e?.preventDefault();

        // If this is admin setting and image is not default, then don't change the color
        if(isAdmin && !imageObject?.isDefault){
            return;
        }
        
        eyeDropper && setEyeDropperColor(color);
        dispatch(setColorPicker(color))
        const newSettings = { ...settings, accentColor: color };
        dispatch(pushToSetting(newSettings));
        setSetting({ settings: newSettings });

        onColorChange && onColorChange(color);
    }

    return (
        <div className='image-card' style={{position: 'unset'}}>
            <div className="image-container">
                {location && <img ref={imgRef} src={location} alt={`Uploaded`} style={{ display: 'none', width: '100%', height: '100%', objectFit: 'cover' }} />}
                <div className={`color-thumbnails`} style={{display: 'flex'}}>
                    {
                        colors.length > 0
                        ?
                        colors.map((color, index) => (
                            color.hex !== null && <a
                                key={index}
                                href="#"
                                className={`color-thumbnail`}
                                onClick={(e) => handleChangeComplete(e, color.hex)}
                                style={{
                                    backgroundColor: color.hex
                                }}
                                title={color.hex}
                            >
                                {color.hex === colorPicker && <Tick color="#fff" strokeWidth={2} />}
                            </a>
                        ))
                        :
                        null
                    }
                    {!isAdmin && location && eyeDropperColor && <EyeDropperColorPicker color={colorPicker} imageSrc={location} onChange={(e, color) => handleChangeComplete(e, color, true)} />}
                </div>
            </div>
        </div>
    )
}

export default BackgroundImage