import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { forgotPassword } from '../Components/action/common'
import ResetPasswordNavigation from './ResetPasswordNavigation'

function EmailSentToInbox() {
  const { handleSubmit, register, formState: { errors }, setValue } = useForm()
  let email = localStorage.getItem("loginEmail")
  const [isLoader, setIsLoader] = useState(false)
  const [isEmailSubmited, setIsEmailSubmited] = useState(false)
  const navigate = useNavigate()


  const onSubmitEmail = async (data) => {
    setIsEmailSubmited(true)
    try {
      setIsLoader(true)
      let response = await forgotPassword({ "email": data?.email })

      setIsLoader(false)
      localStorage.setItem("email", data?.email)
      navigate('/reset-password')
    } catch ({ response }) {
      setIsLoader(false)
    }
  }

  useEffect(() => {
    setValue("email", email)
  }, [])
  return (

    <>
      <div className="main-body-wrap task-wrap inner-pages-wrap login-wrap password-reset">
        <header className="header">
          <div className="d-flex header-inner">
            <div className="site-logo">
              <a href="#" className="logo">LifeTab</a>
            </div>
            <div className="heder-midle">

            </div>
            <div className="header-right">

            </div>
          </div>
        </header>

        <div className="page-wrappers login">
          <div className="page-container">
            <div className="contentquoteSection">
              <div className="form-content">
                <div className="login-form">
                  <div className="slide-form">
                    <div className="slide-fields email-slide active">

                      <div className="login-block ">
                        <form onSubmit={handleSubmit(onSubmitEmail)}>
                          <span className="log-name text-uppercase color-green">RESET PASSWORD</span>
                          <div className="input-block">
                            <label className="form-label">What is your email address? </label>
                            <input placeholder="the@game.com" name='email' {...register("email", {
                              required: {
                                value: true,
                                message: "Email is required"
                              },
                              pattern: {
                                value:
                                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                message: "Please enter valid email address",
                              },
                            })
                            } className="field-input emailAddress" />
                          </div>



                          <div className="action-button">
                            <button type="submit" className="login-submit sb-btn" style={{ cursor: "pointer" }} >{isLoader == true ? <span className="loaderTemp" style={{ borderColor: "white white transparent" }}></span> : <>Submit</>}</button>

                          </div>

                          <div className="nack-option">
                          </div>
                        </form>
                      </div>


                    </div>



                  </div>

                  <div className="form-navigation">
                    <div className="back-action" onClick={() => navigate('/signup')} ><a className="back-link" ><img src="./images/arrow-left.svg" alt='' /></a></div>


                  </div>

                  {/* <ResetPasswordNavigation isEmailSEnd={true} isEmailSubmited={isEmailSubmited}/> */}


                </div>
              </div>
            </div>
            {/* End Quote block */}
          </div>
        </div>
      </div>



    </>



  )
}

export default EmailSentToInbox