/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useRef, useState } from 'react'
import { ReloadIcon2 } from '../LandingHeader/Feedback/icons/Icons';

const DeletedChat = ({theme, trigger, undoChat, c, colorPicker}) => {
    const undoTimer = useRef(null);

    useEffect(() => {
        let width = 0;
        const obj = setInterval(() => {
            undoTimer.current.style.width = `${width++}%`;
            if(width >= 100){
                clearInterval(obj);
                trigger(c.id);
            }
        }, 50);

        return () => clearInterval(obj);
    }, [])

    return (
        <a href='#' className='deleted-chat'>
            <span className={`histry-title-name`}>[Chat Deleted]</span>
            <span className='button primary undo-chat' onClick={e => undoChat(e, c.id)}>Undo <ReloadIcon2 /></span>
            <div ref={undoTimer} className="undo-timer" css={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${colorPicker}`
            }}></div>
        </a>
    )
}

export default DeletedChat