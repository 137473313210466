import React from 'react'
import { useSelector } from 'react-redux'

function GeneralIcon({onMouseEnterGeneral , isActiveGeneral , isActiveSettings}) {
  const {theme , colorPicker} =useSelector(state=>state.theme)

  let strCol=(((theme == "dark" && colorPicker == "#ef233c")) && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "white" : 
  ((theme == "dark" && colorPicker == "#27cb9a") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "black" : 
  ((theme == "dark" && colorPicker == "#f8961e") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "black":
  ((theme == "dark" && colorPicker == "#ffca3a") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "black":
  ((theme == "dark" && colorPicker == "#4cc9f0") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "black":
  ((theme == "dark" && colorPicker == "#3a86ff") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "white":
  ((theme == "dark" && colorPicker == "#9b5de5") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "white":
  ((theme == "dark" && colorPicker == "#ef476f") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "white":
  "gray"


  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      {
        strCol ? <path d="M8.00016 15.1666C11.6821 15.1666 14.6668 12.1818 14.6668 8.49992C14.6668 4.81802 11.6821 1.83325 8.00016 1.83325C4.31826 1.83325 1.3335 4.81802 1.3335 8.49992C1.3335 12.1818 4.31826 15.1666 8.00016 15.1666Z" stroke={strCol} strokeLidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> :
        <path d="M8.00016 15.1666C11.6821 15.1666 14.6668 12.1818 14.6668 8.49992C14.6668 4.81802 11.6821 1.83325 8.00016 1.83325C4.31826 1.83325 1.3335 4.81802 1.3335 8.49992C1.3335 12.1818 4.31826 15.1666 8.00016 15.1666Z" stroke={`${((theme == "dark" && onMouseEnterGeneral) || isActiveGeneral)? "black" : "gray"}`} strokeLidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      }
      {
        strCol ? <path d="M5.3335 9.83325C5.3335 9.83325 6.3335 11.1666 8.00016 11.1666C9.66683 11.1666 10.6668 9.83325 10.6668 9.83325" stroke={strCol} strokeLidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
       : <path d="M5.3335 9.83325C5.3335 9.83325 6.3335 11.1666 8.00016 11.1666C9.66683 11.1666 10.6668 9.83325 10.6668 9.83325" stroke={`${((theme == "dark" && onMouseEnterGeneral) || isActiveGeneral)? "black" : "gray"}`} strokeLidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      }
      {
        strCol ?  <path d="M6 6.5H6.00667" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        : <path d="M6 6.5H6.00667" stroke={`${((theme == "dark" && onMouseEnterGeneral) || isActiveGeneral)? "black" : "gray"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      }
      {
        strCol ? <path d="M10 6.5H10.0067" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        :   <path d="M10 6.5H10.0067" stroke={`${((theme == "dark" && onMouseEnterGeneral) || isActiveGeneral)? "black" : "gray"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      }

    </svg>
  )
}

export default GeneralIcon