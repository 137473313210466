/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Cancel, CirclePlusIconWithoutStroke, PlayRound, TargetIcon } from '../../../Components/LandingHeader/Feedback/icons/Icons';
import TimerOptions from "../../../Components/FocusTimer/TimerOptions";

import moment from "moment";
import { setFocusTasksList } from "../../../redux/slices/TaskSlice";
import { CheckFilledIcon, CirclePlusIcon } from "../../../Components/LandingHeader/Feedback/icons/Icons";
import { setTimerFullscreen, startTimer } from "../../../redux/slices/timer";
import { arrayMoveImmutable } from 'array-move';
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import EditTaskList from "../../AddTasks/EditTaskList";
import { decodeToken } from "react-jwt";
import { useSocket } from "../../../SocketContext";
import { verifyRepeatedTask } from "../../../Components/action/utilities";
import TaskSourceIcon from "../../Tasks/TaskSourceIcon";
import AddTaskStatic from "../../AddTasks/AddTaskStatic";
import DragIcon from "../../Tasks/DragIcon";
import RecurringIcon from "../../Tasks/RecurringIcon";
import { useLocation } from "react-router-dom";
import Tooltip from "../../../Components/common/Tooltip";
import ProjectTagIcon from "../../Tasks/ProjectTagIcon";
import { usePosthog } from "../../../PosthogProvider";

const DragHandle = sortableHandle(() => <span className="dragdrop-option"><DragIcon /></span>);

const SortableItem = sortableElement(({children, theme, colorPicker, editTaskHandler, task}) => (
  <li className="task-item li-task-list hr-line pad-20-40"
      css={{
        background: theme == "dark" ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),#000000 !important;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`,
        'span.title': {
          color: theme == "dark" ? "white !important" : "black !important"
        },
      }}

      onClick={e => editTaskHandler(e, task.id)}
  >
    <DragHandle />
    {children}
  </li>
));

const SortableContainer = sortableContainer(({children}) => {
    
    return (
      <ul className="ul-task-list ul-task-list-focused">{children}</ul>
    )
});

const FocusModeSettingsModal = ({todaySessions = 0, show = true, onHide}) => {
    const { themeColor, colorPicker, theme } = useSelector(state => state.theme);
    const {focusTasksList, taskList} = useSelector(state => state.task);
    const [isMounted, setIsMounted] = useState(false);
    const [submit, setSubmit] = useState(false);
    // const [showPopup, setShowPopup] = useState(false);
    const [editTask, setEditTask] = useState(null);
    const [updatedValue, setUpdatedValue] = useState(null);
    const dispatch = useDispatch();
    const socket = useSocket();
    const location = useLocation();
    const { captureEvent } = usePosthog();

    const [todayTasks, setTodayTasks] = useState(location?.hash == '#added' ? [...focusTasksList].filter(task => !task.deletedAt && task.status != 1 && verifyRepeatedTask(task, moment().toISOString(), moment(task?.date).startOf('date').toISOString() == moment().startOf('date').toISOString())) : []);

    useEffect(() => {
        const pendingTasks = taskList.filter(t => t.status != 1 && !t.deletedAt && verifyRepeatedTask(t, moment().toISOString(), moment(t?.date).startOf('date').toISOString() == moment().startOf('date').toISOString()));
        
        if(pendingTasks?.length > 0){
            const newTasks = pendingTasks.filter(a => !todayTasks.map(b=>b.id).includes(a.id)).map(task => (!isMounted ? task : {...task, focus: 1}));
            setTodayTasks([...newTasks, ...todayTasks]);
        }
        setIsMounted(true);
    }, [taskList])

    useEffect(() => {
        if(updatedValue !== null){
            setTodayTasks(tasks => tasks.map(task => task.id == updatedValue.id ? {...task, title: updatedValue.title} : task));
        }
    }, [updatedValue])

    useEffect(() => {
        const focusTasks = todayTasks.filter(task => task?.focus === 1);
        setSubmit(focusTasks.length > 0);
        dispatch(setFocusTasksList(focusTasks));
        triggerWebSocket({ action: "focusTasksList", tasks: focusTasks })
    }, [todayTasks])

    const addToFocusList = (e, task) => {
        e.preventDefault();

        setTodayTasks(todayTasks => todayTasks.map(t => t.id != task.id ? t : {...task, focus: 1}));
    };

    const removeFocusTask = (e, task) => {
        e.preventDefault();

        setTodayTasks(todayTasks => todayTasks.map(t => t.id != task.id ? t : {...task, focus: 0}));
    }

    const startTimerHandler = (e) => {
        e.preventDefault();
        if(submit){
            dispatch(startTimer('focusMode'));
            if (typeof window.chrome != 'undefined' && window?.chrome?.storage) {
                window.chrome.runtime?.sendMessage({ action: 'focus-timer-store-tabId' });
            }
            captureEvent('Focus Session Started')
            onHide(e);
        }
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        setTodayTasks(arrayMoveImmutable(todayTasks, oldIndex, newIndex))
    };

    const editTaskHandler = (e, id) => {
        if(!e.target?.closest('.task-action'))
        setEditTask(id);
    }

    const triggerWebSocket = (data) => {
        // Trigger timer in wepapp and other clients
        if (socket !== null){
            const user = decodeToken(localStorage.getItem('token'));
            socket.emit('focusTimer', {room: user['outseta:accountUid'], data});
        }
    }

    return (
        <Modal show={show} onHide={onHide} className="task-modal focus-mode-settings-modal" css={{
            '.darkPrimaryColor': {
                background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker} !important`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
            },
            '.darkPrimaryColor20': {
                background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), ${colorPicker} !important`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
            },

            '.task-action-focus':{
                'svg': {
                    width: '32px',
                    height: '32px'
                }
            },
            '.type-header':{
                '&.hr-line':{
                    '&::after':{
                        left: '20px !important'
                    }
                }
            }
        }}>
            <Modal.Body>
            <div className="full-model md-model-updates" style={{ display: 'block' }}>
                <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                    <div className="body-data" css={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '90vh',
                        overflow: 'hidden'
                    }}>
                        <div className='ctTitle relative'>
                            <TargetIcon color={colorPicker} fill={colorPicker} />
                            <span className='darkPrimaryColor'>Focus Session {todaySessions.length + 1}</span>
                            <div className="d-flex justify-center align-center gap-10 mr-0 m-auto">
                                <span className="font font-family inter size-14 opacity d5 mr-10">{focusTasksList.length > 0 && `${focusTasksList.length} Task${focusTasksList.length > 1 ? 's' : ''}`}</span>
                                <a href="#" className="button primary" onClick={onHide}><Cancel color={colorPicker} fill={colorPicker} />Cancel</a>
                                {
                                    submit
                                    ?
                                    <a href="#" className={`button primary text-white`} onClick={startTimerHandler} css={{
                                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${colorPicker}`
                                    }}><PlayRound color="#fff" dark={false} />Start Session</a>
                                    :
                                    <Tooltip text="Please add at least one task before starting your focus session." wrapperClassName="focus-settings-tooltip" className='overDue-tooltip' color={colorPicker}>
                                        <a href="#" className={`button primary text-white tooltip-hover opacity d2`} onClick={startTimerHandler} css={{
                                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${colorPicker}`
                                        }}><PlayRound color="#fff" dark={false} />Start Session</a>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                        <div className="focus-mode-settings-time-options">
                            <TimerOptions /> 
                        </div>
                        <div className="pad-0-20 padding top-20"><AddTaskStatic /></div>
                        <div className="focus-session-tasks pad-20-0 padding top-0 overflow y-auto x-hidden">
                            <SortableContainer onSortEnd={onSortEnd} helperContainer={document.querySelector('.ul-task-list-focused')} useDragHandle>
                                {todayTasks.map((task, index) => (
                                    task?.focus === 1
                                    ?
                                    <React.Fragment key={task.id}>
                                    {
                                        (
                                            editTask === task.id
                                            ?
                                            <EditTaskList hideActions={true} item={task} setUpdatedValue={setUpdatedValue} setEditTaskId={setEditTask} />
                                            :
                                            <SortableItem key={`item-${task.id}`} index={index} colorPicker={colorPicker} theme={theme} editTaskHandler={editTaskHandler} task={task}>
                                            <>
                                                <span
                                                    className="title font-family inter size-16"
                                                    css={{
                                                        color: theme == "dark" ? "white !important" : "black !important",
                                                    }}
                                                >{task?.title}
                                                <div className="d-flex align-center justify-start gap-10">
                                                <TaskSourceIcon task={task} colorPicker={colorPicker} />
                                                <RecurringIcon task={task} colorPicker={colorPicker} />
                                                <ProjectTagIcon task={task} />
                                                </div>
                                                </span>
                                                <a href="#" className="task-action task-action-focus" onClick={e => removeFocusTask(e, task)}><CheckFilledIcon color={colorPicker} /></a>
                                            </>
                                            </SortableItem>
                                        )
                                    }
                                    </React.Fragment>
                                    :
                                    null
                                ))}
                                </SortableContainer>
                                <ul className="ul-task-list">
                                {
                                    todayTasks.map((task, index) => (
                                        task?.focus !== 1
                                        ?
                                        <React.Fragment key={task.id}>
                                            {
                                            editTask === task.id
                                            ?
                                            <EditTaskList hideActions={true} item={task} setUpdatedValue={setUpdatedValue} setEditTaskId={setEditTask} />
                                            :
                                            <li key={task.id} className={`task-item li-task-list hr-line pad-20-40 ${task.id}`}
                                                css={{
                                                    '&:hover': {
                                                    background: theme == "dark" ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),#000000 !important;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`,
                                                    },
                                                    '&:hover span.title': {
                                                    color: theme == "dark" ? "white !important" : "black !important"
                                                    },
                                                }}

                                                onClick={e => editTaskHandler(e, task.id)}
                                            >
                                                <span
                                                    className="title font-family inter size-16"
                                                    css={{
                                                        color: theme == "dark" ? "white !important" : "black !important",
                                                    }}
                                                >{task?.title}
                                                <div className="d-flex align-center justify-start gap-10">
                                                <TaskSourceIcon task={task} colorPicker={colorPicker} />
                                                <RecurringIcon task={task} colorPicker={colorPicker} />
                                                <ProjectTagIcon task={task} />
                                                </div>
                                                </span>
                                                <a href="#" className="task-action" onClick={e => addToFocusList(e, task)}><CirclePlusIconWithoutStroke color={colorPicker} fill={colorPicker} /></a>
                                            </li>
                                            }
                                        </React.Fragment>
                                        :
                                        null
                                    ))
                                }
                             </ul>
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default FocusModeSettingsModal