/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from "@emotion/core";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { stopTimer } from '../../redux/slices/timer';
import BackgroundImage from '../common/BackgroundImage';
import { focusTimerFormatV2 } from '../action/utilities';
import {ArrowLeft} from '../LandingHeader/Feedback/icons/Icons';

function TimerCompleted() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeColor, theme, colorPicker } = useSelector(state => state.theme);
  const { timer, additionalTimer, activeTimer } = useSelector(state => state.focusTimer);

  useEffect(() => {
    if(activeTimer === null){
      navigate(process.env.REACT_APP_ROOT)
    }
  }, [])

  const exit = (e) => {
    e.preventDefault();

    dispatch(stopTimer());
    navigate(process.env.REACT_APP_ROOT); 
  }

  return (
    <div className={`main-body-wrap timerComplete ${theme}`} 
    css={{
      background: colorPicker
    }}>
        <div className="page-wrapper">
          <div className="page-container">
            <div className="slide-fields welcome-slide active">
              <div className="login-block">
                <div className="input-block">
                  <h3 className="h3-label" style={{color: 'white'}}>Timer Complete</h3>
                  <p className="text-label" style={{color: 'white'}}>You were focused for&nbsp;<span dangerouslySetInnerHTML={{ __html: focusTimerFormatV2(timer + additionalTimer) }}></span></p>
                  <div className="logins-actions">
                    <div className="logins-button">
                    {/* <a className="google-btn" style={{color: '#fff'}} css={{background: colorPicker}} onClick={exit}>Take a Break</a> */}
                    <a className="login-submit sb-btn back-to-home-btn" style={{color: '#808080', background: '#fff', border: '1px solid #fff'}} onClick={exit}><ArrowLeft /> Back to today</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='backgroundBottom'>
          <BackgroundImage color="white" height={979} />
        </div>
    </div>
  )
}

export default TimerCompleted
