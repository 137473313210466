import _ from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions';
import { FolderIcon, PlusIcon } from '../LandingHeader/Feedback/icons/Icons';

const TaskInputField = forwardRef(({id = 'inputField', projects = [], taskTitle, setTaskTitle, handleKeyPress, setFocus, currentPage, colorPicker, addTaskOnupComingFocus, onDateChage, addTaskOnFocus, classNames = '', onChange = () => {}}, ref) => {
    const mentionStyle = {
        input: {
          border: 0
        },
        textarea: {
          border: 0,
          lineHeight: '1.5em'
        },
        control: {
          fontSize: 16,
          minHeight: '18px',
          lineHeight: '1.5em'
        },
        suggestions: {
          backgroundColor: "transparent",
          item: {
            color: "#000",
            padding: "12px 20px",
            fontFamily: "Inter",
            fontSize: "14px",
            lineHeight: "14px",
            "&focused": {
              backgroundColor: colorPicker,
              color: "#fff",
              svg: {
                path: {
                  stroke: "#fff"
                }
              }
            },
          },
        },
    };

    function getMentionTags() {
        const items = [].map((t) => {
          return {
            id: t.name,
            display: t.name,
          };
        });
        const regex = /#(\w+)/g;
    
        let match;
    
        while ((match = regex.exec(taskTitle))) {
          const isAlready = items.find((i) => i.display === match[1]);
          if (!isAlready) {
            items.push({
              id: null,
              display: match[1],
            });
          }
        }
        return items;
    }
     
    function getProjectMentions(query) {
        const items = projects?.filter(t => 
          t?.label?.toLowerCase().startsWith(query?.toLowerCase())
        )?.map((t) => ({
          id: t?.value?.toLowerCase(),
          display: t?.label,
        }));
        const regex = /@(\w+)/ig;
    
        let match;
    
        while ((match = regex.exec(taskTitle))) {
          const isAlready = items.find((i) => i.display.toLowerCase() === match[1].toLowerCase());
          if (!isAlready) {
            items.push({
              id: null,
              display: match[1],
            });
          }
        }
        return items;
    }
    
    const suggestionTags = getMentionTags();
    // const suggestionProjects = getProjectMentions();

    return (
        <MentionsInput
            id={id}
            focusIndex={4}
            onFocusChange={(e) => {console.log(e)}}
            inputRef={ref}
            value={taskTitle}
            singleLine={false}
            onChange={(e, newVal, newPlain, mentions) => {
              // Remove @ or # from the beginning
              const lastDisplayValue = _.get(_.last(mentions), 'display', '');
              onChange(lastDisplayValue);
              setTaskTitle(newVal);
            }}
            placeholder="Add a Task..."
            className={classNames}
            onKeyDown={handleKeyPress}
            autoFocus={setFocus} 
            autoComplete="off"
            onFocus={() => { addTaskOnupComingFocus && !setFocus && (currentPage == "upComing" ? addTaskOnupComingFocus(onDateChage) : addTaskOnFocus()) }}
            style={mentionStyle}
            isLoading={true}
            customSuggestionsContainer={(children)=><div className='suggestions-list'>{children}</div>}
        >
            <Mention
                trigger="#"
                markup="#[__display__]"
                data={suggestionTags}
                renderSuggestion={(suggestion) => {
                return (
                    <div className="w-40 px-2 py-1">{suggestion.display}</div>
                );
                }}
                className="tags-mention outline-none border stroke-0"
                style={{
                    background: `linear-gradient(0deg,rgba(255,255,255,0.70) 0%,rgba(255,255,255,0.70) 100%), ${colorPicker}`,
                    borderRadius: "4px"
                }}
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => "#" + display}
            />
            <Mention
                trigger="@"
                markup="@[__display__]"
                data={getProjectMentions}
                renderSuggestion={(suggestion) => {
                  return (
                      <div className="w-40 px-2 py-1 d-flex align-center justify-start gap-5 line height-12">
                        {
                          suggestion.id
                          ?
                          <><FolderIcon width={15} height={15} color="black" fill="none" />{suggestion.display}</>
                          :
                          <><PlusIcon width={15} height={15} color="black" fill="none" strokeWidth={2} opacity={0.5} /> <span className='font italic'>Create `{suggestion.display}` project</span></>
                        }
                      </div>
                  );
                }}
                className="project-mention outline-none border stroke-0"
                style={{
                    background: `linear-gradient(0deg,rgba(255,255,255,0.70) 0%,rgba(255,255,255,0.70) 100%), ${colorPicker}`,
                    borderRadius: "4px"
                }}
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => "@" + display}
            />
        </MentionsInput>
    )
})

export default TaskInputField