import React, { useState } from 'react'
import ChatGPTModal from '../../pages/Settings/Modal/ChatGPTModal'
import { AIChatIcon, CmdKeyIcon } from "./Feedback/icons/Icons"

const AIAssistantInput = ({colorPicker}) => {
    const [chatGPTModal, setChatGPTModal] = useState(false);
    const isMac = navigator?.userAgent?.includes('Macintosh') ?? false;

    return (
        <>
            <div className="ai-assistant-shortcut parts" onClick={e => setChatGPTModal(true)}>
                <AIChatIcon width={12} height={13} color={colorPicker} />
                <span style={{opacity: 0.4}} className="darkPrimaryColor">Ask AI</span>
                <span className="shortcut">{isMac ? <CmdKeyIcon /> : 'Ctrl'} K</span>
            </div>
            {
                chatGPTModal && <ChatGPTModal onHide={() => setChatGPTModal(false)} />
            }
        </>
    )
}

export default AIAssistantInput