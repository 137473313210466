import axios from "axios"

export const fetchProjectList = (params = {}) => {
    return axios({
      method: 'GET',
      url: '/projects',
      params
    })
}

export const deleteProject = (id, includeTask = false) => {
    return axios({
        method: 'DELETE',
        url: `/projects`,
        data: {
            id,
            includeTask
        }
    })
}

export const updateProject = (id, title) => {
    return axios({
        method: 'PUT',
        url: `/projects`,
        data: {
            id,
            title
        }
    })
}

export const createProject = (title) => {
    return axios({
        method: 'POST',
        url: `/projects`,
        data: {title}
    })
}
