/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx } from "@emotion/core";
import { Modal } from 'react-bootstrap';
import AddTask from '../../AddTasks/AddTask';
import { useSelector } from 'react-redux';
import EditTaskList from '../../AddTasks/EditTaskList';
import { useParams } from 'react-router-dom';

const TaskModal = React.memo(({ onHide, data, show, currentPage, groupDate = "", groupKey = null }) => {
    const { theme } = useSelector(state => state.theme);
    const focusTimerState = useSelector(state => state.focusTimer);
    const {projectTitle, id} = useParams();

    return (
        <Modal show={show} onHide={onHide} className="task-modal" style={{transform: 'none', top: '150px'}}>
            <Modal.Body className='modal-body-extra-padding'>
            <div className="full-model md-model-updates" style={{ display: 'block' }}>
                <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                    <div className="body-data">
                        {
                            data === null
                            ?
                            <AddTask onHide={onHide} setFocus={true} currentPage={currentPage} focusTimerState={focusTimerState} groupDate={groupDate} groupKey={groupKey ?? (projectTitle ?? null)} projectId={id} />
                            :
                            <EditTaskList setEditTaskId={onHide} item={data} projectId={id} />
                        }
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
})

export default TaskModal