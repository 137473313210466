/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState, useRef } from 'react'
import { extractColors } from "extract-colors";
import EyeDropperColorPicker from "../ColorEyeDropper/EyeDropperColorPicker";
import { useDispatch } from "react-redux";
import { setColorPicker } from "../../redux/slices/ThemeSlice";
import { ArrowLeft, Checkbox, Tick } from "../LandingHeader/Feedback/icons/Icons";
import _ from "lodash";

const BackgroundImageCard = React.memo(({color = '#ffffff', location = null, showColorPalette = true, onClick = null, onCancel = null, onApply = null, className = '', id = 0, backgroundImageId = null, showEyeDropper = false, imageObject = null}) => {
    const [colors, setColors] = useState([]);
    const dispatch = useDispatch();
    const imageRef = useRef(null);

    useEffect(() => {
        if(!showEyeDropper)
            return;
        
        document.body.classList.add('pick-color-preview-open');

        return () => {
            document.body.classList.remove('pick-color-preview-open');
        }
    }, [showEyeDropper]);

    useEffect(() => {
        if(location && showColorPalette){
            (async () => {
                let colors;
                if(imageObject?.palette){
                    colors = imageObject.palette.replaceAll('"', '').split(',').map(color => ({hex: color}));
                }else{
                    colors = await extractColors(location, {crossOrigin: 'anonymous'});
                }
                
                const arraySize = showEyeDropper ? 4 : 5;
                id === backgroundImageId || showEyeDropper
                ?
                setColors(_.uniqBy([...(colors.slice(0, arraySize).map(color => ({hex: color.hex}))), {hex: color}], 'hex'))
                :
                setColors(colors.slice(0, arraySize))

                setTimeout(() => {
                    imageRef.current.style.backgroundImage = onClick && location ? `url("${location}")` : 'none'
                }, 100);
            })();
        }

    }, [location, showColorPalette]);

    const handleChangeComplete = (e, color) => {
        e?.stopPropagation();
        e?.preventDefault();
        dispatch(setColorPicker(color))
    }

    return (
        <div className={`background-image-card ${className}`}>
            <div className="background-image-card-image" ref={imageRef} css={{
                backgroundColor: colors?.[0]?.hex ?? color,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }} onClick={e => onClick && onClick(colors?.[0]?.hex)}>
                {
                    onClick !== null
                    &&
                    (
                        id === backgroundImageId
                        ?
                        <span className="button primary bg-white"><Tick /> Selected</span>
                        :
                        (
                            location
                            ?
                            <button className="button primary bg-white preview-button">Preview</button>
                            :
                            null
                        )
                    )
                }
                {
                    onCancel !== null
                    ?
                    <button className="button primary bg-white" onClick={e => onCancel()}><ArrowLeft color="#000" /> Back</button>
                    :
                    null
                }
                {
                    onApply !== null && showEyeDropper
                    ?
                    <button className="button primary bg-white" onClick={e => onApply(e, color)}><Checkbox width={14} height={14} opacity={0.5} /> Select</button>
                    :
                    null
                }
            </div>
            {
                showColorPalette
                &&
                <div className="background-image-colors">
                {
                    colors.length > 0
                    ?
                    colors.map((c, index) => (
                        <a
                            key={index}
                            href={`#`}
                            className="color-thumbnail"
                            onClick={(e) => showEyeDropper ? handleChangeComplete(e, c.hex) : onApply(e, c.hex)}
                            style={{
                                backgroundColor: c.hex
                            }}
                            title={c.hex}
                        >
                            {c.hex === color && <Tick color="#fff" strokeWidth={2} />}
                        </a>
                    ))
                    :
                    null
                }
                {location && showEyeDropper && <EyeDropperColorPicker color={color} imageSrc={location} onChange={handleChangeComplete} />}
                </div>
            }
            
        </div>
    )
})

export default BackgroundImageCard