import React from 'react'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import _ from "lodash"
import { ChatIcon } from '../LandingHeader/Feedback/icons/Icons';

function AiChatHistory({ loop, highlightColor = '#d3d3d3' }) {
  return (
    <SkeletonTheme baseColor="#fff" highlightColor={highlightColor}>                 
      {_.times(loop !== undefined ? loop : 7, (key) => {
        return (
            <li key={key}>
                {
                    (
                        <>
                            <div className='histry-title'>
                                <span className='histry-title-name'><s><Skeleton height={"30px"} width= "320px" /></s></span>
                            </div>
                        </>
                    )
                }
            </li>
        );
      })}
    </SkeletonTheme>
  );
}

export default AiChatHistory