/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { useSelector } from 'react-redux'
import { jsx } from "@emotion/core";

function LogoutIcon({onMouseEnterLogout}) {
  const { theme , colorPicker} = useSelector(state => state.theme)

  let strCol=(((theme == "dark" && colorPicker == "#ef233c")) && (onMouseEnterLogout)) ? "white" : 
  ((theme == "dark" && colorPicker == "#27cb9a") && (onMouseEnterLogout)) ? "black" : 
  ((theme == "dark" && colorPicker == "#f8961e") && (onMouseEnterLogout)) ? "black":
  ((theme == "dark" && colorPicker == "#ffca3a") && (onMouseEnterLogout)) ? "black":
  ((theme == "dark" && colorPicker == "#4cc9f0") && (onMouseEnterLogout)) ? "black":
  ((theme == "dark" && colorPicker == "#3a86ff") && (onMouseEnterLogout)) ? "white":
  ((theme == "dark" && colorPicker == "#9b5de5") && (onMouseEnterLogout)) ? "white":
  ((theme == "dark" && colorPicker == "#ef476f") && (onMouseEnterLogout)) ? "white":
  "gray"

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {
        strCol ? <path d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6" stroke={strCol}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :
        <path d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6" stroke={`${(theme == "dark" && onMouseEnterLogout) ? "black" : "gray"}`}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ? <path d="M10.6667 11.3333L14.0001 8L10.6667 4.66666" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
       :  <path d="M10.6667 11.3333L14.0001 8L10.6667 4.66666" stroke={`${(theme == "dark" && onMouseEnterLogout) ? "black" : "gray"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ? <path d="M14 8H6" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
       : <path d="M14 8H6" stroke={`${(theme == "dark" && onMouseEnterLogout) ? "black" : "gray"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
    </svg>
  )
}

export default LogoutIcon