import { createSlice } from "@reduxjs/toolkit";
import audioFile from '../../Components/FocusTimer/audio.mp3';
//const audio = new Audio('/timerSounds/Victory Trumpets.wav');
let audio = null;

export const bc = typeof window != "undefined" ? new BroadcastChannel("timer") : null;

export const playSound = (soundFile, loop = true) => {
  audio !== null && audio.pause();
  audio = new Audio('/timerSounds/' + (soundFile ?? 'Airy Melody.wav'));
  audio.loop = loop;
  audio.play();
}

export const pauseSound = () => {
  audio !== null && audio.pause();
}

const initialState = {
  currentTimer: null,
  timer: null,
  pause: false,
  start: false,
  breakTime: false,
  breakPause: false,
  resetTimerFlag: null,
  additionalTimer: 0,
  hourFoucsTime1: "00",
  minFoucsTimer1: "00",
  secFoucsTimer1: "00",
  activeTimer:null,
  breakTimer:null,
  resetBreakTimer:null,
  timerBoxFocus: false,
  timerStartTime: null,
  focusSessions: [],
  fullscreen: false,
  timerCompleted: false
}

const TimerSlice = createSlice({
  name: 'focusTimer',
  initialState,
  reducers: {

    // setHourFocusTime(hourFoucsTime => {
    //   let time = timeValue == 0 ? '0' : timeValue.replace(/^[0]{1,}/, '');
    //   return time > 99 ? hourFoucsTime  : (timeValue > 9 ? time : `0${time}`)
    // } )

    initFocusTimer(state, action){
      state.currentTimer = action.payload.currentTimer ?? null;
      state.timer = action.payload?.timer ?? null;
      state.pause = action.payload?.pause ?? false;
      state.start = action.payload?.start ?? false;
      state.breakTime = action.payload?.breakTime ?? false;
      state.breakPause = action.payload?.breakPause ?? false;
      state.resetTimerFlag = action.payload?.resetTimerFlag ?? null;
      state.additionalTimer = action.payload?.additionalTimer ?? 0;
      state.hourFoucsTime1 = action.payload?.hourFoucsTime1 ?? "00";
      state.minFoucsTimer1 = action.payload?.minFoucsTimer1 ?? "00";
      state.secFoucsTimer1 = action.payload?.secFoucsTimer1 ?? "00";
      state.activeTimer = action.payload?.activeTimer ?? null;
      state.breakTimer = action.payload?.breakTimer ?? null;
      state.resetBreakTimer = action.payload?.resetBreakTimer ?? null;
      state.timerBoxFocus = action.payload?.timerBoxFocus ?? false;
      state.timerStartTime = action.payload?.timerStartTime ?? null;
      state.focusSessions = action.payload?.focusSessions ?? [];
      state.fullscreen = action.payload?.fullscreen ?? false;
      state.timerCompleted = action.payload?.timerCompleted ?? false;
    },

    setFocusSessions(state, action){
      if(state.activeTimer === null)
        state.focusSessions = action.payload;
    },

    setTimerBoxFocus(state, action){
      state.timerBoxFocus = action.payload;
    },

    setHourFocusTime1(state, action) {
      console.log(action.payload, "action::::")
      let time = action.payload == 0 ? '0' : action.payload.replace(/^[0]{1,}/, '');
      state.hourFoucsTime1 = time > 99 ? state.hourFoucsTime1 : (action.payload > 9 ? time : `0${time}`)
    },

    setMinFocusTimer1(state, action) {
      state.minFoucsTimer1 = action.payload
    },

    setSecFocusTimer1(state, action) {
      state.secFoucsTimer1 = action.payload
    },

    setTimer(state, action) {
      state.timer = action.payload
      state.currentTimer = action.payload
    },
    setBreakTimer(state,action){
      state.breakTimer=action.payload
    },
    setActiveTimer(state,action){
      state.activeTimer=action.payload
    },

    setAdditionalTime(state, action) {
      //state.additionalTimer = state.additionalTimer + action.payload
      state.timer = state.timer + action.payload
      state.currentTimer = state.currentTimer + action.payload
    },

    increaseTimer(state, action) {
      const timeSpan = parseInt(action.payload);
      //state.additionalTimer = state.additionalTimer + action.payload
      state.timer = state.timer + timeSpan
      state.currentTimer = state.currentTimer + timeSpan
    },

    decreaseTimer(state, action) {
      const timeSpan = parseInt(action.payload);
      //state.additionalTimer = state.additionalTimer + action.payload
      state.timer = state.timer - timeSpan > 0 ? state.timer - timeSpan : 0
      state.currentTimer = state.currentTimer - timeSpan > 0 ? state.currentTimer - timeSpan : 0
    },

    startTimer(state, action) {
      state.start = true;
      state.pause = false;
      state.fullscreen = true
      state.activeTimer = action.payload;
      state.timerCompleted = false;
      state.focusSessions = [...state.focusSessions, {startTime: Date.now(), endTime: null, pauses: []}]
    },

    setTimerFullscreen(state, action) {
      state.fullscreen = action.payload
    },

    stopTimer(state, action) {
      state.timer = action?.payload ?? null;
      state.start = false;
      state.currentTimer = state.timer;
      state.additionalTimer = 0;
      state.activeTimer = null;
      state.timerCompleted = false;
      pauseSound();
      document.title = 'LifeTab';
      if(!state.focusSessions[state.focusSessions.length - 1]?.endTime)
        state.focusSessions[state.focusSessions.length - 1].endTime = Date.now();
    },

    cancelTimer(state, action) {
      state.timer = null;
      state.start = false;
      state.currentTimer = null;
      state.additionalTimer = 0;
      state.activeTimer = null;
      state.timerCompleted = false;
      pauseSound();
      document.title = 'LifeTab';
      state.focusSessions.pop();
    },

    completeTimer(state, action){
      const index = state.focusSessions.length - 1;
      state.fullscreen = true;
      //state.currentTimer = 0;
      state.focusSessions[index].pauses = [...state.focusSessions[index].pauses, {startTime: Date.now(), endTime: Date.now()}];
      if(!state.focusSessions[index]?.endTime)
        state.focusSessions[index].endTime = Date.now();
    },

    tmpStopTimer(state, action){
      state.pause = true;
      state.activeTimer = 'focusMode';
      state.fullscreen = true;
      const index = state.focusSessions.length - 1;
      // if(!state.focusSessions[index]?.endTime){
      //   state.focusSessions[index].endTime = Date.now();
      // }
      const pauseIndex = state.focusSessions[index].pauses.length - 1;
      state.focusSessions[index].endTime = Date.now();
      if(state.focusSessions[index].pauses?.[pauseIndex]?.startTime
        && !state.focusSessions[index].pauses?.[pauseIndex]?.endTime
      )
        state.focusSessions[index].pauses[pauseIndex].endTime = Date.now();
      else
        state.focusSessions[index].pauses = [...state.focusSessions[index].pauses, {startTime: Date.now(), endTime: Date.now()}];
    },

    setTimerCompleted(state, actioin){
      state.timerCompleted = true;
    },

    pauseTimer(state, action) {
      state.pause = true;
      state.timerCompleted = false;
      if(action?.payload){
        const index = state.focusSessions.length - 1;
        state.focusSessions[index].endTime = Date.now();
        state.focusSessions[index].pauses = [...state.focusSessions[index].pauses, {startTime: Date.now(), endTime: null}];
       // state.fullscreen = true;
        state.activeTimer = action.payload;
      }
    },

    resumeTimer(state, action) {
      state.pause = false;
      state.timerCompleted = false;
      if(action?.payload){
        const index = state.focusSessions.length - 1;
        const pauseIndex = state.focusSessions[index].pauses.length - 1;
        state.focusSessions[index].endTime = null;
        state.focusSessions[index].pauses[pauseIndex].endTime = Date.now();
        state.activeTimer = action.payload;
      }
    },

    pauseBreakTimer(state,action){
      state.breakPause=true
    },
    resumeBreakTimer(state,action){
      state.breakPause=false
    },

    resetTimer(state, action) {
      // state.currentTimer = action.timer
      state.timerCompleted = false;
      state.currentTimer = state.timer;
      state.resetTimerFlag = Date.now()
      state.focusSessions[state.focusSessions.length - 1] = {startTime: Date.now(), endTime: null, pauses: []};
    },

    resetBreakTime(state,action){
      state.resetBreakTimer=Date.now()
    },

    setCurrentTimer(state, action) {
      state.currentTimer = action.payload;
    },

    setTimerStartTime(state, action) {
      state.timerStartTime = Date.now();
    }
  }
})


// reducers exports
export const {initFocusTimer, setTimerStartTime, setTimerBoxFocus, increaseTimer, decreaseTimer, resetBreakTime,pauseBreakTimer,resumeBreakTimer,setBreakTimer , setActiveTimer, setHourFocusTime1, setMinFocusTimer, setSecFocusTimer, setTimer, startTimer, stopTimer, pauseTimer, resumeTimer, resetTimer, setCurrentTimer, setAdditionalTime, cancelTimer, setTimerFullscreen, tmpStopTimer, completeTimer, setTimerCompleted, setFocusSessions } = TimerSlice.actions;

export default TimerSlice.reducer;