/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowUp, Bug, Edit, Feedback, Mail, Request, Twitter } from "./icons/Icons";

const FeedbackButton = () => {
  const [isActive, setActive] = useState(false);
  const { colorPicker, theme } = useSelector(state => state.theme)

  useEffect(() => {
    document.addEventListener('click', togglePopup);

    return () => document.removeEventListener('click', togglePopup);
  }, []);

  const togglePopup = (e) => {
    if (!e.target.closest('#feedbackPopup')) {
      setActive(false);
    }
  }

  return (
    <div className="headerSidePopup" id="feedbackPopup">
            <button className={`feedback ${isActive ? 'active' : ''}`} onClick={e => setActive(isActive => !isActive)} style={{ background: theme == 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(41, 44, 54, 0.1)', opacity: theme == 'dark' ? '0.5' : '1', color: theme == "dark" ? "white" : "" }}>
              <Feedback color={theme == 'dark' ? 'white' : '#292C36'} />
              feedback 
              <i className="arrow"> <ArrowUp color={theme == 'dark' ? 'white' : 'black'} /></i>
            </button>
            <div className={`feedbackSlide ${isActive ? 'show' : 'hide'}`}  style={{ background: theme == "dark" ? "linear-gradient(0deg, rgba(41, 44, 54, 0.05), rgba(41, 44, 54, 0.05)), #000000" : "linear-gradient(0deg, rgba(41, 44, 54, 0.05), rgba(41, 44, 54, 0.05)), #FFFFFF" }}>
              <div className="feedbackSlide-header">
                <h3 className="feedbackTitle" style={{color: theme == 'dark' ? 'white' : '#292C36'}}><span css={{
                  color: `${colorPicker} !important`,
                }}>Help shape LifeTab into the app you want it to be!</span> Requests, ideas, thoughts, feedback — anything! Let us know. </h3>
                <div className="feedbackAction">
                  <ul className="actionbtn-ul">
                    <li className="actionbtn-li"><a style={{opacity: theme == 'dark' ? '0.5' : '1', color: theme == 'dark' ? 'white' : '#292C36', background: theme == 'dark' ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000' : 'linear-gradient(0deg, rgba(41, 44, 54, 0.1), rgba(41, 44, 54, 0.1)), #FFFFFF'}} href="https://tally.so/r/mJ1ZAr" className="feedbackActionBtn"><Request color={theme == 'dark' ? 'white' : '#292C36'} /> Feature Request</a></li>
                    <li className="actionbtn-li"><a style={{opacity: theme == 'dark' ? '0.5' : '1', color: theme == 'dark' ? 'white' : '#292C36', background: theme == 'dark' ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000' : 'linear-gradient(0deg, rgba(41, 44, 54, 0.1), rgba(41, 44, 54, 0.1)), #FFFFFF'}} href="https://tally.so/r/waOgl2" className="feedbackActionBtn"><Bug color={theme == 'dark' ? 'white' : '#292C36'} /> Bug Report</a></li>
                    <li className="actionbtn-li"><a style={{opacity: theme == 'dark' ? '0.5' : '1', color: theme == 'dark' ? 'white' : '#292C36', background: theme == 'dark' ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000' : 'linear-gradient(0deg, rgba(41, 44, 54, 0.1), rgba(41, 44, 54, 0.1)), #FFFFFF'}} href="https://tally.so/r/mOQezY" className="feedbackActionBtn"><Edit color={theme == 'dark' ? 'white' : '#292C36'} /> General Feedback</a></li>
                  </ul>
                </div>
                <div className="reachOut">
                  <p className="reachOut-text" style={{color: theme == 'dark' ? 'white' : '#292C36'}}>If you would like to reach out to us directly , feel free to send us an email at <span css={{
                  color: `${colorPicker} !important`,
                }}>hello@lifetab.co</span></p>
                </div>
              </div>
              <div className="feedbackSlide-footer">
                  {/* <div className="community-card" style={{background: theme == 'dark' ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #5865F2' : 'linear-gradient(0deg, rgba(41, 44, 54, 0.05), rgba(41, 44, 54, 0.05)), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #5865F2'}}>
                    <button className="join-Btn"><img src="../images/join-community.svg" /> JOIN THE DISCORD COMMUNITY</button>
                  </div> */}
          <div className="community-card" style={{ background: "none" }}>
            <a href="https://discord.gg/3gNDHWCTQX" className="join-Btn"><img src="../images/join-community.svg" /> JOIN THE DISCORD COMMUNITY</a>
          </div>
          <div className="followUs">
            <p className="follow-text" style={{ color: theme == 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)' }}>Follow us on <Twitter color={theme == 'dark' ? 'white' : '#292C36'} style={{ marginLeft: '4px' }} /> Twitter to keep up with latest updates <a href="https://twitter.com/lifetabapp" css={{
              color: `${colorPicker} !important`,
            }}>@LifeTab</a></p>
          </div>
        </div>
      </div>
    </div >
  )
}

export default FeedbackButton