import React from 'react'
import { useSocket } from '../SocketContext';
import { decodeToken } from 'react-jwt';
import { useSelector } from 'react-redux';
import { useMemo, useEffect } from 'react';

const FocusTimerEvents = () => {
    const socket = useSocket();
    const focusTasksList = useSelector(state => state.task.focusTasksList);
    const focusTasksListString = JSON.stringify(focusTasksList);
    const focusTasksListMemo = useMemo(() => JSON.parse(focusTasksListString), [focusTasksListString]);

    useEffect(() => {
        console.log('focusTasksListMemo - list', focusTasksListMemo)
        triggerWebSocket({ action: "focusTasksList", tasks: focusTasksListMemo })
    }, [focusTasksListMemo])

    const triggerWebSocket = (data) => {
        // Trigger timer in wepapp and other clients
        if (socket !== null){
            const user = decodeToken(localStorage.getItem('token'));
            socket.emit('focusTimer', {room: user['outseta:accountUid'], data});
        }
    }

    return null;
}

export default FocusTimerEvents