import React from 'react'
import { useSelector } from 'react-redux'

function PlusIcon() {
  const { colorPicker, theme } = useSelector(state => state.theme)

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path d="M14.25 2.25H3.75C2.92157 2.25 2.25 2.92157 2.25 3.75V14.25C2.25 15.0784 2.92157 15.75 3.75 15.75H14.25C15.0784 15.75 15.75 15.0784 15.75 14.25V3.75C15.75 2.92157 15.0784 2.25 14.25 2.25Z" stroke={theme == "dark" ? "white" :"black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 6V12" stroke={theme == "dark" ? "white" :"black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 9H12" stroke={theme == "dark" ? "white" :"black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export default PlusIcon