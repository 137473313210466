import React from 'react'
import { useSelector } from 'react-redux'

function SiteBlockerIcon({onMouseEnterPlan , isActivePlans , isActiveSettings}) {
  const { theme , colorPicker} = useSelector(state => state.theme)

  let strCol=(((theme == "dark" && colorPicker == "#ef233c")) && (onMouseEnterPlan || (isActivePlans && isActiveSettings == "billing"))) ? "white" : 
  ((theme == "dark" && colorPicker == "#27cb9a") && (onMouseEnterPlan || (isActivePlans && isActiveSettings == "billing"))) ? "black" : 
  ((theme == "dark" && colorPicker == "#f8961e") && (onMouseEnterPlan || (isActivePlans && isActiveSettings == "billing"))) ? "black":
  ((theme == "dark" && colorPicker == "#ffca3a") && (onMouseEnterPlan || (isActivePlans && isActiveSettings == "billing"))) ? "black":
  ((theme == "dark" && colorPicker == "#4cc9f0") && (onMouseEnterPlan || (isActivePlans && isActiveSettings == "billing"))) ? "black":
  ((theme == "dark" && colorPicker == "#3a86ff") && (onMouseEnterPlan || (isActivePlans && isActiveSettings == "billing"))) ? "white":
  ((theme == "dark" && colorPicker == "#9b5de5") && (onMouseEnterPlan || (isActivePlans && isActiveSettings == "billing"))) ? "white":
  ((theme == "dark" && colorPicker == "#ef476f") && (onMouseEnterPlan || (isActivePlans && isActiveSettings == "billing"))) ? "white":
  "gray"

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_10_380)">
      <path d="M7.99967 14.6666C11.6816 14.6666 14.6663 11.6818 14.6663 7.99992C14.6663 4.31802 11.6816 1.33325 7.99967 1.33325C4.31778 1.33325 1.33301 4.31802 1.33301 7.99992C1.33301 11.6818 4.31778 14.6666 7.99967 14.6666Z" stroke="white" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.28711 3.28662L12.7138 12.7133" stroke="white" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_10_380">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default SiteBlockerIcon