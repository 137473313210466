/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, {useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap'
import { Checkbox } from '../LandingHeader/Feedback/icons/Icons'
import { disconnectTodoistIntegration } from "../action/common";
import { useSocket } from "../../SocketContext";
import { decodeToken } from "react-jwt";
import { usePosthog } from "../../PosthogProvider";

const DisconnectOptionsModal = ({ show = true, onHide, colorPicker, theme }) => {
    const [selectedOptions, setSelectedOptions] = useState({
        todoist: false,
        lifeTab: false
    })
    const [submitting, setSubmitting] = useState(false)
    const [responseLog, setResponseLog] = useState([])
    const socket = useSocket();
    const user = decodeToken(localStorage.getItem('token'));
    const { captureEvent } = usePosthog();

    useEffect(() => {
        socket.on('disconnectTodoistProgress', handleDisconnectTodoist);
        return () => {
            socket.off('disconnectTodoistProgress', handleDisconnectTodoist);
        }
    }, [])

    useEffect(() => {
        const logElement = document.getElementById('disconnect-response-log');
        if (logElement) {
            logElement.scrollTop = logElement.scrollHeight;
        }
    }, [responseLog]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(submitting) 
            return

        setSubmitting(true)

        socket.emit('disconnectTodoist', {room: user['outseta:accountUid'], data: selectedOptions});

        // Capture disconnect event
        captureEvent('Todoist Disconnected');
    }

    const handleDisconnectTodoist = (data) => {
        const {room, message, error, disconnectComplete} = data;
        if(user['outseta:accountUid'] !== room){
            return;
        }

        if(message){
            setResponseLog(prev => [...prev, {message, disconnectComplete}]);
        }

        if(error){
            setResponseLog(prev => [...prev, {error}]);
            setSubmitting(false)
        }

        if(disconnectComplete){
            setTimeout(() => {
               onHide(true)
            }, 1000)
        }
    }

    const handleChange = (e) => {
        setSelectedOptions({
            ...selectedOptions,
            [e.target.name]: e.target.checked
        })
    }

    return (
        <Modal show={show} onHide={onHide} className="colorPicker-model" css={{
            '.darkPrimaryColor': {
                background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker} !important`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
            },
            '.darkPrimaryColor20': {
                background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), ${colorPicker} !important`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
            }
        }}>
            <Modal.Body>
            <div className="full-model md-model-updates">
                <div className="full-model-body">
                    <div className="body-data bg-white border-radius25">
                        <div className='ctTitle relative d-flex fd-col align-start gap-2' css={{alignItems: 'flex-start'}}>
                            <span className='darkPrimaryColor'>Disconnect Integration</span>
                            <p className='font size-12 color-grey font-family inter'>Syncing will stop, and any future changes will not reflect in Todoist</p>
                        </div>
                        <div className='d-flex pad-30-40 gap-30 justify-center align-center justify-start width-100'>
                            <form onSubmit={handleSubmit} onChange={handleChange}>
                                <ul>
                                    <li>
                                        <label className='d-flex align-center gap-10 font size-14 color-black'>
                                            <Checkbox disabled={submitting} colorPicker={colorPicker} type="checkbox" name="todoist" id="disconnect-option-1" />
                                            <span>Delete all Lifetab tasks from Todoist</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className='d-flex align-center gap-10 font size-14 color-black'>
                                            <Checkbox disabled={submitting} colorPicker={colorPicker} type="checkbox" name="lifeTab" id="disconnect-option-2" />
                                            <span>Delete all Todoist tasks from Lifetab</span>
                                        </label>
                                    </li>
                                </ul>
                                {
                                    responseLog.length > 0 && (
                                        <div id="disconnect-response-log" className='d-flex fd-col gap-10 align-start' css={{
                                            width: '100%',
                                            maxHeight: '250px',
                                            border: '1px solid grey',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            background: '#000',
                                            overflowY: 'auto'
                                        }}>
                                            {responseLog.map((log, index) => (
                                                <p key={index} className={`font size-12 font-family inter ${log?.error ? 'color-red' : 'color-grey'} ${log?.disconnectComplete ? 'color-green' : ''}`}>{log?.message ?? log?.error}</p>
                                            ))}
                                        </div>
                                    )
                                }
                                
                                <button className={`button primary mt-20 ${submitting ? 'disabled' : ''}`} css={{background: colorPicker, color: 'white'}} disabled={submitting}>{submitting ? 'Disconnecting...' : 'Disconnect'}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
    </Modal>
  )
}

export default DisconnectOptionsModal