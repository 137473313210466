import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom';
import Layout from './pages/Layout';
import toast, { Toaster } from 'react-hot-toast';
import { AuthProvider } from './AuthProvider';
import { SocketProvider } from './SocketContext';
import { PosthogProvider } from './PosthogProvider';

function App() {
  return (
    <AuthProvider>
      <SocketProvider>
        <BrowserRouter>
          <PosthogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} 
            host={process.env.REACT_APP_PUBLIC_POSTHOG_API_HOST}
          >
            <Suspense fallback={<div className="initial-loader" />}>
              <Layout />
            </Suspense>
          </PosthogProvider>
        </BrowserRouter>  

        <Toaster       
          toastOptions={{ duration: 1000}}
          reverseOrder={false}
          containerClassName="deleteToastClass"
        />
      </SocketProvider>
    </AuthProvider>
  );
}

export default App;
