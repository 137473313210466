/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { jsx } from "@emotion/core";
import { AuthContext } from '../../AuthProvider'
import { AIChatGredientIcon, TasksMenuIcon, TodayMenuIcon, SettingGredientIcon, AdminGredientIcon, SiteLogoIcon, UpgradeIcon } from '../LandingHeader/Feedback/icons/Icons'

const SideNav = ({children, theme, colorPicker, label = ''}) => {
  return (
    <span  className={`menu-block ${(theme == "dark") ? "sideBarDarkMode" : ""}`}>
      <span style={{color: colorPicker}}>{label}</span>{children}
    </span>
  )
}

const HoverEffect = ({label = '', textRight = '-59px'}) => {
  return (
    <div className='menu-hover-pull-effect'>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="300" viewBox="0 0 20 300" fill="none">
        <path d="M0 0C0 109.5 20 110.25 20 150C20 189.75 0 188.25 0 300L0 188.25V150V114.75L0 0Z" fill="white"/>
      </svg>
      <span className='menu-hover-pull-effect-text' style={{right: textRight}}>{label}</span>
    </div>
  )
}

function MainNavV2() {
  const [disableMenu, setDisableMenu] = useState(false)
  const { colorPicker, theme } = useSelector(state => state.theme)
  const { subscription, userProfile } = useContext(AuthContext);
  // console.log('userProfile', userProfile)
  const activeMenuCss = {
    '.active': {
        'svg': {
          'path': {
            '&.var-fill': {
              fill: colorPicker
            },
            '&.var-stroke': {
              stroke: colorPicker
            }
          },
          'radialGradient': {
            'stop':{
              '&.var-stop-color': {
                stopColor: colorPicker
              }
            }
          }
      }
    }
  }

  useEffect(() => {
    subscription?.isFreeTrial && subscription?.accountStage > 3 && setDisableMenu(true)
  }, [subscription])

  return (
    disableMenu
    ?
    null
    :
    <>
      <div className="site-logo">
        <SiteLogoIcon color={colorPicker} />
      </div>
      <ul className="ul-setting" css={{
        '.li-links.active': {
          '~.menu-hover-pull-effect': {
            '.menu-hover-pull-effect-text': {
              background: colorPicker,
              color: '#fff'
            }
          }
        }
      }}>
        <li className="li-setting" css={activeMenuCss}>
          <NavLink to={process.env.REACT_APP_ROOT || '/index.html'} end className={({ isActive }) => isActive ? 'li-links active' : 'li-links'}>
            <SideNav colorPicker={colorPicker} theme={theme} label="Today" >
              <TodayMenuIcon width="22" height="22" />
            </SideNav>
          </NavLink>
          <HoverEffect label="Today" />
        </li>
        <li className="li-setting" css={activeMenuCss}>
          <NavLink to="/tasks" className={({ isActive }) => isActive ? 'li-links active' : 'li-links'}>
            <SideNav colorPicker={colorPicker} theme={theme} label="Tasks">
              <TasksMenuIcon width="22" height="22" />
            </SideNav>
          </NavLink>
          <HoverEffect label="Tasks" textRight='-54px' />
        </li>
        {
          (userProfile?.email == "vikasgarg.wd@gmail.com" || userProfile?.email == "sam@Lifetab.co" || userProfile?.email == "sam@talkoftoday.com") &&
          <li className="li-setting" css={activeMenuCss}>
            <NavLink to='/admin' className={({ isActive }) => isActive ? 'li-links active' : 'li-links'}>
              <SideNav colorPicker={colorPicker} theme={theme} label="Admin">
                <AdminGredientIcon width="22" height="22" />
              </SideNav>
            </NavLink>
            <HoverEffect label="Admin" />
          </li>
        }
        {
          (userProfile?.email == "vikasgarg.wd@gmail.com" || userProfile?.email == "sam@Lifetab.co" || userProfile?.email == "sam@talkoftoday.com") &&
          <li className="li-setting" css={activeMenuCss}>
            <NavLink to='/admin/settings' className={({ isActive }) => isActive ? 'li-links active' : 'li-links'}>
              <SideNav colorPicker={colorPicker} theme={theme} label="Admin Settings">
                <AdminGredientIcon width="22" height="22" />
              </SideNav>
            </NavLink>
            <HoverEffect label="Admin Settings" textRight='-122px' />
          </li>
        }
        <li className="li-setting" css={activeMenuCss}>
          <NavLink to='/ai-assistant' className={({ isActive }) => isActive ? 'li-links active' : 'li-links'}>
            <SideNav colorPicker={colorPicker} theme={theme} label="AI Assistant">
              <AIChatGredientIcon width="22" height="22" />
            </SideNav>
          </NavLink>
          <HoverEffect label="AI Assistant" textRight='-97px' />
        </li>
        <li className="li-setting" css={activeMenuCss}>
          <NavLink to='/settings' className={({ isActive }) => isActive ? 'li-links active' : 'li-links'}>
            <SideNav colorPicker={colorPicker} theme={theme} label="Settings">
              <SettingGredientIcon width="22" height="22" />
            </SideNav>
          </NavLink>
          <HoverEffect label="Settings" textRight='-72px' />
        </li>
        <li className="li-setting" css={activeMenuCss}>
          <NavLink to='/subscription-plan' className={({ isActive }) => isActive ? 'li-links active' : 'li-links'}>
            <SideNav colorPicker={colorPicker} theme={theme} label="Subscription Plan">
              <UpgradeIcon width="22" height="22" id='upgrade-icon-sidbar' />
            </SideNav>
          </NavLink>
          <HoverEffect label="Subscription Plan" textRight='-133px' />
        </li>
      </ul>
    </>
  )
}

export default MainNavV2