import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import { verifyRepeatedTask, highlightText, caseInsensitiveSortBy } from '../../Components/action/utilities'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { fetchTaskList } from '../../Components/action/common'
import { FolderIcon, NoDateIcon, OverDueIcon, UpcomingTasksIcon } from '../../Components/LandingHeader/Feedback/icons/Icons';
import ArrowUpDn from './ArrowUpDn';
import TaskList from './TaskList';
import moment from 'moment';

const ToggleAccordionArrow = ({ theme }) => {
    return (
      <AccordionItemState>
        {({ expanded }) => (
          expanded 
          ? 
          <span className={`accordion_arrow active d-flex align-center justify-center pad-0-8`}>
            {
              theme == "dark" ? <ArrowUpDn/> : <img src="/images/arrow-up-dn.svg" alt="arrow" />
            }
          </span>
          : 
          <span className={`accordion_arrow d-flex align-center justify-center pad-0-8`}>
            {
              theme == "dark" ? <ArrowUpDn/> : <img src="/images/arrow-up-dn.svg" alt="arrow" />
            }
          </span>
        )}
      </AccordionItemState>
    )
}

const TasksSearchResults = () => {
    const [allTasks, setAllTasks] = useState([])
    const [tasks, setTasks] = useState([]) 
    const [tasksSortBy, setTasksSortBy] = useState('date')
    const [tasksSortDirection, setTasksSortDirection] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const {theme, colorPicker} = useSelector(state => state.theme)
    const {settings} = useSelector(state => state.settings)
    const keyword = searchParams.get('keyword');
    const wrapperRef = useRef(null)

    const fetchTasks = async () => {
        const response = await fetchTaskList({keyword});
        setAllTasks(response?.data?.responseData ?? [])
    }

    useEffect(() => {
        const bound = wrapperRef.current.getBoundingClientRect()
        wrapperRef.current.style.height = window.innerHeight - bound.top + 'px'
    }, [])

    useEffect(() => {
        setTasksSortBy(settings?.sortBy ?? 'date');
        setTasksSortDirection(settings?.sort ?? null);
    }, [settings])

    useEffect(() => {
        if(!keyword) return;
        
        fetchTasks();
    }, [keyword])

    useEffect(() => {
        if(!allTasks) return;

        console.log(allTasks)

        const today = moment().startOf('day').toISOString();
        // Overdue tasks
        const overdue = _.orderBy(allTasks.map(item => {
        if(item?.repeat?.rule){
            const result = verifyRepeatedTask(item, null, [], true);
            return result?.length > 0 ? {...item, date: new Date(result[0]).toISOString()} : false;
        }else{
            const result = item.date !== null && item.date < today;
            return result ? item : false;
        }

        }).filter(item => item !== false), ['date'], ['desc']);

        // Upcoming tasks
        const upcoming = _.orderBy(allTasks.map(item => {
        if(item?.repeat?.rule){
            const result = verifyRepeatedTask(item, null, [], false, true);
            return result?.length > 0 ? {...item, date: new Date(result[0]).toISOString()} : false;
        }else{
            const result = item.date !== null && item.date >= today;
            return result ? item : false;
        }

        }).filter(item => item !== false), ['date'], ['asc']);

        // No date tasks
        const noDate = allTasks.filter(item => {
            return item.date === null;
        });

        // Set tasks
        setTasks([
            {
                title: "Overdue",
                tasks: tasksSortBy && tasksSortDirection ? caseInsensitiveSortBy(overdue, tasksSortBy, tasksSortDirection) : overdue,
                count: overdue.length,
                icon: <OverDueIcon color={colorPicker} />
            },
            {
                title: "Upcoming",
                tasks: tasksSortBy && tasksSortDirection ? caseInsensitiveSortBy(upcoming, tasksSortBy, tasksSortDirection) : upcoming,
                count: upcoming.length,
                icon: <UpcomingTasksIcon color={colorPicker} />
            },
            {
                title: "No Date",
                tasks: tasksSortBy && tasksSortDirection ? caseInsensitiveSortBy(noDate, tasksSortBy, tasksSortDirection) : noDate,
                count: noDate.length,
                icon: <NoDateIcon color={colorPicker} />
            }
        ])

    }, [allTasks, tasksSortBy, tasksSortDirection])

    const highlightKeyword = (text) => {
        return highlightText(text, keyword);
    }

    return (
        <div ref={wrapperRef} id="tasks-list-wrapper" className="overflow x-hidden y-auto border radius-20">
            <div className="upcoming-form">
                <div className="accordion-view">
                    <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['accitem']}>
                        <AccordionItem uuid={`accitem`} className={`accordion-item ${!tasks.length ? 'no-task' : ''}`}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                {
                                    <div className={`accordion_tab cursor pointer`} style={{ background: theme == "dark" ? "black" : "white" }}>
                                        <div className="heading width-100">
                                            <FolderIcon color={colorPicker} fill={colorPicker} />
                                            <h3 className="accordion-title darkPrimaryColor">Search Results: <strong>{keyword}</strong></h3>
                                        </div>
                                        <ToggleAccordionArrow theme={theme} />
                                    </div>
                                }
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            {
                                <AccordionItemPanel>
                                    <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={[`accitem0`, `accitem1`]}>
                                    {
                                        tasks.map((group, index) => {
                                            return (
                                                    <AccordionItem uuid={`accitem${index}`} className={`accordion-item ${!group.tasks.length ? 'no-task' : ''}`}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                <div className={`accordion_tab cursor pointer`} style={{ background: theme == "dark" ? "black" : "white", paddingBottom: '20px' }}>
                                                                    <div className="heading width-100">
                                                                        {group.icon}
                                                                        <h3 className="accordion-title darkPrimaryColor font size-20">{group.title}</h3>
                                                                        <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                                                        <span className="pad-0-14 font size-12 color-black font-family inter opacity d5">{`${group.tasks.length} Task${group.tasks.length > 1 ? 's' : ''}`}</span>
                                                                    </div>
                                                                    <ToggleAccordionArrow theme={theme} />
                                                                </div>
                                                                {index < 2 && <div className="hr-line"></div>}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                        {
                                                            group.tasks.map(task => {
                                                                return (
                                                                    <TaskList 
                                                                        task={task} 
                                                                        key={`item-${task.id}${task.updatedAt}`} 
                                                                        disableSort={true}  
                                                                        currentPage="home" 
                                                                        groupDate={task.date} 
                                                                        showDate={true} 
                                                                        fetchTasks={fetchTasks} 
                                                                        highlightKeyword={highlightKeyword}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                            )
                                        })
                                    }    
                                    </Accordion>
                                </AccordionItemPanel>
                            }
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default TasksSearchResults
