import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ArrowUp from './Quotes/ArrowUp'
import { useDispatch } from 'react-redux'
// import { setAddQuickLinkClick } from '../redux/slices/CardSlice'
import { useNavigate } from 'react-router-dom'
import { setAddQuickLinkClick, setQuickLinksVisit } from '../redux/slices/SettingSclice'
import { DragHandler } from './Tasks/DragHandler'
import { CollapseIcon, SettingsIcon, PlusIcon } from '../Components/LandingHeader/Feedback/icons/Icons'

function QuickLink() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [collapse, setCollapse] = useState(false)
  const { settings } = useSelector(state => state.settings)
  const { theme } = useSelector(state => state.theme)
  const defaultLinks = [{link: 'https://meet.google.com/new'}, {link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'}, {link: 'https://mail.google.com/mail'}, {link: 'https://chat.openai.com/'}];

  const [quotecollapse, setquotecollapse] = useState(true);
  
  return (
    <div className='bar'>
      {
        quotecollapse ?
          <div className="quickLink widgetWrapper" style={{ background: theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgb(0, 0, 0)" : "" }} >
            <DragHandler />
        
            <div className="quoteContainer" >
              <div className='link-imgs' >
                {
                  (settings?.quickLinks ?? defaultLinks)?.map((link, index) => {
                    return (
                      link.link.length > 0 ? <a key={index} className='link-icon' href={link.link} target="_blank">
                  <img src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${link.link}&size=24`} />
                      </a> : null
                    )
                  })
                }
                {
                  !settings?.quickLinksVisit && (settings?.quickLinks ?? defaultLinks).filter(link => link.link.length > 0).length < 5
                  &&
                  <div className='add-links' onClick={() => { dispatch(setQuickLinksVisit(true), navigate('/settings')) }} >
                    <PlusIcon color={theme == 'dark' ? 'white' : 'black'} />
                  </div>
                }
                
              </div>
            </div>

              <div className='onHoverShorts quiCkLink'>
                <div className='actOnhove'>
                    <div className='hovAct ht-collapse' onClick={() => setquotecollapse(false)}> <CollapseIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                    <div className='hovAct ht-settings' onClick={() => navigate(`/settings#cards:quicklinks`) }> <SettingsIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                </div>
              </div>
            </div>
          : 
                
          <div className='barContent'  onClick={() => setquotecollapse(true)}>
            <div className='barContentHeading'>
                <h4 className='barHead'>Quick Links</h4>
                <span className='headBrk'></span>
            </div>
          </div>
        }
       
      </div>
    
  )
}

export default QuickLink
