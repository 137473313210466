import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { forgotPassword, updatePassword } from '../Components/action/common';
import {useNavigate} from 'react-router-dom'
import ResetPasswordNavigation from './ResetPasswordNavigation';



function PasswordReset() {
  const navigate=useNavigate()
  const [isLoader, setIsLoader] = useState(false)
  const [isActivePassword,setisActivePassword]=useState(false)
  const [isActiveCPassword,setisCActivePassword]=useState(false)
  const { register, handleSubmit } = useForm()
  const [error,setError]=useState("")
  let code=localStorage.getItem("code");
  let email=localStorage.getItem("email")

  const onPasswordChange=async(data)=>{
    setIsLoader(true)
    if(data?.password !== data?.confirmPassword){
      setIsLoader(false)
      setError("Password did not match: Please try again...")
    }else{
      let requestBody={
        "code": code,
        "email": email,
        "password": data?.password
      }
      try {
        let response=await updatePassword(requestBody)
        localStorage.setItem("token",response?.data?.responseData?.accessToken)
        navigate(process.env.REACT_APP_ROOT)
      } catch ({response}) {
        setIsLoader(false)
      }
    }
  }
  return (
    <>
      <div className="main-body-wrap task-wrap inner-pages-wrap login-wrap new-password">
        <header className="header">
          <div className="d-flex header-inner">
            <div className="site-logo">
              <a href="#" className="logo">LifeTab</a>
            </div>
            <div className="heder-midle">

            </div>
            <div className="header-right">

            </div>
          </div>
        </header>

        <div className="page-wrappers login">
          <div className="page-container">
            <div className="contentquoteSection">
              <div className="form-content">
                <div className="login-form">
                  <div className="slide-form">


                    <div className="slide-fields name-slide active">
                      <div className="login-block">
                        <form onSubmit={handleSubmit(onPasswordChange)} >
                          <span className="log-name text-uppercase color-green">PASSWORD RESET</span>
                          <div className="input-block">
                             <label className="form-label">Enter your new password and confirm it below.</label>
                            {/* <input type="text" placeholder="Type your code here" className="field-input" name="code"  /> */}
                          </div>


                          <div className={`input-block f-in ${isActivePassword && 'show'} `}>
                            <input type={isActivePassword ? "text" :"password"} autoFocus placeholder="Type your answer here" className="field-input passwordInput" {...register("password")} style={{letterSpacing:'1px'}} />
                            <span className="right-icon" onClick={()=>setisActivePassword(!isActivePassword)} ><i className={'icon-eye'} /></span>
                          </div>

                          <div className={`input-block f-in ${isActiveCPassword && 'show'} `} style={{marginTop:"50px"}}>
                            <input type={isActiveCPassword ? "text" :"password"} placeholder="Confirm Password . . ." className="field-input passwordInput" {...register("confirmPassword")} style={{letterSpacing:'1px'}}/>
                            <span className="right-icon" onClick={()=>setisCActivePassword(!isActiveCPassword)} ><i className={'icon-eye'} /></span>
                          </div>
                          <div className="action-button">
                            <div className="error"><p>{error}
                            </p></div>
                          </div>
                         

                          <div className="action-button">
                            <button type="submit" className="submit-btn sb-btn" >{isLoader == true ? <span class="loaderTemp" style={{borderColor: "white white transparent"}}></span> : <>Log In</>}</button>
                          </div>
                        </form>

                      </div>
                    </div>
                       

                  </div>

                  <div className="form-navigation">

                    <div className="back-action" onClick={()=>navigate('/signup')} ><a className="back-link" ><img src="./images/arrow-left.svg" alt='' /></a></div>


                  </div>

                  {/* <ResetPasswordNavigation isResetPassword={true}/>  */}


                </div>
              </div>
            </div>
            {/* End Quote block */}
          </div>
        </div>
      </div>



    </>
  )
}

export default PasswordReset