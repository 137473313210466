/** @jsxRuntime classic */
/** @jsx jsx */
import moment from 'moment'
import React from 'react'
import { jsx } from "@emotion/core";
import { useState } from 'react'
import { useSelector } from 'react-redux'
import TaskList from './TaskList'
import _ from "lodash"
import {AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel, Accordion, AccordionItemState,} from 'react-accessible-accordion';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { setAllTaskList, setGroupedList, setNoDateTaskList, setOverDueTaskList, setTaskList, setTaskProjects } from '../../redux/slices/TaskSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { taskPosition, taskProjectPosition } from '../../Components/action/common'
import ArrowUpDn from './ArrowUpDn'
import { ArrowUp, CheckIcon, FolderIcon, TasksListIcon } from '../../Components/LandingHeader/Feedback/icons/Icons';
import { useSearchParams } from 'react-router-dom';
import { calendarFormat, calendarFormat2, caseInsensitiveSortBy, format, verifyRepeatedTask } from '../../Components/action/utilities';
import AddTaskStatic from '../AddTasks/AddTaskStatic';
import DragIcon from './DragIcon';
import { setCollapsedGroupAccordions } from '../../redux/slices/ProjectSlice';

const DragHandle = SortableHandle(() => <span className="dragdrop-option"><DragIcon /></span>);

const ToggleAccordionArrow = ({ theme }) => {
  return (
    <AccordionItemState>
      {({ expanded }) => (
        expanded 
        ? 
        <span className={`accordion_arrow active d-flex align-center justify-center pad-0-8`}>
          {
            theme == "dark" ? <ArrowUpDn/> : <img src="/images/arrow-up-dn.svg" alt="arrow" />
          }
        </span>
        : 
        <span className={`accordion_arrow d-flex align-center justify-center pad-0-8`}>
          {
            theme == "dark" ? <ArrowUpDn/> : <img src="/images/arrow-up-dn.svg" alt="arrow" />
          }
        </span>
      )}
    </AccordionItemState>
  )
}

const customSelector = (selector, slice, props = []) => {
  let result = {};
  props.forEach(prop => {
    result[prop] = selector(state => state[slice][prop])
  })
  
  return result;
}

function TaskListDetails({ setDeleteLoader, deleteLoader, fetchTotalCount, currentPage, selectedDate, setSelectedDate, allTaskList, homePage, overDue, groupDate, onAddTaskHome, setOnAddTaskHome, upComing, setDeleteTasks, upComingtaskList, title = null, setEditTaskListData, setAddTaskInputValue, fetchAddTaskData, fetchTaskListData, fetchMoreData=() => {}, index = 0, isTodayPage = false }) {

  const dispatch = useDispatch()

  // global state
  const { taskList, overDueTaskList, dateChange, noDataTaskList, taskGroupedList, taskProjects } = customSelector(useSelector, 'task', ['taskList', 'overDueTaskList', 'dateChange', 'noDataTaskList', 'taskGroupedList', 'taskProjects'])
  const { settings } = customSelector(useSelector, 'settings', ['settings'])
  const [tasksGroupBy, setTasksGroupBy] = useState(null);
  const [tasksSortBy, setTasksSortBy] = useState(null);
  const [tasksSortDirection, setTasksSortDirection] = useState(null);
  const {theme , colorPicker} = customSelector(useSelector, 'theme', ['theme', 'colorPicker'])
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes))
  const date = moment(dateChange).format('YYYY-MM-DD')
  const currentDate = moment(currentTime).format('YYYY-MM-DD')
  const [tasksArray, setTasksArray] = useState([]);
  const [allDatesTasks, setAllDatesTasks] = useState([]);
  const [allHomePageTasks, setAllHomePageTasks] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date())
  const [searchParams] = useSearchParams();
  const [isAccordionOpen, setIsAccordionOpen] = useState(index === 0 ? true : false);
  const {collapsedGroupAccordions} = customSelector(useSelector, 'project', ['collapsedGroupAccordions'])

  useEffect(() => {
    setTasksGroupBy(settings?.groupBy ?? null);
    setTasksSortBy(settings?.sortBy ?? 'date');
    setTasksSortDirection(settings?.sort ?? null);
  }, [settings])

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  }

  const toggleGroupAccordion = (key) => {
    dispatch(setCollapsedGroupAccordions(collapsedGroupAccordions.indexOf(key) !== -1 ? collapsedGroupAccordions.filter(k => k !== key) : [...collapsedGroupAccordions, key]))
  }

  // sort function
  const onSortEnd = ({ oldIndex, newIndex, collection }) => {
    let newList = [];
    const newGroupDate = moment(groupDate).startOf('date').toISOString();

    if (currentPage == "home") {
      //const groupTaskListData = groupedTasks(_.filter(allHomePageTasks, ["date", moment(dateChange).startOf('date').toISOString()]));
      const allHomePageTasksPending = _.filter(allHomePageTasks, t => !t.deletedAt && t.status == 0);
      const allHomePageTasksComplete = _.filter(allHomePageTasks, t => !t.deletedAt && t.status == 1); 
      const groupTaskListData = groupedTasks(_.filter(allHomePageTasksPending, (t) => verifyRepeatedTask(t, dateChange, moment(t?.date).startOf('date').toISOString() == moment(dateChange).startOf('date').toISOString())), _.filter(allHomePageTasksComplete, (t) => verifyRepeatedTask(t, dateChange, moment(t?.date).startOf('date').toISOString() == moment(dateChange).startOf('date').toISOString())));
      const groupKey = groupTaskListData.projects[collection];
      newList = arrayMoveImmutable(allHomePageTasksPending, _.findIndex(allHomePageTasksPending, ["id", groupTaskListData.groups[groupKey][oldIndex].id]), _.findIndex(allHomePageTasksPending, ["id", groupTaskListData.groups[groupKey][newIndex].id]));
      newList = [...newList, ...allHomePageTasksComplete];
      setTasksArray(newList?.filter(t => verifyRepeatedTask(t, dateChange, moment(t?.date).startOf('date').toISOString() == moment(dateChange).startOf('date').toISOString())));
      setAllHomePageTasks(newList);
      dispatch(setTaskList(newList));

    } else if (currentPage == "upComing") {
      // const groupTaskListData = groupedTasks(_.filter(allDatesTasks, ["date", newGroupDate]));
      const groupTaskListData = groupedTasks(_.filter(allDatesTasks, (t) => verifyRepeatedTask(t, groupDate, moment(t?.date).startOf('date').toISOString() == newGroupDate)));
      const groupKey = groupTaskListData.projects[collection];
      newList = arrayMoveImmutable(allDatesTasks, _.findIndex(allDatesTasks, ["id", groupTaskListData.groups[groupKey][oldIndex].id]), _.findIndex(allDatesTasks, ["id", groupTaskListData.groups[groupKey][newIndex].id]));
      setTasksArray(newList?.filter(t => verifyRepeatedTask(t, groupDate, moment(t?.date).format('L') == groupDate)));
      setAllDatesTasks(newList)
      dispatch(setGroupedList(newList))

    } else if (currentPage == "overDue") {
      const groupTaskListData = groupedTasks(overDueTaskList);
      const groupKey = groupTaskListData.projects[collection];
      newList = arrayMoveImmutable(overDueTaskList, _.findIndex(overDueTaskList, ["id", groupTaskListData.groups[groupKey][oldIndex].id]), _.findIndex(overDueTaskList, ["id", groupTaskListData.groups[groupKey][newIndex].id]))
      dispatch(setOverDueTaskList(newList))

    } else if (currentPage == "allTask") {
      const groupTaskListData = groupedTasks(allTaskList);
      const groupKey = groupTaskListData.projects[collection];
      newList = arrayMoveImmutable(allTaskList, _.findIndex(allTaskList, ["id", groupTaskListData.groups[groupKey][oldIndex].id]), _.findIndex(allTaskList, ["id", groupTaskListData.groups[groupKey][newIndex].id]))
      dispatch(setAllTaskList(newList))

    } else {
      const groupTaskListData = groupedTasks(noDataTaskList);
      const groupKey = groupTaskListData.projects[collection];
      newList = arrayMoveImmutable(noDataTaskList, _.findIndex(noDataTaskList, ["id", groupTaskListData.groups[groupKey][oldIndex].id]), _.findIndex(noDataTaskList, ["id", groupTaskListData.groups[groupKey][newIndex].id]))
      dispatch(setNoDateTaskList(newList))
    }

    currentPage != "upComing" && currentPage != "home" && setTasksArray(newList);
    let list = newList.map((item) => {
      return item?.id
    })
    taskPosition({ "positions": list })
  };

  const onSortEndTaskGroup = async ({ oldIndex, newIndex, collection }) => {
    const groupTaskListData = groupedTasks(tasksArray);
    const groupKeys = groupTaskListData.projects;
    const newList = arrayMoveImmutable(groupKeys, oldIndex, newIndex);//.filter(key => key !== '@');
    dispatch(setTaskProjects(newList.map(t => ({title: t}))))

    if(oldIndex == newIndex)
      return;
    try{
      await taskProjectPosition({ "positions": newList })
    }catch(e){
      console.log(e)  
    }
  } 

  const TaskGroup = sortableElement(({groupkey, groupIndex, index, nowTaskGroupIndex, groupTaskListData, inCompletedTasks, completedTasks, allTasksCompletedForToday}) => {
    const key = groupkey;

    return <AccordionItem key={key} uuid={`taskgroup${key}`} className={`accordion-item ${nowTaskGroupIndex === groupIndex ? 'taskgroupacc' : ''} taskgroup${key}`} dangerouslySetExpanded={groupIndex === -1 ? true : null}>
      <div className={`${tasksGroupBy === 'project' || tasksGroupBy === 'tag' ? 'grouped-tasks-list' : ''} ${isTodayPage ? 'custradius' : ''}`} key={groupIndex}>
        <AccordionItemHeading onClick={() => toggleGroupAccordion(key)}>
          <AccordionItemButton>
            {tasksGroupBy !== 'project' && tasksGroupBy !== 'tag'
              ? <h3 className="task-meta-key"></h3> 
              : <h3 className="task-meta-key d-flex gap-10 align-center pad-30-40 font size-24 weight-400 font-family rl cursor pointer">   
                  <DragHandle />
                  {
                    tasksGroupBy === 'project' 
                    ? <FolderIcon color={colorPicker} fill={colorPicker} /> 
                    : tasksGroupBy === 'tag' ? '#' : ''
                  }
                  <span className='darkPrimaryColor'>{key === '@' ? 'No Project' : key}</span>
                  <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                  <span className='font size-12 font-family inter weight-500 opacity d5'>{`${inCompletedTasks.length} task${inCompletedTasks.length > 1 ? 's' : ''} pending`}</span>
                  <ToggleAccordionArrow theme={theme} />
                </h3>
            }
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {
            !allTasksCompletedForToday && (tasksGroupBy === 'project' || tasksGroupBy === 'tag') && <AddTaskStatic currentPage={currentPage} groupDate={groupDate} groupKey={key} />
          }
          <TaskGroupContainer hideSortableGhost={false} onSortStart={onSortStart} useDragHandle={true} onSortEnd={onSortEnd} helperClass="sortable-helper">
            <ul className={`ul-task-list pending-tasks${inCompletedTasks.length}`}>
            {
              inCompletedTasks.map((task, i) => {
                let taskDate = moment(task.date).format('YYYY-MM-DD');
                
                return (
                  (
                    currentPage === 'noDate'
                    ?
                    (
                      !task.date 
                      ? <SortableItem disabled={task.status == 1} key={`item-${task.id}`} index={i} collection={groupIndex} task={task} /> 
                      : null
                    )
                    :
                    (
                      currentPage === 'overDue'
                        ?
                        (
                          moment(taskDate).isBefore(currentDate, 'day') 
                          ? <SortableItem disabled={task.status == 1} key={`item-${task.id}`} index={i} collection={groupIndex} task={task} />
                          : null
                        )
                        :
                        currentPage !== 'home' 
                        || 
                        (verifyRepeatedTask(task, date, ((date === currentDate && taskDate === currentDate) || (taskDate !== currentDate && date !== currentDate))))
                          ?
                          <SortableItem disabled={task.status == 1} key={`item-${task.id}`} isNowGroup={nowTaskGroupIndex === groupIndex} taskIndex={i} index={i} collection={groupIndex} task={task} />
                          :
                          null
                    )
                  )
                )
              })
            }
          
            {
              completedTasks.length > 0
              &&
              <>
                {
                  allTasksCompletedForToday && (tasksGroupBy === 'project' || tasksGroupBy === 'tag')
                  &&
                  <>
                    {/* {groupkey === '@' && <AllDone />} */}
                    <AddTaskStatic groupKey={key} currentPage={currentPage} groupDate={groupDate} />
                  </>
                }
                {<CompletedTasksList completedTasks={completedTasks} collection={99999} disabled={true} index={groupIndex} groupkey={key} />}
              </>
            }
            </ul>
          </TaskGroupContainer>
        </AccordionItemPanel>
      </div>
      </AccordionItem>
  })

  const CompletedTasksList = ({ completedTasks }) => {
    const taskOfDay = completedTasks.filter(t => t.isMostImportant == 1);
    const onlyCompletedTasks = _.orderBy(completedTasks.filter(t => t.isMostImportant != 1), ['completedAt'], ['desc']);
    const isPastDate = moment(date).isBefore(currentDate, 'day');
    const [showAllCompletedTasks, setShowAllCompletedTasks] = useState(isPastDate);

    const toggleCompletedTasks = (e) => {
      e.preventDefault();
      setShowAllCompletedTasks(flag => !flag);
    }

    return (
      <li style={{marginBottom: '0px', background: theme == "dark" ? "black" : "white"}}>
        <div className={`toggle-completed-tasks-btn ${showAllCompletedTasks ? 'show' : 'hide'}`}>
          <div className='outstanding-box' css={{
            background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.95) 100%), ${colorPicker}` : `linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%), ${colorPicker}`,
            borderRadius: "15px"
          }}>
            {
              taskOfDay.length > 0 &&
              <div className='task-of-day'>
                {
                  taskOfDay.map((task, i) => {
                    return (
                      <SortableItem disabled={true} key={`item-${task.id}`} index={i} collection={99999} task={task} />
                    )
                  })
                }
              </div>
            }
            {
              onlyCompletedTasks.length > 0
              &&
              <>
                <a href="#" className='show-tasks-btn' style={{paddingTop: taskOfDay?.length > 0 ? '20px' : '0'}} onClick={toggleCompletedTasks}>
                  <CheckIcon color="gray" strokeWidth={1} />
                  Completed Tasks 
                  <span className='size ml-auto'>{onlyCompletedTasks.length}<ArrowUp color="gray" /></span>
                </a>
                {
                showAllCompletedTasks
                &&
                <ul>
                  {
                    onlyCompletedTasks.map((task, i) => {
                      return (
                        <SortableItem disabled={true} key={`item-${task.id}`} index={i} collection={99999} task={task} />
                      )
                    })
                  }
                </ul>
                }
              </>
            }
          </div>
        </div>
      </li>
    )
  }
  
  const NoTaskForDay = () => {
    return (
      <div>
        <div className="task-list-data">
          <div className='emptyDataList'>
            <h1 className='darkPrimaryColor'>No tasks for {calendarFormat(groupDate || dateChange)}</h1>
          </div>
        </div>
      </div>
    )
  }

  const AllDone = () => {
    return (
      <div className="task-list" style={{ background: theme == "dark" ? "black" : "white" }}>
        <div className="task-list-data">
          <div className='emptyDataList'>
            <h1 className='darkPrimaryColor'>All done :)</h1>
            <p className='darkPrimaryColor'>Enjoy your day!</p>
          </div>
        </div>
      </div>
    )
  }

  const groupedTasks = (items, completedTasks = []) => {
    let taskItems = items;
    const projectFilter = searchParams.get('project');
    let groupTaskListData = {};
    let groupCompletedTaskListData = {};
    let completedTaskItems = completedTasks;
    let projects = [];

    // Check the projects/tags filter
    if(projectFilter){
      taskItems = taskItems.filter(task => task?.Project?.title?.toLowerCase() === projectFilter?.toLowerCase());
      if(completedTasks){
        completedTaskItems = completedTasks.filter(task => task?.Project?.title?.toLowerCase() === projectFilter?.toLowerCase());
      }
    }

    const allTasksCompleted = _.find(taskItems, task => task.status == 0 && !task.deletedAt) ? false : true;

    if(tasksGroupBy !== 'none'){
      if(tasksGroupBy === 'project'){
        groupTaskListData = _.groupBy(taskItems.filter(task => task?.Project?.title?.length > 0), (task) => {
          return task?.Project?.title
        });
        groupTaskListData['@'] = taskItems.filter(task => !task?.Project?.title?.length);

        if(completedTaskItems){
          groupCompletedTaskListData = _.groupBy(completedTaskItems.filter(task => task?.Project?.title?.length > 0), (task) => {
            return task?.Project?.title
          });
        }

        taskProjects.forEach((project) => {
          if(groupTaskListData?.[project.title]?.length >= 0 || groupCompletedTaskListData?.[project.title]?.length >= 0){
            projects.push(project.title)
          }
        });

        groupTaskListData['@'].length === 0 && (delete groupTaskListData['@']);
      }

      if(tasksGroupBy === 'tag'){
        const fn = _.spread(_.union);
        let taskTagsList = fn(taskItems.map(task => task?.Tags?.length > 0 ? task.Tags.map(tag => tag.name) : []));
        taskTagsList = [...taskTagsList, '#'];
        taskTagsList.forEach((tag) => {
          if(tag === '#'){
            groupTaskListData[tag] = taskItems.filter(task => task.Tags.length === 0);
          }else{
            const tagTasks = taskItems.filter((task) =>
              task.Tags.some((taskTag) => taskTag.name === tag)
            );
            if (tagTasks.length) {
              groupTaskListData[tag] = tagTasks;
            }
          }
        });
      }
    }

    if(!projects.includes('@')){
      projects = ['@', ...projects]
    }

    if(Object.keys(groupTaskListData).length === 0){
      groupTaskListData['@'] = taskItems;
    }

    if(!groupTaskListData?.['@']){
      groupTaskListData['@'] = [];
    }

    if(tasksSortBy === 'Project.title' && tasksSortDirection){
      projects.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
      if(tasksSortDirection === 'desc'){
        projects.reverse();
      }
    }
    
    return {groups: groupTaskListData, projects, allTasksCompleted};
  }

  const SortableItem = sortableElement(({ task, taskIndex, isNowGroup }) => {
   // setTodayTask(task)
    return (
      <TaskList
        setCurrentDateTime={setCurrentDateTime}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        upComing={upComing}
        groupDate={groupDate}
        isNowGroup={isNowGroup}
        onAddTaskHome={onAddTaskHome}
        setOnAddTaskHome={setOnAddTaskHome}
        taskComplete={true}
        homePage={homePage}
        currentPage={currentPage}
        fetchTaskListData={fetchTaskListData}
        setDeleteTasks={setDeleteTasks}
        key={task.id}
        task={task}
        isDate={false}
        taskList={taskList}
        setAddTaskInputValue={setAddTaskInputValue}
        setEditTaskListData={setEditTaskListData}
        fetchAddTaskData={fetchAddTaskData}
        fetchTotalCount={fetchTotalCount}
        setDeleteLoader={setDeleteLoader}
        deleteLoader={deleteLoader}
        isTodayPage={isTodayPage}
        taskIndex={taskIndex}
        fetchMoreData={fetchMoreData}
      />
    )
  });

  const SortableContainer = sortableContainer(() => {
    const groupTaskListData = groupedTasks(tasksArray);
    const isGrouped = tasksGroupBy === 'project' || tasksGroupBy === 'tag';
    let preExpanded = ['taskgroup@'];
    
    if(isGrouped){
      preExpanded = groupTaskListData.projects.map((key) => collapsedGroupAccordions.includes(key) ? null : `taskgroup${key}`);
    }
    
    return <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={preExpanded}>
      <SortableList groupTaskListData={groupTaskListData} />
    </Accordion>;
  });

  const TaskGroupContainer = sortableContainer(({children}) => {
    return children
  })

  const SortableList = ({ groupTaskListData }) => {
    let nowTaskGroupIndex = null;
    const allTasksCompletedForToday = groupTaskListData.allTasksCompleted;
    
    return (
      groupTaskListData.projects.map((key, index) => {
        const completedTasks = groupTaskListData.groups[key].filter(i =>  i.status == 1);
        const inCompletedTasks = groupTaskListData.groups[key].filter(i =>  i.status != 1); 

        if(nowTaskGroupIndex === null){
          nowTaskGroupIndex = inCompletedTasks.length > 0 ? index : null;
        }
 
        return <TaskGroup key={`task-group-${key}`} groupIndex={index} index={index} collection={key == '@' && false ? 99999 : 1} disabled={key == '@' && false} groupkey={key} nowTaskGroupIndex={nowTaskGroupIndex} groupTaskListData={groupTaskListData} inCompletedTasks={inCompletedTasks} completedTasks={completedTasks} allTasksCompletedForToday={allTasksCompletedForToday} />
      })
    )
  };

  useEffect(() => {
    let tasksGroup = [];

    switch (currentPage) {
      case 'home':
        tasksGroup = [...(_.sortBy(taskList.filter(t => t.status != '1'), ['status'])), ...(_.orderBy(taskList.filter(t => t.status == '1'), ['isMostImportant', 'completedAt'], ['desc', 'desc']))];
        break;

      case 'upComing':
        tasksGroup = upComingtaskList.filter(t => !t.deletedAt && !t.completedAt);
        break;

      case 'overDue':
        tasksGroup = overDueTaskList.map(item => {
          const result = verifyRepeatedTask(item, null, [1], true);
          return result?.length > 0 ? {...item, date: new Date(result[0]).toISOString()} : false;
        }).filter(t => !t.deletedAt && !t.completedAt);

        break;

      case 'allTask':
        tasksGroup = allTaskList;
        break;

      case 'noDate':
        tasksGroup = noDataTaskList
        break;
    }

    if(currentPage !== 'home'){
      tasksGroup = tasksSortBy && tasksSortDirection ? caseInsensitiveSortBy(tasksGroup, tasksSortBy, tasksSortDirection) : tasksGroup;
    }
    
    setTasksArray(tasksGroup.filter(t => t.deletedAt || (currentPage === 'home' && !verifyRepeatedTask(t, dateChange, !(moment(t.date).startOf('date').toISOString() != moment(dateChange).startOf('date').toISOString()))) ? false : true));
  }, [currentPage, dateChange, upComingtaskList, taskList, noDataTaskList, overDueTaskList, allTaskList, tasksSortBy, tasksSortDirection])

  // useEffect(() => {
  //   collapsedGroupAccordions = [];
  // }, [currentPage, groupDate, dateChange, isAccordionOpen])

  useEffect(() => {
    setAllDatesTasks(taskGroupedList);
  }, [taskGroupedList])

  useEffect(() => {
    setAllHomePageTasks(taskList);
  }, [taskList])

  const onSortStart = ({node}) => {
    node.classList.add('task-item-dragging')
  }

  return (
    <AccordionItem uuid={`accitem${index}`} className={`accordion-item accitem${index} ${!tasksArray.length ? 'no-task' : ''}`}>
      <AccordionItemHeading onClick={toggleAccordion}>
        <AccordionItemButton>
          {
            currentPage == "upComing" && <div className={`accordion_tab cursor pointer`} style={{ background: theme == "dark" ? "black" : "white" }}>
              <div className="heading">
                <TasksListIcon color={colorPicker} />
                <h3 className="accordion-title darkPrimaryColor">{format(groupDate, 'DD MMMM YYYY')}</h3>
                <p>{calendarFormat2(groupDate)} &nbsp;&nbsp;{tasksArray?.length} {tasksArray?.length > 1 ? 'Tasks' : 'Task'}</p>
              </div>
              <ToggleAccordionArrow theme={theme} />
            </div>
          }
        </AccordionItemButton>
      </AccordionItemHeading>
      {
        <AccordionItemPanel>
        {
            isAccordionOpen &&
            <div className={`accordion_content tasks-list-accordion ${tasksGroupBy !== 'none' ? 'grouped-tasks' : 'non-grouped-tasks'}`}>
                {
                  tasksArray?.length == 0 
                  ? <NoTaskForDay />
                  : tasksArray.filter(i =>  i.status != 1).length == 0 && <AllDone />
                }
                <AddTaskStatic isTodayPage={true} taskStatus={{completed: tasksArray.filter(i =>  i.status == 1).length, total:  tasksArray.filter(i =>  i.status != 1).length + tasksArray.filter(i =>  i.status == 1).length}} groupsDropdown={currentPage === 'home'} currentPage={currentPage} groupDate={groupDate} classNames="padding top-20 bg none" className="bg none" separator={(tasksGroupBy === 'project' || tasksGroupBy === 'tag') ? false : true} />
                {
                  tasksArray?.length > 0 
                  &&
                  <div className="task-list">
                    <div className="task-list-data" css={{
                      '.task-item-dragging':{
                        borderRadius: '15px !important',
                        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), linear-gradient(0deg, #889427 0%, ${colorPicker} 100%), #FFF !important`,
                        '.task-sections':{
                          visibility: 'hidden'  
                        },
                        '.on-hover-list':{
                          visibility: 'hidden'
                        },
                        '.dragdrop-option':{
                          visibility: 'hidden'  
                        },
                        margin: '0px 20px !important',
                        zIndex: 1
                      }
                    }}>
                      <SortableContainer hideSortableGhost={true} useDragHandle={true} onSortStart={onSortStart} onSortEnd={onSortEndTaskGroup} helperClass="sortable-helper"></SortableContainer>
                    </div>
                  </div>
                }
            </div>
        }
        </AccordionItemPanel>
      }
    </AccordionItem>
  )
}

export default TaskListDetails