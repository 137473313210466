import React from 'react'
import { useSelector } from 'react-redux'

function RightArrow() {
  const {colorPicker , theme}=useSelector(state=>state.theme)
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <g opacity="0.3">
        <path d="M7.5 9.5L4.5 6.5L7.5 3.5" stroke={theme == "dark" ? "white" : "black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
    
  )
}

export default RightArrow