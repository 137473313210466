/** @jsxRuntime classic */
/** @jsx jsx */
import moment from 'moment'
import React from 'react'
import { Fragment } from 'react'
import { completedTask, deleteTask, totalTaskCount } from '../../Components/action/common'
import { setTotalCount, updateCompleteTaskStatus, updateTrashTaskStatus } from '../../redux/slices/TaskSlice'
import { useDispatch , useSelector} from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import EditTaskList from '../AddTasks/EditTaskList'
import Toaster from '../CustomToast/Toaster'
import toast from 'react-hot-toast'
import CalendarIcon from './CalendarIcon'
import HistoryIcon from './HistoryIcon'
import { jsx } from "@emotion/core";

function CompletedAndTrashList({fetchAddTaskData, currentPage, list}) {
  const dispatch = useDispatch()
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes));
  const [isEditable, setIsEditable] = useState(false)
  const [activeTaskValue,setActiveTaskValue]=useState(null)
  const {theme} =useSelector(state=>state.theme)
  const {settings} =useSelector(state=>state.settings)
  let dateFormate=settings?.dateFormat ? settings?.dateFormat : "DD/MM/YY"
  
  const onRestoreTask = async (taskDetail) => {
    try {
      if (currentPage == "completedList") {
        let requestBody = {
          "id": taskDetail?.id,
          "status": 0
        }
        let response = await completedTask(requestBody)
        dispatch(updateCompleteTaskStatus(requestBody))
        toast.custom(<Toaster currentPage={currentPage} title="Task Uncomplete" task={taskDetail}  fetchAddTaskData={fetchAddTaskData}/>)
        fetchTotalCount()
       

      } else if (currentPage == "trash") {
        let requestBody = {
          "id": taskDetail?.id,
          "undo": 1
        }
        let response = await deleteTask(requestBody)
        toast.custom(<Toaster fetchAddTaskData={fetchAddTaskData} currentPage={currentPage} title="Task Uncomplete" task={taskDetail} />)
        fetchTotalCount()
        dispatch(updateTrashTaskStatus(requestBody))
      }

    } catch ({ response }) {

    }
  }


  const fetchTotalCount = async () => {
    try {
      let response = await totalTaskCount({ "date": currentTime.toISOString() })
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({ response }) {
    }
  }

  
  useEffect(() => {
    fetchTotalCount()
  }, [])

  return (
    <>
      {
        list?.map((item ) => {
          let date = moment(item?.date).format(dateFormate);
          return (
            <>
              {
                 activeTaskValue == item?.id ? <>
                
                   <EditTaskList
                     item={item}
                     setEditTaskId={setActiveTaskValue}
                     fetchAddTaskData={fetchAddTaskData}
                     currentPage={currentPage}
                   />
                 </> : <li className="li-task-list " css={{
                   '&:hover .ut-data':{
                     color: theme == "dark" ? "black !important":""
                   },
                   '&:hover .check-label':{
                    color: theme == "dark" ? "black !important":""
                  },
                  
                 }}>
                  <div className="task-sections task-status " >
                    <div className="task-status-history" style={{ cursor: "pointer" }}>
                      <figure className="round-circle" style={{border : theme == "dark" ? "1px solid gray" :""}} onClick={() => onRestoreTask(item)}>
                        <HistoryIcon/>
                        {/* <img src="/images/history.svg" alt="history"  /> */}
                        </figure>
                    </div>
                    <div className="task-status-content" onClick={() => {setIsEditable(!isEditable);setActiveTaskValue(item?.id)}}>
                      <label className="check-label" style={{color : theme == "dark" ? "white" : "gray"}}>{item?.title}
                      </label>
                      {
                        item?.date != null && <div className="task-info">
                          <span className="task-deta">
                            {/* <img src="/images/light-calendar.svg" alt='' />  */}
                            <CalendarIcon/>
                            <label className="ut-data" style={{color : theme == "dark" ? "white" : "gray"}}>
                              {
                                moment().format(dateFormate) == date ? <span>Today</span> :  moment().add(1,"days").format(dateFormate) == date ? <span>Tomorrow</span> : moment().subtract(1,"days").format(dateFormate) == date ? <span>Yesterday</span> :<span>{date}</span>
                              }
                             </label></span>
                        </div>
                      }

                    </div>
                  </div>
                </li>
              }
            </>
          )
        })
      }
    </>


  )
}

export default CompletedAndTrashList