/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from "@emotion/core";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { decreaseTimer, increaseTimer, pauseSound, resumeTimer, setTimerCompleted, stopTimer } from '../../redux/slices/timer';
import { focusTimerFormatV5 } from '../action/utilities';
import moment from 'moment';
import { setFocusTasksList } from '../../redux/slices/TaskSlice';
import { saveSessions } from '../action/timer';
import { decodeToken } from "react-jwt";
import { useSocket } from '../../SocketContext';
import { ArrowUp, CheckIcon, StopIcon, TargetIcon, Teacup } from '../LandingHeader/Feedback/icons/Icons';
import TimeDropdown from './TimeDropdown';
import TasksStatusWidget from '../../pages/Tasks/TasksStatusWidget';
import TaskList from '../../pages/Tasks/TaskList';
import { usePosthog } from '../../PosthogProvider';

function FocusModeCompletedV2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme, colorPicker } = useSelector(state => state.theme);
  const focusTimer = useSelector(state => state.focusTimer);
  const { activeTimer, focusSessions, pause, timerCompleted, timer } = focusTimer;
  const {focusTasksList} = useSelector(state => state.task);
  const { settings } = useSelector(state => state.settings);
  const [todaySessions, setTodaySessions] = useState([]);
  const [showModal, setShowModal] = useState(!pause);
  const [showCompletedTasks, setShowCompletedTasks] = useState(true);
  const [tasksByStatus, setTasksByStatus] = useState({
    completed: [],
    incomplete: []
  });
  const [showIncompleteTasks, setShowIncompleteTasks] = useState(true);
  const socket = useSocket();
  const { captureEvent } = usePosthog();

  useEffect(() => {
    if(activeTimer === null){
        navigate(process.env.REACT_APP_ROOT)
    }else{
      focusSessions?.length > 0 && saveSessions({"sessions": {sessions: focusSessions}});
      // Capture focus session completed event
      captureEvent('Focus Session Completed');
    }
  }, []);

  useEffect(() => {
    const completedTasks = focusTasksList.filter(task => task.status === 1);
    const incompleteTasks = focusTasksList.filter(task => task.status !== 1);
    setTasksByStatus({ completed: completedTasks, incomplete: incompleteTasks });
  }, [focusTasksList]);

  useEffect(() => {
    if(!showModal){
      pauseSound();
    }
  }, [showModal])

  useEffect(() => {
      setTodaySessions(focusSessions.filter(session => {
          return moment().format('L') === moment(session.startTime).format('L')
      }));
      
  }, [focusSessions])

  const calcFocusedTime = (lastSession = false) => {
    let seconds = 0;
    let pauseSeconds = 0;

    if(!todaySessions.length)
      return '';

    if(lastSession){
      const session = todaySessions[todaySessions.length - 1];
      seconds += session.endTime - session.startTime;
      session?.pauses.map(pause => {
        pauseSeconds += pause.endTime - pause.startTime;
      });
    }else{
      todaySessions.map(session => {
        seconds += session.endTime - session.startTime;
        session?.pauses.map(pause => {
          pauseSeconds += pause.endTime - pause.startTime;
        });
      });
    }

    return focusTimerFormatV5((seconds - pauseSeconds)/1000, true);
  }

  const stopSound = (e) => {
    e.preventDefault();
    setShowModal(false);
    dispatch(setTimerCompleted());
  }

  const stopAction = (e) => {
    e.preventDefault();
    dispatch(stopTimer());
    dispatch(setFocusTasksList([]));
    // bc.postMessage({ action: "stopTimer", redirect: process.env.REACT_APP_ROOT });
    triggerWebSocket({ action: "stopTimer", redirect: process.env.REACT_APP_ROOT })
    navigate(process.env.REACT_APP_ROOT);
  }

  const newSessionAction = (e) => {
    e.preventDefault();
    dispatch(stopTimer(parseInt(settings?.defaultTimePeriodFocus ?? '25') * 60));
    dispatch(setFocusTasksList([]));
    // bc.postMessage({ action: "stopTimer", redirect: '/focus-mode-settings' });
    triggerWebSocket({ action: "stopTimer", redirect: '/focus-mode-settings' })
    navigate('/focus-mode-settings');
  }

  const increaseDecreaseTimer = (timerSpan, type, resume = false) => {
    if(type == 'decrease'){
        dispatch(decreaseTimer(timerSpan));
        // bc.postMessage({ action: "decreaseTimer", timerSpan });
        triggerWebSocket({ action: "decreaseTimer", timerSpan })
    }else{
        dispatch(increaseTimer(timerSpan));
        // bc.postMessage({ action: "increaseTimer", timerSpan });
        triggerWebSocket({ action: "increaseTimer", timerSpan })
    }

    resume && setTimeout(() => {
        dispatch(resumeTimer('focusMode'));
        // bc.postMessage({ action: "resumeTimer", redirect: process.env.REACT_APP_ROOT });
        triggerWebSocket({ action: "resumeTimer", redirect: process.env.REACT_APP_ROOT })
        navigate(process.env.REACT_APP_ROOT);
    }, 1)
  }

  const triggerWebSocket = (data) => {
    // Trigger timer in wepapp and other clients
    if (socket !== null){
        const user = decodeToken(localStorage.getItem('token'));
        socket.emit('focusTimer', {room: user['outseta:accountUid'], data});
    }
  }

  const toggleCompletedTasks = (e) => {
    e.preventDefault();
    setShowCompletedTasks(flag => !flag);
  }

  const toggleIncompleteTasks = (e) => {
    e.preventDefault();
    setShowIncompleteTasks(flag => !flag);
  }

  const lastSessionCompletionTime = calcFocusedTime(true);
  const showSummary = !(showModal && !timerCompleted);

  return (
    <div className={`main-body-wrap focus-timer-bg break-focus-page break-timer-start focus-mode-timer-start focus-mode-timer-completed ${theme}`}
    css={{
        background: 'none', 
        backdropFilter: 'blur(0px)',
        '.hr-line':{
            '&::after':{
                left: '20px'
            }
        }
    }}>
      <div className="page-wrapper focus-mode-start" id="focus-mode-settings-page">
        <div className="page-container" css={{overflowY: 'auto', paddingBottom: '120px'}}>
            <div className={showSummary ? 'focus-timer-summary' : ''}>
                <div className='focus-timer-complete-body'>
                    <div className='inner-body'>
                        <div className='inner-body-title'>
                            <div className='inner-body-title-left'>
                                <TargetIcon color={colorPicker} fill={colorPicker} />
                                <h2 className='darkPrimaryColor'>Focus Session {todaySessions.length} Complete</h2>
                            </div>
                            <TasksStatusWidget className="focus-timer-widget" taskStatus={{completed: tasksByStatus.completed.length, total: focusTasksList.length}} direction='right' />
                        </div>
                    </div>
                    <div className='inner-body'>
                        <div className='inner-body-content'>
                            <h1><span style={{color: colorPicker}} dangerouslySetInnerHTML={{__html: lastSessionCompletionTime}}></span><span className='darkPrimaryColor'> spent focused</span></h1>
                            <div className='inner-body-content-actions'>
                                <span style={{color: theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}} dangerouslySetInnerHTML={{ __html: `/ ${focusTimerFormatV5(timer, true)}` }} />
                                <TimeDropdown colorPicker={colorPicker} theme={theme} increaseDecreaseTimer={increaseDecreaseTimer} />
                                <span style={{color: theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}}>Add more time, if needed</span>
                            </div>
                            {showSummary && <p>You did <span dangerouslySetInnerHTML={{__html: lastSessionCompletionTime?.length ? lastSessionCompletionTime : '0 min'}}></span> of deep work today!</p>}
                        </div>
                    </div>
                    <div className='inner-body align-center fd-row justify-center'>
                        {
                            !showSummary
                            ?
                            <a href="#" className='button primary' onClick={stopSound} css={{background: `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}`}}><StopIcon color={colorPicker} /><span className='darkPrimaryColor'>Stop</span></a>
                            :
                            <>
                                <a href="#" onClick={newSessionAction} className='button primary darkPrimaryColor'><Teacup color={colorPicker} /> Start New Session</a>
                                <a href="#" onClick={stopAction} className='button primary end-session' css={{background: `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}`}}><StopIcon color={colorPicker} opacity={1} /> <span className='darkPrimaryColor'>End Session</span></a>
                            </>
                        }
                    </div>
                </div>
                {
                    showSummary
                    &&
                    <>
                        <div className={`toggle-completed-tasks-btn ${showCompletedTasks ? 'show' : 'hide'}`} css={{color: colorPicker}}>
                            <div>
                                <a href="#" className='show-tasks-btn' onClick={toggleCompletedTasks}>
                                    <CheckIcon color="gray" strokeWidth={1} />
                                    Completed Tasks 
                                    <span className='size ml-auto'>{tasksByStatus.completed.length}<ArrowUp color="gray" /></span>
                                </a>
                                {
                                    showCompletedTasks
                                    &&
                                    <ul className="ul-task-list">
                                    {
                                        tasksByStatus.completed.map(task => ( 
                                            <TaskList task={task} key={task.id} disableSort={true} disableActions={true} disableHover={true} currentPage="home" />
                                        ))
                                    }
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className={`toggle-completed-tasks-btn ${showIncompleteTasks ? 'show' : 'hide'}`}>
                            <div>
                                <a href="#" className='show-tasks-btn' onClick={toggleIncompleteTasks} css={{color: colorPicker}}>
                                    <CheckIcon color="gray" strokeWidth={1} />
                                    Tasks for Another Time
                                    <span className='size ml-auto'>{tasksByStatus.incomplete.length}<ArrowUp color="gray" /></span>
                                </a>
                                {
                                    showIncompleteTasks
                                    &&
                                    <ul className="ul-task-list">
                                    {
                                        tasksByStatus.incomplete.map(task => ( 
                                            <TaskList task={task} key={task.id} disableSort={true} disableActions={true} currentPage="home" />
                                        ))
                                    }
                                    </ul>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
      </div>
    </div>
  )
}

export default FocusModeCompletedV2
