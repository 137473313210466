import React from 'react';
import { Outlet } from 'react-router-dom';
import TaskSidebar from '../../Components/SideBar/TaskSidebar';
import TaskSearchBar from '../../Components/common/TaskSearchBar';
import ProjectSidebar from './ProjectSidebar';

function TasksLayout({ children }) {

  return (
    <>
        {/* <div id="tasks-sidebar">
            <TaskSidebar />
            <ProjectSidebar />
        </div> */}
        <div className="form-content">
          <TaskSearchBar />
          {
              children ?? <Outlet />
          }
        </div>
    </>
  )
}

export default TasksLayout