/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector } from 'react-redux';
import BackgroundImage from "../common/BackgroundImage";
import AIAssistant from "../SideBar/AIAssistant";
import Chat from "./Chat";

function Fullscreen() {
  const { themeColor, colorPicker, theme } = useSelector(state => state.theme);

  return (
    <div className="form-content">
        <Chat fullscreen={true} />
    </div>
  )
}

export default Fullscreen
