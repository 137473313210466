/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import PlusIcon from "./PlusIcon";
import TasksStatusWidget from "../Tasks/TasksStatusWidget";
import TaskModal from "../Settings/Modal/TaskModal";
import GroupByDropdown from "../../Components/common/GroupByDropdown";

const AddTaskStatic = React.memo(({taskStatus = null, currentPage = "home", className = "", groupDate = null, isTodayPage = false, groupsDropdown = false, groupKey = null, classNames = "", separator = true}) => {
  const { colorPicker, theme } = useSelector(state => state.theme);
  const [openTaskModal, setOpenTaskModal] = useState(null);

  return (
    <>
        <div className={`type-header relative ${classNames} ${separator ? 'hr-line' : ''}`}>
            <form 
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                className="add-task-static"
                >
                <div className={`filed-type ${className}`} style={{ width: '100%', background: theme == "dark" ? "#000" : "#fff", borderBottom: '0 !important' }} 
                css={{
                        input: {
                        color: theme == "dark" ? "white !important" : ""
                        }
                    }}>
                    <div style={{ position: 'relative', overflowY: 'visible' }}>
                    <div style={{ fontSize: '16px', height: '18px' }}>
                        <div style={{
                        position: 'relative',
                        boxSizing: 'border-box',
                        width: '100%',
                        color: 'transparent',
                        overflow: 'hidden',
                        whiteSpace: 'pre',
                        border: '1px solid transparent',
                        textAlign: 'start'
                        }}> </div>
                        <input
                        type="text"
                        placeholder="Add a Task..."
                        className="add-task-input active"
                        readOnly={true}
                        css={{
                            display: 'block',
                            width: '100%',
                            position: 'absolute',
                            margin: '0px',
                            top: '0px',
                            left: '0px',
                            boxSizing: 'border-box',
                            backgroundColor: 'transparent',
                            fontFamily: 'inherit',
                            fontSize: 'inherit',
                            letterSpacing: 'inherit',
                            border: '0px',
                            '&::placeholder': {
                                color: theme == "dark" ? "white" : ""
                            }
                        }}
                        onClick={(e) => {
                            e.preventDefault()
                            setOpenTaskModal({currentPage, groupDate})
                        }}
                        />
                    </div>
                    </div>
                    <span className="add-sqc-static">
                        <PlusIcon />
                    </span>
                    <div className="press-option">
                    <span className="pr-content" style={{ color: 'gray', opacity: 0.3 }}>Press Ctrl+Enter</span>
                    </div>
                </div>
                {
                    isTodayPage && taskStatus && <TasksStatusWidget styles={groupsDropdown ? {width: '280px', marginRight: '10px'} : {}} taskStatus={taskStatus} />
                }
                {
                    groupsDropdown
                    &&
                    <GroupByDropdown />
                }
                
            </form>
        </div>
        {
            openTaskModal
            &&
            <TaskModal
                groupKey={groupKey}
                show={true}
                data={null}
                currentPage={openTaskModal.currentPage}
                groupDate={openTaskModal.groupDate}
                onHide={() => {
                    setOpenTaskModal(null)
                }}
            />
        }
    </>
  );
});

export default AddTaskStatic;