import axios from "axios";

export const saveSessions = (props) => {
    return axios({
      method: 'POST',
      url: '/focus-sessions',
      data: props
    })
  }
  
  export const fetchSessions = (props = {}) => {
    return axios({
      method: 'GET',
      url: '/focus-sessions',
      params: props
    })
  }