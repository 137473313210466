import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { THEME } from "../../Components/common/constants";

const initialState = {
  colorPicker: "#27CB9A",
  colorPickerRGB: 'rgb(39 203 154 / 10%)',
  newBackgroundPopup: false,
  themeColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF',
  theme: 'light',
  colorPickerMode: "Light",
  backgroundImageId: null,
  backgroundImages: [],
  colorPickerPopup: false
}

const ThemeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setColorPickerMode(state, action) {
      state.colorPickerMode = action.payload
    },
    setTheme(state, action) {
      state.theme = 'light';//action.payload
    },

    setThemeColor(state, action) {
      state.themeColor = action.payload
    },

    setNewBackgroundColor(state, action) {
      state.newBackgroundPopup = action.payload
    },

    setColorPicker(state, action) {
      state.colorPicker = action.payload ?? THEME.DEFAULT_COLOR
    },
    setColorPickerRGB(state,action){
      state.colorPickerRGB=action.payload
    },
    setThemeDefaultImage(state, action) {
      state.backgroundImageId = action.payload
    },
    setBackgroundImages(state, action) {
      state.backgroundImages = action.payload
    },
    setBackgroundImage(state, action) {
      state.backgroundImages = [...state.backgroundImages, action.payload]
    },
    setColorPickerPopup(state, action) {
      state.colorPickerPopup = action.payload
    }
  }
})


// reducers exports
export const {
  
  setColorPickerMode,
  setTheme,
  setThemeColor,
  setNewBackgroundColor,
  setColorPicker,
  setColorPickerRGB,
  setThemeDefaultImage,
  setBackgroundImage,
  setBackgroundImages,
  setColorPickerPopup
} = ThemeSlice.actions;

export default ThemeSlice.reducer;