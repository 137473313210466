/* eslint-disable */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { useSelector } from 'react-redux';
import FocusTimer from "./FocusTimer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setFocusTasksList } from "../../redux/slices/TaskSlice";
import { CheckFilledIcon, CirclePlusIcon } from "../LandingHeader/Feedback/icons/Icons";
import { setTimerFullscreen, startTimer } from "../../redux/slices/timer";
import DragIcon from "../../pages/Tasks/DragIcon";
import { arrayMoveImmutable } from 'array-move';
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import EditTaskList from "../../pages/AddTasks/EditTaskList";
import { decodeToken } from "react-jwt";
import { useSocket } from "../../SocketContext";
import { verifyRepeatedTask } from "../action/utilities";
import RecurringIcon from "../../pages/Tasks/RecurringIcon";
import TaskSourceIcon from "../../pages/Tasks/TaskSourceIcon";
import AddTaskStatic from "../../pages/AddTasks/AddTaskStatic";

const DragHandle = sortableHandle(() => <span className="dragdrop-option"><DragIcon /></span>);

const SortableItem = sortableElement(({children, theme, colorPicker, editTaskHandler, task}) => (
  <li className="task-item li-task-list hr-line"
      css={{
        background: theme == "dark" ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),#000000 !important;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`,
        'span.title': {
          color: theme == "dark" ? "white !important" : "black !important"
        },
      }}

      onClick={e => editTaskHandler(e, task.id)}
  >
    <DragHandle />
    {children}
  </li>
));

const SortableContainer = sortableContainer(({children}) => {
    
    return (
      <ul className="ul-task-list ul-task-list-focused">{children}</ul>
    )
});

function FocusModeSettings() {
  const { themeColor, colorPicker, theme } = useSelector(state => state.theme);
  const {focusTasksList} = useSelector(state => state.task);
  const {taskList} = useSelector(state => state.task);
  const [todayTasks, setTodayTasks] = useState([...focusTasksList]);
  const [focusTasks, setFocusTasks] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [submit, setSubmit] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [updatedValue, setUpdatedValue] = useState(null);
  const dispatch = useDispatch();
  const socket = useSocket();

  useEffect(() => {
    const pendingTasks = taskList.filter(t => t.status != 1 && !t.deletedAt && verifyRepeatedTask(t, moment().toISOString(), moment(t?.date).startOf('date').toISOString() == moment().startOf('date').toISOString()));
      
    if(pendingTasks?.length > 0){
      const newTasks = pendingTasks.filter(a => !todayTasks.map(b=>b.id).includes(a.id)).map(task => (!isMounted ? task : {...task, focus: 1}));
      setTodayTasks([...newTasks, ...todayTasks]);
    }
    setIsMounted(true);
  }, [taskList])

  useEffect(() => {
    if(updatedValue !== null){
      setTodayTasks(tasks => tasks.map(task => task.id == updatedValue.id ? {...task, title: updatedValue.title} : task));
    }
  }, [updatedValue])

  useEffect(() => {
    const focusTasks = todayTasks.filter(task => task?.focus === 1);
    setSubmit(focusTasks.length > 0);
    dispatch(setFocusTasksList(focusTasks));
    triggerWebSocket({ action: "focusTasksList", tasks: focusTasks })
  }, [todayTasks])

  const addToFocusList = (e, task) => {
    e.preventDefault();

    setTodayTasks(todayTasks => todayTasks.map(t => t.id != task.id ? t : {...task, focus: 1}));
  };

  const removeFocusTask = (e, task) => {
    e.preventDefault();

    setTodayTasks(todayTasks => todayTasks.map(t => t.id != task.id ? t : {...task, focus: 0}));
  }

  const startTimerHandler = (e) => {
    e.preventDefault();
    if(submit){
      //dispatch(setTimerFullscreen(true))
      dispatch(startTimer('focusMode'));

      if (typeof window.chrome != 'undefined' && window?.chrome?.storage) {
        window.chrome.runtime?.sendMessage({ action: 'focus-timer-store-tabId' });
      }
    }else{
      //setShowPopup(true);
    }
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    setTodayTasks(arrayMoveImmutable(todayTasks, oldIndex, newIndex))
  };

  const editTaskHandler = (e, id) => {
    if(!e.target?.closest('.task-action'))
      setEditTask(id);
  }

  const triggerWebSocket = (data) => {
    // Trigger timer in wepapp and other clients
    if (socket !== null){
        const user = decodeToken(localStorage.getItem('token'));
        socket.emit('focusTimer', {room: user['outseta:accountUid'], data});
    }
  }

  return (
    <div className="main-body-wrap" css={{overflow: "auto"}}>
      <div className={`page-wrapper ${theme}`} id="focus-mode-settings-page">
        <div className="page-container">
            <FocusTimer defaultOption="focusMode" timerOptions = {false} showTimeTabs = {true} /> 
            <div className="tasks-list-container">
                <div className="focus-session-tasks" style={{background: theme == "dark" ? '#000' : '#fff'}}>
                    <div className="focused-items-heading">
                      Selected Tasks {focusTasksList.length > 0 ? <span style={{color: colorPicker}}>{focusTasksList.length} Selected</span> : null}
                    </div>
                    <li className="task-item li-task-list" css={{'&:hover': {background: 'none'}}}>
                        <AddTaskStatic />
                    </li>
                    <SortableContainer onSortEnd={onSortEnd} helperContainer={document.querySelector('.ul-task-list-focused')} useDragHandle>
                      {todayTasks.map((task, index) => (
                        task?.focus === 1
                        ?
                        (
                            editTask === task.id
                            ?
                            <EditTaskList hideActions={true} item={task} setUpdatedValue={setUpdatedValue} setEditTaskId={setEditTask} />
                            :
                            <SortableItem key={`item-${task.id}`} index={index} colorPicker={colorPicker} theme={theme} editTaskHandler={editTaskHandler} task={task}>
                              <>
                                <span className="checkmark"
                                    css={{
                                        border: theme == "dark" ? '1px solid white !important' : "1px solid black !important",
                                        borderRadius: "20px",
                                        width: "15px",
                                        height: "13px",
                                    }}
                                />
                                <span
                                    className="title"
                                    css={{
                                        color: theme == "dark" ? "white !important" : "black !important",
                                    }}
                                >{task?.title}
                                <div className="d-flex items-center justify-start gap-10">
                                  <TaskSourceIcon task={task} colorPicker={colorPicker} />
                                  <RecurringIcon task={task} colorPicker={colorPicker} />
                                </div>
                                </span>
                                <a href="#" className="task-action" onClick={e => removeFocusTask(e, task)}><CheckFilledIcon color={colorPicker} /></a>
                              </>
                            </SortableItem>
                        )
                        :
                        null
                      ))}
                    </SortableContainer>
                    <ul className="ul-task-list">
                      {
                        todayTasks.map((task, index) => (
                          task?.focus !== 1
                          ?
                          <>
                            {
                              editTask === task.id
                              ?
                              <EditTaskList hideActions={true} item={task} setUpdatedValue={setUpdatedValue} setEditTaskId={setEditTask} />
                              :
                              <li key={task.id} className={`task-item li-task-list hr-line ${task.id}`}
                                  css={{
                                    '&:hover': {
                                      background: theme == "dark" ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),#000000 !important;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`,
                                    },
                                    '&:hover span.title': {
                                      color: theme == "dark" ? "white !important" : "black !important"
                                    },
                                  }}

                                  onClick={e => editTaskHandler(e, task.id)}
                              >
                                <span className="checkmark"
                                    css={{
                                        border: theme == "dark" ? '1px solid white !important' : "1px solid black !important",
                                        borderRadius: "20px",
                                        width: "15px",
                                        height: "13px",
                                    }}
                                />
                                <span
                                    className="title"
                                    css={{
                                        color: theme == "dark" ? "white !important" : "black !important",
                                    }}
                                >{task?.title}
                                <div className="d-flex items-center justify-start gap-10">
                                  <TaskSourceIcon task={task} colorPicker={colorPicker} />
                                  <RecurringIcon task={task} colorPicker={colorPicker} />
                                </div>
                                </span>
                                <a href="#" className="task-action" onClick={e => addToFocusList(e, task)}><CirclePlusIcon color={colorPicker} /></a>
                              </li>
                            }
                          </>
                          :
                          null
                        ))
                      }
                    </ul>
                </div>
            </div>

            <button className={`start-session-btn ${submit ? '' : 'disabled'}`} style={{background: colorPicker}} onClick={startTimerHandler}>{submit ? "Start Session" : "Add Tasks to Begin"}</button>
        </div>
      </div>
      {/* {
        showPopup
        &&
        <Popup onHide={e => setShowPopup(false)} show={showPopup} text='Please ensure at least one task is added before starting the session.' />
      } */}
    </div>
  )
}

export default FocusModeSettings
