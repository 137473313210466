/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import './style.css';
import { useSelector } from 'react-redux';
import { DragHandler } from '../../pages/Tasks/DragHandler';
import { FocusMode } from "../LandingHeader/Feedback/icons/Icons";
import FocusModeSettingsModal from "../../pages/Settings/Modal/FocusModeSettingsModal";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { focusTimerFormatV3 } from "../action/utilities";

function FocusTimerWidget() {
    const { theme, colorPicker } = useSelector(state => state.theme);
    const [settingsModal, setSettingModal] = useState(false);
    const [todaySessions, setTodaySessions] = useState([]);
    const { focusSessions } = useSelector(state => state.focusTimer);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(location.pathname === '/focus-mode-settings'){
            setSettingModal(true);
        }else{
            setSettingModal(false);
        }
    }, [location.pathname])

    useEffect(() => {
        setTodaySessions(focusSessions.filter(session => {
            return moment().format('L') === moment(session.startTime).format('L')
        }));
        
    }, [focusSessions])

    const startTimerHandler = (e) => {
        e.preventDefault();
        navigate('/focus-mode-settings');
    }

    const removeModal = (e) => {
        e && e.preventDefault();
        navigate(process.env.REACT_APP_ROOT);
    }

    const calcFocusedTime = () => {
        let seconds = 0;
        let pauseSeconds = 0;
        todaySessions.map((session, index) => {
            let lastPause = 0;
            session?.pauses.map(pause => {
                pauseSeconds += pause.endTime - pause.startTime;
                lastPause = pause;
            });

            seconds += (session.endTime ? session.endTime : (lastPause?.endTime ?? session.startTime)) - session.startTime;
        });

        return focusTimerFormatV3((seconds - pauseSeconds) / 1000, true);
    }

    return (
        <div className='bar widgetWrapper'>
            <div className={`dailyQuote quoteInner focus-timer ${theme ?? ''}`}>
                <div className="quoteContainer focus-timer-container">
                    <DragHandler />
                    <>
                        <div className='focusTimerSt'>
                            {
                                todaySessions.length > 0
                                ?
                                <div className="slectFocusOpt" style={{color: theme === 'dark' ? '#fff' : '#000'}}><span css={{
                                    color: colorPicker,
                                    opacity: '1 !important',
                                    'span': {
                                        opacity: '1 !important'
                                    }
                                }} dangerouslySetInnerHTML={{__html: calcFocusedTime()}} /> focused today...</div>
                                :
                                <div className={`slectFocusOpt`} style={{gap: '10px', color: theme === 'dark' ? '#fff' : '#000'}}><FocusMode color={colorPicker} /><span>Focus</span></div>
                            }
                            
                            <div className='focusStart'>
                                <button id="openFocusTimerSettings" onClick={startTimerHandler} className='startEvnt' style={{color: 'grey', background: theme == "dark" ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF'}}  css={
                                    {
                                        '&:hover': {background: `${colorPicker} !important`, color: `${theme == 'dark' ? '#000' : '#fff'} !important`},
                                        '&:hover svg path': {
                                            stroke: theme == 'dark' ? "#000000" : '#ffffff'
                                        }
                                    }
                                }>Start Session</button>
                            </div>
                        </div>
                        {
                            settingsModal
                            &&
                            <FocusModeSettingsModal todaySessions={todaySessions} show={true} onHide={removeModal} />
                        }
                    </>
                </div> 
            </div>
        </div>
    )
}

export default FocusTimerWidget