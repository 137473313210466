const OUTSETA_ID = "LifetabOutseta";
let outsetaPromise = null;

const createScript = (OPTIONS) => {
  const optionsKey = `${OUTSETA_ID}Options`;

  // Set the options on window
  window[optionsKey] = {id: OUTSETA_ID, ...OPTIONS};
  document.getElementById("outseta-script")?.remove();
  // Create external script element
  const script = document.createElement("script");
  script.src = "https://cdn.outseta.com/outseta.min.js";
  script.id = "outseta-script";
  // Set name of options key on window
  script.dataset.options = optionsKey;

  return script;
};

export const loadOutseta = async (OPTIONS) => {
  // In case loadOutseta is called several times,
  // lets make sure we only make one promise.
 // if (outsetaPromise) return outsetaPromise;

  outsetaPromise = new Promise((resolve, reject) => {
    delete window[OUTSETA_ID];
    if (window[OUTSETA_ID]) {
      // If Outseta is initialized
      // lets resolve right away
      resolve(window[OUTSETA_ID]);
    } else {
      const script = createScript(OPTIONS);

      script.onload = () => {
        if (window[OUTSETA_ID]) {
          resolve(window[OUTSETA_ID]);
        } else {
          reject(new Error("Outseta.js not available"));
        }
      };

      script.onerror = () => {
        reject(new Error("Failed to load Outseta.js"));
      };

      document.head.appendChild(script);
    }
  });

  return outsetaPromise;
};
