/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useRef, useState } from 'react'
import { createProject, updateProject } from "../../../Components/action/tasks";
import { SaveIcon } from "../../../Components/LandingHeader/Feedback/icons/Icons";
import { usePosthog } from "../../../PosthogProvider";

const RenameProjectModal = ({onHide, project}) => {
    const titleRef = useRef('');
    const [error, setError] = useState('');
    const { captureEvent } = usePosthog();

    useEffect(() => {
        document.addEventListener('click', documentClickHandler);
    
        return () => {
          document.removeEventListener('click', documentClickHandler);
        };
    }, [])
    
    const documentClickHandler = (e) => {
        if(!e.target.closest('.project-list-item')){
            setError('')
            onHide()
        }
    }

    const renameProjectHandler = async (e, project) => {
        e.preventDefault()
        e.stopPropagation()
        
        const value = titleRef.current.value;

        if(!value.trim()){
            setError('Please enter a new project name')
            return
        }

        try{
            if(project?.project_id){
                await updateProject(project.project_id, value)
                // Capture project added or renamed event
                captureEvent('Project Renamed', {
                    old_project_name: project?.title ?? '',
                    new_project_name: value.trim()
                })
            }else{
                await createProject(value)
                // Capture project added or renamed event
                captureEvent('New Project Added', {
                    new_project_name: value.trim()
                })
            }
            setError('')
            onHide(true, null, project?.project_id, value.trim(), project?.projectSource)
        }catch(err){
            console.log('err', err)
            setError(err.response.data.message)
        }
    }

    return (
        <>
            <input type="text" defaultValue={project?.title} placeholder={project?.title ?? 'New Project...'} autoFocus={true} className='font size-16 border stroke-0 outline-none' css={{width: 'calc(100% - 60px)'}} ref={titleRef} onKeyDown={(e) => {
                if(e.key == 'Enter'){
                    renameProjectHandler(e, project)
                }else if(e.key == 'Escape'){
                    setError('')
                    onHide()
                }
            }} />
            <a href="#" onClick={(e) => renameProjectHandler(e, project)}><SaveIcon /></a>
            {error && <p className='font size-14 opacity d5 color-red font-family inter padding top-0'>{error}</p>}
        </>
    )
}

export default RenameProjectModal;