/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useRef } from 'react'
import { saveConversation } from '../action/chatGPT';
import { Cancel, Tick } from '../LandingHeader/Feedback/icons/Icons';

const EditChatTitle = ({theme, colorPicker, trigger, cancelAction, c}) => {
    const field = useRef('');

    const save = (e) => {
        e.preventDefault();
        if(field.current.value.replace(/^\s|\s$/, '').length === 0){
            return false;
        }
        const data = {id: c.id, title: field.current.value, conversation: c.conversation};
        saveConversation(data).then(response => {
            trigger(data);
        });
    }

    const cancel = (e) => {
        e.preventDefault();
        cancelAction(null);
    }

    return (
        <form className='edit-chat-title' onSubmit={save} style={{width: '100%', display: 'flex'}} css={{
            'a': {
                background: `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}`,
                '&:hover': {
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), ${colorPicker}`
                }
            }
        }}>
            <div>
                <input className='darkPrimaryColor' autoFocus={true} type="text" defaultValue={c.title} ref={field} />
            </div>
            <a href="#" onClick={save}><Tick color={theme == 'dark' ? 'gray' : 'black'} /></a>
            <a href="#" onClick={cancel}><Cancel color={theme == 'dark' ? 'gray' : 'black'} /></a>
        </form>
    )
}

export default EditChatTitle