/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { useSelector } from 'react-redux'
import { jsx } from "@emotion/core";

function CardsIcon({onMouseEnterCards , isCardActive , isActiveSettings}) {
  const {theme , colorPicker} =useSelector(state=>state.theme)

  let strCol=(((theme == "dark" && colorPicker == "#ef233c")) && (onMouseEnterCards || (isCardActive && isActiveSettings == "cards"))) ? "white" : 
  ((theme == "dark" && colorPicker == "#27cb9a") && (onMouseEnterCards || (isCardActive && isActiveSettings == "cards"))) ? "black" : 
  ((theme == "dark" && colorPicker == "#f8961e") && (onMouseEnterCards || (isCardActive && isActiveSettings == "cards"))) ? "black":
  ((theme == "dark" && colorPicker == "#ffca3a") && (onMouseEnterCards || (isCardActive && isActiveSettings == "cards"))) ? "black":
  ((theme == "dark" && colorPicker == "#4cc9f0") && (onMouseEnterCards || (isCardActive && isActiveSettings == "cards"))) ? "black":
  ((theme == "dark" && colorPicker == "#3a86ff") && (onMouseEnterCards || (isCardActive && isActiveSettings == "cards"))) ? "white":
  ((theme == "dark" && colorPicker == "#9b5de5") && (onMouseEnterCards || (isCardActive && isActiveSettings == "cards"))) ? "white":
  ((theme == "dark" && colorPicker == "#ef476f") && (onMouseEnterCards || (isCardActive && isActiveSettings == "cards"))) ? "white":
  "gray"


  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
      {
        strCol ?  <path d="M6.66667 2H2V8H6.66667V2Z" stroke={strCol} css={{
          opacity:0.7,
        }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :  <path d="M6.66667 2H2V8H6.66667V2Z" stroke={`${((theme == "dark" && onMouseEnterCards) || isCardActive) ? "black" : "gray"}`} css={{
          opacity:0.7,
        }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ?  <path d="M14 2H9.33337V5.33333H14V2Z" stroke={strCol} css={{
          opacity:0.7,
        }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :  <path d="M14 2H9.33337V5.33333H14V2Z" stroke={`${((theme == "dark" && onMouseEnterCards) || isCardActive) ? "black" : "gray"}`} css={{
          opacity:0.7,
        }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
     {
        strCol ?  <path d="M14 8H9.33337V14H14V8Z" stroke={strCol} css={{
          opacity:0.7,
        }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :  <path d="M14 8H9.33337V14H14V8Z" stroke={`${((theme == "dark" && onMouseEnterCards) || isCardActive) ? "black" : "gray"}`} css={{
          opacity:0.7,
        }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
       {
        strCol ?  <path d="M6.66667 10.6667H2V14.0001H6.66667V10.6667Z" stroke={strCol} css={{
          opacity:0.7,
        }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :  <path d="M6.66667 10.6667H2V14.0001H6.66667V10.6667Z" stroke={`${((theme == "dark" && onMouseEnterCards) || isCardActive) ? "black" : "gray"}`} css={{
          opacity:0.7,
        }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
    </svg>
  )
}

export default CardsIcon
