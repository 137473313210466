/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';
import { jsx } from "@emotion/core";
import './style.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setTimer, decreaseTimer, increaseTimer } from '../../redux/slices/timer';
import { setFocusOnTimer } from '../action/utilities';
import { Add, Minus } from '../LandingHeader/Feedback/icons/Icons';

function TimerOptions({defaultOption = 'focusModeSettings'}) {
  const { timer, start } = useSelector(state => state.focusTimer);
  const { theme, colorPicker } = useSelector(state => state.theme);
  const { settings } = useSelector(state => state.settings);
  const dispatch = useDispatch();
  const timeTabs = [[5, "<span>5</span><span>mins</span>"], [15, "<span>15</span><span>mins</span>"], [30, "<span>30</span><span>mins</span>"], [60, "<span>60</span><span>mins</span>"], [90, "<span>90</span><span>mins</span>"], [120, "<span>120</span><span>mins</span>"]];
  const [hrMinSecValue, setHrMinSec] = useState(0)
  const [selectInputFocus, setselectInputFocus] = useState(false);
  const customTimeInput = useRef('');
  const timerSpan = settings?.timerNudgeAmount ?? 300; // secs

  useEffect(() => {
    !start && setHrMinSec(0);
  }, [start])

  useEffect(() => {
    if(selectInputFocus === false && customTimeInput?.current){
      customTimeInput.current.value = '';
    }
      
  }, [selectInputFocus])

  const setCustomTimeHandler = (e) => {
    setselectInputFocus(true);
    customTimeInput.current.focus();
    setHrMinSec(0)
    const element = document.querySelector('.set-time');
    
    if(element)
      element.style.borderRight = '1px solid rgb(0, 0, 0)';
  }

  const setCustomTime = (e) => {

    let time = isNaN(e.target.value) || e.target.value == '' ? 0 : e.target.value;
    customTimeInput.current.value = time.toString().length > 3 ? time.toString().slice(0, 3) : time;
    setHrMinSec(parseInt(time));
  }

  useEffect(() => {
    if(defaultOption === 'focusMode' && !selectInputFocus)
      return;

    let focusTime = hrMinSecValue
    if(!focusTime){
      const defaultTimePeriod = settings?.defaultTimePeriodFocus ?? '25';
      focusTime = defaultTimePeriod?.replace(/\s/, '') !== '' ? defaultTimePeriod : 0;
    }
    
    dispatch(setTimer(parseInt(focusTime) * 60));
  }, [hrMinSecValue])
  
  const setDefaultTimePeriod = (timerType, timerValue = null) => {
    let defaultTimePeriod = timerValue !== null ? `${timerValue}` : (timerType == 'focus' || timerType == 'focusModeSettings' ? (settings?.defaultTimePeriodFocus ?? '25') : (settings?.defaultTimePeriodBreak ?? '5'));
    setselectInputFocus(false);
    if(defaultTimePeriod){
      const focusTime = defaultTimePeriod?.replace(/\s/, '') !== '' ? parseInt(defaultTimePeriod) * 60 : 0;
      dispatch(setTimer(focusTime));
    }
  }

  const increaseDecreaseTimer = (timerSpan, type) => {
    setselectInputFocus(false);
    type == 'decrease' ? dispatch(decreaseTimer(timerSpan)) : dispatch(increaseTimer(timerSpan));
    setFocusOnTimer();
  }

  const selectedTime = `${selectInputFocus ? hrMinSecValue : timer / 60}`.padStart(2, '0');

  return (
    <div className='d-flex pad-20 align-start gap-20' css={{
        border: '1px solid rgba(0, 0, 0, 0.05)',
        background: '#FFF', 
        '.actBtns': {
            padding: '12px 8px',
            alignItems: 'flex-start',
            gap: '10px',
            borderRadius: '50px',
            border: '1px solid rgba(0, 0, 0, 0.10)',
            '&:hover': {
                border: `1px solid ${colorPicker}`,
                background: `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}`,

                '.path': {
                    stroke: colorPicker
                }
            }
        }
    }}>
        <div className='d-flex pad-20 align-center justify-center gap-40 pad-10-20 width-100'>
            <div className='d-flex align-center justify-between' css={{flex: '1 0 0'}}>
            {
                timeTabs.map((tab, index) =>
                    <button className={`d-flex align-center justify-center fd-col bg none font-family rl font size-30 border stroke-0 time-tab ${timer / 60 == tab[0] ? 'active darkPrimaryColor20' : 'opacity d5'}`} key={index} onClick={e => setDefaultTimePeriod(null, tab[0])} css={{
                        'color': colorPicker,
                        'span:first-child': {
                          lineHeight: '30px'
                        },
                        'span:last-child': {
                          fontSize: '14px'
                        }
                    }} dangerouslySetInnerHTML={{ __html: tab[1] }}>
                </button>
            )}
            </div>
            <div className='d-flex align-center gap-20'>
                <a href="javascript: void(0);" className='desc actBtns' onClick={e => increaseDecreaseTimer(timerSpan, 'decrease')}>
                    <Minus color='grey'/>
                </a>

                <div className='d-flex pad-10 align-center justify-center fd-col darkPrimaryColor20 font-family rl' id="uu" onClick={setCustomTimeHandler} css={{
                    borderRadius: '20px',
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                }}>
                    <span className={`font size-50 darkPrimaryColor20 ${selectedTime == '00' ? 'opacity d2' : ''}`} css={{
                        lineHeight: '50px',
                        borderRight: selectInputFocus ? '1px solid rgba(0, 0, 0, 0.5)' : 'none'
                    }}>{selectedTime}</span>
                    <span className='font size-20'>mins</span>
                </div>
                
                <input className="timer-custom-time" ref={customTimeInput} onInput={setCustomTime} />

                <a href="javascript: void(0);" className='ased actBtns' onClick={e => increaseDecreaseTimer(timerSpan, 'increase')}>
                    <Add color='grey' />
                </a>
            </div>
        </div>
    </div>
  )
}

export default TimerOptions