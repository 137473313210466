import React, { useState } from 'react'
import BackgroundImageCard from '../../Components/common/BackgroundImageCard'
import { setBackgroundImages, setColorPicker, setThemeDefaultImage } from '../../redux/slices/ThemeSlice';
import { useDispatch } from 'react-redux';
import FileUploadModal from '../Settings/Modal/FileUploadModal';
import { CirclePlusIcon, Trash } from '../../Components/LandingHeader/Feedback/icons/Icons';
import { deleteBackgroundImage } from '../../Components/action/common';

const CustomBackgroundImages = ({backgroundImages, colorPicker, onApplyColorPicker, backgroundImageId, setImage}) => {
    const dispatch = useDispatch();
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const customImages = backgroundImages.filter(image => !image.systemDefault);
    const maxUploads = 8;
    const maxUploadSize = 5; // 5 MB
    const allowUpload = customImages.length < maxUploads;

    const onClickImageCard = (color, image) => {
        setImage(image);
        dispatch(setColorPicker(color))
    }

    // delete image
    const handleDeleteImage = async (e, id) => {
        e.stopPropagation();
        e.preventDefault();
        const images = backgroundImages.filter(image => image.id !== id);
        deleteBackgroundImage(id);
        dispatch(setBackgroundImages(images));
        if(id == backgroundImageId){
            dispatch(setThemeDefaultImage(null))
        }
    };

    return (
        <>
        {
            allowUpload && <div className='image-card new-image-card'>
            <label onClick={() => setShowFileUploadModal(true)}>
                <>
                    <CirclePlusIcon color={colorPicker} />
                    <span style={{color: colorPicker}}>Upload Image</span>
                </>
                </label>
            </div>
        }
        {
            customImages.map((image) => (
                <div className='image-card-wrapper'><BackgroundImageCard imageObject={image} location={image.location} key={image.id} id={image.id} color={colorPicker} onClick={color => onClickImageCard(color, image)} onApply={onApplyColorPicker} backgroundImageId={backgroundImageId} /><button title="Delete" className="delete-icon d-none" onClick={(e) => handleDeleteImage(e, image.id)}>
                    <Trash color='red' />
                </button></div>
            ))
        }
        {
            showFileUploadModal && allowUpload && <FileUploadModal onHide={() => setShowFileUploadModal(false)} show={showFileUploadModal} maxUploads={maxUploads} maxUploadSize={maxUploadSize} />
        }
        </>
    )
}

export default CustomBackgroundImages