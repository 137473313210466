import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import { caseInsensitiveSortBy, verifyRepeatedTask } from '../../Components/action/utilities'
import _ from 'lodash'
import { setProjectTasks } from '../../redux/slices/ProjectSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { fetchTaskList } from '../../Components/action/common'
import { FolderIcon, NoDateIcon, OverDueIcon, TasksListIcon, UpcomingTasksIcon } from '../../Components/LandingHeader/Feedback/icons/Icons';
import ArrowUpDn from './ArrowUpDn';
import TaskList from './TaskList';
import moment from 'moment';
import TasksStatusWidget from './TasksStatusWidget';
import TaskSourceIcon from './TaskSourceIcon';

const ToggleAccordionArrow = ({ theme }) => {
    return (
      <AccordionItemState>
        {({ expanded }) => (
          expanded 
          ? 
          <span className={`accordion_arrow active d-flex align-center justify-center pad-0-8`}>
            {
              theme == "dark" ? <ArrowUpDn/> : <img src="/images/arrow-up-dn.svg" alt="arrow" />
            }
          </span>
          : 
          <span className={`accordion_arrow d-flex align-center justify-center pad-0-8`}>
            {
              theme == "dark" ? <ArrowUpDn/> : <img src="/images/arrow-up-dn.svg" alt="arrow" />
            }
          </span>
        )}
      </AccordionItemState>
    )
}

const ProjectTasks = () => {
    const [tasks, setTasks] = useState([])
    const [tasksSortBy, setTasksSortBy] = useState('date')
    const [tasksSortDirection, setTasksSortDirection] = useState(null)
    let { id, projectTitle } = useParams()
    projectTitle = projectTitle.replaceAll('_', ' ');
    const [projectId, projectSource] = id.split('_');
    const dispatch = useDispatch()
    const {projectTasks, projectsListWithTasksCount} = useSelector(state => state.project)
    const {theme, colorPicker} = useSelector(state => state.theme)
    const {settings} = useSelector(state => state.settings)
    const wrapperRef = useRef(null)

    useEffect(() => {
        setTasksSortBy(settings?.sortBy ?? 'date');
        setTasksSortDirection(settings?.sort ?? null);
    }, [settings])

    useEffect(() => {
        const bound = wrapperRef.current.getBoundingClientRect()
        wrapperRef.current.style.height = window.innerHeight - bound.top + 'px'
    }, [])

    useEffect(() => {
        if(!projectId) return;
        
        const fetchTasks = async () => {
            const response = await fetchTaskList({projectId, status: 0});
            dispatch(setProjectTasks(response?.data?.responseData ?? []));
        }
        fetchTasks();
    }, [projectId, projectTitle])

    useEffect(() => {
        if(!projectTasks) return;

        const today = moment().startOf('day').toISOString();
        // Overdue tasks
        const overdue = _.orderBy(projectTasks.map(item => {
        if(item?.repeat?.rule){
            const result = verifyRepeatedTask(item, null, [], true);
            return result?.length > 0 ? {...item, date: new Date(result[0]).toISOString()} : false;
        }else{
            const result = item.status == 0 && item.date !== null && item.date < today;
            return result ? item : false;
        }

        }).filter(item => item !== false), ['date'], ['desc']);

        // Upcoming tasks
        const upcoming = _.orderBy(projectTasks.map(item => {
        if(item?.repeat?.rule){
            const result = verifyRepeatedTask(item, null, [], false, true);
            return result?.length > 0 ? {...item, date: new Date(result[0]).toISOString()} : false;
        }else{
            const result = item.status == 0 && item.date !== null && item.date >= today;
            return result ? item : false;
        }

        }).filter(item => item !== false), ['date'], ['asc']);

        // No date tasks
        const noDate = projectTasks.filter(item => {
            return item.date === null;
        });

        // Set tasks
        setTasks([
            {
                title: "Overdue",
                tasks: tasksSortBy && tasksSortDirection ? caseInsensitiveSortBy(overdue, tasksSortBy, tasksSortDirection) : overdue,
                colorClass: "darkOverDueColor",
                count: overdue.length,
                icon: <OverDueIcon color={'#D95555'} />
            },
            {
                title: "Upcoming",
                tasks: tasksSortBy && tasksSortDirection ? caseInsensitiveSortBy(upcoming, tasksSortBy, tasksSortDirection) : upcoming,
                colorClass: "darkPrimaryColor",
                count: upcoming.length,
                icon: <UpcomingTasksIcon color={colorPicker} />
            },
            {
                title: "No Date",
                tasks: tasksSortBy && tasksSortDirection ? caseInsensitiveSortBy(noDate, tasksSortBy, tasksSortDirection) : noDate,
                colorClass: "darkPrimaryColor",
                count: noDate.length,
                icon: <NoDateIcon color={colorPicker} />
            }
        ])

    }, [projectTasks, tasksSortBy, tasksSortDirection])

    const StatusWidget = () => {
        return useMemo(() => {
            const projects = projectsListWithTasksCount.filter(project => project.project_id == projectId)?.[0];
            const completedTasksCount = projects?.completedTasksCount ?? 0;
            const pendingTasksCount = projects?.pendingTasksCount ?? 0;
    
            return <TasksStatusWidget className="border stroke-0 mr-0 width-auto" direction="right" taskStatus={{completed: completedTasksCount, total: pendingTasksCount + completedTasksCount}}  />
        }, [projectsListWithTasksCount])
    }

    return (
        <div ref={wrapperRef} id="tasks-list-wrapper" className="overflow x-hidden y-auto border radius-20">
            <div className="upcoming-form">
                <div className="accordion-view">
                    <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['accitem']}>
                        <AccordionItem uuid={`accitem`} className={`accordion-item ${!projectTasks.length ? 'no-task' : ''}`}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                {
                                    <div className={`accordion_tab cursor pointer`} style={{ background: theme == "dark" ? "black" : "white" }}>
                                        <div className="heading width-100">
                                            <FolderIcon color={colorPicker} fill={colorPicker} />
                                            <h3 className="accordion-title darkPrimaryColor">{projectTitle}</h3>
                                            <TaskSourceIcon task={{id, taskSource: projectSource}} colorPicker={colorPicker} />
                                            <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                            <StatusWidget />
                                        </div>
                                        <ToggleAccordionArrow theme={theme} />
                                    </div>
                                }
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            {
                                <AccordionItemPanel>
                                    <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={[`accitem0`, `accitem1`]}>
                                    {
                                        tasks.map((group, index) => {
                                            return (
                                                    <AccordionItem key={index} uuid={`accitem${index}`} className={`accordion-item ${!group.tasks.length ? 'no-task' : ''}`}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                <div className={`accordion_tab cursor pointer`} style={{ background: theme == "dark" ? "black" : "white", paddingBottom: '20px' }}>
                                                                    <div className="heading width-100">
                                                                        {group.icon}
                                                                        <h3 className={`accordion-title ${group.colorClass} font size-20`}>{group.title}</h3>
                                                                        <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                                                        <span className="pad-0-14 font size-12 color-black font-family inter opacity d5">{`${group.tasks.length} Task${group.tasks.length > 1 ? 's' : ''}`}</span>
                                                                    </div>
                                                                    <ToggleAccordionArrow theme={theme} />
                                                                </div>
                                                                {index < 2 && <div className="hr-line"></div>}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                        {
                                                            group.tasks.map(task => {
                                                                return (
                                                                    <TaskList projectId={projectId} task={task} key={`item-${task.id}${task.updatedAt}`} disableSort={true}  currentPage="home" groupDate={task.date} showDate={true} />
                                                                )
                                                            })
                                                        }
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                            )
                                        })
                                    }    
                                    </Accordion>
                                </AccordionItemPanel>
                            }
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default ProjectTasks
