import React, { useCallback, useMemo, useState } from 'react'
import { useEffect } from 'react'
import { fetchTaskList, totalTaskCount } from '../../Components/action/common'
import _ from 'lodash';
import moment from 'moment'
import TaskListDetails from './TaskListDetails'
import { getDtstartISO, hexToRgb, verifyRepeatedTask } from '../../Components/action/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { setDateChange, setGroupedList, setTaskProjects, setTotalCount } from '../../redux/slices/TaskSlice';
import InfiniteScroll from "react-infinite-scroll-component";
import UpcomingLoader from '../../Components/SkeletonLoading/UpcomingLoader';
import { Accordion } from 'react-accessible-accordion';
import AllLoader from '../../Components/SkeletonLoading/AllLoader';

function Upcoming() {
  // use hooks
  const dispatch = useDispatch();
  const [alldays, setAllDays] = useState([]);
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes));

  // global states
  const { taskGroupedList } = useSelector(state => state?.task);

  const fetchTaskListData = useCallback(async () => {
    const date = currentTime.toISOString();

    let requestBody = {
      "date": date,
      "type": 3
    }

    try {
      let response = await fetchTaskList(requestBody)
      dispatch(setTaskProjects(response?.data?.projects ?? []))
      dispatch(setGroupedList(response?.data?.responseData.filter(t => t.status == 0 && t.date !== null && t.deletedAt === null)));
    } catch ({ response }) {}
    
  }, [currentTime, dispatch]);

  useEffect(() => {
    let days = [];
    for(let index=0; index<10; index++){
      days.push(moment().add(index, 'days').format('MM/DD/YYYY'));
    }

    setAllDays(days);
    dispatch(setDateChange((new Date()).toISOString()))

    fetchTotalCount()
    fetchTaskListData()
  }, [])

  const fetchMoreData = () => {
    setAllDays(alldays => {
      let days = [];
      const lastDay = alldays[alldays.length - 1];
      for(let index=1; index<=10; index++){
        days.push(moment(lastDay, 'MM/DD/YYYY').add(index, 'days').format('MM/DD/YYYY'));
      }

      return [...alldays, ...days];
    })
  };
  
  const fetchTotalCount=async()=>{
    try {
      let response=await totalTaskCount({"date":currentTime.toISOString()})
    
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({response}) {}
  }

  return (
    <div id="tasks-list-wrapper">
      <div className="upcoming-form">
        <div className="accordion-view">
          <InfiniteScroll
            dataLength={alldays?.length ?? 0}
            next={fetchMoreData}
            hasMore={true}
            loader={<AllLoader  />}
            scrollableTarget="tasks-list-wrapper"
          >
            {
              useMemo(() => {
                return <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['accitem0']}>
                {
                  alldays?.map((groupDate, index) => {
                    return (
                      <TaskListDetails
                        key={groupDate}
                        currentPage="upComing"
                        fetchTaskListData={fetchTaskListData}
                        upComingtaskList={taskGroupedList?.filter(t => verifyRepeatedTask(t, groupDate, moment(t?.date).format('L') === groupDate))}
                        groupDate={groupDate}
                        showAddTask={true}
                        index={index}
                        isDefaultActive = {index === 0}
                        fetchTotalCount={fetchTotalCount}
                      />
                    )
                  })
                }
                </Accordion>
              }, [alldays, taskGroupedList])
            }
            
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default Upcoming