/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { useEffect, useState } from "react"
import { SITE_BASE_URL } from "../common/constants"
import { UpgradeIcon } from "../LandingHeader/Feedback/icons/Icons"
import { useSelector } from "react-redux";
import { fetchBlockedSitesData } from "../action/common";
import { loadOutseta } from "../Outseta";

const PreExpire = ({data, colorPicker, theme, accountStage = 0}) => {

    const {focusSessions} = useSelector(state => state.focusTimer);
    const {taskList} = useSelector(state => state.task);
    const [focusTime, setFocusTime] = useState(0);
    const [totalTasks, setTotalTasks] = useState(0);
    const [totalBlockedSites, setTotalBlockedSites] = useState(0);
    const [totalInCompletedTasks, setTotalInCompletedTasks] = useState(0);

    const OPTIONS = {
        domain: "lifetab.outseta.com",
        load: "profile"
    };

    useEffect(() => {
        if(window?.chrome?.storage){
            return;
        }

        document.body.classList.remove('dark')
        document.body.classList.remove('light')
        document.body.classList.add(theme);
        document.body.classList.add('plan-expired')
        document.body.style.setProperty('--accent--color-app', colorPicker);

        loadOutseta(OPTIONS).then((response) => {
            // Initialize Outseta with your access token
            response.setAccessToken(localStorage.getItem('token'));

            response.on('subscription.update', (event) => {
                setTimeout(() => {
                    window.location.href = `${SITE_BASE_URL}${process.env.REACT_APP_ROOT}?access_token=${response.getAccessToken()}`
                    response.profile.close();
                }, 2000)
            })
        });

        return () => {
            document.body.classList.remove('plan-expired')
        };
    }, [])

    useEffect(() => {
        document.body.classList.remove('dark')
        document.body.classList.remove('light')
        document.body.classList.add(theme);
    }, [theme])

    useEffect(() => {
        const totalTime = calculateTotalTime(focusSessions);
        setFocusTime(totalTime);
    }, [focusSessions])

    useEffect(() => {
        const totalTasks = taskList.filter(task => task.taskSource === null && !task?.deletedAt);
        const totalInCompletedTasks = totalTasks.filter(task => task.status != '1' && !task?.deletedAt);
        setTotalTasks(totalTasks.length);
        setTotalInCompletedTasks(totalInCompletedTasks.length);
        fetchBlockedSitesData().then(response => {
            setTotalBlockedSites(response.blockCount);
        })
    }, [taskList])

    const calculateTotalTime = (data) => {
        let totalMilliseconds = 0;
        let pauseDuration = 0;
        data.map((session) => {
            let lastPause = 0;
            session?.pauses.map(pause => {
                pauseDuration += pause.endTime - pause.startTime;
                lastPause = pause;
            });

            totalMilliseconds += (session.endTime ? session.endTime : (lastPause?.endTime ?? session.startTime)) - session.startTime;
        });

        return (totalMilliseconds - pauseDuration) / 1000
    }

    const insertError = () => {
        const intervalId = setInterval(() => {
            if(document.querySelector('.o--SectionGroup--sectionGroup')){
                clearInterval(intervalId);
                document.querySelector('.o--SectionGroup--sectionGroup').insertAdjacentHTML('afterend', '<div class="o--ErrorMessages--errorMessages o--ErrorMessages--single o--ErrorMessages--cornerShape-rounded"><ul><li>Your subscription has expired, please choose a new plan.</li></ul></div>');
            }
        }, 100);
    }

    return (
        <>
            <div className='d-flex pad-40-35 gap-10 fd-col' css={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
                <div className='font size-50 font-family rl f-weight-400'>
                    {
                        accountStage > 3
                        ?
                        <span className='darkPrimaryColor'>Your trial has expired</span>    
                        :
                        <><span className='darkPrimaryColor'>Your trial expires {data?.daysLeft === 0 ? '' : 'in'} </span>{typeof data?.daysLeft != 'undefined' && <span style={{color: colorPicker}}>{data?.daysLeft === 0 ? ' Today' : `${data?.daysLeft} ${data?.daysLeft > 1 ? 'days' : 'day'}`}</span>}</>
                    }
                </div>
                <div className="d-flex fd-col gap-5">
                    <p className="font size-14 font-family inter opacity d5">Upgrade now to secure one of only 1,000 spots in our Early Supporter Plan.</p>
                    <p className="font size-14 font-family inter f-weight-500" css={{color: colorPicker}}>Lock in our lowest pricing forever and continue enjoying a calmer, science-based take on productivity.</p>
                    <p className="font size-12 font-family inter font italic opacity d5">Have any questions? Get in touch by sending an email to <a href="mailto:support@lifetab.co" style={{color: 'black'}}>support@lifetab.co</a></p>
                </div>
            </div>
            <div className="d-flex pad-30 gap-20 align-center width-100" css={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
                <UpgradeIcon color={colorPicker} fill={colorPicker} id='upgrade-icon-1' />
                <div className="d-flex gap-10">
                    <span className="darkPrimaryColor font size-24 font-family rl f-weight-400">$6/Month</span>
                    <span className="font size-24 font-family rl f-weight-400 opacity d5">Early Supporter Plan</span>
                </div>
                <div style={{flex: '1 0 0px', alignSelf: 'stretch'}}></div>
                {
                    window?.chrome?.storage
                    ?
                    <a href={`${SITE_BASE_URL}/settings#profile`} className='button primary' style={{background: colorPicker, color: '#fff'}}><UpgradeIcon color='white' fill='none' gradient={false} id='upgrade-icon-2' strokeWidth={2} width={15.184} height={15.184} /> Upgrade</a>
                    :
                    <a href="https://lifetab.outseta.com/profile?tab=planChange&stateProps=%7B%22planUid%22%3A%227maZ41QE%22%2C%22planPaymentTerm%22%3A%22monthly%22%7D#o-authenticated" className='button primary' style={{background: colorPicker, color: '#fff'}}><UpgradeIcon color='white' fill='none' gradient={false} id='upgrade-icon-2' strokeWidth={2} width={15.184} height={15.184} /> Upgrade</a>
                }
            </div>
            <div className="pad-20">
                <div className="pad-20 d-flex gap-20 fd-col align-start justify-start">
                    <div className="font size-14 font-family inter f-weight-400 opacity d5">Your journey with LifeTab so far ...</div>
                    <div className="d-flex justify-between align-center width-100 font-family rl f-weight-400">
                        <div className="d-flex gap-2 fd-col align-center" css={{color: colorPicker}}>
                            <span className="font size-60">{totalTasks}</span>
                            <span className="font size-14">Tasks created</span>
                        </div>
                        <div className="d-flex gap-2 fd-col align-center" css={{color: colorPicker}}>
                            <span className="font size-60">{totalInCompletedTasks}</span>
                            <span className="font size-14">Incomplete tasks</span>
                        </div>
                        <div className="d-flex gap-2 fd-col align-center" css={{color: colorPicker}}>
                            <span className="font size-60">{totalBlockedSites}</span>
                            <span className="font size-14">Site blocked</span>
                        </div>
                        <div className="d-flex gap-2 fd-col align-center" css={{color: colorPicker}}>
                            <span className="font size-60">{Math.round(focusTime / 60)}</span>
                            <span className="font size-14">mins spent focused</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='d-flex pad-30-40 gap-30 justify-center align-center width-100'>
                <div id="subscription-plan">
                    <div className='part1'>
                        
                        {
                            window?.chrome?.storage
                            ?
                            <a href={`${SITE_BASE_URL}/settings#profile`} className='btn' style={{background: colorPicker, color: theme === 'light' ? '#fff' : '#000'}}>Upgrade <span className="darkColor darkColor1">$6/Month</span></a>
                            :
                            <a href="https://lifetab.outseta.com/profile?tab=planChange&stateProps=%7B%22planUid%22%3A%227maZ41QE%22%2C%22planPaymentTerm%22%3A%22monthly%22%7D#o-authenticated" className='btn' style={{background: colorPicker, color: theme === 'light' ? '#fff' : '#000'}}>Upgrade <span className="darkColor darkColor1">$6/Month</span></a>
                        }
                    </div>
                    <div className='part1 part2'>
                        <h2 className="darkColor">Your journey with LifeTab so far ...</h2>
                        <ul>
                            <li><RoundCheckbox color={colorPicker} /> <span className="darkColor" dangerouslySetInnerHTML={{__html: focusTimerFormatV3(focusTime)}}></span> Spent focused</li>
                            <li><RoundCheckbox color={colorPicker} /> <span className="darkColor">{totalTasks}</span> Tasks created</li>
                            <li><RoundCheckbox color={colorPicker} /> <span className="darkColor">{totalInCompletedTasks}</span> Incomplete tasks</li>
                            <li><RoundCheckbox color={colorPicker} /> <span className="darkColor">{totalBlockedSites}</span> <span>{totalBlockedSites > 1 ? 'Sites' : 'Site'} blocked, <span dangerouslySetInnerHTML={{__html: focusTimerFormatV4(totalBlockedSites * 5 * 60)}}></span> of time saved.</span></li>
                        </ul>
                        <p>Have any questions? Get in touch by sending an email to <a href="mailto:support@lifetab.co" className="darkColor">support@lifetab.co</a></p>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default PreExpire