import React, {  useState } from 'react'
import AdminSettings from './Backgrounds/Settings'
import AiAssistant from './AiAssistant'
import NotificationSettings from './NotificationSettings'

function Settings() {
  const [activeTab, setActiveTab] = useState('')

  return (
    <div className="form-content">
      <div className="setting-form common-form">
        <div className="setting-tabs">
          <ul className="ul-setting-tab">
            <AdminSettings activeTab={activeTab} setActiveTab={setActiveTab} />
            <AiAssistant activeTab={activeTab} setActiveTab={setActiveTab} />
            <NotificationSettings activeTab={activeTab} setActiveTab={setActiveTab} />
          </ul>
        </div></div>
    </div>
  )
}

export default Settings