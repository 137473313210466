/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from "@emotion/core";
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { deleteConversation, fetchConversations, undoConversation } from '../action/chatGPT';
import HistoryItem from './HistoryItem';
import SkeletonLoader from '../SkeletonLoading/AiChatHistory';
import { clearChat } from '../../redux/slices/AiAssistant';

const HistoryTab = ({theme, colorPicker}) => {
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(false);
    const {chatData, changed, id} = useSelector(state =>  state.aiAssistant);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        fetchConversations().then(result => {
            setLoading(false);
            setConversations(result.data.responseData);
        });
    }, [])

    useEffect(() => {
        changed === false && fetchConversations().then(result => setConversations(result.data.responseData));
    }, [chatData, changed])

    const deleteChat = (e, chatId) => {
        e.preventDefault();
        deleteConversation(chatId).then(result => {
            const index = _.findIndex(conversations, ["id", chatId]);
            if(index > -1){
                setConversations(conversations => {
                    conversations[index].deletedAt = new Date();
                    return [...conversations];
                });
                chatId == id && dispatch(clearChat({changed: true}));
            }
        });
    }

    const undoChat = (e, id) => {
        e.preventDefault();
        undoConversation(id).then(result => {
            const index = _.findIndex(conversations, ["id", id]);
            if(index > -1){
                setConversations(conversations => {
                    conversations[index].deletedAt = null;
                    return [...conversations];
                });
            }
       });
    }

    const disappearChat = (id) => {
        setConversations(conversations => {
            return [...conversations].filter(c => c.id != id);
        });
    }

    return (
        <ul className='ul-menus'>
        {
            loading
            ?
            <SkeletonLoader loop={4} />
            :
            conversations.map(c => <HistoryItem key={c.id} c={c} id={id} theme={theme} colorPicker={colorPicker} deleteChat={deleteChat} disappearChat={disappearChat} undoChat={undoChat} />)
        }
        </ul>
    )
}

export default HistoryTab