import React from 'react';
import { useSelector } from 'react-redux';
import WebsiteTimeTracker from './WebsiteTimeTracker';

function Admin() {
    const { themeColor, colorPicker, theme } = useSelector(state => state.theme);

    return (
        <div className="main-body-wrap" css={{overflow: "auto"}}>
        <div className={`page-wrapper ${theme}`} id="admin-page">
            <div className="page-container">
                <div className='contentquoteSection mainHomepage inner-page' id='uniqueClose'>
                    <div className='adminSection-inner' style={{background: theme === 'light' ? '#fff' : '#000'}}>
                        <WebsiteTimeTracker/>
                        <WebsiteTimeTracker type='external'/>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Admin;