/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { jsx } from "@emotion/core";
import CardsIcon from './CardsIcon';
import { fetchSetting, setSetting } from '../../Components/action/common';
import { pushToSetting, setAddQuickLinkClick } from '../../redux/slices/SettingSclice';
import QuickLinks from './QuickLinks';
import FocusTimerSettings from './FocusTimerSettings';
import { usePosthog } from '../../PosthogProvider';
// import { TESTING_CODE } from '../../Components/common/constants';

function Cards({ activeTab, setActiveTab, activeCard = null }) {
  const cardDiv=useRef()
  const quickLinksCard=useRef()
  const focusTimerCard = useRef();
  const aiCard = useRef();
  const dispatch = useDispatch()
  const { theme, colorPicker } = useSelector(state => state.theme)
  const { focus , quickLink , quotes, aiAssistant, testingCode} = useSelector(state => state.settings?.settings ?? {})
  const { settings } = useSelector(state => state.settings)
  const [settingDetailLink, setSettingDetailLink] = useState(null)
  const [isActiveCard, setIsActiveCard] = useState(activeCard == 'quicklinks' ? true : false)
  const [isFocusTimerActive,setIsFocustimerActive]=useState(activeCard == 'focus' ? true : false)
  const {addQuickLinkClick }=useSelector(state=>state.settings)
  const [onMouseEnterCards,setOnMouseEnterCard]=useState(false)
  const { captureEvent } = usePosthog();

 const onChangeCards=async(name,value)=>{
  try {
    const newSettings = { ...settings, [name]: value };
    dispatch(pushToSetting(newSettings));
    let response = await setSetting({ "settings": newSettings })
    // Capture card settings changed event
    captureEvent(`${name.charAt(0).toUpperCase() + name.slice(1)} Card Settings ${value ? 'Enabled' : 'Disabled'}`);
  } catch ({response}) {
    
  }
 }

  const fetchSettingData = async () => {
    try {
      let response = await fetchSetting()
      if (response?.data?.responseData?.settings) {
        setSettingDetailLink(response?.data?.responseData?.settings)
      }
    } catch ({ response }) {

    }
  }

  useEffect(() => {
    fetchSettingData()
  }, [])

  useEffect(()=>{
    if(!activeCard && addQuickLinkClick == true){
      setIsActiveCard(true)
      setTimeout(() => cardDiv.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }), 1)
    }else{
      if(activeCard == 'focus')
        setTimeout(() => focusTimerCard.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }), 1)
      if(activeCard == 'quicklinks')
        setTimeout(() => quickLinksCard.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }), 1)
      if(activeCard == 'ai')
        setTimeout(() => aiCard.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }), 1)
    }
    
  },[])


  return (
    <li 
    ref={cardDiv}
    id='card-section-goToTop'
    
    css={{
      '&:hover .accordion-title':{
        color:theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c")) ? "white !important" : (theme == "dark" && colorPicker == "#f8961e") ? "black !important" : (theme == "dark" && colorPicker == "#ffca3a") ? "black !important" : (theme == "dark" && colorPicker == "#27cb9a") ? "black !important" : (theme == "dark" && colorPicker == "#4cc9f0") ? "black !important" : "white !important") : "white !important"
       },
       '.accordion-title':{
         color:theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c") && (activeTab == "cards")) ? "white !important" : ((theme == "dark" && colorPicker == "#f8961e") && (activeTab == "cards")) ? "black !important" : ((theme == "dark" && colorPicker == "#ffca3a") && (activeTab == "cards")) ? "black !important" : ((theme == "dark" && colorPicker == "#27cb9a") && (activeTab == "cards")) ? "black !important" : ((theme == "dark" && colorPicker == "#4cc9f0") && (activeTab == "cards")) ? "black !important" : "white !important") :"white"
       }
     }}
    className={`li-setting-tab cards-inner-tabs ${activeTab == 'cards' ? 'active' : ''}`}>
      <div className="settings-accordion-tab"
         onClick={() => {setActiveTab(activeTab => activeTab == 'cards' ? null : 'cards'); dispatch(setAddQuickLinkClick(false))}}
        style={{ background: theme == "dark" ? "black" : "" }}
        css={{
          background: activeTab == 'cards' ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` : 'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff',
          '&:hover': {
            background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important`,
          }
        }}
      >
        <div className="settings-accordion-heading">
          <figure className="settings-icon">
            {
              theme == "dark" ? <CardsIcon isActiveSettings={activeTab == 'cards'} onMouseEnterCards={onMouseEnterCards} isCardActive={activeTab == 'cards'} /> :
              // <CardIconLightMode /> 
               <img src="images/layout-dashboard.svg" className="img-default" />
            }

          </figure>
          <h3 className="accordion-title text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Cards</h3>
        </div>
        <div className="settings-accordion-arrow">
          {/* {
            theme == "dark" ?  <ArrowDown onMouseEnterCards={onMouseEnterCards} isCardActive={isCardActive} isCard="card"/> :  */}
            <img src="images/arrow-down.svg" />
          {/* } */}
        </div>
      </div>
      {
        activeTab == 'cards' && <div className="settings-accordion-content">
          <div className="inner-form">
            <div className="formProfile">
              <div>
                <div className='cards-today-quotes cards-data'>
                  <div className='card-header' style={{background: theme == "dark" ? "black" :""}}>
                    <h3 className='card-title'  style={{color: theme == "dark" ? "white" :"gray"}}>TODAY’s QUOTE</h3>
                    <div className='card-toggle'>
                      <div class="on-off-toggle" >
                        <input class="on-off-toggle__input" type="checkbox" id="today" onChange={(e) => {onChangeCards("quotes", e.target.checked);  }}  checked={typeof quotes == 'undefined' || quotes ? true : false} />
                        <label for="today" className="on-off-toggle__slider focus-label" 
                        css={{
                          background:typeof quotes == 'undefined' || quotes ? `${colorPicker} !important` :""
                        }}
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  <div className='cards-today-quotes cards-data'>
                    <div className='card-header' ref={aiCard} style={{background: theme == "dark" ? "black" :""}}>
                      <h3 className='card-title'  style={{color: theme == "dark" ? "white" :"gray"}}>AI ASSISTANT</h3>
                      <div className='card-toggle'>
                        <div class="on-off-toggle" >
                          <input class="on-off-toggle__input" type="checkbox" id="aiAssistant" onChange={(e) => {onChangeCards("aiAssistant", e.target.checked);  }}  checked={typeof aiAssistant == 'undefined' || aiAssistant ? true : false} />
                          <label for="aiAssistant" className="on-off-toggle__slider focus-label" 
                          css={{
                            background:typeof aiAssistant == 'undefined' || aiAssistant ? `${colorPicker} !important` :""
                          }}
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                
                <div className={`cards-focus-timer cards-data active ${isFocusTimerActive ? "card-open " :"" } `}>
                  <div className='card-header' 
                   ref={focusTimerCard}
                   css={{
                    background:isFocusTimerActive ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` : theme == "dark" ? "black !important" :" linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important;"
                   }}
                  onClick={(e) => !e.target.classList.contains('on-off-toggle') && setIsFocustimerActive(!isFocusTimerActive)} >
                    <h3 className='card-title' style={{color: isFocusTimerActive ? "white" :  theme == "dark" ? "white" :"gray" , }}>FOCUS TIMER</h3>
                    <div className='card-toggle' >
                      <div class="on-off-toggle" >
                        <input class="on-off-toggle on-off-toggle__input" type="checkbox" id="focusd" onChange={(e) => {onChangeCards("focus", e.target.checked);  }}  checked={typeof focus == 'undefined' || focus ? true : false}  />
                        <label for="focusd" className="on-off-toggle on-off-toggle__slider focus-label" 
                         css={{
                          background:typeof focus == 'undefined' || focus ? `${colorPicker} !important` :""
                        }}
                        ></label>
                      </div>
                    </div>
                    <div class="settings-accordion-arrow"><img src="images/arrow-down.svg" /></div>

                  </div>

                  {
                    isFocusTimerActive && <FocusTimerSettings colorPicker={colorPicker} theme={theme} />
                  }
                </div>

                <div className={`cards-quick-links cards-data active  ${isActiveCard ? " card-open " : ""}`} 
                 
                >
                  <div className='card-header' ref={quickLinksCard} onClick={(e) => !e.target.classList.contains('on-off-toggle') && setIsActiveCard(!isActiveCard)} 
                 css={{
                  background:isActiveCard ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` : theme == "dark" ? "black !important" : "linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important",
                  color:"white"
                }}
                  >
                    <h3 className='card-title'  style={{color: isActiveCard ? "white" :  theme == "dark" ? "white" :"gray" , }}>QUICK LINKS</h3>
                    <div className='card-toggle' onChange={(e) => {onChangeCards("quickLink", e.target.checked)}}>
                      <div class="on-off-toggle" >
                        <input class="on-off-toggle on-off-toggle__input" type="checkbox" id="quickd"   checked={typeof quickLink == 'undefined' || quickLink ? true : false} />
                        <label for="quickd" className="on-off-toggle on-off-toggle__slider focus-label" 
                        css={{
                          background:typeof quickLink == 'undefined' || quickLink ? `${colorPicker} !important` :""
                        }}
                        ></label>
                      </div>
                    </div>
                    <div class="settings-accordion-arrow"><img src="images/arrow-down.svg" /></div>
                  </div>
                  {
                    isActiveCard && <div className='card-body' style={{ background: theme == "dark" ? "#1a1616" : "white" }}>
                      <QuickLinks />    
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </li>


  )
}

export default Cards


