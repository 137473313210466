/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect } from 'react'

function ConfirmRecurringTask({ onConfirm, theme, onHide, iconComponent = null }) {

    const handleFormChange = (value) => {
        onConfirm(parseInt(value));
    }

    useEffect(() => {
        setTimeout(() => {
            document.addEventListener('click', toggleDropdown);
        }, 1);
    
        return () => document.removeEventListener('click', toggleDropdown);
    }, []);
    
    const toggleDropdown = (e) => {
        if (!e.target.closest('.recurring-task-actions')) {
          onHide();
        }
    }

    return (
        <div className="box-model changePlan-updates-model md-model-updates recurring-task-actions dropDownBg"
        css={{
            background: `${theme == "dark" ? "black" : "white"}`,
            position: "absolute",
            right: "0",
            width: "300px",
        }}
        style={{ display: 'block' }}>
            <div className="box-model-body" style={{ background: theme == "dark" ? "black" : "" }}>
                <div className="body-data">
                    <div className="confirm-options">
                        <div className="confirm-option">
                            <label onClick={e => handleFormChange(1)}>{iconComponent} This task</label>
                        </div>
                        <div className="confirm-option">
                            <label onClick={e => handleFormChange(2)}>{iconComponent} This and all following tasks</label>
                        </div>
                        <div className="confirm-option">
                            <label onClick={e => handleFormChange(3)}>{iconComponent} All tasks</label>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default ConfirmRecurringTask