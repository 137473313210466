import React from 'react'
import HistoryTab from '../ChatGPT/HistoryTab'
import { useSelector, useDispatch } from 'react-redux';
import { SquarePlusIcon } from '../LandingHeader/Feedback/icons/Icons';
import { clearChat } from '../../redux/slices/AiAssistant';

const AIAssistantNav = () => {
    const {theme, colorPicker} = useSelector(state => state.theme);
    const dispatch = useDispatch();

    const startNewChat = (e) => {
        e.preventDefault(); 
        dispatch(clearChat());
        setTimeout(() => {
            const promptInput = document.querySelector('.chatTextarea');
            promptInput.focus();
            promptInput.style.height = '90px';
        }, 100)
    }
    
    return (
        <div id="tasks-nav" className='left-sidebar ai-assistant-nav'>
            <h1 className='darkPrimaryColor'>AI Assistant</h1>
            <a href="#" className="button primary" style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${colorPicker}`,
                color: "white"
            }} onClick={startNewChat}>New Chat <SquarePlusIcon color="white" /></a>
            <div className='hr-line'></div>
            <h3 style={{color: colorPicker}}>Recent Chats</h3>
            <div className='task-menus overflow-auto'>
                <HistoryTab theme={theme} colorPicker={colorPicker} />
            </div>
        </div>
    )
}

export default AIAssistantNav