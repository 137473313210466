/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from "@emotion/core";
import TaskModal from '../../pages/Settings/Modal/TaskModal';
import Datepicker from '../DatePicker/components/Datepicker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { GroupSortIcon, SquarePlusIcon } from '../LandingHeader/Feedback/icons/Icons';
import { useLocation } from 'react-router-dom';
import TaskSearchInput from './TaskSearchInput';
import GroupAndSortByDropdown from './GroupAndSortByDropdown';

const TaskSearchBar = () => {
    const [task, setTask] = useState(false);
    const [date, setDate] = useState(new Date());
    const [bookingDate, setBookingDate] = useState({minBookingDate: undefined, maxBookingDate: undefined});
    const [currentPage, setCurrentPage] = useState('home');
    const { colorPicker } = useSelector(state => state.theme);
    const location = useLocation();

    useEffect(() => {
        let currentPage = 'home';
        let minBookingDate = undefined;
        let maxBookingDate = undefined;
        switch (location.pathname) {
            case '/tasks':
                currentPage = 'upComing';
                minBookingDate = new Date();
                break;
            case '/tasks/overdue':
                currentPage = 'overDue';
                maxBookingDate = new Date();
                break;
            case '/tasks/inbox':
                currentPage = 'allTask';
                break;
            case '/tasks/nodate':
                currentPage = 'noDate';
                break;
            case '/tasks/complete':
                currentPage = 'completedList';
                break;
            case '/tasks/trash':
                currentPage = 'Trash';
                break;
            default:
                currentPage = 'home';
        }

        setCurrentPage(currentPage);
        setBookingDate({minBookingDate, maxBookingDate});
        setDate(new Date());
    }, [location.pathname]);

    const openTaskModal = (e, task) => {
        e.preventDefault();
        setTask(task);
    }

    const onDatePickerDateChange = (e) => {
        setDate(e);
    }

    return (
        <>
            <div className='task-search-bar'>
                {
                    currentPage !== 'noDate' && (
                        <div className='search-date'>
                            <span>{moment(date).format('dddd')}</span>
                            <span>{moment(date).format('Do MMMM, YYYY')}</span>
                            <Datepicker onChange={onDatePickerDateChange} value={date} minBookingDate={bookingDate.minBookingDate} maxBookingDate={bookingDate.maxBookingDate} />
                        </div>
                    )
                }
                <div className='search-tasks'>
                    <TaskSearchInput />
                    <GroupAndSortByDropdown colorPicker={colorPicker} />
                    <div className='repeTions'>
                        <a className='recAct-btn save-btn ws nowrap' css={{
                            background: `${colorPicker} !important`,
                            border: `1px solid rgba(0,0,0,0.05) !important`,
                            color: "white !important"
                        }} onClick={(e) => openTaskModal(e, null)}>Add task <SquarePlusIcon color={'white'} /></a>
                    </div>
                </div>
            </div>

            {
                task !== false && (
                <TaskModal
                    show={true}
                    data={task}
                    currentPage={currentPage}
                    onHide={() => setTask(false)}
                />
                )
            }
        </>
    )
}

export default TaskSearchBar