/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { setDateChange, setSelectedDate, setTaskDaySelected } from '../../redux/slices/TaskSlice'
import Datepicker from '../DatePicker/components/Datepicker'
import LeftArrow from "./LeftArrow"
import RightArrow from './RightArrow'
import { CalendarIcon } from "./Feedback/icons/Icons"
import { useEffect, useState, useMemo } from "react"
import AIAssistantInput from "./AIAssistantInput"

function TodayTopBar() {

  const dispatch = useDispatch()
  const todayDate = moment().format('YYYY-MM-DD')
  // global state
  const { dateChange } = useSelector(state => state.task)
  const { colorPicker, theme } = useSelector(state => state.theme);
  const [datePickerPos, setDatePickerPos] = useState(0);

  const [dateRange, setDateRange] = useState((new Date()).toISOString())
  const [isGettingData, setIsGettingData] = useState(false)
  const dateTime = moment(dateChange).format('YYYY-MM-DD')

  useEffect(() => {
    dispatch(setDateChange((new Date()).toISOString()))

    setDatePickerPos(document.querySelector('.date-form .dif-tag').getBoundingClientRect().left);
  }, [])

  useEffect(() => {
    dispatch(setDateChange(dateRange))
    setTimeout(() => {
      setDatePickerPos(document.querySelector('.date-form .dif-tag').getBoundingClientRect().left);
    }, 100)
  }, [dateRange])

  useEffect(() => {
    if (moment(dateChange).format('YYYY-MM-DD') == todayDate) {
      dispatch(setTaskDaySelected('Today'))
    } else {
      dispatch(setTaskDaySelected('DatePicker'))
    }
  }, [dateChange])


  const onDateChange = (date) => {
    dispatch(setSelectedDate(date))
    dispatch(setDateChange(date))
    setDateRange(date)
  }

  const onRightArrowClick = async () => {

    const newDate = new Date(moment(dateRange).subtract(1, 'days').startOf('date'));
    setDateRange(newDate.toISOString());
    try {
    } catch ({ response }) {
      setIsGettingData(false)
    }
  }

  const onLeftArrowClick = async () => {
    if (dateTime == todayDate) {
      dispatch(setTaskDaySelected('Today'))
    } else {
      dispatch(setTaskDaySelected('DatePicker'))
    }
    const newDate = new Date(moment(dateRange).add(1, 'days').startOf('date'));
    setDateRange(newDate.toISOString());

  }

  const goToToday = async () => {
    if (dateTime == todayDate) {
      dispatch(setTaskDaySelected('Today'))
    } else {
      dispatch(setTaskDaySelected('DatePicker'))
    }
    const newDate = new Date(moment().startOf('date'));
    setDateRange(newDate.toISOString());
  }

  const hideDatePickerOnHoverOut = (e) => {
    //e.target.closest('.days')?.querySelector('.react-datetime-picker__calendar-button')?.click();
    e.target.closest('.days')?.querySelector('.advancedDatePicker')?.classList?.remove('active');
  }

  return (
    <div id="today-top-bar" className={`${dateTime != todayDate ? 'not-today' : ''}`}>
        <div className="date-form parts">
            <span className={`right_arrow m-0 ${theme == "dark" ? "cl-arrowDarkMode" : ""}`} style={{ cursor: isGettingData == true ? "not-allowed" : "pointer", border: theme == "dark" ? "1 px solid gray" : "" }} onClick={isGettingData == true ? {} : onRightArrowClick}>
                <RightArrow />
            </span>

            {/* <span className="days" style={{ color: theme == "dark" ? "gray" : "" }} onMouseLeave={hideDatePickerOnHoverOut}> */}
            <span className="day-time-slot">

                <span className="days" css={{
                'button.react-calendar__tile--active':{
                    backgroundColor: `${colorPicker} !important`,
                },
                '.react-calendar .react-calendar__tile--now':{
                    backgroundColor: `${colorPicker} !important`,
                },
                '.react-calendar__month-view__days button:hover':{
                    backgroundColor: `${colorPicker} !important`,
                },
                '.advancedDatePicker.active':{
                    marginLeft: `calc(-${datePickerPos}px + 50vw - 157px)`,
                    marginTop: '50px'
                }
                }} 
                
                //  onMouseEnter={showDatePickerOnHoverIn} 
                onMouseLeave={hideDatePickerOnHoverOut}
                style={{ paddingBottom: '40px', marginTop: '40px', color: theme == "dark" ? "gray" : "" }}>
                {moment(dateChange).format('dddd')}
                <b className="dif-tag" style={{ color: theme == "dark" ? "white" : "" }}>
                    {moment(dateChange).format('Do MMMM, YYYY')}
                    {/* {useMemo(() => <DateTimePicker value={dateChange} onChange={(date) => { onDateChange(date) }} />, [dateChange])} */}
                    {useMemo(() => <Datepicker showTopDates={false} value={dateChange} onChange={(date) => { onDateChange(date.toISOString()) }} />, [dateChange])}
                </b>
                </span>
                {
                dateTime != todayDate
                &&
                <span className="time todayBtn" style={{ cursor: "pointer"}} onClick={goToToday} css={{
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}`,
                    '&:hover':{
                    background: `radial-gradient(50% 100% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}`
                    }
                }}><CalendarIcon width={12} height={12} /> <span className="darkPrimaryColor">Today</span></span>
                }
            </span>
            <span className={`left_arrow m-0 ${theme == "dark" ? "cl-arrowDarkMode" : ""}`} style={{ cursor: isGettingData == true ? "not-allowed" : "pointer" }} onClick={isGettingData == true ? {} : onLeftArrowClick}>
                {/* <img src='/images/left_arrow.svg' /> */}
                <LeftArrow />
            </span>
        </div>
        <AIAssistantInput colorPicker={colorPicker}/>
    </div>
  )
}

export default TodayTopBar
