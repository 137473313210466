/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState, useRef } from 'react'
import { jsx } from "@emotion/core";
import { EllipsisIcon } from '../LandingHeader/Feedback/icons/Icons'

const ActionsDropdown = ({colorPicker, actions}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const targetRef = useRef(null);

    useEffect(()=>{
        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        }
    }, [])

    useEffect(()=>{
        showDropdown && togglePosition();
    }, [showDropdown])

    const closeDropdown = (e) => {
        if(!e.target?.closest('.actions-dropdown-container')){
            setShowDropdown(false);
        }
    }

    const toggleDropdown = (e) => {
        e.preventDefault();

        setShowDropdown(value => !value);
    }

    const togglePosition = (e) => {
        const dimPicker = dropdownRef.current.getBoundingClientRect();
        const dimBtn = targetRef.current.getBoundingClientRect();

        //auto 0 auto auto
        if((window.innerHeight - dimBtn.top) > dimPicker.height + 50){
            dropdownRef.current.style.inset = '100% 0px auto auto';
        } else {
            dropdownRef.current.style.inset = 'auto 0px 100% auto';
        }
    }

    return (
        <div className='actions-dropdown-container'>
            <span className='ellipsis-icon' onClick={toggleDropdown} ref={targetRef}>
                <EllipsisIcon color={colorPicker} />
            </span>
            {
                showDropdown
                &&
                <div className="actions-dropdown" ref={dropdownRef}>
                    <ul>
                        {
                            actions.map((tab, index) => {
                                return <li key={index}><a className='action-item' href="javascript: void(0);" onClick={e => tab.action(e)}  css={{
                                        '&:hover': {
                                            background: `linear-gradient(0deg, rgba(255,255,255,0.80) 0%,rgba(255,255,255,0.80) 100%),linear-gradient(0deg,${colorPicker} 0%,${colorPicker} 100%),#FFF !important`,
                                        }
                                    }}>
                                        {tab.icon}
                                        {tab.title}
                                    </a>
                                </li>
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

export default ActionsDropdown