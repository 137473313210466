/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Add, Cancel, CheckIcon, MinimizeIcon, Minus, PauseIcon, PlayRound, ResetIcon, StopIcon, TargetIcon, Teacup } from '../LandingHeader/Feedback/icons/Icons';
import { decreaseTimer, increaseTimer, pauseTimer, stopTimer, cancelTimer, resetTimer, resumeTimer, setTimerFullscreen, tmpStopTimer, bc } from '../../redux/slices/timer';
import { focusTimerFormatV2, focusTimerFormatV3, focusTimerFormatV4 } from '../action/utilities';
import { useNavigate } from 'react-router-dom';
import TimeDropdown from "./TimeDropdown";
import { useSocket } from "../../SocketContext";
import { isExpired, decodeToken } from "react-jwt";
import TasksStatusWidget from "../../pages/Tasks/TasksStatusWidget";
import { setFocusTasksList } from "../../redux/slices/TaskSlice";

const FocusTimerBar = ({resumeTimerFlag = false, lastSessionCompletionTime = null, showSec = false}) => {
    const { themeColor, colorPicker, theme } = useSelector(state => state.theme);
    const focusTimer = useSelector(state => state.focusTimer);
    const { activeTimer, pause, start, fullscreen, resetTimerFlag, timer, currentTimer } = focusTimer;
    const { settings } = useSelector(state => state.settings);
    const dispatch = useDispatch();
    const timerSpan = settings?.timerNudgeAmount ?? 300; // secs
    const navigate = useNavigate();
    const {focusTasksList} = useSelector(state => state.task);
    const focusTasksListString = JSON.stringify(focusTasksList);
    const focusTasksListMemo = useMemo(() => JSON.parse(focusTasksListString), [focusTasksListString]);
    const socket = useSocket();

    useEffect(() => {
        triggerWebSocket({ action: "initTimer", focusTimer })
    }, [])

    useEffect(() => {
        triggerWebSocket({ action: "focusTasksList", tasks: focusTasksListMemo })
    }, [focusTasksListMemo])
    
    const increaseDecreaseTimer = (timerSpan, type, resume = false) => {
        if(type == 'decrease'){
            dispatch(decreaseTimer(timerSpan));
            // bc.postMessage({ action: "decreaseTimer", timerSpan });
            triggerWebSocket({ action: "decreaseTimer", timerSpan })
        }else{
            dispatch(increaseTimer(timerSpan));
            // bc.postMessage({ action: "increaseTimer", timerSpan });
            triggerWebSocket({ action: "increaseTimer", timerSpan })
        }

        resume && setTimeout(() => {
            dispatch(resumeTimer('focusMode'));
            // bc.postMessage({ action: "resumeTimer", redirect: process.env.REACT_APP_ROOT });
            triggerWebSocket({ action: "resumeTimer", redirect: process.env.REACT_APP_ROOT })
            navigate(process.env.REACT_APP_ROOT);
        }, 1)
    }

    const pauseAction = (e) => {
        e.preventDefault();
        dispatch(pauseTimer('FocusModePause'));
        // bc.postMessage({ action: "focusModePause" });
        triggerWebSocket({ action: "focusModePause" })
    }

    const stop = (e) => {
        e.preventDefault();
        dispatch(tmpStopTimer());
        // bc.postMessage({ action: "tmpStopTimer" });
        triggerWebSocket({ action: "tmpStopTimer" })
        navigate('/focus-mode-completed');
    }

    const stopAction = (e) => {
        e.preventDefault();
        dispatch(stopTimer());
        dispatch(setFocusTasksList([]));
        // bc.postMessage({ action: "stopTimer", redirect: process.env.REACT_APP_ROOT });
        triggerWebSocket({ action: "stopTimer", redirect: process.env.REACT_APP_ROOT })
        navigate(process.env.REACT_APP_ROOT);
    }

    const cancel = (e) => {
        e.preventDefault();
        dispatch(cancelTimer());
        dispatch(setFocusTasksList([]));
        // bc.postMessage({ action: "cancelTimer" });
        triggerWebSocket({ action: "cancelTimer" })
    }

    const reset = (e) => {
        e.preventDefault();
        dispatch(resetTimer());
        // bc.postMessage({ action: "resetTimer" });
        triggerWebSocket({ action: "resetTimer" })
    }

    const resume = (e) => {
        e.preventDefault();
        dispatch(resumeTimer('focusMode'));
        // bc.postMessage({ action: "resumeTimer" });
        triggerWebSocket({ action: "resumeTimer" })
    }

    const newSessionAction = (e) => {
        e.preventDefault();
        dispatch(stopTimer(parseInt(settings?.defaultTimePeriodFocus ?? '25') * 60));
        dispatch(setFocusTasksList([]));
        // bc.postMessage({ action: "stopTimer", redirect: '/focus-mode-settings' });
        triggerWebSocket({ action: "stopTimer", redirect: '/focus-mode-settings' })
        navigate('/focus-mode-settings');
    }

    const minimize = (e) => {
        e.preventDefault();
        dispatch(setTimerFullscreen(false));
        triggerWebSocket({ action: "fullscreen", fullscreenValue: false })
    }

    const triggerWebSocket = (data) => {
        // Trigger timer in wepapp and other clients
        if (socket !== null){
            const user = decodeToken(localStorage.getItem('token'));
            socket.emit('focusTimer', {room: user['outseta:accountUid'], data});
        }
    }

    return (
        <div id="focus-timer-bar" className={theme} css={{
            '&:before' : {
                background: `linear-gradient(180deg, rgba(39, 203, 154, 0) 0%, ${colorPicker} 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)`,
                width: `${100 - (currentTimer * 100) / timer}% !important`
            }
        }}>
            {
                lastSessionCompletionTime !== null
                ?
                <div className='timer-calc'>
                    <div className='timer-calc-display' style={{color: 'rgba(0, 0, 0, 0.5)'}}>
                        <TargetIcon color={colorPicker} fill={colorPicker} />
                        <span style={{color: colorPicker}} dangerouslySetInnerHTML={{ __html: `${lastSessionCompletionTime} done` }} />
                        <TimeDropdown colorPicker={colorPicker} theme={theme} increaseDecreaseTimer={increaseDecreaseTimer} />
                        <span style={{color: theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}}>Add more time, if needed</span>
                    </div>
                </div>
                :
                <div className='timer-calc'>
                    <div className='timer-calc-display' style={{color: 'rgba(0, 0, 0, 0.5)'}}>
                        <TargetIcon color={colorPicker} fill={colorPicker} />
                        {
                            resumeTimerFlag
                            &&
                            <span className='darkPrimaryColor'>Session Paused</span>
                        }
                        <span style={{color: colorPicker}} dangerouslySetInnerHTML={{ __html: `${focusTimerFormatV3(currentTimer, showSec)} left...` }} />
                        <span style={{color: theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}} dangerouslySetInnerHTML={{ __html: `/ ${focusTimerFormatV2(timer)}` }} />
                    </div>
                    <div className={`timer-calc-actions ${theme}`}>
                        <a href="javascript: void(0);" className='desc actBtns' onClick={e => increaseDecreaseTimer(timerSpan, 'decrease')}>
                            <Minus color={theme == "dark" ? '#fff' : 'grey'} border={false} />
                        </a>
                        <a href="javascript: void(0);" className='ased actBtns' onClick={e => increaseDecreaseTimer(timerSpan, 'increase')}>
                            <Add color={theme == "dark" ? '#fff' : 'grey'} border={false} />
                        </a>
                    </div>
                    <TasksStatusWidget taskStatus={{completed: focusTasksList.filter(task => task.status == 1).length, total: focusTasksList.length}} direction="right" className="focus-timer-widget" />
                </div>
            }
            <div className='timer-actions'>
                {
                    lastSessionCompletionTime !== null
                    ?
                    <>
                        <a href="#" onClick={stopAction} className='darkPrimaryColor'><Cancel color={colorPicker} /> Close</a>
                        <a href="#" onClick={newSessionAction}><Teacup color={colorPicker} /> Start New Session</a>
                    </>
                    :
                    <>
                        {
                            resumeTimerFlag
                            ?
                            <>
                                {
                                    focusTasksList.filter(task => task.status == 0).length > 0 
                                    ?
                                    <a href="#" onClick={resume} className='' css={{
                                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${colorPicker}`,
                                        color: '#fff !important'
                                    }}><PlayRound color={'white'} dark={false} /> Continue Session</a>
                                    :
                                    null
                                }

                                <a href="#" onClick={stop} className="darkPrimaryColor"><StopIcon color={colorPicker} opacity={1} /> End Session</a> 
                            </>
                            :
                            <>
                                <a href="#" onClick={stop} className='darkPrimaryColor'><StopIcon color={colorPicker} opacity={1} /> End Session</a>
                                <a href="#" onClick={pauseAction} className="darkPrimaryColor"><Teacup color={colorPicker} /> Pause</a>
                            </>
                            
                        }
                        
                        <a href="#" onClick={cancel} className="darkPrimaryColor">
                            <Cancel color={colorPicker} />Cancel
                        </a>
                        <a href="#" onClick={reset} className="tooltip-link">
                            <ResetIcon color={colorPicker} />
                            <span className="tooltip-hover"></span>
                            <span 
                                className="block-on-hover">
                                Reset
                            </span>
                        </a>
                        <a href="#" className="tooltip-link" onClick={minimize}>
                            <MinimizeIcon color={colorPicker} />
                            <span className="tooltip-hover"></span>
                            <span 
                                className="block-on-hover">
                                Minimise
                            </span>
                        </a>
                        
                    </>
                }
            </div>
        </div>
    )
}

export default FocusTimerBar