import React from 'react'
import {useNavigate} from 'react-router-dom'
import ResetPasswordNavigation from './ResetPasswordNavigation'
import AuthSideBar from './SignUp/AuthSideBar'

function ResetPasswordConfirmation() {
  const navigate=useNavigate()
  let email=localStorage.getItem("email")
  return (

    <>
      <div className="main-body-wrap task-wrap inner-pages-wrap login-wrap checkinbox">
        <header className="header">
          <div className="d-flex header-inner">
            <div className="site-logo">
              <a href="#" className="logo">LifeTab</a>
            </div>
            <div className="heder-midle">

            </div>
            <div className="header-right">

            </div>
          </div>
        </header>

        <div className="page-wrappers login">
          <div className="page-container">
            <div className="contentquoteSection">
              <div className="form-content">
                <div className="login-form">
                  <div className="slide-form">

                    <div className="slide-fields passwordReset active">
                      <div className="login-block">
                        <span className="log-name text-uppercase color-green">PASSWORD RESET</span>
                        <div className="input-block">
                          <h3 className="h3-label">Check your  <br /> inbox!</h3>
                          <p className="text-label">We’ve sent a password reset code to your email address <a href='#'>{email}</a>. If you can’t find the email, make sure you check your spam folder.</p>
                          <div className="logins-actions">
                            <div className="logins-button">
                              <a className="login-submit  sb-btn" onClick={()=>navigate('/verification-code')}>Enter Code</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>

                  <div className="form-navigation">

                    <div className="back-action" onClick={()=>navigate('/email-sent')}><a className="back-link" ><img src="./images/arrow-left.svg" alt=''/></a></div>

                   
                  </div>
                  {/* <ResetPasswordNavigation isReceivedEmail={true}/> */}

                  {/* <AuthSideBar/> */}


                </div>
              </div>
            </div>
            {/* End Quote block */}
          </div>
        </div>
      </div>



    </>


  )
}

export default ResetPasswordConfirmation