import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TaskModal from './Settings/Modal/TaskModal'
// import BroadcastChannelAPI from '../Components/FocusTimer/BroadcastChannelAPI'
import { AuthContext } from '../AuthProvider'
import PublicRoutes from './PublicRoutes'
import { authentication, fetchBackgroundImages } from '../Components/action/utilities'
import PrivateRoutes from './PrivateRoutes'
import FocusTimerSockets from './FocusTimerSockets'
import { useSocket } from '../SocketContext'
import { isExpired, decodeToken } from "react-jwt";
import { initFocusTimer } from '../redux/slices/timer'
import { useDispatch } from 'react-redux'
import { setFocusTasksList } from '../redux/slices/TaskSlice'
import { fetchSetting, subscriptionDetails } from '../Components/action/common'
import { setSettings } from '../redux/slices/SettingSclice'
import { setBackgroundImages, setColorPicker, setThemeDefaultImage } from '../redux/slices/ThemeSlice'
import AiAssistantModal from './Settings/Modal/AiAssistantModal'
import FocusTimerEvents from './FocusTimerEvents'
import FeatureUpdateModal from './Settings/Modal/FeatureUpdateModal'
import { THEME } from '../Components/common/constants'

function Layout() {
  const focusTimer = useSelector(state =>  state.focusTimer);
  const {activeTimer, pause, start, fullscreen} = focusTimer;
  const focusTimerString = JSON.stringify(focusTimer);
  const focusTimerMemo = useMemo(() => JSON.parse(focusTimerString), [focusTimerString]);
  const {focusTasksList} = useSelector(state => state.task);
  const focusTasksListString = JSON.stringify(focusTasksList);
  const focusTasksListMemo = useMemo(() => JSON.parse(focusTasksListString), [focusTasksListString]);
  const { isAuthenticated, setSubscription, redirection } = useContext(AuthContext);
  const location = useLocation();
  const socket = useSocket();
  const dispatch = useDispatch();
  const { backgroundImageId, backgroundImages, theme, colorPicker } = useSelector(state => state.theme);
  const [openTaskModal, setOpenTaskModal] = useState(null);

  useEffect(() => {
    fetchSettinValue().then(settings => {
      fetchBackgroundImages().then( (images) => {
        let defaultImage = null;
        let defaultAccentColor = null;
        // Local background image
        if(settings?.backgroundImageId){
          defaultImage = images.find(img => img.id == settings?.backgroundImageId)?.id ?? null;
        }
        // If local not found then apply global default background image
        if(!defaultImage){
          defaultImage = (images.find(img => img.isDefault))?.id ?? null;
          defaultAccentColor = (images.find(img => img.isDefault))?.defaultColor ?? THEME.DEFAULT_COLOR;
        }
        dispatch(setBackgroundImages(images));
        defaultImage && dispatch(setThemeDefaultImage(defaultImage));
        defaultAccentColor && dispatch(setColorPicker(defaultAccentColor));
      });
    });
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', focusInputFieldCmd, false);
    document.addEventListener('keyup', focusInputFieldCtrl, false);

    return () => {
      document.removeEventListener('keydown', focusInputFieldCmd, false);
      document.removeEventListener('keyup', focusInputFieldCtrl, false);
    }
  }, []);

  useEffect(() => {
    subscriptionDetails().then(data => {
        if(data?.isFreeTrial && data?.accountStage > 3){
          const daysLeft = Math.ceil((new Date(data?.renewalDate) - new Date()) / (1000 * 60 * 60 * 24));
          setSubscription({...data, daysLeft})
        }
    })
  }, [location.pathname])

  useEffect(() => {
    if(!(isAuthenticated && authentication())) 
      return;

    setBackgroundImage(backgroundImageId);
  }, [backgroundImageId])

  const fetchSettinValue = async () => {
    let settings = {};
    try {
      let response = await fetchSetting();
      settings = response?.data?.responseData?.settings ?? {};
      dispatch(setSettings(settings));
    } catch ({ response }) {}

    return settings;
  }

  const setBackgroundImage = async (id) => {
    // document.body.style.setProperty('background', `linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.96) 6.22%, rgba(255, 255, 255, 0.70) 100%), ${colorPicker}`);
    const bodyStyle = document.body.style;
    const bgImage = id ? backgroundImages.find(img => img.id == id) : {location: THEME.DEFAULT_IMAGE};
    if(bgImage?.location){
      bodyStyle.backgroundImage = `
        url("${bgImage?.location}"),
        linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%),
        linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.96) 6.22%, rgba(255, 255, 255, 0.70) 100%)
      `;
    }else{
      bodyStyle.backgroundImage = `linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%),
        linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.96) 6.22%, rgba(255, 255, 255, 0.70) 100%)`;
    }

    bodyStyle.backgroundColor = colorPicker ?? '#889427';
    bodyStyle.backgroundPosition = '50%';
    bodyStyle.backgroundSize = 'cover';
    bodyStyle.backgroundRepeat = 'no-repeat';
  }

  const focusInputFieldCmd = (e) => {
    if ((e.metaKey)  && e.key == 'Enter') {
      focusTaskInputField();
    }
  }
  const focusInputFieldCtrl = (e) => {
    if ((e.ctrlKey == true)  && e.key == 'Enter') {
      focusTaskInputField();
    }
  }

  const focusTaskInputField = () => {
    const path = location.pathname;
    let currentPage = 'home';
    switch (path) {
      case '/tasks':
        currentPage = 'upComing';
        break;
      case '/tasks/overdue':
        currentPage = 'overDue';
        break;
      case '/tasks/inbox':
        currentPage = 'allTask';
        break;
      case '/tasks/nodate':
        currentPage = 'noDate';
        break;
      case '/tasks/complete':
        currentPage = 'completedList';
        break;
      default:
        currentPage = 'home';
    }
    setOpenTaskModal({currentPage});
  }

  useEffect(() => {
      if(socket){
        const handleFocusTimer = (data) => {
            const { action, focusTimerData, focusTasksListData } = data;
            // Stop timer in other opened tabs
            switch (action) {
                case 'fetchFocusTimer':
                  triggerWebSocket({action: 'initTimer', focusTimerData: focusTimerMemo, focusTasksListData: focusTasksListMemo})
                  break;

                case 'initTimer':
                  dispatch(initFocusTimer(focusTimerData));
                  dispatch(setFocusTasksList(focusTasksListData));
                  break;
            }
        }
        socket.off('focusTimerInit', handleFocusTimer);
        socket.on('focusTimerInit', handleFocusTimer);

        // Cleanup function to remove the event listener
        return () => {
          socket.off('focusTimerInit', handleFocusTimer);
        };
      }
  }, [socket, focusTimerMemo, focusTasksListMemo])

  const triggerWebSocket = (data) => {
    // Trigger timer in wepapp and other clients
    if (socket !== null){  
        const user = decodeToken(localStorage.getItem('token'));
        socket.emit('focusTimerInit', {room: user['outseta:accountUid'], data});
    }
  }

  return (
    isAuthenticated && authentication()
    ?
    <>
      {
        openTaskModal
        &&
        <TaskModal
            show={true}
            data={null}
            currentPage={openTaskModal.currentPage}
            onHide={() => {
              setOpenTaskModal(null)
            }}
        />
      }
      {/* <BroadcastChannelAPI /> */}
      {redirection && <Navigate to={redirection} />}
      <PrivateRoutes activeTimer={activeTimer} pause={pause} location={location} start={start} fullscreen={fullscreen} theme={theme} colorPicker={colorPicker} />
      {socket && <FocusTimerSockets socket={socket} />}
      {location.pathname !== '/ai-assistant' && <AiAssistantModal fullscreenButton={!(activeTimer === 'focusMode' || activeTimer === 'FocusModePause')} />}
      {!(fullscreen || location.pathname === '/focus-mode-settings') && <FocusTimerEvents />}
    </>
    :
    (
      location.pathname.match(/\/(signup|login|welcome|logout|plan-upgrade)$/) === null
      ?
      <Navigate to="/welcome" />
      :
      <PublicRoutes />
    )
  )
}

export default Layout