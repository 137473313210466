import React from 'react'
import Tooltip from '../../Components/common/Tooltip';

const TaskSourceIcon = ({task, colorPicker}) => {
  return (
    task.taskSource ? (
        <Tooltip className='overDue-tooltip tooltip-mt0' text={task.taskSource.charAt(0).toUpperCase() + task.taskSource.slice(1)} wrapperClassName="relative" color={colorPicker}> 
          <a className="hovr-btn tooltip-hover">
            <span
              key={"task-" + task.id}
              className="d-flex align-center justify-center gap-2"
            >
              <img width={15} src={`/images/${task.taskSource}.png`} alt={task.taskSource} />
            </span>
          </a>
        </Tooltip>
    ) : null
  )
}

export default TaskSourceIcon;