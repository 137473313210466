/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useContext } from 'react'
import { useState, useRef } from 'react'
import { createTask, fetchTaskList } from '../../Components/action/common'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  pushAllTaskList,
  pushCompletedTaskList,
  pushGroupedList,
  pushNoDateTaskList,
  pushOverDueTaskList,
  pushSetTaskList,
  setFocusTasksList,
  setTaskProjects
} from '../../redux/slices/TaskSlice'
import { setAllProjectsList, setProjectsListWithTasksCount, setProjectTasks } from '../../redux/slices/ProjectSlice'
import { useEffect } from 'react'
import moment from 'moment'
import momentTimezone from 'moment-timezone';
import Datepicker from '../../Components/DatePicker/components/Datepicker'
import { jsx } from "@emotion/core";
import PlusIcon from './PlusIcon'
import CalendarIcon from './CalendarIcon'
import { calendarFormat, format, isValidDate, parseMentionInput } from '../../Components/action/utilities'
import Select from 'react-select'
import RepeatedTaskModal from '../Settings/Modal/RepeatedTaskModal'
import { RRule } from 'rrule'
import Skeleton from 'react-loading-skeleton'
import TaskInputField from '../../Components/common/TaskInputField'
import { fetchProjectList } from '../../Components/action/tasks'
import { usePosthog } from '../../PosthogProvider'

function AddTask({ fetchTotalCount, currentPage, groupDate, setSelectedDate = (date) => {}, selectedDate, completedList, fetchAddTaskData, onAddTaskUpdate, setTodayTaskList, upComing, onHide, setFocus = false, isActive = true, focusTimerState = {}, isSettled = true, groupKey = null, projectId = null}) {
  const dispatch = useDispatch()
  const inputRef = useRef('');
  const titleRef = useRef();
  const { captureEvent } = usePosthog();

  // global state
  const { headerName, taskDaySelected, focusTasksList, taskProjects } = useSelector(state => state.task)
  const dateChange = useSelector(state => projectId ? null : state.task.dateChange)
  const { allProjectsList } = useSelector(state => state.project)
  const { colorPicker, theme } = useSelector(state => state.theme)
  const [selectedDay, setSelectedDay] = useState(taskDaySelected);
  const [addTaskFocus, setAddTaskFocus] = useState(setFocus)
  const [onDateChage, setOnDateChage] = useState(null)
  const [onInputFocus, setOnInputFocus] = useState(setFocus)
  const [inputFieldValue, setInputFieldValue] = useState(false)
  const [taskTitle, setTaskTitle] = useState('');
  const [selectedRepeat, setSelectedRepeat] = useState(1);
  const [repeatListOptions, setRepeatListOptions] = useState([]);
  const [openCustomRepeat, setOpenCustomRepeat] = useState(false);
  const [selectedProject, setSelectedProject] = useState(groupKey);
  const repeatOptionsRef = useRef(null);

  const initRepeatListOptions = useCallback(() => {
    const TaskDate = {
      from: onDateChage || new Date()
    }
    const dtstart = momentTimezone(TaskDate?.from).tz(momentTimezone.tz.guess()).endOf('day').toDate()
    const selectRepeatList = [
      { value: 1, label: `Does not repeat`, rule: null },
      { value: 2, label: `Daily`, rule: (new RRule({dtstart, freq: RRule.DAILY})).toString() },
      { value: 3, label: `Weekly on ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.WEEKLY, interval: 1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 4, label: `Monthly on the first ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.MONTHLY, interval: 1, bysetpos: 1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 5, label: `Monthly on the last ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.MONTHLY, interval: 1, bysetpos: -1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 6, label: `Annually on ${TaskDate?.from && format(TaskDate.from, "MMMM DD")}`, rule: (new RRule({dtstart, freq: RRule.YEARLY, interval: 1})).toString() },
      { value: 7, label: `Every weekday (Monday to Friday)`, rule: (new RRule({dtstart, freq: RRule.WEEKLY, byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]})).toString() },
      { value: 99, label: null, rule: null },
      { value: 100, label: `Custom...`, rule: null }
    ];

    setSelectedRepeat(1)
    setRepeatListOptions(selectRepeatList)
  }, [onDateChage]);

  useEffect(() => {
   setSelectedDay(currentPage == 'noDate' ? 'No Date' : taskDaySelected)
  }, [taskDaySelected])

  const reset = () => {
    setTaskTitle('');
  }

  const onAddTask = async () => {
    //const data = getValues();
    const dateTime = moment(dateChange).format('YYYY-MM-DD')
    let date = null;

    try{
      switch (selectedDay) {
        case 'No Date':
          date = null
          break;
        default:
          date = new Date(moment(onDateChage).startOf('date')).toISOString()
      }
    }catch(e){
      date = null
    }
    
    let {tags, project, title} = parseMentionInput(taskTitle ?? "");
    project = project ?? selectedProject;
    project = project?.replace(/@/g, '');

    if (title) {
      try {
        const requestBody = {
          "title": title,
          "tags": tags,
          "project": project,
          "date": date,
          "repeat": repeatListOptions.filter(item => item.value === selectedRepeat)?.[0] ?? null,
          "status": completedList === true ? 1 : 0
        }
        let response = await createTask(requestBody)
        if(response?.data?.responseData?.data){
          response.data.responseData.data.Project = project ? {title: project} : null
          response.data.responseData.data.Tags = tags ? tags.map((tag, index) => ({name: tag, id: index+1})) : [];
        }
        if(project){
          let projects = [];
          if(taskProjects.find(item => item.title.toLowerCase() == project.toLowerCase())){
            projects = taskProjects;
          }else{
            projects = [{title: project}, ...taskProjects];
          }
          dispatch(setTaskProjects(projects))
        }
        switch (currentPage) {
          case 'upComing':
            dispatch(pushGroupedList(response?.data?.responseData?.data))
            break;

          case 'overDue':
            dispatch(pushOverDueTaskList(response?.data?.responseData?.data))
            // fetchTotalCount()
            break;

          case 'allTask':
            dispatch(pushAllTaskList(response?.data?.responseData?.data))
            // fetchTotalCount()
            break;

          case 'noDate':
            dispatch(pushNoDateTaskList(response?.data?.responseData?.data))
            // fetchTotalCount()
            break;
          case 'completedList':
            dispatch(pushCompletedTaskList(response?.data?.responseData?.data))
           // fetchTotalCount()
            break;

          default:
            // if (moment(response?.data?.responseData?.data?.date).format('YYYY-MM-DD') == dateTime) {
            dispatch(pushSetTaskList(response?.data?.responseData?.data));
            const {pause, start, activeTimer, fullscreen} = focusTimerState;
            //console.log({pause, start, activeTimer, fullscreen, focusTasksList})
            if(pause === false && start === true && activeTimer === 'focusMode' && fullscreen === true){
              dispatch(setFocusTasksList([...focusTasksList, {...response?.data?.responseData?.data, focus: 1}]));
            }

            if (dateTime != moment().format('YYYY-MM-DD')) {
              setSelectedDay('DatePicker')
            } else {
              setSelectedDay('Today')
            }
        }

        if(fetchTotalCount){
          fetchTotalCount();
        }

        if(projectId){
          const response = await fetchTaskList({projectId, status: 0});
          dispatch(setProjectTasks(response?.data?.responseData ?? []));
          const res = await fetchProjectList({includeTasksCount: true, projectId})
          dispatch(setProjectsListWithTasksCount(res?.data?.responseData?.data ?? {}))
        }else if(currentPage !== 'home'){
          const res = await fetchProjectList({includeTasksCount: true, ordering: '1'})
          dispatch(setProjectsListWithTasksCount(res?.data?.responseData?.data ?? []))
        }

        // Capture add task event
        captureEvent(`New Task Added ${project ? `- Project: ${project}` : ''}`, {
          project: project
        });

        if(onHide){
          onHide(response?.data?.responseData?.data);
          return;
        }

        setOnInputFocus(false)
        setAddTaskFocus(false)
        if (currentPage == "upComing") {
          setOnInputFocus(false)
          let repotionsBlock = document.getElementById(groupDate)
          if (repotionsBlock) repotionsBlock.style.display = "none"
          setAddTaskFocus(false);
          reset()
        } else {
          reset()
          let repotionsBlock = inputRef.current; //document.querySelector("#repotionsBlock")
          repotionsBlock.style.display = "none"
        }

        setSelectedDate(dateTime)
        if (dateTime == selectedDate) {
          fetchAddTaskData()
        }
        if(setTodayTaskList)
          setTodayTaskList(true)
        if(onAddTaskUpdate)
          onAddTaskUpdate()

      } catch (response) {
        console.log('ERROR==>', response)
      }
    }
  }

  const selectDayOfDate = (date) => {
    if ((moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))) {
      setSelectedDay('Today')
    } else if ((moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD') == moment(new Date(moment().startOf().add(1, 'days'))).format('YYYY-MM-DD'))) {
      setSelectedDay('Tomorrow')
    } else if ((moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD') == moment(new Date(moment().startOf().add(7, 'days'))).format('YYYY-MM-DD'))) {
      setSelectedDay('Next Week')
    } else {
      setSelectedDay('DatePicker')
    }
  }

  const addTaskOnupComingFocus = (groupDate) => {
    setOnInputFocus(true)
    selectDayOfDate(groupDate);

    let repotionsBlock = document.getElementById(groupDate)
    if (repotionsBlock) {
      repotionsBlock.style.display = "block"
    }
    setAddTaskFocus(true)
  }

  const addTaskOnFocus = () => {
    setOnInputFocus(true)
    let repotionsBlock = inputRef.current; //document.getElementById("repotionsBlock")
    if (repotionsBlock) {
      repotionsBlock.style.display = "block";
    }
    setAddTaskFocus(true)
  }

  const onCancelUpComingTask = (groupDate) => {
    setOnInputFocus(false)
    let repotionsBlock = document.getElementById(groupDate)
    if (repotionsBlock) repotionsBlock.style.display = "none"
    setAddTaskFocus(false);
    reset();
    setSelectedRepeat(1)

    if(onHide)
      onHide();
  }
  const onCancelTask = (e) => {
    setOnInputFocus(false)
    inputRef.current.style.display = "none";
    setAddTaskFocus(false);
    reset();
    setSelectedRepeat(1)

    if(onHide)
      onHide();
  }

  const onClickDateRangeSet = (day) => {
    setSelectedDay(day)
    handleAddTaskFilter(day)
  }

  const onDatePickerDateChange = (e) => {
    setOnDateChage(e)
  }

  function handleAddTaskFilter(taskDaySelected) {
    let date = null
    if (taskDaySelected === 'Today') {
      date = new Date()
    } else if (taskDaySelected === 'Tomorrow') {
      date = new Date(moment().startOf().add(1, 'days'))
    } else if (taskDaySelected === 'Next Week') {
      // date = moment(date).add(7, 'days').toISOString()
      date = new Date(moment().add(7, 'days'))
    }
    else {
      date = null
    }

    if (date)
      setOnDateChage(date);
  }

  const onInputValueChange = (value) => {
    if (value != "") {
      setInputFieldValue(true)
    } else {
      setInputFieldValue(false)
    }
  }

  useEffect(() => {
    switch (headerName) {
      case 'All':
        setSelectedDay("Today")
        break;
      case 'Overdue':
        setSelectedDay("Today")
        break;
      case 'Completed':
        setSelectedDay("Today")
        break;
      case 'NoDate':
        setSelectedDay('No Date')
        break;
      default:
    }
  }, [headerName])

  useEffect(() => {
    let currentDate = moment(dateChange).format('D MMM YYYY');
    setOnDateChage(groupDate ?? currentDate);
    // Fetch projects list
    const getProjects = async () => {
      const {data} = await fetchProjectList();
      const projects = data?.responseData?.data ? data?.responseData?.data?.map(option => ({value: option.title, label: option.title})) : [];
      dispatch(setAllProjectsList(projects))
    }
    getProjects();
  }, [])

  useEffect(() => {
    if(titleRef.current){
      titleRef.current.className = `add-task-input  ${inputFieldValue == false ? "" : "input-filled"} ${(theme == "dark") ? " add-task-placeholder" : ""} ${isActive ? 'active' : ''} ${addTaskFocus == true ? 'focus-input add-task-placeholderOnFocus' : ''}`
    }
  }, [inputFieldValue, addTaskFocus, isActive])

  useEffect(() => {
    !groupDate && setOnDateChage(dateChange)
  }, [dateChange])

  useEffect(() => {
    initRepeatListOptions();
  }, [onDateChage])

  useEffect(() => {
    setTaskTitle(title => {
      let newTitle = title.replace(/@\[[^\]]+\]/g, '');
      if(selectedProject !== null && selectedProject !== '@'){
        newTitle = newTitle.trim();
        newTitle += ` @[${selectedProject}] `;
      }
      titleRef?.current?.focus();
      return newTitle
    })
  }, [selectedProject])

  const CustomStyle = {
    singleValue: (base, state) => {
      return {
        ...base,
        background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}`,
        '-webkit-text-fill-color': 'transparent',
        '-webkit-background-clip': 'text !important'
      }
    },
   option: (base, state) => {
      let background = 'black';
      let color = 'white';
      let backgroundImage = 'none';
      let backgroundPosition = '0 0';
      let opacity = 1;
      const isFirstOption = state.options.indexOf(state.data) === 0;
      
      if (isFirstOption) {
        opacity = 0.5;
      }

      if (state.isFocused) {
        background = `linear-gradient(0deg,rgba(255,255,255,0.9),rgba(255,255,255,0.9)),${colorPicker} !important`;
      }

      if (state.isSelected) {
        color = "white !important";
        background = `black url('data:image/svg+xml;charset=UTF-8,%3Csvg width%3D%2214%22 height%3D%2210%22 viewBox%3D%220 0 14 10%22 fill%3D%22none%22 xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath d%3D%22M13 1L4.75 9.25L1 5.5%22 stroke%3D%22${colorPicker.replace(/^#/, '%23')}%22 stroke-width%3D%221.5%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22/%3E%3C/svg%3E') no-repeat !important`;
        backgroundPosition = 'right 20px center !important';
      }

      return {
        ...base,
        color,
        background,
        backgroundImage,
        backgroundPosition,
        opacity
      };
    }
  } 

  const setCustomRule = (value) => {
    if(value?.rule){
      setSelectedRepeat(99);
      setRepeatListOptions(options => options.map(option => option.value === 99 ? {...option, ...value} : option));
    }else{
      setOpenCustomRepeat(value);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.ctrlKey && !e.metaKey) {
      e.preventDefault(); // Prevent default form submission
      onAddTask();
    }else{
      if (e.key == 'Escape' || e.keyCode === 27) {
        e.target.closest('.type-header')?.classList?.remove('bg-light-addTask');
        e.target?.classList?.remove('focus-input');
        e.target?.classList?.remove('active');
        e.target?.blur();
        e.target.value = '';
        e.target.closest('.type-header').querySelector('.repetions-block').style.
        display = 'none';
      }
    }
  };

  const onChangeHandler = (value) => {
    if(value.match(/@/)){
      setSelectedProject(value.split('@')?.[1] ?? null) 
    }else{
      setSelectedProject(null)
    }
  }

  return (
    <>
      <div className={`type-header  ${onInputFocus ? 'bg-light-addTask' : ''}`}>
        <form onSubmit={(e) => {
          e.preventDefault();
          onAddTask();
        }}
        css={{
          borderRadius: "15px",
          background: theme == "dark" ? `linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #000` : `linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.80) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #FFF`
        }}
        >
          <div className="filed-type" style={{ background: theme == "dark" ? "#000" : "#fff", borderBottom: theme == "dark" ? "1px solid white" : "" }} css={{
            input: {
              color: theme == "dark" ? "white !important" : ""
            }
          }}>
            {
              isSettled ?
              <TaskInputField ref={titleRef} onChange={onChangeHandler} projects={allProjectsList} taskTitle={taskTitle} setTaskTitle={setTaskTitle} handleKeyPress={handleKeyPress} setFocus={setFocus} currentPage={currentPage} colorPicker={colorPicker} addTaskOnupComingFocus={addTaskOnupComingFocus} onDateChage={onDateChage} addTaskOnFocus={addTaskOnFocus} />
              :
              <Skeleton width={100} height={20} />
            }
            <span className="add-sqc">
              <PlusIcon />
            </span>
          </div>
          <div ref={inputRef} className="repetions-block" id={currentPage == "upComing" ? groupDate : 'repotionsBlock'} style={{ display: setFocus ? "block" : "none" }}>
            <div className="task-repetions">
              <div className="add-repetions">
                <div className={`cs-select`}
                  css={{
                    color: theme == "dark" ? "white !important" : "white !important",
                    border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                    backgroundColor: `${theme == 'dark' ? '#000' : '#fff'} !important`
                    }}
                  onClick={() => onClickDateRangeSet('DatePicker')}>
                    <CalendarIcon color="gray" width={14} height={14} />
                    <span className="sl-ico" css={{
                        background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}`
                      }}>
                      {calendarFormat(onDateChage)}
                    </span> 
                  <Datepicker onChange={(e) => { onDatePickerDateChange(e) }} value={currentPage == "upComing" ? onDateChage : dateChange} />
                </div>
                {
                  isValidDate(onDateChage) 
                  &&
                  <div ref={repeatOptionsRef} className={`cs-select recurring-options-dropdown ${selectedDay === 'Custom' ? 'activeDate' : ''} `}
                    css={{
                      color: theme == "dark" ? "white !important" : "black !important",
                      border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                      backgroundColor: `${theme == 'dark' ? '#000' : '#fff'} !important`,
                      '&:hover': {
                        background: theme == "dark" && `${colorPicker} !important`
                      }
                    }}>
                    <Select
                        styles={CustomStyle}
                        onChange={(value) => value.value === 100 ? setOpenCustomRepeat(true) : setSelectedRepeat(value.value)}
                        options={repeatListOptions.filter(option => option.label !== null)}
                        value={repeatListOptions.filter(option => option.value === selectedRepeat)[0]}
                        className="react-select repeated-task-select"
                        classNamePrefix={"repeating-task-setting" || ""}
                    />
                    {
                      openCustomRepeat
                      &&
                      <RepeatedTaskModal targetRef={repeatOptionsRef} onHide={(value = null) => setCustomRule(value)} task={{date: onDateChage || new Date()}} />
                    }
                  </div>
                }
                <div className={`cs-select projects-tags-dropdown`}
                    css={{
                      color: theme == "dark" ? "white !important" : "black !important",
                      border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                      backgroundColor: `${theme == 'dark' ? '#000' : '#fff'} !important`,
                      '&:hover': {
                        background: theme == "dark" && `${colorPicker} !important`
                      },
                      '.repeated-task-select': {
                        '.repeating-task-setting__menu-list': {
                          maxHeight: '300px !important'
                        }
                      }
                    }}>
                      <Select
                        styles={CustomStyle}
                        placeholder="Add to Project"
                        onChange={(value) => setSelectedProject(value.value)}
                        options={[{value: null, label: 'None'}, ...allProjectsList]}
                        value={allProjectsList.filter(option => option.value === selectedProject)?.[0] ?? null}
                        className="react-select repeated-task-select"
                        classNamePrefix={"repeating-task-setting"}
                      />
                </div>
              </div>
              <div className="repetions-action">
                <div className='repeTions'>
                  <button type="button" className="recAct-btn cancel-btn" css={{
                      color: theme == "dark" ? "rgba(255,255,255,0.5) !important" : "black !important",
                      backgroundColor: `${theme == "dark" ? "#000" : "#fff"} !important`,
                      border: '1px solid rgba(0, 0, 0, 0.03) !important'
                    }} onClick={() => { upComing == true ? onCancelUpComingTask(groupDate) : onCancelTask() }}>
                      Cancel
                  </button>
                  <div className="repeTions">
                    <button type='submit' className="recAct-btn save-btn" css={{
                      background: `${colorPicker} !important`,
                      border: `1px solid rgba(0,0,0,0.05) !important`,
                      color: "white !important"
                    }}>Add Task</button> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddTask