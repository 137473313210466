import React, { useEffect, useState } from 'react';
import { fetchData } from '../action/timeTracker';
import { focusTimerFormatV3 } from '../action/utilities';
import { useSelector } from 'react-redux';

const WebsiteTimeTracker = ({type = null}) => {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const { theme, colorPicker } = useSelector((state) => state.theme);

    useEffect(() => {
        fetchData({type}).then((res) => {
            setData(res.data?.responseData ?? []);
        });
    }, []);

    const exportToCSV = () => {
        fetchData({startDate, endDate, type}).then((res) => {
            const filteredData = res.data?.responseData ?? [];
            const csvContent = [
                ['User Email', 'Website', 'Time Spent', 'Date'],
                ...filteredData.map(entry => [
                    entry.userEmail,
                    entry.domain,
                    Math.round(entry.timeSpent / 1000),
                    entry.date.split('T')[0]
                ])
            ].map(row => row.join(',')).join('\n');
    
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'website_time_tracker.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };

    return (
        <div className="website-time-tracker">
            <h3 className='inner-title'>Website Time Tracker {type ?? ''}</h3>
            <div className='date-filter'>
                <label>Start Date: </label>
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                <label>End Date: </label>
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                <button className='btn' style={{background: colorPicker, color: theme === 'light' ? '#000' : '#fff'}} onClick={exportToCSV}>Export CSV</button>
            </div>
            <div className="table-responsive">
                <table className="time-tracker-table">
                    <thead>
                        <tr>
                            <th>User Email</th>
                            <th>Website</th>
                            <th>Time Spent</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((entry) => (
                            <tr key={entry.id}>
                                <td>{entry.userEmail}</td>
                                <td>{entry.domain}</td>
                                <td dangerouslySetInnerHTML={{ __html: focusTimerFormatV3(Math.round(entry.timeSpent / 1000), true) }}></td>
                                <td>{entry.date.split('T')[0]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WebsiteTimeTracker;