import React from 'react'
import { FolderCloseIcon, TagIcon } from '../../Components/LandingHeader/Feedback/icons/Icons'
import _ from 'lodash'
import { Link } from 'react-router-dom'

const ProjectTagIcon = ({task, opacity = 'd7'}) => {
  return (
    <>
        {task.Project && (
            <span
            key={"task-" + task.id}
            className="d-flex align-center justify-center gap-5"
            >
            <FolderCloseIcon />
            <span className={`font size-12 font-family inter text-sm`} style={{letterSpacing: 'normal'}}>
                <Link className={`font color-black opacity ${opacity}`} to={`/tasks/projects/${task.Project.title}/${task.Project.project_id}${task.Project?.projectSource ? `_${task.Project.projectSource}` : ''}`}>{task.Project.title}</Link>
            </span>
            </span>
        )}
        {task.Tags &&
            task.Tags.map(({ name, tagId }) => (
            <span
                key={tagId}
                className="d-flex align-center justify-center gap-5"
            >
                <TagIcon />
                <span className={`font size-12 font-family inter color-black opacity ${opacity} text-sm`} style={{letterSpacing: 'normal'}}>
                {_.capitalize(name)}
                </span>
            </span>
        ))}
    </>
  )
}

export default ProjectTagIcon