/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

export default function NavButton({ children, onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="navBtn datepicker-month-nav"
      css={{
        border: "1px solid #929598",
        background: "transparent",
        padding: "8px",
        fontSize: "12px",
        border: 'none',
        cursor: 'pointer'
      }}
    >
      {children}
    </button>
  );
}
