import React, { useEffect, useState } from 'react'
import QuickLink from '../../pages/QuickLink'
import TodayQuotes from '../../pages/Quotes/TodayQuotes'
import TimerWidget from '../FocusTimer/TimerWidget'
// import { TESTING_CODE } from './constants'
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { pushToSetting } from '../../redux/slices/SettingSclice'
import { setSetting } from '../action/common'
import _ from 'lodash';
// import AIAssistantWidget from '../SideBar/AIAssistantWidget';

const SortableItem = SortableElement(({component, showId}) => <div className='scaleClass' id = {showId} >{component}</div>);

const SortableList = SortableContainer(({items, settings}) => {
    
    return (
        <div className="quoteSection" id="quote">
            <div className='quoteSection-qt' id="quote-sec">
                {items.map((item, index) => (
                    item?.key && (typeof settings[item.key] == 'undefined' || settings?.[item.key]) ? <SortableItem showId = {"container_"+item.key} id = {"container"+index} key={`item-${item.key}`} index={index} component={item.value} /> : null
                ))}
            </div>
        </div>
    )
});

const Sidebar = ({systemSettings}) => {
    const { settings } = useSelector(state => state.settings)
    const dispatch = useDispatch();

    const [items, setItems] = useState([
        {key: 'quotes', value: <TodayQuotes />}, 
        {key: 'focus', value: <TimerWidget />}, 
        {key: 'quickLink', value: <QuickLink />},
        // {key: 'aiAssistant', value: <AIAssistantWidget />}
    ]);

    useEffect(() => {
        if(settings?.widgetsIndex){
            let list = [];
            const c = [...items];
            settings?.widgetsIndex.map(widget => {
                let index = _.findIndex(c, ['key', widget]);
                list.push(c[index]);
                items[index] = null;
            });

            list = [...list, ...items.filter(item => item !== null)];
            setItems(list);
        }
    }, [])

    const onSortEnd = async ({oldIndex, newIndex}) => {
        const list = arrayMove(items, oldIndex, newIndex);
        const widgetsIndex = list.map(item => item.key);
        const newSettings = { ...settings, widgetsIndex };

        setItems(list)
        dispatch(pushToSetting(newSettings));

        try {
            await setSetting({ "settings": newSettings })
        } catch ({ response }) {}
    };

    return <SortableList helperContainer={document.querySelector('.quoteSection')} useDragHandle={true} items={items} settings={systemSettings} lockAxis={'y'} onSortEnd={onSortEnd} />;
}

export default Sidebar