import React, { useState, useRef, useEffect } from 'react';
import { cancelUploadBackgroundImage, uploadBackgroundImage } from '../Components/action/common';
import { Spinner } from '../Components/LandingHeader/Feedback/icons/Icons';
import { setBackgroundImage } from '../redux/slices/ThemeSlice';
import { useDispatch } from 'react-redux';
import { extractColors } from 'extract-colors';

function FileUploader({ colorPicker, maxUploads = 8, maxUploadSize = 5, onHide = () => {} }) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch()

  const handleFileSelect = (event) => {
    setFile(event.target.files[0])
  };

  const handleButtonClick = (e) => {
    e && e.stopPropagation();
    e && e.preventDefault();
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.target.classList.add('dragover');
  };

  const handleDragLeave = (event) => {
    event.target.classList.remove('dragover');
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.target.classList.remove('dragover');
    setFile(event.dataTransfer.files[0])
  };

  useEffect(() => {
    if (file && file.type.startsWith('image/')) {
      handleUpload(file);
    }
  }, [file]);

  const handleUpload = async (file) => {
    setIsUploading(true);

    // Check if file is an image
    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      setIsUploading(false);
      return;
    }
    
    // Check file size in MB
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > maxUploadSize) {
      setError(`File size must be less than ${maxUploadSize}MB`);
      setIsUploading(false);
      return;
    }

    try{
      const formData = new FormData();
      // Pass the local file URL to extractColors instead of the file object
      const colors = await extractColors(URL.createObjectURL(file));
      
      formData.append("files", file);
      formData.append("palette", colors.map(color => color.hex));
      const resp = await uploadBackgroundImage(formData);
      dispatch(setBackgroundImage(resp.data.responseData))
      setIsUploading(false);
      onHide();
    }catch(e){
      setIsUploading(false);
      console.log(e);
    }
  };

  const handleCancelUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    cancelUploadBackgroundImage();
    onHide();
  }

  return (
    <>
      <div>
        {/* Hidden file input */}
        <input
          type="file"
          disabled={isUploading}
          accept='image/*'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
        />

        {/* Drop zone */}
        <div
          className='file-drag-drop-container'
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleButtonClick}
        >
          <h3>Drop your image here or select a file.</h3>
          <p>For the best result, choose an image that has a dimensions 1920px wide, with a 4/3 aspect ratio, and a maximum size of {maxUploadSize}mb.</p>
          <p>You can upload up to {maxUploads} images.</p>
          {/* Upload button */}
          {
              isUploading ? (
                  <>
                      <Spinner /> <span style={{color: colorPicker}}>Uploading...</span>
                  </>
              ) : (
                  <button className='btn btn-primary' style={{ background: colorPicker, color: '#fff' }} onClick={handleButtonClick}>Browse files</button>
              )
          }
          {
            error && <p className='font color-red'>{error}</p>
          }
        </div>
      </div>
      <div className='text-note-container'>
          <p className='text-note'>Learn more about how to upload images and where to find some great photos for free</p>
          <a href='#' onClick={handleCancelUpload}>Cancel</a>
      </div>
    </>
  );
}

export default FileUploader;
