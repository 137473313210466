/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState } from 'react'
import { LinkIcon } from '../LandingHeader/Feedback/icons/Icons';
import { TODOIST } from '../common/constants';
import DisconnectOptionsModal from './DisconnectOptionsModal';
import { useDispatch } from 'react-redux';
import { pushToSetting } from '../../redux/slices/SettingSclice';

const Todoist = ({fetchSettingData, settings, theme, colorPicker}) => {
    const [showDisconnectOptionsModal, setShowDisconnectOptionsModal] = useState(false);
    const dispatch = useDispatch();

    const popupWindow = (url, w, h) => {
        const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
    
        return window.open(url, 'Todoist Auth', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }

    const disconnectTodoist = (disconnect = false) => {
        setShowDisconnectOptionsModal(false);
        if(disconnect){
            dispatch(pushToSetting({ ...settings, todoist_verification_token: '' }));
        }
    }

    const confirmDisconnect = (e) => {
        e?.preventDefault();
        setShowDisconnectOptionsModal(true);
    }
    
    const connectTodoist = (e) => {
        e.preventDefault();
        const api = `${TODOIST.API}?client_id=${TODOIST.CLIENT_ID}&scope=${TODOIST.SCOPE}&state=${localStorage.getItem('token')}`;
        const popup = popupWindow(api, 600, 600);
        const interval = setInterval(() => {
            if (popup.closed) {
                clearInterval(interval);
                console.log('Popup Closed');
                fetchSettingData();
            }
        }, 500);
    }
    return (
        <>
            <div className="field-block mt-30" >
                <h3 className="form-heading text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}> Integrations </h3>
                <div className='d-flex align-center'>
                    <img src="images/logos_todoist-icon.svg" />
                    <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '', margin: 0, marginLeft: '10px', marginRight: '10px' }}>Todoist Integration</label>
                    {settings?.todoist_verification_token?.length > 0 && <span><LinkIcon color={colorPicker} /> <span style={{color: colorPicker,fontWeight: 700, fontSize: '16px', lineHeight: '19px'}}>Connected</span></span>}
                </div>
                <div className="field-info" style={{color: theme == "dark" ? "white" : "#909090"}}>Enjoy a 2-way sync between your scheduled Todoist tasks and LifeTab.</div>
            </div>   
            <div className="field-button" style={{marginTop:"10px"}}>
            {
                settings?.todoist_verification_token?.length > 0
                ?
                <button className="cancel"
                onClick={confirmDisconnect}
                style={{
                background:theme == "dark" && ((theme == "dark" && colorPicker == "#ef233c") ?
                    `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #3A86FF` :
                    (theme == "dark" && colorPicker == "#f8961e") ?
                    `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #27CB9A`
                    : (theme == "dark" && colorPicker == "#27cb9a") ?
                        `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #D95555` :
                        (theme == "dark" && colorPicker == "#4cc9f0") ?
                        `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #D95555` :
                        (theme == "dark" && colorPicker == "#3a86ff") ?
                            `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #D95555` :
                            (theme == "dark" && colorPicker == "#9b5de5") ?
                            `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #F8961E` :
                            (theme == "dark" && colorPicker == "#ef476f") ?
                                `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #3A86FF` :
                                `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), rgb(217, 85, 85)`),
                color:theme == "dark" && ((theme == "dark" && colorPicker == "#ef233c") ? "#3A86FF" : (theme == "dark" && colorPicker == "#f8961e") ? "#27CB9A" : (theme == "dark" && colorPicker == "#9b5de5") ? "#F8961E" : (theme == "dark" && colorPicker == "#ef476f") ? "#3A86FF" : "rgb(217, 85, 85)")
                }}
                >Disconnect</button>
                :
                <button className="changeName"
                    onClick={connectTodoist}
                    css={{
                    background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                    color: `${colorPicker} !important`
                }}
                >Connect Todoist</button>
            }
            </div>
            {
                showDisconnectOptionsModal && <DisconnectOptionsModal show={true} onHide={disconnect => disconnectTodoist(disconnect)} theme={theme} colorPicker={colorPicker} />
            }
        </>
    )
}

export default Todoist