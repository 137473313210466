import React, { useCallback, useState, useRef } from 'react'
import { useEffect } from 'react'
import { fetchInboxTaskList, totalTaskCount } from '../../Components/action/common'
import TaskListDetails from './TaskListDetails'
import { useDispatch, useSelector } from 'react-redux'
import { setAllTaskList, setDateChange, setTotalCount } from '../../redux/slices/TaskSlice'
import UpcomingLoader from '../../Components/SkeletonLoading/UpcomingLoader'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'
import moment from 'moment'
import { Accordion } from 'react-accessible-accordion'
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'
import { verifyRepeatedTask } from '../../Components/action/utilities'

function All() {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true)
  const [list, setList] = useState([])
  const currentTimes=moment().startOf('date')
  const [currentTime,setCurrentTime]=useState(new Date(currentTimes))
  const wrapperRef = useRef(null)
  // global state
  const { allTaskList } = useSelector(state => state.task)
  const { theme} = useSelector(state => state.theme)
  const [refresh, setRefresh] = useState(false)

  const fetchAddTaskData = useCallback(async () => {
    setLoader(true)
    try {
      const response = await fetchInboxTaskList({date: currentTime.toISOString()})
      const pendingTasks = response?.data?.responseData.filter(t => t.status != '1')
      const completedTasks = response?.data?.responseData.filter(t => t.status == '1')
      const totalAllTasks = [...(_.orderBy(pendingTasks.filter(t => verifyRepeatedTask(t, null, true, false, false, true)), ['projectId', 'date'], ['desc', 'desc'])), ...(_.orderBy(completedTasks.filter(t => verifyRepeatedTask(t, null, true, false, false, true)), ['isMostImportant', 'completedAt'], ['desc', 'desc']))];

      const allTaskList = _.chunk(totalAllTasks, 10);

      document.querySelector('.inbox-count').textContent = totalAllTasks?.length

      const chunk = allTaskList.splice(0, 1);
      setList(allTaskList)
      dispatch(setAllTaskList(chunk?.[0] ?? []))
      setLoader(false)
    } catch ({ response }) {
      setLoader(false)
    }
  }, [dispatch])

  const fetchTotalCount=async()=>{
    try {
      let response=await totalTaskCount({"date":currentTime.toISOString()})
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({response}) {
      
    }
  }

  useEffect(() => {
    dispatch(setDateChange((new Date()).toISOString()))
    fetchAddTaskData()
  }, [])

  useEffect(() => {
    if(loader) return;

    const bound = wrapperRef.current.getBoundingClientRect()
    wrapperRef.current.style.height = window.innerHeight - bound.top + 'px'
  }, [loader])
  

  const fetchMoreData = (allTaskList) => {
    const chunk = list.splice(0, 1);
    if(chunk.length > 0){
      setList(list)
      dispatch(setAllTaskList([...allTaskList, ...chunk[0]]))
    }
  }

  const refreshList = () => {
    setRefresh(true)
  }

  useEffect(() => {
    if(refresh){
      fetchMoreData(allTaskList)
      setRefresh(false)
    }
  }, [refresh, allTaskList])

  return (
      loader ? <AllLoader /> : <> <div id="tasks-list-wrapper" ref={wrapperRef} className="overdue-form task-list-page common-form border radius-20" style={{background:theme == "dark" ? "black" :"white"}} >
          <div className="task-list">
            <div className="task-list-data">
              <div className="ul-task-list">
                <div className="task-list">
                <InfiniteScroll
                    dataLength={allTaskList?.length ?? 0}
                    next={() => fetchMoreData(allTaskList)}
                    hasMore={list.length > 0}
                    loader={<AllLoader  />}
                    scrollableTarget="tasks-list-wrapper"
                  >
                <Accordion preExpanded={['accitem0']}>
                  <TaskListDetails
                    allTaskList={allTaskList}
                    currentPage="allTask"
                    fetchMoreData={refreshList}
                    fetchAddTaskData={fetchAddTaskData}
                    fetchTotalCount={fetchTotalCount}
                  />
                </Accordion>
                </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}

export default All