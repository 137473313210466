/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx } from "@emotion/core";
// import MainNav from './MainNav';
import TasksNav from './TasksNav';
import MainNavV2 from './MainNavV2';
import AIAssistantNav from './AIAssistantNav';
import SettingsNav from './SettingsNav';

function SideBar({innerPages}) {

  return (
    // <div className="left-setting-tab" id="setting-tab">
    //   <MainNav />
    //   {innerPages && <TasksNav />}
    // </div>
    <>
      <div className="left-sidebar" id="setting-tab">
        <MainNavV2 />
      </div>
      {(() => {
        switch (true) {
          case innerPages?.includes("tasks"):
            return <TasksNav />;
          case innerPages?.includes("ai-assistant"):
            return <AIAssistantNav />;
          case innerPages?.includes("settings"):
            return null;
            //return <SettingsNav />;
          default:
            return null;
        }
      })()}
    </>
  )
}

export default SideBar