// App.js
import React from 'react';
import LoginForm from '../../Components/Outseta/Login';

const LoginOutseta = () => {
    return (
        <div className="page-wrapper">
            <LoginForm />
        </div>
    );
};

export default LoginOutseta;
