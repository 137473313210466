import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { CHATGPT } from "../../Components/common/constants";

const initialState = {
   chatData: [],
   id: null,
   title: null,
   changed: false,
   persona: CHATGPT.PERSONAS[0],
   prompt: ''
}

const AiAssistantSlice = createSlice({
  name: 'aiAssistant',
  initialState,
  reducers: {
    setChat(state, action) {
        state.chatData = action.payload.data;
        state.id = action.payload.id;
        state.title = action.payload.title;
        state.changed = action.payload?.changed ?? false;

        const sender = action.payload.data[action.payload.data.length - 1]?.sender;
        if(sender){
          state.persona = _.find(CHATGPT.PERSONAS, ['value', sender]);
        }else{
          state.persona = CHATGPT.PERSONAS[0];
        }
    },
    setCurrentPersona(state, action) {
      state.persona = action.payload;
    },
    setCurrentPrompt(state, action) {
      state.prompt = action.payload;
    },
    clearChat(state, action){
        state.id = null;
        state.chatData = [];
        state.changed = action.payload?.changed ?? false;
        state.persona = CHATGPT.PERSONAS[0];
        state.prompt = '';
        state.title = null
    }
  }
})


// reducers exports
export const {setChat , clearChat, setCurrentPersona, setCurrentPrompt} = AiAssistantSlice.actions;

export default AiAssistantSlice.reducer;