/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from "@emotion/core";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { stopTimer } from '../../redux/slices/timer';
import BackgroundImage from '../common/BackgroundImage';
import { focusTimerFormatV2 } from '../action/utilities';
import TeacupSvg from './TeacupSvg';
import {ArrowLeft} from '../LandingHeader/Feedback/icons/Icons';

function BreakCompleted() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { themeColor, theme, colorPicker } = useSelector(state => state.theme)
  const { timer, additionalTimer, activeTimer } = useSelector(state => state.focusTimer);

  useEffect(() => {
    if(activeTimer === null){
      navigate(process.env.REACT_APP_ROOT)
    }
  }, [])

  const exit = (e) => {
    e.preventDefault();

    dispatch(stopTimer());
    navigate(process.env.REACT_APP_ROOT); 
  }

  return (
    <div className={`main-body-wrap timerComplete breakCompleted ${theme}`}>
        <div className="page-wrapper">
          <div className="page-container">
            <div className="slide-fields welcome-slide active">
              <div className="login-block">
                <div className="input-block">
                  <h3 className="h3-label">Timer Complete</h3>
                  <p className="text-label">You were on a break for&nbsp;<span dangerouslySetInnerHTML={{ __html: focusTimerFormatV2(timer + additionalTimer) }}></span></p>
                  <div className="logins-actions">
                    <div className="logins-button">
                    {/* <a className="google-btn active" style={{background: colorPicker}} onClick={exit}>Focus Timer</a> */}
                    <a className="login-submit back-to-home-btn" style={{color: '#808080', background: '#fff', border: '1px solid #fff'}} onClick={exit}><ArrowLeft /> Back to today</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='backgroundBottom'>
          <TeacupSvg />
        </div>
    </div>
  )
}

export default BreakCompleted
