/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { pushToSetting } from '../../redux/slices/SettingSclice';
import { setSetting } from '../action/common';
import CollapseExpandAll from './CollapseExpandAll';
import { CheckIcon, GroupIcon, GroupSortIcon, SortIcon, Tick } from '../LandingHeader/Feedback/icons/Icons';

const GroupAndSortByDropdown = React.memo(({collapseExpandAll = true, colorPicker}) => {
    const { settings } = useSelector(state => state.settings)
    const dispatch = useDispatch()
    const groupOptions = [{value: 'none', label: 'Default'}, {value: 'project', label: 'Project'}]
    const sortOptions = [{value: 'asc', label: 'Ascending', icon: <SortIcon />}, {value: 'desc', label: 'Descending', icon: <SortIcon direction="desc" />}]
    const sortByOptions = [{value: 'title', label: 'Name'}, {value: 'Project.title', label: 'Project'}, {value: 'date', label: 'Do Date', default: true}, {value: 'createdAt', label: 'Date Added'}]
    const [showDropdown, setShowDropdown] = useState(false)

    useEffect(()=>{
        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        }
    }, [])

    const closeDropdown = (e) => {
        if(!e.target?.closest('.actions-dropdown-container')){
            setShowDropdown(false);
        }
    }
    
    const selectGroupBy = ({value}) => {
        const newSettings = { ...settings, groupBy: value };
        dispatch(pushToSetting(newSettings))
        setSetting({ settings: newSettings });
    }

    const selectSort = ({value}) => {
        const newSettings = { ...settings, sort: value };
        dispatch(pushToSetting(newSettings))
        setSetting({ settings: newSettings });
    }

    const selectSortBy = ({value}) => {
        const newSettings = { ...settings, sortBy: value };
        dispatch(pushToSetting(newSettings))
        setSetting({ settings: newSettings });
    }

    const toggleDropdown = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setShowDropdown(!showDropdown)
    }

    return (
        <div className='actions-dropdown-container' css={{
            display: 'flex',
            li: {
                '&:hover': {
                    background: `linear-gradient(0deg, rgba(255,255,255,0.80) 0%,rgba(255,255,255,0.80) 100%),linear-gradient(0deg,${colorPicker} 0%,${colorPicker} 100%),#FFF !important`,
                }
            }
        }}>
            <a href="#" className='group-sort-icon' onClick={toggleDropdown}><GroupSortIcon color={colorPicker} fill={showDropdown ? colorPicker : 'none'} /></a>
            {
                showDropdown &&
                <div className="actions-dropdown overflow visible" css={{top: '40px !important', right: '0px !important'}}>
                    <ul>
                        <li css={{borderRadius: '15px 15px 0 0'}}>
                            <a className='action-item relative border-radius-15-15-0-0' href="javascript: void(0);" css={{
                                '&:hover': {
                                    '.actions-dropdown': {
                                        display: 'block',
                                    }
                                }
                            }}>
                                <SortIcon />
                                Sort by
                                <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                <span style={{color: colorPicker}}>{settings?.sortBy ? sortByOptions.find(option => option.value === settings?.sortBy)?.label : 'Default'}</span>
                                <div className="actions-dropdown" css={{
                                    top: '0 !important',
                                    right: '100% !important',
                                    display: 'none'
                                }}>
                                    <ul>
                                        {
                                            sortOptions.map((option, index) => (
                                                <li key={index}>
                                                    <a className='action-item border-bottom-0' href="javascript: void(0);" onClick={() => selectSort(option)}>
                                                        {option.icon}
                                                        {option.label}
                                                        <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                                        {settings?.sort === option.value && <Tick color={colorPicker} />}
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <div className='hr-line' css={{
                                        '&:after': {
                                            left: '0px !important',
                                        }
                                    }}></div>
                                    <ul>
                                        {
                                            sortByOptions.map((option, index) => (
                                                <li key={index}>
                                                    <a className='action-item border-bottom-0' href="javascript: void(0);" onClick={() => selectSortBy(option)}>
                                                        {option.label}
                                                        {option?.default && <span className='default-label opacity d3'>Default</span>}
                                                        <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                                        {(settings?.sortBy || 'date') === option.value && <Tick color={colorPicker} />}
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </a>
                        </li>
                        <li css={{borderRadius: '0 0 15px 15px'}}>
                            <a className='action-item relative border-radius-0-0-15-15' href="javascript: void(0);" css={{
                                '&:hover': {
                                    '.actions-dropdown': {
                                        display: 'block',
                                    }
                                }
                            }}>
                                <GroupIcon />
                                Group by
                                <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                <span style={{color: colorPicker}}>{settings?.groupBy ? groupOptions.find(option => option.value === settings?.groupBy)?.label : 'Default'}</span>
                                <div className="actions-dropdown" css={{
                                    top: '0 !important',
                                    right: '100% !important',
                                    display: 'none',
                                }}>
                                    <ul>
                                        {
                                            groupOptions.map((option, index) => (
                                                <li key={index}>
                                                    <a className='action-item' href="javascript: void(0);" onClick={() => selectGroupBy(option)}>
                                                        {option.label}
                                                        <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                                                        {settings?.groupBy === option.value && <Tick color={colorPicker} />}
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
})

export default GroupAndSortByDropdown