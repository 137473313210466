import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { pushToSetting } from '../../redux/slices/SettingSclice';
import { setSetting } from '../action/common';
import CollapseExpandAll from './CollapseExpandAll';

const GroupByDropdown = React.memo(({collapseExpandAll = true}) => {
    const { settings } = useSelector(state => state.settings)
    const dispatch = useDispatch()
    const options = [{value: 'none', label: 'Default'}, {value: 'project', label: 'Project'}]
    
    const selectGroupBy = ({value}) => {
        const newSettings = { ...settings, groupBy: value };
        dispatch(pushToSetting(newSettings))
        setSetting({ settings: newSettings });
    }

    return (
        <>
            <Select 
                onChange={selectGroupBy}
                options={options}
                value={options.filter(option => option.value === settings?.groupBy)?.[0]}
                className="react-select groups-dropdown"
                classNames={{control: () => 'groups-dropdown-control'}}
            />
            {collapseExpandAll && settings?.groupBy !== 'none' && settings?.groupBy !== null && <CollapseExpandAll />}
        </>
    )
})

export default GroupByDropdown