import React from 'react'
import { useSelector } from 'react-redux'

function ProfileIcon({onMouseEnterProfile , isProfileActive , isActiveSettings}) {
  const { theme , colorPicker } = useSelector(state => state.theme)

  let strCol=(((theme == "dark" && colorPicker == "#ef233c")) && (onMouseEnterProfile || (!isProfileActive && isActiveSettings == "profile"))) ? "white" : 
  ((theme == "dark" && colorPicker == "#27cb9a") && (onMouseEnterProfile || (!isProfileActive && isActiveSettings == "profile"))) ? "black" : 
  ((theme == "dark" && colorPicker == "#f8961e") && (onMouseEnterProfile || (!isProfileActive && isActiveSettings == "profile"))) ? "black":
  ((theme == "dark" && colorPicker == "#ffca3a") && (onMouseEnterProfile || (!isProfileActive && isActiveSettings == "profile"))) ? "black":
  ((theme == "dark" && colorPicker == "#4cc9f0") && (onMouseEnterProfile || (!isProfileActive && isActiveSettings == "profile"))) ? "black":
  ((theme == "dark" && colorPicker == "#3a86ff") && (onMouseEnterProfile || (!isProfileActive && isActiveSettings == "profile"))) ? "white":
  ((theme == "dark" && colorPicker == "#9b5de5") && (onMouseEnterProfile || (!isProfileActive && isActiveSettings == "profile"))) ? "white":
  ((theme == "dark" && colorPicker == "#ef476f") && (onMouseEnterProfile || (!isProfileActive && isActiveSettings == "profile"))) ? "white":
  "gray"

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      {
        strCol ?  <path d="M12.6666 14.5V13.1667C12.6666 12.4594 12.3856 11.7811 11.8855 11.281C11.3854 10.781 10.7072 10.5 9.99992 10.5H5.99992C5.29267 10.5 4.6144 10.781 4.1143 11.281C3.6142 11.7811 3.33325 12.4594 3.33325 13.1667V14.5" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> :  <path d="M12.6666 14.5V13.1667C12.6666 12.4594 12.3856 11.7811 11.8855 11.281C11.3854 10.781 10.7072 10.5 9.99992 10.5H5.99992C5.29267 10.5 4.6144 10.781 4.1143 11.281C3.6142 11.7811 3.33325 12.4594 3.33325 13.1667V14.5" stroke={`${((theme == "dark" && onMouseEnterProfile) || !isProfileActive) ? "black" : "gray"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      }
      {
        strCol ? <path d="M7.99992 7.83333C9.47268 7.83333 10.6666 6.63943 10.6666 5.16667C10.6666 3.69391 9.47268 2.5 7.99992 2.5C6.52716 2.5 5.33325 3.69391 5.33325 5.16667C5.33325 6.63943 6.52716 7.83333 7.99992 7.83333Z" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> :     <path d="M7.99992 7.83333C9.47268 7.83333 10.6666 6.63943 10.6666 5.16667C10.6666 3.69391 9.47268 2.5 7.99992 2.5C6.52716 2.5 5.33325 3.69391 5.33325 5.16667C5.33325 6.63943 6.52716 7.83333 7.99992 7.83333Z" stroke={`${((theme == "dark" && onMouseEnterProfile) || !isProfileActive) ? "black" : "gray"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      }
    </svg>
    
  )
}

export default ProfileIcon