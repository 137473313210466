/* eslint-disable */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { jsx } from "@emotion/core";
import "./style.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  stopTimer,
  pauseTimer,
  resumeTimer,
  resetTimer,
  setCurrentTimer,
  setTimer,
  setAdditionalTime,
  playSound,
  decreaseTimer,
  increaseTimer,
  completeTimer,
} from "../../redux/slices/timer";
import {
  focusTimerFormat,
  focusTimerFormatV2,
  hexToRgb,
  planTimeFormat,
} from "../action/utilities";
import { useNavigate } from "react-router-dom";
import ArrowUp from "../../pages/Quotes/ArrowUp";
import ExtendTimeSlot from "./ExtendTimeSlot";
import {
  Add,
  MaximizeIcon,
  MinimizeIcon,
  Minus,
  PauseIcon,
  PlayIcon,
  ResetIcon,
} from "../LandingHeader/Feedback/icons/Icons";
import { useBeforeunload } from "react-beforeunload";

let timerWorker = null;

function RunningFocusTimer({ defaultMaximizTimer = false, focusMode = false, display = true }) {
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const [timeSetting, setTimeSetting] = useState(false);
  const [time, setTime] = useState(null);
  const [maximizeTimer, setMaximizeTimer] = useState(defaultMaximizTimer);
  const [timerLimit, setTimerLimit] = useState(0);
  //const [timerInterval, setTimerInterval] = useState(null);
  // const refOne = useRef(null)
  const {
    timer,
    start,
    pause,
    currentTimer,
    resetTimerFlag,
    additionalTimer,
    activeTimer,
    timerCompleted
  } = useSelector((state) => state.focusTimer);
  const { settings } = useSelector((state) => state.settings);
  const timerSpan = settings?.timerNudgeAmount ?? 300; // secs
  const dispatch = useDispatch();
  const { theme, colorPicker } = useSelector((state) => state.theme);

  // useBeforeunload((event) => {
  //   event.preventDefault();

  //   return "You have a timer in progress. Are you sure you want to close this page?";
  // });

  useEffect(() => {
    if (maximizeTimer) {
      navigate("/break-timer-start");
    } else {
      navigate(process.env.REACT_APP_ROOT);
    }
  }, [maximizeTimer]);

  useEffect(() => {
    if (resetTimerFlag !== null) {
      setTime(timer);
      startTimerHandler(timer);
    }
  }, [resetTimerFlag]);

  useEffect(() => {
    if (timer !== null && timer > 0) {
      setTime(currentTimer);
      startTimerHandler(currentTimer);
      setTimerLimit(focusTimerFormatV2(timer + additionalTimer));
    }
  }, [timer, additionalTimer]);

  useEffect(() => {
    pause || !start ? clearTimer() : startTimerHandler(time);
  }, [pause, start]);

  useEffect(() => {
    if (time === -1) {
      document.title = "LifeTab";
      clearTimer();
      if(!timerCompleted)
        playSound(settings?.timerSound);
      // Focus on timer completion screen 
      !timerCompleted && triggerServiceWorker();
      dispatch(completeTimer());
      navigate("/focus-mode-completed");
    } else {
      //   pauseSound()
      document.title = `LifeTab (${planTimeFormat(time)})`;
      dispatch(setCurrentTimer(time));
    }
  }, [time]);

  const triggerServiceWorker = () => {
    if (typeof chrome != 'undefined' && chrome.runtime) {
      try{
        chrome.runtime.sendMessage({ action: 'focus-timer' });
      }catch(e){}
    }
  }

  const beforeUnloadListener = (e) => {
    e.preventDefault();
  };

  const startTimerHandler = (time) => {
    if (!(time !== null && start) || pause) 
      return;

    clearTimer();
    setTimeSetting(true);
    timerWorker = new Worker("/timerWorker.js");
    timerWorker.postMessage({ task: "startTimer", payload: time });
    timerWorker.onmessage = (event) => {
      setTime(event.data);
    };
  };

  const clearTimer = () => {
    timerWorker !== null && timerWorker.terminate();
    timerWorker = null;
  };

  const exit = () => {
    dispatch(stopTimer());
    navigate(process.env.REACT_APP_ROOT);
  };

  const exitWithoutRedirect = () => {
    dispatch(stopTimer());
    activeTimer == "break" && navigate(process.env.REACT_APP_ROOT);
  };

  const stopTimerBroadcast = (e) => {
    exit();
    // bc.postMessage({ action: "stopTimer" });
  };

  // console.log(stopTimerBroadcast, "stop");

  const pauseTimerBroadcast = (e) => {
    dispatch(pauseTimer());
    // bc.postMessage({ action: "pauseTimer" });
  };

  const resumeTimerBroadcast = (e) => {
    dispatch(resumeTimer());
    // bc.postMessage({ action: "resumeTimer" });
  };

  const resetTimerBroadcast = (e) => {
    dispatch(resetTimer());
    // bc.postMessage({ action: "resetTimer" });
  };

  const increaseTimerBroadcast = (e) => {
    dispatch(increaseTimer(timerSpan));
    // bc.postMessage({ action: "increaseTimer" });
  };

  const decreaseTimerBroadcast = (e) => {
    dispatch(decreaseTimer(timerSpan));
    // bc.postMessage({ action: "decreaseTimer" });
  };

  
  return (
    display
    ?
    <div
      className={`dailyQuote focus-timer ${theme ?? ""}`}
      style={{
        boxShadow:
          theme == "dark" && defaultMaximizTimer
            ? `0px 4px 250px ${hexToRgb(colorPicker)}`
            : "none",
      }}
    >
      <div
        className={`quoteHeader ${collapse ? "head-option focusRunning" : ""} `}
        style={{
          background:
            theme == "dark"
              ? "linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000"
              : activeTimer == "focus"
              ? "#F2F2F2"
              : "#F2F2F2",
          position: "relative",
          overflow: "hidden",
        }}
        onClick={() => setCollapse(!collapse)}
      >
        {time !== null && (
          <span
            className="title-progress-bar"
            style={{
              background: colorPicker,
              width: `${
                ((timer + additionalTimer - (time + additionalTimer)) * 100) /
                (timer + additionalTimer)
              }%`,
              maxWidth: "100%",
            }}
          ></span>
        )}
        <span
          className={`quotetitle ${
            theme == "dark" ? "quotesTitleDarkMode" : ""
          }`}
        >
          {activeTimer == "focus" ? "FOCUS TIMER" : "BREAK"}
        </span>
        {collapse === true && (
          <span
            className="running-timer-collapse"
            dangerouslySetInnerHTML={{
              __html: planTimeFormat(
                time + additionalTimer,
                theme == "dark" ? "#fff" : "#000"
              ),
            }}
          ></span>
        )}
        <span className="time-limit">
          {" "}
          / <span dangerouslySetInnerHTML={{ __html: timerLimit }}></span>
        </span>
        <span
          className={`header-arrow ${collapse ? "active" : ""}`}
          onClick={() => setCollapse(!collapse)}
        >
          {theme == "dark" ? (
            <ArrowUp />
          ) : (
            <img src="../../../images/arrow-up-dn.svg" alt="" />
          )}
        </span>
      </div>
      {collapse === false && (
        <div
          className={`quoteContainer focus-timer-container ${theme} ${activeTimer}`}
        >
          <div className="timeColthree runningFocusTimer">
            <a
              href="javascript: void(0);"
              className="desc actBtns"
              onClick={decreaseTimerBroadcast}
            >
              <Minus color={theme == "dark" ? "#fff" : "#000"} />
            </a>
            <div
              className="set-time"
              dangerouslySetInnerHTML={{
                __html: focusTimerFormat(
                  time + additionalTimer,
                  theme == "dark" ? "#fff" : "#000"
                ),
              }}
            ></div>
            <a
              href="javascript: void(0);"
              className="ased actBtns"
              onClick={increaseTimerBroadcast}
            >
              <Add color={theme == "dark" ? "#fff" : "#000"} />
            </a>
          </div>
          {activeTimer == "focus" ? (
            <div className="focus-timer-weight">
              {pause ? (
                <button
                  style={{ borderColor: "#80808061" }}
                  className="pause-btn timer-buttons "
                  onClick={resumeTimerBroadcast}
                >
                  <PlayIcon color={theme == "dark" ? "#fff" : "#000"} />
                </button>
              ) : (
                <button
                  style={{ borderColor: "#80808061" }}
                  className="pause-btn timer-buttons"
                  onClick={pauseTimerBroadcast}
                >
                  <PauseIcon color={theme == "dark" ? "#fff" : "#000"} />
                </button>
              )}
              <button
                style={{
                  borderColor: "#80808061",
                  color: theme == "dark" ? "#fff" : "#000",
                }}
                className="timer-buttons cstm-width focusTimerWidth"
                onClick={stopTimerBroadcast}
              >
                Stop
              </button>
              <button
                style={{ borderColor: "#80808061" }}
                className="timer-buttons"
                onClick={resetTimerBroadcast}
              >
                <ResetIcon color={theme == "dark" ? "#fff" : "#000"} />
              </button>
            </div>
          ) : (
            <div className="timerAction focus-timer-weight">
              {pause ? (
                <button
                  style={{ borderColor: "#80808061" }}
                  className="pause-btn timer-buttons"
                  onClick={resumeTimerBroadcast}
                >
                  <PlayIcon color={theme == "dark" ? "#fff" : "#000"} />
                </button>
              ) : (
                <button
                  style={{ borderColor: "#80808061" }}
                  className="pause-btn timer-buttons"
                  onClick={pauseTimerBroadcast}
                >
                  <PauseIcon color={theme == "dark" ? "#fff" : "#000"} />
                </button>
              )}
              <button
                style={{
                  color: theme == "dark" ? "#000" : "#fff",
                  background: colorPicker,
                  border: `1px solid ${colorPicker}`,
                }}
                className="back-to-work-btn timer-buttons active cstm-width"
                onClick={stopTimerBroadcast}
              >
                Stop
              </button>

              <button
                style={{ borderColor: "#80808061" }}
                className="timer-resset-btn timer-buttons"
                onClick={resetTimerBroadcast}
              >
                <ResetIcon color={theme == "dark" ? "#fff" : "#000"} />
              </button>
              {
                !focusMode
                &&
                <button
                  style={{ borderColor: "#80808061" }}
                  className="minimie-btn timer-buttons"
                  onClick={(e) => setMaximizeTimer((value) => !value)}
                >
                  {!maximizeTimer ? (
                    <MaximizeIcon color={theme == "dark" ? "#fff" : "#000"} />
                  ) : (
                    <MinimizeIcon color={theme == "dark" ? "#fff" : "#000"} />
                  )}
                </button>
              }
              
            </div>
          )}
        </div>
      )}

      <div className='onHoverShorts foCusTimer'>
          <div className='actOnhove'>
              <div className='hovAct ht-collapse'> <img src='/images/collapse.svg' title='Collapse' /> </div>
              <div className="hovAct ht-scale"> <img src="/images/scale.svg" title='full Screen'/> </div>
              <div className='hovAct ht-settings'> <img src='/images/settings.svg'  title='Setting' /> </div>
          </div>
      </div>
    </div>
    :
    null
  );
}

export default RunningFocusTimer;
