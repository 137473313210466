import React from 'react'
import Upcoming from './Upcoming'

function Tasks() {

  return (

    <>
      <div className="togglemenu" data-menu={1}>
        <span className="line-one bg-white" />
        <span className="line-two bg-white" />
        <span className="line-three bg-white" />
      </div>
      <Upcoming />
    </>

  )
}

export default Tasks