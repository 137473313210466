import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { BASE_URL } from './Components/common/constants';
import { isExpired, decodeToken } from "react-jwt";

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const newSocket = io(BASE_URL, {
        auth: { token }
    }); // Adjust according to your server URL
    setSocket(newSocket);

    if(newSocket !== null && token){
      const user = decodeToken(token);
     
      newSocket.emit('focusTimerInit', {room: user['outseta:accountUid'], data: {action: 'fetchFocusTimer'}});
    }
    
    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
