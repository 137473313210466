/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { deleteBackgroundImage, setSetting } from '../../Components/action/common';
import { setBackgroundImages, setColorPicker, setColorPickerPopup, setThemeDefaultImage } from '../../redux/slices/ThemeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { jsx } from "@emotion/core";
import { fetchBackgroundImages } from '../../Components/action/utilities';
import { ColorPaletteIcon, Trash } from '../../Components/LandingHeader/Feedback/icons/Icons';
import { pushToSetting, setSettings } from '../../redux/slices/SettingSclice';
import BackgroundImageCard from '../../Components/common/BackgroundImageCard';
import DefaultBackgroundImages from './DefaultBackgroundImages';
import CustomBackgroundImages from './CustomBackgroundImages';

function ColorPickerSetting({ show = true, onHide }) {
  const dispatch = useDispatch()

  // global state
  const { settings } = useSelector(state => state.settings)
  const { colorPicker, theme, backgroundImageId, backgroundImages } = useSelector(state => state.theme)
  const [image, setImage] = useState(null);
  const [activeTab, setActiveTab] = useState('default');

  useEffect(() => {
    fetchBackgroundImages().then(async (images) => {
      dispatch(setBackgroundImages(images));
      dispatch(setColorPickerPopup(false));
    });
  }, [])

  useEffect(() => {
    image && dispatch(setThemeDefaultImage(image.id))
  }, [image])

  const fetchSettingData = async (applied = false) => {
    if(applied){
      onHide();
      return;
    }

    try {
      let defaultImage = null;
      setImage(null);
      dispatch(setSettings(settings));
      // Set color picker
      dispatch(setColorPicker(settings?.accentColor ?? colorPicker))
      // Local background image
      if(settings?.backgroundImageId){
        defaultImage = backgroundImages.find(img => img.id == settings?.backgroundImageId)?.id ?? null;
      }
      // If local not found then apply global default background image
      if(!defaultImage){
        defaultImage = (backgroundImages.find(img => img.isDefault))?.id ?? null;
      }

      defaultImage && dispatch(setThemeDefaultImage(defaultImage));
    } catch ({ response }) {}
  }

  const onApplyColorPicker = async (e, color) => {
    e?.stopPropagation();
    e?.preventDefault();
    try {
        const newSettings = { ...settings, accentColor: color, theme: theme, backgroundImageId: image.id };
        dispatch(setThemeDefaultImage(image.id))
        dispatch(pushToSetting(newSettings));
        setSetting({ settings: newSettings });
        onHide();
    } catch (error) {}
  }

  const toggleActiveTab = (tab, e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveTab(tab);
  }

  return (
    <>
      <Modal show={image === null} onHide={onHide} className="colorPicker-model">
        <Modal.Body className='modal-body-extra-padding'>
          <div className="full-model md-model-updates width-100" css={{
            '.darkPrimaryColor': {
              background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
            },
            '.background-image-card': {
              position: 'unset !important',
              transform: 'none !important',
              boxShadow: 'none !important',
              background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #97B9BF !important'
            }
          }}>
              <div className="full-model-body">
                  <div className="body-data bg-white border-radius25">
                    <div className='ctTitle relative'>
                        <ColorPaletteIcon color={colorPicker} fill={colorPicker} />
                        <span className='darkPrimaryColor'>Background Image</span>
                    </div>
                    <div className='d-flex pad-30-40 gap-30 justify-center align-center fd-column width-100'>
                      {/* <div className='tabs d-flex gap-10 align-center justify-start width-100' css={{
                        '.tab-item': {
                          '&.active': {
                            backgroundColor: colorPicker,
                            color: '#fff'
                          }
                        }
                      }}> 
                        <a href='#' className={`button button-primary tab-item ${activeTab === 'default' ? 'active' : ''}`} onClick={(e) => toggleActiveTab('default', e)}>Default</a>
                        <a href='#' className={`button button-primary tab-item ${activeTab === 'custom' ? 'active' : ''}`} onClick={(e) => toggleActiveTab('custom', e)}>Custom</a>
                      </div> */}
                      <div className="bg-images-thumbnails" css={{
                        display: activeTab === 'default' ? 'flex' : 'none'
                      }}>
                        <DefaultBackgroundImages backgroundImages={backgroundImages} colorPicker={colorPicker} onApplyColorPicker={onApplyColorPicker} backgroundImageId={backgroundImageId} setImage={setImage} />
                      </div>
                      <div className="bg-images-thumbnails" css={{
                        display: activeTab === 'custom' ? 'flex' : 'none'
                      }}>
                        <CustomBackgroundImages backgroundImages={backgroundImages} colorPicker={colorPicker} onApplyColorPicker={onApplyColorPicker} backgroundImageId={backgroundImageId} setImage={setImage} />
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </Modal.Body>
      </Modal>
      {
       image !== null && <BackgroundImageCard imageObject={image} location={image.location} color={colorPicker} onCancel={fetchSettingData} onApply={onApplyColorPicker} showEyeDropper={true} />
      }
    </>
  )
}

export default ColorPickerSetting