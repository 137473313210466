/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from 'react'
import { Route, Routes } from 'react-router-dom';
import FocusModeCompletedV2 from '../Components/FocusTimer/FocusModeCompletedV2';
import FocusModeStart from '../Components/FocusTimer/FocusModeStart';
import TimerCompleted from '../Components/FocusTimer/TimerCompleted';
import BreakCompleted from '../Components/FocusTimer/BreakCompleted';
import TodoistAuth from '../Components/TodoistAuth';
import DashboardLayout from '../Components/common/DashboardLayout';
import Logout from './Logout';
import PlanUpgrade from './PlanUpgrade';

const PrivateRoutes = React.memo(({activeTimer, pause, start, fullscreen, theme, colorPicker}) => {
    
    return (
        <div css={{
            // background: themeColor,
            '.darkPrimaryColor': {
                  background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}`,
              '-webkit-text-fill-color': 'transparent',
              '-webkit-background-clip': 'text !important',
              paddingRight: '2px'
            },
            '.darkOverDueColor': {
                  background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #D95555`,
              '-webkit-text-fill-color': 'transparent',
              '-webkit-background-clip': 'text !important',
              paddingRight: '2px !important'
            },
            '.overdueTaskColor':{
              color: '#D95555'
            }
        }}>
            <Routes>
                <Route path='/logout' element={<Logout />} />
                <Route path='/plan-upgrade' element={<PlanUpgrade />} />
                {
                    (activeTimer === 'focusMode') && fullscreen === true
                    ?
                    <>
                        <Route path='/focus-mode-completed' element={<FocusModeCompletedV2 />}/>
                        <Route path='/*' element={<FocusModeStart />}/>
                    </>
                    :
                    (
                        pause === true && start === true && fullscreen === true
                        ?
                        <Route path='/*' element={<FocusModeStart timerPaused={true} />}/>
                        :
                        <>
                            <Route path='/focus-completed' element={<TimerCompleted/>}/>
                            <Route path='/break-completed' element={<BreakCompleted/>}/>
                            <Route path='/auth/todoist' element={<TodoistAuth />}/>
                            <Route path='/*' element={<DashboardLayout />} />
                        </>
                    )
                }
            </Routes>
        </div> 
    )
});

export default PrivateRoutes