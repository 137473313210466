import React from 'react'
import { ProgressIcon } from '../../Components/LandingHeader/Feedback/icons/Icons'
import { generateArcPath } from '../../Components/action/utilities'
import { useSelector } from 'react-redux'

const TasksStatusWidget = ({taskStatus = null, direction = 'left', styles = {}, className = '', showLabel = true, width = 15, height = 15}) => {
    const { colorPicker } = useSelector(state => state.theme)
    const endAngle = (taskStatus.completed / taskStatus.total) * 359
    return (
        <span className={`task-status-widget ${className}`} style={styles}>
            {direction === 'left' ? <ProgressIcon color={colorPicker} width={width} height={height} pathd={generateArcPath(7.5, 7.5, 5.625, 0, endAngle)} /> : null}
            {showLabel && <span style={{lineHeight: "normal"}}><span className='darkPrimaryColor'>{taskStatus.completed}</span> <span className='total-count'>/ {taskStatus.total} Tasks done</span></span>}
            {direction === 'right' ? <ProgressIcon color={colorPicker} width={width} height={height} pathd={generateArcPath(7.5, 7.5, 5.625, 0, endAngle)} /> : null}
        </span>
    )
}

export default TasksStatusWidget