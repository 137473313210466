import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import _ from "lodash"
import {useSelector} from 'react-redux'

function UpcomingLoader({ loop }) {
  const {theme} =useSelector(state=>state.theme)
 
  return (
    <>
      {_.times(loop !== undefined ? loop : 1, (key) => {
        return (
          <>
            <div style={{background: theme == "dark" ? "black" : "white"}}>
              <ul className="accordion-list">
                <li className="accordion active" style={{background: theme == "dark" ? "black" : "white"}}>
                  <div className="accordion_tab active">
                    <div className="heading">
                      <h3 className="accordion-title"><span className="ac-today"><Skeleton height={"30px"} width="450px" /></span></h3>
                    </div>
                    {/* <div className="accordion_arrow">
                      <img src="images/arrow-up-dn.svg" alt="arrow" />
                    </div> */}
                  </div>
                  <div className="accordion_content">
                    <div className="task-list">
                      <div className="task-list-data">
                        <ul className="ul-task-list">
                          <li className="li-task-list">
                            
                            <div className="task-sections">
                              <label className="check-container">
                              <Skeleton height={"20px"} width="390px" />
                                <div className="checkmark" style={{border:"none"}} ><Skeleton height={"20px"} width="20px" /></div>
                              </label>
                            </div>
                            
                          </li>
                          <li className="li-task-list">
                            
                            <div className="task-sections">
                              <label className="check-container">
                              <Skeleton height={"20px"} width="390px" />
                                <div className="checkmark" style={{border:"none"}} ><Skeleton height={"20px"} width="20px" /></div>
                              </label>
                            </div>
                          </li>
                          <li className="li-task-list">
                            
                            <div className="task-sections">
                              <label className="check-container">
                              <Skeleton height={"20px"} width="390px" />
                                <div className="checkmark" style={{border:"none"}} ><Skeleton height={"20px"} width="20px" /></div>
                              </label>
                            </div>
                          </li>
                          <li className="li-task-list">
                            
                            <div className="task-sections">
                              <label className="check-container">
                              <Skeleton height={"20px"} width="390px" />
                                <div className="checkmark" style={{border:"none"}} ><Skeleton height={"20px"} width="20px" /></div>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        );
      })}
    </>
  )
}

export default UpcomingLoader