/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select'
import GeneralIcon from "../../pages/Settings/GeneralIcon";
import DynamicEditor from "./DynamicEditor";
import { CHATGPT } from "../common/constants";
import { fetchGlobalSetting, saveGlobalSetting } from "../action/common";
import AiSystemPromptPreviewModal from "../../pages/Settings/Modal/AiSystemPromptPreviewModal";

function AiAssistant({ activeTab, setActiveTab }) {
    const { theme, colorPicker } = useSelector(state => state.theme);
    const [settings, setSettings] = useState({});
    const [saving, setSaving] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const { MODELS } = CHATGPT;

    const getSettings = async () => {
        const response = await fetchGlobalSetting();
        let settings = {}
        if(response?.data?.responseData) {
            response?.data?.responseData.map((item) => {
                settings = { ...settings, [item?.settingName]: item?.settingValue };
            });

            setSettings(settings);
        }
    }

    const saveSettings = async (e) => {
        e.preventDefault();
        setSaving(true)
        try{
            const response = await saveGlobalSetting({settings});
            if(response?.data?.responseData) {
                setSaving(null)
            }
        } catch(e) {
            console.log(e)
            setSaving(false)
        }
    }

    const handleChange = (value, name) => {
        setSettings((prev) => ({ ...prev, [name]: value }));
        setSaving(false)
    }

    const previewPrompt = (e) => {
        e.preventDefault();
        setShowPreviewModal(true);
    }

    useEffect(() => {
        getSettings();
    }, []);

    return (
        <>
            <li
            css={{
                '&:hover .accordion-title': {
                color: theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c")) ? "white !important" : (theme == "dark" && colorPicker == "#f8961e") ? "black !important" : (theme == "dark" && colorPicker == "#ffca3a") ? "black !important" : (theme == "dark" && colorPicker == "#27cb9a") ? "black !important" : (theme == "dark" && colorPicker == "#4cc9f0") ? "black !important" : "white !important") : "white !important"
                },
                '.accordion-title': {
                color: theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c") && (activeTab == "aiassistant")) ? "white !important" : ((theme == "dark" && colorPicker == "#f8961e") && (activeTab == "aiassistant")) ? "black !important" : ((theme == "dark" && colorPicker == "#ffca3a") && (activeTab == "aiassistant")) ? "black !important" : ((theme == "dark" && colorPicker == "#27cb9a") && (activeTab == "general")) ? "black !important" : ((theme == "dark" && colorPicker == "#4cc9f0") && (activeTab == "aiassistant")) ? "black !important" : "white !important") : "white"
                }
            }}
            className={`li-setting-tab ${activeTab == "aiassistant" ? 'active' : ''}`}>
                <div className="settings-accordion-tab"
                    onClick={() => { setActiveTab(activeTab => activeTab == 'aiassistant' ? null : 'aiassistant'); }}
                    style={{ background: theme == "dark" ? "black" : "" }}
                    css={{
                    background: activeTab == 'aiassistant' ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` :
                        'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff',
                    '&:hover': {
                        background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important`,
                    }
                    }}>
                    <div className="settings-accordion-heading">
                    <figure className="settings-icon">
                        {
                        theme == "dark" ? <GeneralIcon isActiveSettings={activeTab == 'aiassistant'} isActiveGeneral={activeTab} /> : <img src="/images/general.svg" className="img-default" />
                        }
                    </figure>
                    <h3 className="accordion-title text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>AI Assistant</h3>
                    </div>
                    <div className="settings-accordion-arrow">
                        <img src="/images/arrow-down.svg" />
                    </div>
                </div>
                {
                    activeTab == 'aiassistant' 
                    && 
                    <div className="settings-accordion-content">
                        <div className="inner-form" style={{ background: theme == "dark" ? "black" : "" }}>
                            <h3>AI Assistant System prompt</h3>
                            <p>The prompt used by all users for each session</p>
                            <h4>Variables:</h4>
                            <div>
                                <DynamicEditor onInput={(content) => handleChange(content, 'aiAssistantSystemPrompt')} value={settings?.aiAssistantSystemPrompt ?? ''} />
                                <button className="changeName mt-20"
                                css={{
                                    background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                                    color: `${colorPicker} !important`
                                }}
                                onClick={previewPrompt}>Preview</button>
                            </div>

                            <div className="mt-40" css={{marginBottom: '200px'}}>
                                <h3>API Keys</h3>
                                <p>API key used for all LifeTab users.</p>
                                <div className="field-block mt-30">
                                    <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>OpenAI</label>    
                                    <div className="field-data">
                                        <input value={settings?.openAiApiKey ?? ''} type="text" className="input-field" placeholder="Enter your OpenAI API key" onInput={(e) => handleChange(e.target.value, "openAiApiKey")} />
                                    </div>
                                </div>
                                <div className="field-block mt-30">
                                    <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Anthropic</label>
                                    <div className="field-data">
                                        <input value={settings?.anthropicApiKey ?? ''} type="text" className="input-field" placeholder="Enter your Anthropic API key" onInput={(e) => handleChange(e.target.value, "anthropicApiKey")} />
                                    </div>
                                </div>
                                <div className="field-block mt-30">
                                    <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Default Model</label>
                                    <div className="field-data">
                                        <div className="filed-data-block" css={{
                                            '.general-setting__option--is-selected': {
                                            background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
                                            }
                                            }}  >
                                            <Select
                                                placeholder="Select AI Model"
                                                onChange={(value) => handleChange(value.value, 'aiAssistantModel')}
                                                options={MODELS}
                                                value={MODELS.filter(option => option.value === settings?.aiAssistantModel)?.[0] ?? null}
                                                className="react-select"
                                                classNamePrefix={"general-setting"}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="field-block mt-60">
                                    <button className="changeName mt-20"
                                    css={{
                                        background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                                        color: `${colorPicker} !important`
                                                
                                    }}
                                    onClick={saveSettings}>{saving === true ? 'Saving...' : (saving === false ? 'Save Settings' : 'Saved')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </li>
            {showPreviewModal && <AiSystemPromptPreviewModal show={showPreviewModal} onHide={() => setShowPreviewModal(false)} colorPicker={colorPicker} theme={theme} dateFormat={settings?.dateFormat} content={settings?.aiAssistantSystemPrompt ?? ''} />}
        </>
    );
}

export default AiAssistant;