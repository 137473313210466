import React, { useCallback, useContext, useEffect, useState } from 'react'
import {Modal } from 'react-bootstrap'
import { AuthContext } from '../../../AuthProvider';
import { fetchInboxTaskList } from '../../../Components/action/common';
import _ from 'lodash';
import { verifyRepeatedTask } from '../../../Components/action/utilities';
import moment from 'moment';

const AiSystemPromptPreviewModal = ({ show, onHide, colorPicker, theme, dateFormat = "DD/MM/YY", content = '' }) => {

    const {userProfile} = useContext(AuthContext);
    const [processedContent, setProcessedContent] = useState('Loading...');
    let dateFormate = dateFormat ? dateFormat : "DD/MM/YY"

    const fetchAllTaskList = useCallback(async () => {
        const response = await fetchInboxTaskList({date: (new Date()).toISOString()})
        const allTasks = response?.data?.responseData ?? [];

        const today = moment().startOf('day').toISOString();
        const dateOnLastWeek = moment().subtract(7, 'days').startOf('day').toISOString();   
        const nextWeek = moment().add(7, 'days').startOf('day').toISOString();

        // Today tasks
        const dueToday = _.orderBy(allTasks.map(item => {
        if(item?.repeat?.rule){
            const result = verifyRepeatedTask(item, new Date(), []);
            return result ? {id: item.id, name: item.title, isTaskofDay: item.isMostImportant === 1, isComplete: item.status === 1, dueDate: moment(new Date()).format(dateFormate)} : false;
        }else{
            const result = item.date !== null && item.date === today;
            return result ? {id: item.id, name: item.title, isTaskofDay: item.isMostImportant === 1, isComplete: item.status === 1, dueDate: moment(item.date).format(dateFormate)} : false;
        }
    
        }).filter(item => item !== false), ['date'], ['desc']);

        // Overdue tasks
        const overdue = _.orderBy(allTasks.map(item => {
        if(item?.repeat?.rule){
            const result = verifyRepeatedTask(item, null, [], true);
            return result?.length > 0 ? {id: item.id, name: item.title, isTaskofDay: item.isMostImportant === 1, isComplete: item.status === 1, dueDate: moment(new Date(result[0])).format(dateFormate)} : false;
        }else{
            const result = item.date !== null && item.date < today && item.date >= dateOnLastWeek;
            return result ? {id: item.id, name: item.title, isTaskofDay: item.isMostImportant === 1, isComplete: item.status === 1, dueDate: moment(item.date).format(dateFormate)} : false;
        }

        }).filter(item => item !== false), ['date'], ['desc']);

        // Upcoming tasks
        const upcoming = _.orderBy(allTasks.map(item => {
        if(item?.repeat?.rule){
            const result = verifyRepeatedTask(item, null, [], false, true);
            return result?.length > 0 ? {id: item.id, name: item.title, isTaskofDay: item.isMostImportant === 1, isComplete: item.status === 1, dueDate: moment(new Date(result[0])).format(dateFormate)} : false;
        }else{
            const result = item.date !== null && item.date >= today && item.date <= nextWeek;
            return result ? {id: item.id, name: item.title, isTaskofDay: item.isMostImportant === 1, isComplete: item.status === 1, dueDate: moment(item.date).format(dateFormate)} : false;
        }

        }).filter(item => item !== false), ['date'], ['asc']);

        return {tasks: {dueToday, overdue, upcoming}};
    }, [])

    // Replace system variables in content and handle HTML/newlines
    const processContent = async (text) => {
        const datetoday = new Date().toLocaleDateString();
        const userinfo = `
                        <b>User:</b> ${userProfile?.name}
                        <b>Email:</b> ${userProfile?.email}
                        `;
        let allTaskList = {tasks: {dueToday: [], overdue: [], upcoming: []}};

        // Check {tasks} and replace with actual tasks
        if(text.includes('{tasks}')){
            try{
                allTaskList = await fetchAllTaskList();
            }catch(error){
                console.log(error);
            }
        }

        const processedText = text
            .replace(/{datetoday}/g, datetoday)
            .replace(/{userinfo}/g, userinfo)
            .replace(/{tasks}/g, `<pre style="white-space: pre-wrap;">${JSON.stringify(allTaskList, null, 2)}</pre>`)
            .replace(/\n/g, '<br />'); // Convert newlines to <br> tags

        setProcessedContent(processedText);
    };

    useEffect(() => {
        processContent(content);
    }, [content]);

    return (
        <Modal show={show} onHide={onHide} className="task-modal file-upload-modal transform none absolute top-per-10">
            <Modal.Body>
                <div className="full-model md-model-updates" style={{ display: 'block' }}>
                    <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                        <h3>Preview</h3>
                        <div className="max height-80vh" style={{overflowY: 'auto'}}>
                            <div className="body-data mt-20" dangerouslySetInnerHTML={{ __html: processedContent }} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AiSystemPromptPreviewModal