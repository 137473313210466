/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Settings.css'; // Import the CSS file
import Image from './Image';
import { PlayRound, Trash, Spinner, PlusIcon, CirclePlusIcon } from '../../LandingHeader/Feedback/icons/Icons';
import { setBackgroundImages, setThemeDefaultImage } from '../../../redux/slices/ThemeSlice';
import { deleteBackgroundImage, setDefaultAccentColor, setDefaultBackgroundImage, setSetting, uploadBackgroundImage } from '../../action/common';
import { fetchBackgroundImages } from '../../action/utilities';
import { pushToSetting } from '../../../redux/slices/SettingSclice';
import GeneralIcon from '../../../pages/Settings/GeneralIcon';
import { extractColors } from "extract-colors";

function AdminSettings({ activeTab, setActiveTab }) {
    const { theme, colorPicker, backgroundImages } = useSelector(state => state.theme);
    const { settings } = useSelector(state => state.settings);
    const [isUploading, setIsUploading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchBackgroundImages().then(images => {
            dispatch(setBackgroundImages(images));
        });
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsUploading(true);
            try {
                const formData = new FormData();
                // Pass the local file URL to extractColors instead of the file object
                const colors = await extractColors(URL.createObjectURL(file));
                formData.append("files", file);
                formData.append("systemDefault", true);
                formData.append("palette", colors.map(color => color.hex));
                const resp = await uploadBackgroundImage(formData);
                const images = [...backgroundImages, resp.data.responseData];
                dispatch(setBackgroundImages(images));
                document.getElementById('new-image-upload').value = '';
            } catch (error) {
                console.log(error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    // delete image
    const handleDeleteImage = async (id) => {
        const images = backgroundImages.filter(image => image.id != id);
        deleteBackgroundImage(id);
        dispatch(setBackgroundImages(images));
    };

    // set default image
    const handleDefaultImage = async (image) => {
        const images = backgroundImages.map(img => img.id == image.id ? {...img, isDefault: 1} : {...img, isDefault: 0});
        setDefaultBackgroundImage(image.id)
        dispatch(setBackgroundImages(images)) 
        dispatch(setThemeDefaultImage(image.id))  

        const newSettings = { ...settings, backgroundImageId: image.id };
        dispatch(pushToSetting(newSettings));
        await setSetting({ settings: newSettings });
    };

    const setDefaultColor = (color, imageId) => {
        const images = backgroundImages.map(img => img.id == imageId ? {...img, defaultColor: color} : {...img, defaultColor: null}); 
        setDefaultAccentColor(imageId, color);
        dispatch(setBackgroundImages(images));
    }

    return (
        <li
        css={{
            '&:hover .accordion-title': {
            color: theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c")) ? "white !important" : (theme == "dark" && colorPicker == "#f8961e") ? "black !important" : (theme == "dark" && colorPicker == "#ffca3a") ? "black !important" : (theme == "dark" && colorPicker == "#27cb9a") ? "black !important" : (theme == "dark" && colorPicker == "#4cc9f0") ? "black !important" : "white !important") : "white !important"
            },
            '.accordion-title': {
            color: theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c") && (activeTab == "background")) ? "white !important" : ((theme == "dark" && colorPicker == "#f8961e") && (activeTab == "background")) ? "black !important" : ((theme == "dark" && colorPicker == "#ffca3a") && (activeTab == "background")) ? "black !important" : ((theme == "dark" && colorPicker == "#27cb9a") && (activeTab == "general")) ? "black !important" : ((theme == "dark" && colorPicker == "#4cc9f0") && (activeTab == "background")) ? "black !important" : "white !important") : "white"
            }
        }}
        className={`li-setting-tab ${activeTab == "background" ? 'active' : ''}`}>
            <div className="settings-accordion-tab"
                onClick={() => { setActiveTab(activeTab => activeTab == 'background' ? null : 'background'); }}
                style={{ background: theme == "dark" ? "black" : "" }}
                css={{
                background: activeTab == 'background' ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` :
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff',
                '&:hover': {
                    background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important`,
                }
                }}>
                <div className="settings-accordion-heading">
                <figure className="settings-icon">
                    {
                    theme == "dark" ? <GeneralIcon isActiveSettings={activeTab == 'background'} isActiveGeneral={activeTab} /> : <img src="/images/general.svg" className="img-default" />
                    }
                </figure>
                <h3 className="accordion-title text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Appearance</h3>
                </div>
                <div className="settings-accordion-arrow">
                <img src="/images/arrow-down.svg" />
                </div>
            </div>
            {
                 activeTab == 'background' && <div className="settings-accordion-content">
                 <div className="inner-form" style={{ background: theme == "dark" ? "black" : "" }}>
                     <div className='admin-settings-section'>
                         <div className='admin-settings-section-item'>
                             <label>Upload Background Image</label>
                         </div>
                         <div className='image-gallery'>
                             {backgroundImages.map((image, index) => (
                                 image.systemDefault ? <div key={image.id} className="image-card-wrapper">
                                     <Image imageObject={image} location={image.location} colorPicker={image?.isDefault ? image?.defaultColor : null} onColorChange={color => setDefaultColor(color, image.id)} isAdmin={true} />
                                     <button title={image.location} className="delete-icon" onClick={() => handleDeleteImage(image.id)}>
                                         <Trash color='red' />
                                     </button>
                                     <button className="default-icon" onClick={() => handleDefaultImage(image)} style={{background: image?.isDefault ? 'rgba(255,0,0,.7)' : 'none'}}>
                                         <PlayRound color={image?.isDefault ? 'red' : 'black'} />
                                     </button>
                                 </div> : null
                             ))}
                             <div className='image-card new-image-card'>
                                 <label htmlFor="new-image-upload">
                                     {isUploading ? (
                                         <>
                                             <Spinner /> <span style={{color: colorPicker}}>Uploading...</span>
                                         </>
                                     ) : (
                                         <>
                                             <CirclePlusIcon color={colorPicker} />
                                             <span style={{color: colorPicker}}>Upload Image</span>
                                         </>
                                     )}
                                 </label>
                                 <input 
                                     id="new-image-upload" 
                                     type="file" 
                                     onChange={handleImageUpload} 
                                     disabled={isUploading}
                                 />
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
            }
            
        </li>
    );
}

export default AdminSettings;