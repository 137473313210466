/** @jsxRuntime classic */
/** @jsx jsx */
import { useMonth } from "@datepicker-react/hooks";
import { jsx } from "@emotion/core";
import Day from "./Day";
import NavButton from "./NavButton";
import prev from '../assets/prv.svg';
import nxt from '../assets/nxt.svg';
import TodayMenu from "../../SideBar/TodayMenu";
import { useSelector } from "react-redux";
import { TodayMenuIcon } from "../../LandingHeader/Feedback/icons/Icons";
// import crcl from '../assets/crcl.svg';

function Month({ year, month, firstDayOfWeek, goToPreviousMonths, goToNextMonths, gotoToday }) {
  const {colorPicker} = useSelector(state=>state.theme)
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek
  });

  return (
    <div>
      <div css={{ 
        textAlign: "center", 
        margin: "0 0 16px" ,
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <strong css={{
          color: '#000',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          textTransform: 'uppercase',
          opacity: '0.5'
        }}>{monthLabel}</strong>
        <div className="buttonAction">
          <NavButton onClick={goToPreviousMonths}><img src={prev} /></NavButton>
          <span style={{cursor: "pointer"}} className="crcl datepicker-month-nav" onClick={gotoToday}><TodayMenuIcon activeStyleHome={true} color={month == new Date().getMonth() && year == new Date().getFullYear() ? 'grey' : colorPicker} fill={month == new Date().getMonth() && year == new Date().getFullYear() ? 'none' : colorPicker} /></span>
          <NavButton onClick={goToNextMonths}><img src={nxt} /></NavButton>
        </div>
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
          marginBottom: "10px",
          fontSize: "10px",
          opacity: '0.5',
          background: 'rgba(0, 0, 0, 0.10)'
        }}
      >
        {weekdayLabels.map(dayLabel => (
          <div css={{ 
            textAlign: "center", 
            color: '#000',
            padding: '8px',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal'
          }} key={dayLabel}>
            {dayLabel.toUpperCase()}
          </div>
        ))}
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center"
        }}
      >
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }

          return <div key={index} />;
        })}
      </div>
    </div>
  );
}

export default Month;
