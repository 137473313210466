import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const initialState = {
  istodayQuotes:true,
  isFoucsActive:true,
  quicksLinksActive:false,
  // addQuickLinkClick:true,
  cardsLinks:[]
}

const CardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setIsTodayQuotes(state,action){
      state.istodayQuotes=action.payload
    },
    // setAddQuickLinkClick(state,action){
    //   state.addQuickLinkClick=action.payload
    // },
    setCardsLinks(state,action){
      state.cardsLinks=action.payload
    },
    setIsFocusActive(state,action){
      state.isFoucsActive=action.payload
    },
    setQuicksLinksActive(state,action){
      state.quicksLinksActive=action.payload
    }
  }
})

// reducers exports
export const {setIsTodayQuotes  , setCardsLinks , setIsFocusActive , setQuicksLinksActive} = CardSlice.actions;

export default CardSlice.reducer;
