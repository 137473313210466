import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCollapseAll, setCollapsedGroupAccordions } from '../../redux/slices/ProjectSlice';
import Tooltip from './Tooltip';

const CollapseExpandAll = () => {
    const dispatch = useDispatch();
    const {collapseAll} = useSelector(state => state.project);
    const {taskProjects} = useSelector(state => state.task);
    const {colorPicker} = useSelector(state => state.theme);

    const clickHandler = (e) => {
        e.preventDefault();

        dispatch(setCollapseAll(!collapseAll))
        dispatch(setCollapsedGroupAccordions(!collapseAll ? taskProjects.map(t => t.title) : []))
    }

    return (
        <Tooltip className='overDue-tooltip tooltip-mt0 collapse-expand-all-tooltip' text={collapseAll ? 'Expand all groups' : 'Collapse all groups'} wrapperClassName="relative" color={colorPicker}> 
            <a className="button button-primary collapse-expand-all tooltip-hover" href="#" onClick={clickHandler} style={{width: '45px'}}>
                {
                    collapseAll ? <img src="/images/expand_all_arrow.png" alt="expand all" /> : <img src="/images/collapse_all_arrow.png" alt="collapse all" />
                }
            </a>
        </Tooltip>
    )
}

export default CollapseExpandAll