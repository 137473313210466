/** @jsxRuntime classic */
/** @jsx jsx */
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { fetchSetting, fetchTaskList, totalTaskCount } from '../../Components/action/common'
import TaskListDetails from '../Tasks/TaskListDetails'
import { useDispatch } from 'react-redux'
import { setHeaderName, setTaskList, setTaskProjects, setTotalCount } from '../../redux/slices/TaskSlice'
import { setColorPicker, setColorPickerMode, setNewBackgroundColor, setTheme, setThemeColor } from '../../redux/slices/ThemeSlice'
import { useSelector } from 'react-redux'
import _ from "lodash"
import { authentication } from '../../Components/action/utilities'
import { Navigate } from 'react-router-dom'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'
import JournalSection from './JournalSection'
import ColorPickerSetting from './ColorPickerSetting'
import { Accordion } from 'react-accessible-accordion'
import { jsx } from "@emotion/core";
import { setSettings } from '../../redux/slices/SettingSclice'

function HomePage() {
  // use hooks
  const dispatch = useDispatch()

  // global states
  const { dateChange } = useSelector(state => state.task)
  const { newBackgroundPopup, colorPicker, theme } = useSelector(state => state.theme)
  const [list, setList] = useState([])
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes))
  const [onAddTaskHome, setOnAddTaskHome] = useState(false)
  const date = moment(dateChange).format('YYYY-MM-DD')
  const currentDate = moment(currentTime).format('YYYY-MM-DD')
  const [selectedDate, setSelectedDate] = useState()
  const [isLoader, setIsLoader] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const isTodayPage = date == currentDate;

  useEffect(() => {
    fetchTotalCount();
    fetchSettingDetail()
    dispatch(setHeaderName('Upcoming'))
    // if (!_.find(taskList, ["date", moment().startOf('date').toISOString()])) {
    //   fetchAddTaskData()
    // }else{
    //   fetchAddTaskData(false)
    // }
    document.body.classList.add('homepage')

    return () => document.body.classList.remove('homepage')
  }, [])

  useEffect(() => {
   // if (!_.find(taskList, ["date", moment(dateChange).startOf('date').toISOString()])) {
      fetchAddTaskData(false)
   // }
  }, [dateChange])

  const fetchTotalCount = async () => {
    try {
      let response = await totalTaskCount({ "date": currentTime.toISOString() })

      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({ response }) {}
  }

  const fetchSettingDetail = async () => {
    try {
      let response = await fetchSetting()
      dispatch(setSettings(response?.data?.responseData?.settings));
      dispatch(setColorPicker(response?.data?.responseData?.settings?.accentColor ?? colorPicker))
      // dispatch(setTheme(response?.data?.responseData?.settings?.theme));
      if ((response?.data?.responseData?.settings?.theme == "light") || (response?.data?.responseData?.settings?.theme == "Light")) {
        dispatch(setTheme("light"));
        dispatch(setColorPickerMode("Light"));
        dispatch(setThemeColor("linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #fffff;"))
      } else if ((response?.data?.responseData?.settings?.theme == "dark") || (response?.data?.responseData?.settings?.theme == "Dark")) {
        dispatch(setTheme("dark"));
        dispatch(setThemeColor("linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000;"))
        dispatch(setColorPickerMode("Dark"));
      }
    } catch ({ response }) {

    }
  }

  const fetchAddTaskData = async (showLoader = true) => {
   // showLoader && setIsLoader(true)
    const currentTimes = moment(dateChange).startOf('date')
    const todayDate = new Date(currentTimes)

    let requestBody = {
      "date": moment(todayDate).toISOString() ? moment(todayDate).toISOString() : currentTime.toISOString(),
      "type": 1
    }
    try {
      let response = await fetchTaskList(requestBody)
     // showLoader && setIsLoader(false)
      dispatch(setTaskList(response?.data?.responseData))
      dispatch(setTaskProjects(response?.data?.projects ?? []))
      setOnAddTaskHome(false)
      setList(response?.data?.responseData)
    } catch (error) {
      //showLoader && setIsLoader(false)
    }
  }

  if (!authentication())
    return <Navigate to={'/welcome'} />

  return (
    <div className={`form-content todayPage ${isTodayPage ? 'current-date-page' : ''}`}>
      <div className="metting-form common-form" id="meeting-form">
        {
          //isLoader || true ? <AllLoader /> :
          isLoader && false ? <AllLoader customClassName="form-content" /> :
            <>
              <div className='task-list' style={{ background: theme == "dark" ? "black" : "" }}>
                <div className='task-list-data'>
                  <div className='ul-task-list'>
                    <Accordion preExpanded={['accitem0']}>
                      <TaskListDetails
                        selectedDate={selectedDate} 
                        setSelectedDate={setSelectedDate}
                        taskList={list}
                        homePage={true}
                        currentPage="home"
                        isTodayPage={date == currentDate}
                        fetchAddTaskData={fetchAddTaskData}
                        taskComplete={true}
                        onAddTaskHome={onAddTaskHome}
                        setOnAddTaskHome={setOnAddTaskHome}
                        setDeleteLoader={setDeleteLoader}
                        deleteLoader={deleteLoader}
                        fetchTotalCount={fetchTotalCount}
                      />
                    </Accordion>
                  </div>

                </div>
              </div>
              <JournalSection />
            </>
        }
      </div>
      {
        newBackgroundPopup && <ColorPickerSetting onHide={() => dispatch(setNewBackgroundColor(false))}/>
      }
    </div>
  )
}

export default HomePage