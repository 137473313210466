import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProjectsList: [],
  projectsListWithTasksCount: [],
  projectTasks: [],
  collapsedGroupAccordions: [],
  collapseAll: null
}

const ProjectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setAllProjectsList(state, action){
      state.allProjectsList = action.payload
    },
    setProjectsListWithTasksCount(state, action){
      if(Array.isArray(action.payload)){
        state.projectsListWithTasksCount = action.payload
      }else if(action.payload?.project_id){
        state.projectsListWithTasksCount = [...state.projectsListWithTasksCount].map(project => 
          project.project_id === action.payload.project_id ? action.payload : project
        )
      }
    },
    setProjectTasks(state, action){
      state.projectTasks = action.payload
    },
    setCollapsedGroupAccordions(state, action){
      state.collapsedGroupAccordions = action.payload
    },
    setCollapseAll(state, action){
      state.collapseAll = action.payload
    }
  }
})


// reducers exports
export const {
  setAllProjectsList,
  setProjectsListWithTasksCount,
  setProjectTasks,
  setCollapsedGroupAccordions,
  setCollapseAll
} = ProjectSlice.actions;

export default ProjectSlice.reducer;