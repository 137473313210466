// SignupForm.js
import React, { useEffect } from 'react';
import { loadOutseta } from '../Outseta';

const SignupForm = () => {
    const OPTIONS = {
        domain: "lifetab.outseta.com",
        load: "auth",
        auth: {
          widgetMode: "register",
          planUid: "7manVGQE",
          planPaymentTerm: "month",
          skipPlanOptions: false,
          id: "signup_embed",
          mode: "embed",
          selector: "#outseta-signup-form"
        }
    };

    useEffect(() => {
        loadOutseta(OPTIONS);
    }, []);

    return (
        <div id="outseta-signup-form-wrapper">
            <div id="outseta-signup-form"><h3 style={{textAlign: 'center'}}>Loading ... kindly wait.</h3></div>
            <a href="/login">Already have an account? Click here to login.</a>
        </div>
    );
};

export default SignupForm;
