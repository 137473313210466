/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PreExpire from './PreExpire';
import { subscriptionDetails } from "../action/common";

const SubscriptionPlan = () => {
    const { themeColor, colorPicker, theme } = useSelector(state => state.theme);
    const [subscription, setSubscription] = useState({});

    useEffect(() => {
        subscriptionDetails().then(data => {
            const daysLeft = Math.ceil((new Date(data?.renewalDate) - new Date()) / (1000 * 60 * 60 * 24));
            setSubscription({...data, daysLeft})
        })
    }, [])

    return (
        //!subscription?.isFreeTrial ? null :
        <div className="subscription-plan-detail bg-white border-radius25" css={{width: '700px'}}>
            <PreExpire accountStage={subscription?.accountStage} data={subscription} colorPicker={colorPicker} theme={theme} />
        </div>
    );
};

export default SubscriptionPlan;
