import React from 'react'
import {useSelector} from 'react-redux'

function ArrowUp() {
  const {theme}=useSelector(state=>state.theme)
  return (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 4.25L5 0.75L8.5 4.25" stroke={theme == "dark" ? "white" : "black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowUp