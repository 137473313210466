import { openDB } from 'idb';

const DB_NAME = 'lifetab';

// Initialize the IndexedDB database
export async function initDB(STORE_NAME = 'background-images') {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    },
  });
}

// Add data to IndexedDB
export async function addData(data, STORE_NAME = 'background-images') {
  const db = await initDB();
  return db.put(STORE_NAME, data);
}

// Fetch data from IndexedDB
export async function getData(id, STORE_NAME = 'background-images') {
  const db = await initDB();
  return db.get(STORE_NAME, id);
}

// Fetch all data from IndexedDB
export async function getAllData(STORE_NAME = 'background-images') {
  const db = await initDB();
  return db.getAll(STORE_NAME);
}

// Delete data from IndexedDB
export async function deleteData(id, STORE_NAME = 'background-images') {
  const db = await initDB();
  return db.delete(STORE_NAME, id);
}

export async function deleteAllData(STORE_NAME = 'background-images') {
  const db = await initDB();
  return db.clear(STORE_NAME);
}
