import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { fetchTaskList, totalTaskCount } from '../../Components/action/common'
import CompletedAndTrashList from './CompletedAndTrashList'
import { useDispatch, useSelector } from 'react-redux'
import { setTotalCount, setTrashTaskList } from '../../redux/slices/TaskSlice'
import moment from 'moment'
import TrashLoader from '../../Components/SkeletonLoading/TrashLoader'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'


function Trash() {
  // global state

  const { trashTaskList } = useSelector(state => state.task)
  const {theme} = useSelector(state => state.theme)
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes));
  const [isLoader, setIsLoader] = useState(false)

  // dispatch
  const dispatch = useDispatch()

  const fetchAddTaskData = async (type) => {
    if(type == undefined){
      setIsLoader(true);
    }

    try {
      let response = await fetchTaskList({ "isDeleted": 1 })
      setIsLoader(false)
      dispatch(setTrashTaskList(response?.data?.responseData))
      fetchTotalCount()
    } catch ({ response }) {
      setIsLoader(false)
    }
  }

  const fetchTotalCount = async () => {
    try {
      let response = await totalTaskCount({ "date": currentTime.toISOString() })
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({ response }) {

    }
  }

  useEffect(() => {
    fetchAddTaskData()
    fetchTotalCount()
  }, [])

  return (
    <div className=" trash" >
      {
        isLoader ? <AllLoader /> : <>
          <div className="form-content" >
            <form className="trash-form common-form" style={{background:theme == "dark" ? "black" :"white"}}>
              <div className="task-list" style={{background:theme == "dark" ? "black" :"white"}}>
                <div className="task-list-data">
                  <ul className="ul-task-list">
                    <CompletedAndTrashList
                      list={trashTaskList}
                      // trash={true}
                      currentPage="trash"
                      fetchAddTaskData={fetchAddTaskData}
                    />

                  </ul>
                </div>
              </div>
            </form>
          </div>
        </>

      }

    </div>


  )
}

export default Trash