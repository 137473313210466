/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useRef } from 'react'
import { useSelector } from "react-redux";

const Tooltip = ({ children, wrapperClassName = "", className="", text = '', delay = 500, color = '', textClassName="darkPrimaryColor" }) => {
    const tooltipRef = useRef();
    const {theme} = useSelector(state => state.theme)

    // useEffect(() => {
    //     tooltipRef.current.querySelector('.tooltip-hover').addEventListener('mouseenter', showTooltip)
    //     tooltipRef.current.querySelector('.tooltip-hover').addEventListener('mouseleave', hideTooltip)

    //     return () => {
    //         if (tooltipRef.current) {
    //             tooltipRef.current.querySelector('.tooltip-hover').removeEventListener('mouseenter', showTooltip)
    //             tooltipRef.current.querySelector('.tooltip-hover').removeEventListener('mouseleave', hideTooltip)
    //         }
    //     }
    // }, []);

    // const showTooltip = (e) => {
    //     const t = tooltipRef.current.querySelector('.tooltip-text');
    //     setTimeout(() => {
    //         t.style.display = 'block';
    //     }, delay)
    // }

    // const hideTooltip = (e) => {
    //     const t = tooltipRef.current.querySelector('.tooltip-text');
    //     t.style.display = 'none';
    // }

    return (
        <span ref={tooltipRef} className={wrapperClassName}>
            <span className='tooltip-hover' css={{
                '&:hover':{
                    '&::after':{
                        background: `radial-gradient(95% 95% at 50% 97.5%, rgba(255, 255, 255, 0) 0%, #FFF 100%), ${color}`
                    }
                }
            }}>{children}</span>
            <span className={`${className} tooltip-text`} css={{background: `${theme == "dark" ? `radial-gradient(95% 95% at 50% 97.5%, rgba(0, 0, 0, 0.80) 0%, #000 100%), ${color}` : `radial-gradient(95% 95% at 50% 97.5%, rgba(255, 255, 255, 0.80) 0%, #FFF 100%), ${color}`}`}}><span className={textClassName}>{text}</span></span>
        </span>
    )
}

export default Tooltip