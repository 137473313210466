// App.js
import React from 'react';
import SignupForm from '../../Components/Outseta/Signup';

const SignUpOutseta = () => {
    return (
        <div className="page-wrapper">
            <SignupForm />
        </div>
    );
};

export default SignUpOutseta;
