import React from 'react'
import SiteNav from '../Header/SiteNav';

const TasksNav = () => {
  return (
    <div id="tasks-nav" className='left-sidebar'>
        <h1 className='darkPrimaryColor'>Tasks</h1>
        <SiteNav />
    </div>
  )
}

export default TasksNav