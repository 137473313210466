import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AuthContext } from '../AuthProvider'
import LoginForm from '../Components/Outseta/Login'

function PlanUpgrade() {
  const dispatch = useDispatch()
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    document.cookie = 'authToken=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.clear();
    dispatch({ type: "DESTROY_SESSION" });
    setIsAuthenticated(false);
  }, [])

  return (
    <div className='page-wrapper'>
        <div className='warning'>
            <p>Please login to upgrade your plan.</p>
        </div>
        <LoginForm upgrade={true} />
    </div>
  )
}

export default PlanUpgrade

