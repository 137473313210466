/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { jsx } from "@emotion/core";
import { Cancel, ChatGPTErrorIcon, ChatGPTIcon, ChatIcon2, ChatUserIcon, CopyActiveIcon, CopyIcon, EditIcon2, MaximizeIcon, MessageIcon, ReloadIcon2, SaveIcon, SendIcon, StopIcon, Tick, Trash } from '../LandingHeader/Feedback/icons/Icons';
import './style.css';
import {deleteConversation, getChatTitle, saveConversation, sendPrompt, stopGeneratingCompletion} from '../action/chatGPT';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { clearChat, setChat, setCurrentPersona, setCurrentPrompt } from '../../redux/slices/AiAssistant';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CHATGPT } from '../common/constants';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import { usePosthog } from '../../PosthogProvider';

const Chat = ({fullscreen = false, cancelHandler, fullscreenButton = true}) => {
    const promptInput = useRef('');
    const titleInput = useRef('');
    const chatTitleRef = useRef('');
    const promptInputWrapperRef = useRef('');
    const [focus, setFocus] = useState(true);
    const [copyMessageId, setCopyMessageId] = useState(null);
    const {id, chatData, persona, prompt, title} = useSelector(state =>  state.aiAssistant);
    const [completions, setCompletions] = useState(chatData.length > 0 ? chatData : [{"role": "system", "content": "You are a helpful assistant."}]);
    const [progress, setProgress] = useState(false);
    const chatContainerRef = useRef('');
    const personaRef = useRef('');
    const {theme, colorPicker} = useSelector(state => state.theme)
    const [personaSelection, setPersonaSelection] = useState(null);
    const [promptSelection, setPromptSelection] = useState(null);
    const [responseDone, setResponseDone] = useState(true);
    const [editChatTitle, setEditChatTitle] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { captureEvent } = usePosthog();
    
    useEffect(() => {
        setCompletions(chatData.length > 0 ? chatData : [{"role": "system", "content": "You are a helpful assistant."}]) 
        !chatData.length && promptInput.current.focus();
    }, [chatData])

    useEffect(() => {
        !fullscreen && dispatch(clearChat({changed: false}));
        promptInput.current.focus();
    }, [])

    useEffect(() => {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, [completions])

    useEffect(() => {
        personaSelection !== null && dispatch(setCurrentPersona(personaSelection));
    }, [personaSelection])

    useEffect(() => {
        if(persona?.description){
            setCompletions(completions => {
                const c = JSON.parse(JSON.stringify(completions));
                c[0].content = persona.description;
                return c;
            })
        }
    }, [persona])

    useEffect(() => {
        if(prompt.length > 0){
            updateChatContainerHeight((promptInput.current.scrollHeight < 90 ? 90 : promptInput.current.scrollHeight) + "px")
            promptInput.current.focus();
            setFocus(true);
        }else{
            updateChatContainerHeight()
        }
    }, [prompt])

    useEffect(() => {
        if(promptSelection?.description){
            dispatch(setCurrentPrompt(promptSelection.description));
        }
    }, [promptSelection])
    
    const toggleSendBtn = (e) => {
        e.target.style.height = '90px';
        updateChatContainerHeight((e.target.scrollHeight < 90 ? 90 : e.target.scrollHeight) + "px");
        promptInput.current.value = e.target.value.replace(/^[\s]+/, '');
        dispatch(setCurrentPrompt(promptInput.current.value));
    }
    
    const send = (e, text = null) => {
        e.preventDefault();
        //const prompt = promptInput.current.value;
        let prmt = text !== null ? text : prompt;
        if(prmt.length > 0){
            let data = [...completions, {id: `chatgpt-${Date.now()}`, "role": "user", "content": prmt, sender: 'You', created: Math.floor((new Date()).getTime() / 1000)}].filter(t => t.role != 'error');
            promptInput.current.value = '';
            updateChatContainerHeight('90px');
            setTimeout(() => {
                promptInput.current.focus();
            }, 100)
            dispatch(setCurrentPrompt(''));
            setCompletions(data);
            setProgress(true);
            setResponseDone(false);
            sendPrompt(data.map(d => ({role: d.role, content: d.content}))).then(async (reader) => {
                while (true) {
                    const {value, done} = await reader.read();
                    if (done) {
                        setResponseDone(true);
                        break;
                    }

                    let prompts = value.match(/\{([^\n]*)/g);
                    if(prompts){
                        prompts.map(pmt => {
                            let p = JSON.parse(pmt);
                            if(p.choices[0].finish_reason == 'stop'){
                                // Get chat title
                                if(title !==  null){
                                    saveConversation({id, title, conversation: JSON.stringify(data)}).then(response => {
                                        dispatch(setChat({data, title, id: response.data.responseData?.id ?? null}));
                                    });
                                }else{
                                    getChatTitle([...(data.map(d => ({role: d.role, content: d.content}))), {role: 'user', content: `Very short title for the following question:
                                    
                                    ${data[1].content}`}]).then(response => {
                                        // Save the chat
                                        const chatTitle = response?.choices?.[0]?.message?.content ?? 'New Chat';
                                        saveConversation({id, title: chatTitle, conversation: JSON.stringify(data)}).then(response => {
                                            dispatch(setChat({data, title: chatTitle, id: response.data.responseData?.id ?? null}));
                                        });
                                    })
                                }
                                
                                return;
                            }
                                
                            let index = _.findIndex(data, ["id", p.id]);
                            if(index > -1){
                               data[index] = {...data[index], content: `${data[index].content}${p.choices[0].delta.content}`, sender: persona?.label, created: p.created} 
                               setCompletions([...data]);
                              // dispatch(setChat({data, title, id: p.id}));
                            }else{
                                data.push({id: p.id, role: p.choices[0].delta.role, content: '', sender: persona?.label, created: p.created});
                                setTimeout(() => setProgress(false), 100)
                            }
                        })
                    }
                }

                // Capture AI chat started event
                if(!id){
                    captureEvent('AI Chat Started');
                }else{
                    // Capture AI chat continued event
                    captureEvent('AI Chat Continued - Thread ID: ' + id);
                }
            }).catch(e => {
                setResponseDone(true);
                if(e.name == 'AbortError')
                    return;

                console.log('GPT ERROR:', e);

                data.push({id: `chatgpt-error-${Date.now()}`, prompt: prmt, role: 'error', content: 'Oops! Something went wrong.', sender: persona?.label, created: Math.floor((new Date()).getTime() / 1000)});
                setTimeout(() => setProgress(false), 100);
                const chatTitle = title ?? 'New Chat';
                saveConversation({id, title: chatTitle, conversation: JSON.stringify(data)}).then(response => {
                     dispatch(setChat({data, title: chatTitle, id: response.data.responseData?.id ?? null}));
                });
            });

            setTimeout(() => {
                document.getElementById('container_aiAssistant')?.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }, 100)
        }
    }
    function scrollToBottom() {    
        const element = chatContainerRef.current;
        if(!element.classList.contains('dnt-scroll')){
            element.scrollTop = element.scrollHeight;
        }
    }

    const toggleMinMax = (e) => {
        e.preventDefault();

        navigate('/ai-assistant')
    }

    const chatBoxClickHandler = (e) => {
        const gptContainer = document.querySelector('.gptContainer');
        if(gptContainer && !gptContainer.classList.contains('mounted')){
            gptContainer.classList.add('mounted')
            return;
        }
        if(e.target.closest('.timeline-li') || e.target.closest('.newChat') || e.target.closest('.chatTextarea') || e.target.closest('.chat-action') || e.target.closest('.cstmGetoption') || e.target.closest('.customSelect__menu__option')){
            setFocus(true);
        }else{
            setFocus(false);
        }
    }

    useEffect(() => {
        const element = chatContainerRef.current;
        element.addEventListener('DOMSubtreeModified', scrollToBottom);
        document.addEventListener('click', chatBoxClickHandler);

        return () => {
            element.removeEventListener('DOMSubtreeModified', scrollToBottom);
            document.removeEventListener('click', chatBoxClickHandler);
        };
    }, []);

    const copyMessageToClipboard = (e, text, id) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        chatContainerRef.current.classList.add('dnt-scroll')
        setCopyMessageId(id)
    }

    const removeActivePersona = (e) => {
        dispatch(setCurrentPersona(CHATGPT.PERSONAS[0]));
    }

    const stopGenerating = (e) => {
        e.preventDefault();
        stopGeneratingCompletion();
        setResponseDone(true);
        
        setTimeout(() => {
            promptInput.current.focus();
        }, 100)
    }

    const cancel = (e) => {
        e.preventDefault();

        cancelHandler();
    }

    const deleteChat = (e) => {
        e.preventDefault();
        deleteConversation(id).then(result => {
            dispatch(clearChat({changed: false}));
        });
    }

    const editTitle = (e) => {
        e.preventDefault();
        setEditChatTitle(true);

        setTimeout(() => {
            titleInput.current.focus();
        }, 100)
    }

    const saveTitle = (e) => {
        e.preventDefault();
        if(titleInput.current.value.replace(/^\s|\s$/, '').length === 0){
            return false;
        }
        const data = {id: id, title: titleInput.current.value, conversation: JSON.stringify(completions)};
        saveConversation(data).then(response => {
            dispatch(setChat({data: JSON.parse(data.conversation), title: data.title, id: data.id}));
            setEditChatTitle(false);
        });
    }

    const cancelEditTitle = (e) => {
        e.preventDefault();
        setEditChatTitle(false);
    }

    const updateChatContainerHeight = (promptInputHeight = '90px') => {
        const chatContainer = chatContainerRef.current;
        const promptInputWrapper = promptInputWrapperRef.current;
        const chatTitle = chatTitleRef.current;

        promptInput.current.style.height = '90px';
        promptInput.current.style.height = promptInputHeight;

        setTimeout(() => {
            if(chatContainer && promptInputWrapper && chatTitle){
                chatContainer.style.height = `calc(100% - ${promptInputWrapper.clientHeight + chatTitle.clientHeight}px)`;
                chatContainer.style.bottom = `${promptInputWrapper.clientHeight}px`;
            }
        }, 100)
    }

    return (
        <div id="chat-gpt-widget" className="quoteContainer" style={{background : theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000" : "" }} css={{
            '.darkPrimaryColor, .assistant .prompt-text p': {
              background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
            },
            '.darkErrorColor': {
              background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), #D95555`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important',
                fontWeight: 500
            }
        }}>
            <div className={`gptContainer widgetWrapper quoteInner ${completions?.length > 1 ? 'activeChat' : ''} ${focus || prompt?.length > 0 ? 'onActive' : ''} pb-20`}>
                <div ref={chatTitleRef} className='ctTitle' css={{
                    'a': {
                        'span:hover': {
                            background: `linear-gradient(0deg, rgba(255,255,255,0.80) 0%,rgba(255,255,255,0.80) 100%),linear-gradient(0deg,${colorPicker} 0%,${colorPicker} 100%),#FFF !important`,
                        }
                    }
                }}>
                    {
                        title 
                        ? 
                        <MessageIcon color={colorPicker} fill={colorPicker} />
                        :
                        <ChatIcon2 color={colorPicker} fill={colorPicker} />
                    }
                    
                    {
                        editChatTitle
                        ?
                        <input type="text" ref={titleInput} defaultValue={title} />
                        :
                        <span className='darkPrimaryColor'>{title ? title : 'How can I help you?'}</span>
                    }
                    
                    <span style={{flex: '1 0 0', alignSelf: 'stretch' }}></span>
                    {
                        editChatTitle
                        ?
                        <>
                            <a href="#" onClick={saveTitle}><span className='icon-round'><Tick /></span></a>
                            <a href="#" onClick={cancelEditTitle}><span className='icon-round'><Cancel /></span></a>
                        </>
                        :
                        (
                            fullscreen 
                            ?
                            (
                                title
                                &&
                                <>
                                    <a href="#" onClick={deleteChat}><span className='icon-round'><Trash /></span></a>
                                    <a href="#" onClick={editTitle}><span className='icon-round'><EditIcon2 /></span></a>
                                </>
                            )
                            :
                            <>
                               {fullscreenButton && <a href="#" onClick={toggleMinMax}><span className='icon-round'><MaximizeIcon /></span></a>}
                                <a href="#" onClick={cancel}><span className='icon-round'><Cancel /></span></a>
                            </>
                        )
                    }  
                </div>
                <div ref={chatContainerRef} className='completions-wrapper' id="completion" style={{color: theme== "dark" ? "white" :"black"}}>
                    <div className='main-completion-wrapper'>
                {
                    completions.map(completion => {
                        return completion.role == "system" 
                        ? 
                        null 
                        : 
                        <div key={completion.id} className={`completions ${completion.id} ${completion.role}`}>
                            {
                                completion.role != 'error'
                                &&
                                (
                                    copyMessageId == completion.id
                                    ?
                                    <a href="#" className='copyMessage button primary' onClick={e => copyMessageToClipboard(e, completion.content, completion.id)} css={{
                                        background: `radial-gradient(100% 100% at 0% 100%,rgba(255,255,255,0.60) 165%,rgba(255,255,255,0.90) 100%),linear-gradient(0deg,${colorPicker} 0%,${colorPicker} 100%),#FFF`,
                                        backdropFilter: 'blur(52px)'
                                    }}>
                                        <CopyActiveIcon color={colorPicker} fill={colorPicker} /> <span className='copy'>Copied</span>
                                    </a>
                                    :
                                    <a href="#" className='copyMessage button primary' onClick={e => copyMessageToClipboard(e, completion.content, completion.id)} css={{
                                        'svg:last-of-type':{
                                            display: 'none'
                                        },
                                        '&:hover': {
                                            background: `radial-gradient(50% 100% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #FFF`,
                                            backdropFilter: 'blur(52px)',
                                            'svg:last-of-type':{
                                                display: 'block'
                                            },
                                            'svg:first-child':{
                                                display: 'none'
                                            }
                                        }
                                    }}>
                                        <CopyIcon /> 
                                        <CopyActiveIcon id={`copy_id_${completion.id}`} color={colorPicker} fill={colorPicker} /> 
                                        <span className='copy'>Copy</span>
                                    </a>
                                )
                            }
                            <div className={`prompt-card ${completion.role}`}>
                                {
                                    completion.role == 'error'
                                    ?
                                    <ChatGPTErrorIcon color={'#D95555'} fill={'#D95555'} />
                                    :
                                    (
                                        completion.role == 'user'
                                        ?
                                        <ChatUserIcon color={colorPicker} fill={colorPicker} />
                                        :
                                        <ChatGPTIcon color={colorPicker} fill={colorPicker} />
                                    )
                                }
                                <div className='prompt-text'>
                                    {completion.role !== 'error' && <div className='persona-details'><span style={{color: colorPicker}}>{completion.sender == 'Default' ? 'Assistant' : completion.sender}</span><span className='time'>{completion.created ? moment(parseInt(completion.created) * 1000).format('LT') : ''}</span></div>}

                                    {
                                        completion.role == 'assistant' 
                                        ? 
                                        <ReactMarkdown 
                                            children={completion.content} 
                                            remarkPlugins={[remarkGfm, remarkMath]} 
                                            rehypePlugins={[rehypeMathjax, rehypeKatex]}
                                            components={{
                                                code({node, inline, className, children, ...props}) {
                                                    const match = /language-(\w+)/.exec(className || '');

                                                    return !inline ? (
                                                        <SyntaxHighlighter
                                                        {...props}
                                                        children={String(children).replace(/\n$/, '')}
                                                        style={dark}
                                                        language={(match && match[1]) || ''}
                                                        />
                                                    ) : (
                                                        <pre><code {...props} className={className}>
                                                        {children}
                                                        </code></pre>
                                                    )
                                                }
                                            }} 
                                        /> 
                                        : 
                                        (completion.role !== 'error' ? completion.content : <span className='darkErrorColor'>{completion.content}</span>)
                                    }

                                    {
                                        completion.role == 'error'
                                        &&
                                        <div><a className='button primary' style={{background: `linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%), ${colorPicker}`}} href="#" onClick={e => send(e, completion.prompt)}><ReloadIcon2 /> Try again</a></div>
                                    }
                                </div>
                            </div>
                        </div>
                    })
                }
                {
                   progress && <span className='progress-text darkPrimaryColor'>Generating Response.</span>
                }
                
                </div>
                </div>
                <div ref={promptInputWrapperRef} className='post-prompt-wrapper' css={{
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%), ${colorPicker}`
                }}>
                    {persona && persona.value != 'Default' && <div onClick={removeActivePersona} className='hasOptions has-Prompts' ref={personaRef}>
                        <span className='pmt-text active-persona' style={{color: colorPicker}}>Talking to {persona?.label}</span>
                        <span className='pmt-text remove'>Remove</span>
                    </div>}

                    <textarea onKeyDown={e => {
                        if(e.key == 'Enter') {
                            e.shiftKey === false && send(e);
                        }
                        }} style={{color: theme== "dark" ? "#fff" :"#000"}} value={prompt} ref={promptInput} placeholder={focus ? 'Type something ...' : 'Ask AI ...'} type="text" className="prompt-input chatTextarea" onInput={toggleSendBtn} onFocus={e => setFocus(true)} css={{
                            textIndent: persona && persona.value != 'Default' ? `${(personaRef?.current?.offsetWidth ?? 0) + 10}px !important` : '0',
                            backgroundPositionX: persona && persona.value != 'Default' ? `${(personaRef?.current?.offsetWidth ?? 0) + 5}px !important` : '0',
                            '&::placeholder': {
                                color: theme== "dark" ? "#565656" :"#000"
                            }
                        }}>
                    </textarea>
                    {
                        !responseDone 
                        ?
                        <div className='sendPrompt'>
                            <a href="#" className='button stop-generating' onClick={stopGenerating} style={{background: `linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), ${colorPicker}`}}><StopIcon opacity={1} /> Stop Generating</a>
                        </div>
                        :
                        (
                            prompt.length > 0
                            ?
                            <div className='sendPrompt'>
                                <a href="#" onClick={send} className="button primary" style={{background: colorPicker, color: theme == 'dark' ? '#000' : '#fff'}}>{chatData.length > 0 ? 'Send' : 'Start'} <SendIcon color={theme== "dark" ? "black" : "white"} /></a>
                            </div>
                            :
                            <div className='sendPrompt'>
                                <a href="#" onClick={send} className="button primary">{chatData.length > 0 ? 'Send' : 'Start'} <SendIcon color={theme== "dark" ? "gray" : "#737373"} /></a>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Chat