import React, { useContext, useEffect, useState } from 'react'
import Cards from './Cards'
import General from './Genral'
import Logout from './Logout'
import SiteBlocker from './SiteBlocker'
import OutsetaProfile from './OutsetaProfile'
import { AuthContext } from '../../AuthProvider'

function Settings() {
  const hash = window.location?.hash ? window.location.hash.replace('#', '') : null;
  const { redirection, setRedirection } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(hash == 'planChange' || hash == 'planCancel' ? "billing" : (hash !== null ? hash : ''))
  // const {addQuickLinkClick, settings}=useSelector(state=>state.settings)

  // useEffect(()=>{
  //   if(addQuickLinkClick == true){
  //     setActiveTab("cards")
  //   }
  // },[])

  useEffect(() => {
    if(redirection){
      switch(redirection){
        case '/settings':
          setActiveTab('block');
          break;
        default:
          break;
      }
      setRedirection(false);
    }
  }, [redirection])

  return (
    <div className="form-content">
      <div className="setting-form common-form">
        <div className="setting-tabs">
          <ul className="ul-setting-tab">
            <General activeTab={activeTab} setActiveTab={setActiveTab} />
            <OutsetaProfile trigger={hash} activeTab={activeTab} setActiveTab={setActiveTab} />
            <Cards activeCard={activeTab?.split(':')?.[1] ?? null} activeTab={activeTab?.split(':')?.[0] ?? ''} setActiveTab={setActiveTab} />
            <SiteBlocker activeTab={activeTab} setActiveTab={setActiveTab} />
            <Logout activeTab={activeTab} setActiveTab={setActiveTab} />
            
            {/* <PlansAndBilling trigger={hash} activeTab={activeTab} setActiveTab={setActiveTab} /> */}
            {/* <Today  isActiveSettings={isActiveSettings} setIsActiveSettings={setIsActiveSettings}/> */}
            {/* <Tasks  isActiveSettings={isActiveSettings} setIsActiveSettings={setIsActiveSettings}/> */}
            {/* <Habits isActiveSettings={isActiveSettings} setIsActiveSettings={setIsActiveSettings}/> */}
            {/* <Journal isActiveSettings={isActiveSettings} setIsActiveSettings={setIsActiveSettings}/> */}
            {/* <FocusTimer isActiveSettings={isActiveSettings} setIsActiveSettings={setIsActiveSettings}/> */}
            {/* <SortCuts isActiveSettings={isActiveSettings} setIsActiveSettings={setIsActiveSettings}/> */}
          </ul>
        </div></div>
    </div>
  )
}

export default Settings