/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx } from "@emotion/core";
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import FileUploader from '../../FileUploader';
import PopupHeader from './PopupHeader';

function FileUploadModal({ onHide, show = true, maxUploads = 8, maxUploadSize = 5 }) {
    const { theme, colorPicker } = useSelector(state => state.theme);

    return (
        <>
        <Modal show={show} onHide={onHide} className="task-modal file-upload-modal">
            <Modal.Body>
                <div className="full-model md-model-updates" style={{ display: 'block' }}>
                    <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                        <h3>Upload new background image</h3>
                        <div className="body-data">
                            <FileUploader colorPicker={colorPicker} onHide={onHide} maxUploads={maxUploads} maxUploadSize={maxUploadSize} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default FileUploadModal