import React from 'react'
import { RepeatIcon } from '../../Components/LandingHeader/Feedback/icons/Icons'
import Tooltip from '../../Components/common/Tooltip'
import { calendarFormat } from '../../Components/action/utilities'

const RecurringIcon = ({task, colorPicker, overdue = false}) => {
  const color = overdue ? '#D95555' : colorPicker
  const textClassName = overdue ? 'darkOverDueColor' : 'darkPrimaryColor'
  let label = task?.repeat?.label ? (task?.repeat?.label == 'every! 0 days' ? 'Every day' : task?.repeat?.label) : calendarFormat(task?.date)
  label = label.charAt(0).toUpperCase() + label.slice(1)

  return (
    typeof task?.repeat?.value != 'undefined' && task?.repeat?.value != '1' && task?.date !== null && (task?.repeat?.rule || (task?.orgRepeatId && task?.orgRepeatId !== task?.id)) && 
    <Tooltip wrapperClassName="repeat-icon-span" className='overDue-tooltip tooltip-mt0' text={label} color={color} textClassName={textClassName}>
        <a className="hovr-btn tooltip-hover">
            <span className='repeat-icon'>
                <RepeatIcon color={color} />
            </span>
        </a>
    </Tooltip>
  )
}

export default RecurringIcon