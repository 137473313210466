/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LogoutPopup from './Modal/LogoutPopup'
import { jsx } from "@emotion/core";
import LogoutIcon from './LogoutIcon';
import { setAddQuickLinkClick } from '../../redux/slices/SettingSclice';
// import { setAddQuickLinkClick } from '../../redux/slices/CardSlice';

function Logout({ activeTab, setActiveTab }) {
  const dispatch=useDispatch()
  const [logoutPopup, setLogoutPopup] = useState(false)
  const {colorPicker , theme} = useSelector(state => state.theme)
  const [onMouseEnterLogout,setOnMouseEnterLogout]=useState(false)
 
  return (
    <>
      <li className={`li-setting-tab logout ${activeTab == "logout" ? 'active' : ''}`}
      
      css={{
       '&:hover .accordion-title':{
        color:((theme == "dark" && colorPicker == "#ef233c")) ? "white !important" : (theme == "dark" && colorPicker == "#f8961e") ? "black !important" : (theme == "dark" && colorPicker == "#ffca3a") ? "black !important" : (theme == "dark" && colorPicker == "#27cb9a") ? "black !important" : (theme == "dark" && colorPicker == "#4cc9f0") ? "black !important" : "white !important"
       }
      }}
      onClick={() => {setLogoutPopup(true) ;dispatch(setAddQuickLinkClick(false)); dispatch(setAddQuickLinkClick(false))}}>
        <div className="settings-accordion-tab" 
         style={{background:theme == "dark" ? "black" :""}}
         css={{
          background: activeTab == "logout" ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` : 
          'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff',
          '&:hover':{
           background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important`
         }
       }}>
          <div className="settings-accordion-heading">
            <figure className="settings-icon">
              {
                activeTab == "logout" 
                ?
                 <img src="images/new-log-out.svg" className="img-default" /> 
                 : 
                 <>
                 {
                   theme == "dark" ?  <LogoutIcon onMouseEnterLogout={onMouseEnterLogout}/> :  <img src="images/new-log-out.svg" className="img-default" /> 
                 }
                 </>
              }
            </figure>
            <h3 className="accordion-title text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }} >Logout</h3>
          </div>
        </div>
      </li>
      {
        logoutPopup && (
          <LogoutPopup
            show={logoutPopup}
            onHide={() => setLogoutPopup(false)}
          />
        )
      }
    </>
  )
}

export default Logout