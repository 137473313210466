import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../AuthProvider'
import { EXTENSION } from '../Components/common/constants'

function Logout({ show, onHide }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    document.cookie = 'authToken=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.clear();
    // Clear chrome local storage in case of extension
    if(window?.chrome?.storage){
      window?.chrome.storage.local.clear(function () {});
    } 
    dispatch({ type: "DESTROY_SESSION" });
    if(window.opener){
        // Trigger - Timer Tracker Auth Sync
        try{
            // Logout of lifetab time tracker chrome extension
            window?.chrome.runtime.sendMessage(EXTENSION.ID.TIMETRACKER, { action: 'logoutTimeTracker' }, (response) => {
              console.log('Logout of time tracker', response);
            });
        }catch(e){
            console.log('Timer Tracker Auth Sync - FAILED: ', e)
        }
        window.close();
    }else{
        setIsAuthenticated(false);
        navigate('/welcome')
    }
    
  }, [])

  return (
    null
  )
}

export default Logout

