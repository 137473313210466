import React, { useEffect } from 'react'
import { exchangeTodoistAccessToken } from './action/common';
import { usePosthog } from '../PosthogProvider';

const TodoistAuth = () => {
    const { captureEvent } = usePosthog();
    
    const queryParams = () => {
        const paramsObj = new URLSearchParams(window.location.search);
        let newObj = {};
        for (const [key, value] of paramsObj) {
            newObj[key] = value;
        }
        return newObj;
    }

    useEffect(() => {
        const {error, code, state} = queryParams();
        
        if(error || !code){
            window.close();
        }else{
            exchangeTodoistAccessToken(code, state).then(response => {
                window.close();
                // Capture todoist connected event
                captureEvent('Todoist Connected');
            }).catch(response => {
                window.close();
            });
        }
    }, [])

    return (
        <div style={{
            position: 'fixed',
            paddingTop: '100px',
            textAlign: 'center',
            inset: '0',
            background: 'white'
        }}><img style={{verticalAlign: 'bottom'}} src="/images/logos_todoist-icon.svg" alt=""/> Todoist is connecting ...</div>
    )
}

export default TodoistAuth