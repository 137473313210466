import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription: null
}

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSubscription(state, action) {
      state.subscription = action.payload ?? null
    }
  }
})


// reducers exports
export const { setSubscription } = slice.actions;
export default slice.reducer;
