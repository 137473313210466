import React from 'react'
import Chat from '../../../Components/ChatGPT/Chat'
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

const ChatGPTModal = ({show = true, onHide, fullscreenButton = true}) => {
    const { theme } = useSelector(state => state.theme);

    return (
        <Modal show={show} onHide={onHide} className="task-modal chat-gpt-modal">
            <Modal.Body className='modal-body-extra-padding'>
            <div className="full-model md-model-updates" style={{ display: 'block' }}>
                <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                    <div className="body-data">
                        <Chat cancelHandler={onHide} fullscreenButton={fullscreenButton} />
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default ChatGPTModal