import React from 'react'
import { useSelector } from 'react-redux'

function HistoryIcon() {
  const { theme } = useSelector(state => state.theme)
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M1.75 1.75V4.66667H4.66667" stroke={`${theme == "dark" ? "white" : "black"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.77917 7.58335C1.90838 8.75341 2.42708 9.84609 3.25196 10.6859C4.07684 11.5257 5.16003 12.064 6.32759 12.2142C7.49514 12.3644 8.67929 12.1178 9.68987 11.5141C10.7005 10.9104 11.4788 9.98458 11.9 8.88532C12.3211 7.78606 12.3606 6.57716 12.0121 5.45276C11.6636 4.32836 10.9473 3.35372 9.97824 2.68535C9.0092 2.01699 7.84366 1.69369 6.6688 1.76738C5.49393 1.84107 4.37793 2.30747 3.5 3.09168L1.75 4.66668" stroke={`${theme == "dark" ? "white" : "black"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.375 7.1155L6.2405 8.981L9.9715 5.25" stroke={`${theme == "dark" ? "white" : "black"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>

  )
}

export default HistoryIcon