/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from 'react'
import { FolderIcon, Trash, TrashGradient } from "../../../Components/LandingHeader/Feedback/icons/Icons";
import { Modal } from "react-bootstrap";
import { deleteProject } from "../../../Components/action/tasks";
import TaskSourceIcon from "../../Tasks/TaskSourceIcon";

const DeleteProjectModal = ({onHide, theme, colorPicker, project}) => {
    const projectTitle = project.title.replaceAll('_', ' ');

    const deleteProjectHandler = async (e, deleteTasks = false) => {
        e.preventDefault()
        e.stopPropagation()
        
        try{
            await deleteProject(project.project_id, deleteTasks)
            onHide(true, project.project_id)
        }catch(err){
            console.log(err)
        }
    }

    return (
        <Modal show={true} onHide={onHide} className="task-modal">
            <Modal.Body className='modal-body-extra-padding'>
            <div className="full-model md-model-updates" style={{ display: 'block' }} css={{
                '.darkPrimaryColor': {
                    background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important',
                paddingRight: '2px !important'
                },
                '.lightPrimaryColor': {
                    background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), ${colorPicker}`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important',
                paddingRight: '2px !important'
                }
            }}>
                <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                    <div className="body-data">
                        <div className='ctTitle relative fd-col justify-start gap-20 align-start'>
                            <div className='d-flex align-center justify-start gap-10'>
                                <TrashGradient color={colorPicker} fill={colorPicker} />
                                <span className='darkPrimaryColor'>Are you sure you want to delete this project?</span>
                            </div>
                            <p className='font size-14 opacity d5 color-black font-family inter'>This cannot be undone.</p>
                        </div>
                        <div className='d-flex pad-30-40 gap-20 justify-center align-center width-100'>
                            <div className='d-flex fd-col gap-10'>
                                <div className='d-flex align-center justify-start gap-10'>
                                    <FolderIcon width={18} height={18} color={"black"} fill={"none"} /> {projectTitle} <span className="mn-count" style={{ color: theme == "dark" ? "gray" : "" }}></span>
                                </div>
                                <div className='d-flex align-center justify-start gap-10'>
                                    <TaskSourceIcon task={{id: project.project_id, taskSource: project.projectSource}} colorPicker={colorPicker} />
                                    <p className='font size-14 opacity d5 color-black font-family inter'>{project.completedTasksCount} / {project.pendingTasksCount + project.completedTasksCount} Tasks pending</p>
                                </div>
                            </div>
                            <span style={{flex: '1 0 0px', alignSelf: 'stretch'}}></span>
                            <button className='btn btn-primary font-family inter' css={{background: 'white !important'}} onClick={onHide}><span className="darkPrimaryColor">Cancel</span></button>
                            <button className='btn btn-primary font-family inter' onClick={e => deleteProjectHandler(e, true)} css={{
                                background: `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker} !important`
                            }}><span className="darkPrimaryColor">Delete Project & Tasks</span></button>
                            <button className='btn btn-primary font-family inter' onClick={e => deleteProjectHandler(e, false)} css={{background: `${colorPicker} !important`}}><span className="font color-white">Delete Project</span></button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteProjectModal;