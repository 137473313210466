import React from 'react'
import { useSelector } from 'react-redux'

function InfoIcon({onMouseEnterGeneral , isActiveGeneral , isActiveSettings, color}) {
  const {theme , colorPicker} =useSelector(state=>state.theme)

  let strCol=(((theme == "dark" && colorPicker == "#ef233c")) && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "white" : 
  ((theme == "dark" && colorPicker == "#27cb9a") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "black" : 
  ((theme == "dark" && colorPicker == "#f8961e") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "black":
  ((theme == "dark" && colorPicker == "#ffca3a") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "black":
  ((theme == "dark" && colorPicker == "#4cc9f0") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "black":
  ((theme == "dark" && colorPicker == "#3a86ff") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "white":
  ((theme == "dark" && colorPicker == "#9b5de5") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "white":
  ((theme == "dark" && colorPicker == "#ef476f") && (onMouseEnterGeneral || (isActiveGeneral && isActiveSettings == "general"))) ? "white":
  ((color) ) ? color:
  "gray"


return (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      {
        strCol ? <><path d="M7.99967 15.1666C11.6816 15.1666 14.6663 12.1818 14.6663 8.49992C14.6663 4.81802 11.6816 1.83325 7.99967 1.83325C4.31778 1.83325 1.33301 4.81802 1.33301 8.49992C1.33301 12.1818 4.31778 15.1666 7.99967 15.1666Z" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8 11.1667V8.5" stroke={strCol} strokeWidth="1.5"  strokeLinejoin="round"/>
        <path d="M8 5.83325H8.00833" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </>:

        <><path d="M7.99967 15.1666C11.6816 15.1666 14.6663 12.1818 14.6663 8.49992C14.6663 4.81802 11.6816 1.83325 7.99967 1.83325C4.31778 1.83325 1.33301 4.81802 1.33301 8.49992C1.33301 12.1818 4.31778 15.1666 7.99967 15.1666Z" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8 11.1667V8.5" stroke="white" strokeWidth="1.5"  strokeLinejoin="round"/>
        <path d="M8 5.83325H8.00833" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></>

      }
    </svg>
  )
}

export default InfoIcon
