import React, { useState, useCallback, useEffect } from 'react'
import { MagnifyingGlassIcon } from '../LandingHeader/Feedback/icons/Icons';
import debounce from 'lodash/debounce';
import { useNavigate, useSearchParams } from 'react-router-dom';

const TaskSearchInput = () => {
    const [searchKey, setSearchKey] = useState('');
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const keyword = searchParams.get('keyword');
        setSearchKey(keyword ?? '');
    }, [searchParams])
    
    // Debounced search function
    const debouncedSearch = useCallback(
        debounce((value) => {
            navigate(`/tasks/search?keyword=${value}`);
        }, 300),
        []
    );

    const searchHandler = (e) => {
        const {value} = e.target;
        setSearchKey(value);
        debouncedSearch(value);
    }

    return (
        <div className="relative width-100">
            <input type='text' id="task-search-input" className='search-field width-100 font color-black' onInput={searchHandler} value={searchKey} placeholder='Search tasks...' />
            <span className="absolute right-20 top-12"><MagnifyingGlassIcon color={'black'} /></span>
        </div>
    )
}

export default TaskSearchInput
