import React, { useState, useRef, useEffect } from 'react';
import './DynamicEditor.css';

const DynamicEditor = ({onInput, value}) => {
  const [content, setContent] = useState('');
  const [usedVariables, setUsedVariables] = useState([]);
  const textAreaRef = useRef(null);
  const overlayRef = useRef(null);
  const contentWrapperRef = useRef(null);
  const variables = ['{projectinfo}', '{tasks}', '{userinfo}', '{datetoday}'];

  // Function to insert variable at cursor position
  const insertVariable = (variable) => {
    if (textAreaRef.current) {
      const { selectionStart, selectionEnd } = textAreaRef.current;
      const updatedContent =
        content.slice(0, selectionStart) +
        variable +
        ' ' +
        content.slice(selectionEnd);
      setContent(updatedContent);

      // Move cursor after inserted variable
      setTimeout(() => {
        textAreaRef.current.selectionStart = selectionStart + variable.length + 1;
        textAreaRef.current.selectionEnd = selectionStart + variable.length + 1;
        textAreaRef.current.focus();
        adjustHeight();
        onInput(updatedContent);
      }, 0);
    }
  };

  // Adjust height based on content in the textarea
  const adjustHeight = () => {
    if (contentWrapperRef.current) {
        contentWrapperRef.current.style.height = "auto";
        contentWrapperRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }
  };

  // Handle content change
  const handleContentChange = (e) => {
    const newValue = e.target.value;
    setContent(newValue);
    adjustHeight();
    onInput(newValue);
  };

  // Function to update the list of used variables
  const updateUsedVariables = (text) => {
    const used = variables.filter((variable) => text.includes(variable));
    setUsedVariables(used);
  };

  // Escape HTML tags to prevent them from being rendered
  const escapeHtml = (text) => {
    return text.replace(/&/g, '&amp;')
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;')
               .replace(/"/g, '&quot;')
               .replace(/'/g, '&#39;');
  };
  
  // Function to render content with highlighted variables, escaping HTML tags
  const getHighlightedContent = () => {
    const regex = new RegExp(`(${variables.join('|')})`, 'g');
    const parts = content.split(regex);
  
    return parts
      .map((part) => {
        if (variables.includes(part)) {
          return `<span class="highlight">${part}</span>`;
        }
        return escapeHtml(part);
      })
      .join('');
  };  

  // Adjust height on initial render
  useEffect(() => {
    adjustHeight();
    updateUsedVariables(content);
  }, [content]);

  useEffect(() => {
    setContent(value ?? '');
  }, [value]);

  return (
    <div className="editor-container">
      {/* Variable Buttons */}
      <div className="variable-buttons">
        {variables.map((variable, index) => (
          <button
            key={index}
            onClick={() => insertVariable(variable)}
            className={`variable-button ${usedVariables.includes(variable) ? "used" : ""}`}
          >
            {variable}
          </button>
        ))}
      </div>

      {/* Content Area with Highlighted Variables */}
      <div className="content-wrapper" ref={contentWrapperRef}>
        {/* Overlay for Highlighted Content */}
        <div
          className="highlight-overlay"
          ref={overlayRef}
          dangerouslySetInnerHTML={{ __html: getHighlightedContent() }}
        />
        
        {/* Textarea for Input */}
        <textarea
          ref={textAreaRef}
          value={content}
          onChange={handleContentChange}
          className="content-textarea"
        />
      </div>
    </div>
  );
};

export default DynamicEditor;