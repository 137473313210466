import { combineReducers } from "@reduxjs/toolkit";
import CardSlice from "./CardSlice";

// slices imports

import HomePageSlice from './homePage'
import TaskSlice from "./TaskSlice";
import ThemeSlice from "./ThemeSlice";
import TimerSlice from "./timer";
// import SettingSclice from './SettingSclice'
import SettingSclice from './SettingSclice'
import AiAssistantSlice from './AiAssistant';
import AuthSlice from "./AuthSlice";
import AdminSettingSlice from './AdminSettingSlice';
import ProjectSlice from "./ProjectSlice";

// combine reducer
const MainReducer = combineReducers({

  homePage: HomePageSlice,
  task:TaskSlice,
  focusTimer: TimerSlice,
  theme:ThemeSlice,
  card:CardSlice,
  settings:SettingSclice,
  aiAssistant: AiAssistantSlice,
  auth: AuthSlice,
  adminSettings: AdminSettingSlice,
  project: ProjectSlice
});

export const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") {
    state = undefined
  }
  return MainReducer(state, action);
}