import React from 'react'
import BackgroundImageCard from '../../Components/common/BackgroundImageCard'
import { setColorPicker } from '../../redux/slices/ThemeSlice';
import { useDispatch } from 'react-redux';

const DefaultBackgroundImages = ({backgroundImages, colorPicker, onApplyColorPicker, backgroundImageId, setImage}) => {
    const dispatch = useDispatch();
    const onClickImageCard = (color, image) => {
        setImage(image);
        dispatch(setColorPicker(color))
    }

    return (
        <>
        {
            backgroundImages.map((image) => (
                image.systemDefault ? <BackgroundImageCard imageObject={image} location={image.location} key={image.id} id={image.id} color={colorPicker} onClick={color => onClickImageCard(color, image)} onApply={onApplyColorPicker} backgroundImageId={backgroundImageId} /> : null
            ))
        }
        </>
    )
}

export default DefaultBackgroundImages