import React, { useCallback } from 'react'
import { useState , useEffect} from 'react'
import { fetchTaskList, totalTaskCount } from '../../Components/action/common'
import AddTask from '../AddTasks/AddTask'
import CompletedAndTrashList from './CompletedAndTrashList'
import { useDispatch , useSelector } from 'react-redux'
import { setCompletedTaskList, setTotalCount } from '../../redux/slices/TaskSlice'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'
import moment from 'moment'
import AddTaskStatic from '../AddTasks/AddTaskStatic'

function Completed() {
  // dispatch
  const dispatch=useDispatch()
  const [isLoader,setIsLoader]=useState(false)
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes));

  // global state
  const { completedTaskList  } =useSelector(state=>state.task)
  const { theme } =useSelector(state=>state.theme)

  // action
  const fetchAddTaskData = async (type) => {
    if(type == undefined){
      setIsLoader(true)
    }
    
    try {
      let response = await fetchTaskList({"status":1})
      setIsLoader(false)
      dispatch(setCompletedTaskList(response?.data?.responseData.filter(item => item.status == 1)))
    } catch ({ response }) {
      setIsLoader(false)
    }
  }

  const fetchTotalCount=async()=>{
    try {
      let response=await totalTaskCount({"date":currentTime.toISOString()})
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({response}) {
      
    }
  }

  useEffect(()=>{
    fetchAddTaskData()
    fetchTotalCount()
  },[])


  return (
    <div className="">
    {
      isLoader ? <AllLoader/> :  <>
      <div className="overdue-form task-list-page common-form" style={{background:theme == "dark" ? "black" :"white"}}>
       <AddTaskStatic currentPage="completedList"/>
        <div className="task-list c" style={{background:theme == "dark" ? "black" :"white"}}> 
          <div className="task-list-data">
            <ul className="ul-task-list">
           
              <CompletedAndTrashList 
              // completedList={true}
               list={completedTaskList}
               currentPage="completedList"
               fetchAddTaskData={fetchAddTaskData}
               />
             
            </ul>
          </div>
        </div>
      </div>
    </>  
    }
  </div>
    
  )
}

export default Completed