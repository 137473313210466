/** @jsxRuntime classic */
/** @jsx jsx */
import moment from 'moment'
import React, { useMemo, useRef } from 'react'
import { useState } from 'react'
import { completedTask, createTask, deleteTask, fetchTaskList, updateTaskList } from '../../Components/action/common'
import EditTaskList from '../AddTasks/EditTaskList'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAllTaskList, deleteGroupedList, deleteNoDateTaskList, deleteOverDueTaskList, deleteSetTaskList, pushAllTaskList, pushCompletedTaskList, pushGroupedList, pushNoDateTaskList, pushOverDueTaskList, pushSetTaskList, setFocusTasksList, updateAllTaskList, updateAllTaskStatus, updateFocusTasksWithRecurringTask, updateGroupedList, updateGroupedStatus, updateNoDateTaskList, updateNoDateTaskStatus, updateOverDueTaskList, updateOverDueTaskStatus, updateSetTaskList, updateStatus } from '../../redux/slices/TaskSlice'
import toast from 'react-hot-toast'
import Toaster from '../CustomToast/Toaster'
import { SortableHandle } from 'react-sortable-hoc';
import _ from "lodash";
import Datepicker from '../../Components/DatePicker/components/Datepicker'
import { jsx } from "@emotion/core";
import ReactTooltip from 'react-tooltip';
import DragIcon from './DragIcon'
import { CalendarIcon, CalendarIconWithoutNumber, Checkbox, Clock, Focus, FolderCloseIcon, MostImportant, MoveTomorrowIcon, SetFocusIcon, TagIcon, Trash } from '../../Components/LandingHeader/Feedback/icons/Icons'
import MostImportantTaskButton from './MostImportantTaskButton'
import Tooltip from '../../Components/common/Tooltip'
import ConfirmRecurringTask from './ConfirmRecurringTask'
import { RRule, RRuleSet, rrulestr } from 'rrule';
import momentTimezone from 'moment-timezone';
import RecurringIcon from './RecurringIcon'
import { calendarFormat, calendarFormat2, dateWithOverdueColor } from '../../Components/action/utilities'
import { useNavigate } from 'react-router-dom'
import { useSocket } from '../../SocketContext'
import { decodeToken } from 'react-jwt'
import TaskSourceIcon from './TaskSourceIcon'
import { fetchProjectList } from '../../Components/action/tasks'
import { setProjectsListWithTasksCount, setProjectTasks } from '../../redux/slices/ProjectSlice'
import ProjectTagIcon from './ProjectTagIcon'

function TaskList({ setDeleteLoader = () => {}, deleteLoader, fetchTotalCount = () => {}, currentPage, setSelectedDate = () => {}, task,
  setEditTaskListData = () => {}, fetchAddTaskData = () => {}, groupDate, isTodayPage = false, taskIndex = null, disableSort = false, disableActions = false, disableHover = false, isNowGroup = false, projectId = null, showDate = false, fetchTasks = () => {}, fetchMoreData = () => {} }) {
  const taskTitleRef = useRef('');
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(new Date())
  const [editTaskId, setEditTaskId] = useState(null)

  const navigate = useNavigate();
  const socket = useSocket();

  const { dateChange, focusTasksList, taskList } = useSelector(state => state.task)
  const { colorPicker, theme } = useSelector(state => state.theme)
  const { settings } = useSelector(state => state.settings)

  let dateFormat = settings?.dateFormat ? settings?.dateFormat : "DD/MM/YY"

  const [onDeleteActive, setOnDeleteActive] = useState(false)
  const dateTime = moment(dateChange ?? new Date()).format('YYYY-MM-DD')
  const currentDate = moment(dateRange).format('YYYY-MM-DD')
  let taskDate = moment(task?.date).format('YYYY-MM-DD')
  const [showTooltip, setShowTooltip] = useState(false)
  const [deleteRecurringTask, setDeleteRecurringTask] = useState(false)
  const [changeDateRecurringTask, setChangeDateRecurringTask] = useState({})

  const DragHandle = SortableHandle(() => <span className="dragdrop-option"><DragIcon /></span>);

  // Reload project tasks
  const reloadProjectTasks = async () => {
    fetchTasks();
    if(projectId){
      const response = await fetchTaskList({projectId, status: 0});
      dispatch(setProjectTasks(response?.data?.responseData ?? []));
      const res = await fetchProjectList({includeTasksCount: true, projectId})
      dispatch(setProjectsListWithTasksCount(res?.data?.responseData?.data ?? {}))
    }
  }

  const onCompleteTask = async (taskClick, status = 1) => {
    let requestBody = {
      "id": taskClick?.id,
      status
    }
    try {
      let newTaskDate = groupDate ?? dateChange;
      if((currentPage === "allTask" || currentPage === "overDue" || showDate) && taskClick?.date){
        newTaskDate = taskClick?.date
      }
      if(taskClick?.repeat?.rule){
        const rruleSet = rrulestr(taskClick?.repeat?.rule, {
          forceset: true,
        })
        // If this is a recurring task and want to complete the current occurrence
        rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
        const updatedTask = {id: taskClick.id, date: taskClick?.date, repeat: {...taskClick?.repeat, rule: rruleSet.toString()}}
        await updateTaskList(updatedTask)
        updateTaskListLocally({...updatedTask, updatedAt: new Date()})

        // Extract relevant fields from task object
        const {
          title,
          tags,
          Project,
          time,
          week,
          repeat,
          timeZone,
          taskSource
        } = taskClick;
  
        const response = await createTask({
          title,
          tags,
          project: Project?.title ?? null,
          time,
          week,
          timeZone, 
          status, 
          completedAt: moment(newTaskDate).startOf('date').toISOString(), 
          date: moment(newTaskDate).startOf('date').toISOString(), 
          repeat: repeat ? {...repeat, rule: null} : null,
          origTaskId: taskClick?.id,
          orgRepeatId: taskClick?.orgRepeatId,
          taskSource
        })
        createTaskLocally(response?.data?.responseData?.data);
        status !== 0 && toast.custom(<Toaster undoRecurringTasks={[taskClick]} currentPage={currentPage} fetchAddTaskData={undoRecurringTask} deleteTaskData={false} undoRecurringTask={true} title={"Task Complete"} task={taskClick} completeTaskData={false} newTask={response?.data?.responseData?.data} />)
        dispatch(updateFocusTasksWithRecurringTask({oldTask: taskClick, newTask: response?.data?.responseData?.data}));
      }else{
        await completedTask({...requestBody, completedAt: moment(newTaskDate).startOf('date').toISOString()});
        status !== 0 && toast.custom(<Toaster currentPage={currentPage} title={"Task Complete"} task={taskClick} completeTaskData={true} />)
        if (currentPage === "upComing") {
          dispatch(updateGroupedStatus(requestBody));
          dispatch(updateStatus(requestBody));
        } else if (currentPage === "home") {
          dispatch(updateStatus(requestBody));
        } else if (currentPage === "overDue") {
          dispatch(updateOverDueTaskStatus(requestBody))
          dispatch(updateStatus(requestBody));
        } else if (currentPage === "allTask") {
          dispatch(updateAllTaskStatus(requestBody))
          dispatch(updateStatus(requestBody));
          fetchTotalCount()
        } else if (currentPage === "noDate") {
          dispatch(updateNoDateTaskStatus(requestBody))
        }
      }
      // Reload project tasks
      reloadProjectTasks()
    } catch ({ response }) {

    }
  };

  const undoRecurringTask = (undoRecurringTasks, newCompletedTask = null) => {
    undoRecurringTasks.map(task => {
      updateTaskListLocally({id: task.id, status: task?.status, repeat: task.repeat})
      updateTaskList({id: task.id, status: task?.status, repeat: task.repeat})
      // On undo recurring task, delete the new completed task
      if(newCompletedTask){
        deleteTask({ "id": newCompletedTask?.id });
        updateTaskListLocally({id: newCompletedTask?.id, deletedAt: new Date()});
      }
      // Reload project tasks
      reloadProjectTasks()
    })
  }

  const onDeleteClick = () => {
    if(task?.repeat?.rule && typeof task?.repeat?.value != 'undefined' && task?.repeat?.value != '1' && task?.date !== null){
      setDeleteRecurringTask(true)
    }else{
      onDeleteTask(task);
    }
  }

  const onDeleteTask = async (task, recurring = null) => {
    setDeleteRecurringTask(false)
    try {
      let undoRecurringTasks = [task];
      let deleteDate = groupDate ?? dateChange;
      if((currentPage === "allTask" || currentPage === "overDue" || showDate) && task?.date){
        deleteDate = task?.date
      }
      setOnDeleteActive(true)
      // If this is not a recurring task or want to delete all occurrences of the recurring task
      if(recurring === null || recurring === 3){
        dispatch(deleteSetTaskList({...task, recurring}))
        dispatch(deleteGroupedList({...task, recurring}))
        dispatch(deleteOverDueTaskList({...task, recurring}))
        dispatch(deleteAllTaskList({...task, recurring}))
        dispatch(deleteNoDateTaskList({...task, recurring}))
        dispatch(setFocusTasksList(focusTasksList.filter(t => recurring && recurring == 3 && task?.orgRepeatId && t?.orgRepeatId ? t?.orgRepeatId !== task?.orgRepeatId : t?.id !== task?.id)))
        await deleteTask({ "id": task?.id, recurring })
        toast.custom(<Toaster currentPage={currentPage} setDeleteLoader={setDeleteLoader} deleteLoader={deleteLoader} fetchAddTaskData={fetchAddTaskData} deleteTaskData={true} task={task} title={"Task Deleted"} fetchTotalCount={fetchTotalCount} />)
        fetchTotalCount()
      }else{
        const dtstart = momentTimezone(deleteDate).tz(momentTimezone.tz.guess()).endOf('day').toDate()
        // If this is a recurring task and want to delete the current occurrence
        if(recurring === 1){
          const rruleSet = rrulestr(task?.repeat?.rule, {
            forceset: true,
          })
          rruleSet.exdate(dtstart)
          const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
          await updateTaskList(updatedTask)
          updateTaskListLocally(updatedTask)
        }else{
          undoRecurringTasks = [];
          _.filter(taskList, ["orgRepeatId", task?.orgRepeatId]).map(t => {
            let rruleSet = rrulestr(t?.repeat?.rule ? t?.repeat?.rule : (new RRule({dtstart, freq: RRule.DAILY})).toString(), {forceset: true})
            // Check if the occurrance's until less or greater than deleting date
            let ruleObj = RRule.parseString(rruleSet._rrule.toString());
            let until = momentTimezone(deleteDate).tz(momentTimezone.tz.guess()).add(-1, 'days').endOf('day').toDate();
            if(ruleObj?.until && moment(until).isAfter(ruleObj?.until)){
              until = ruleObj.until;
            }
            // If this is a recurring task and want to delete the current occurrence and all future occurrences of the task
            let rrule = new RRule({...ruleObj, until});
            rruleSet._rrule[0] = rrule;
            rruleSet.rrule(rrule);
            let updatedTask = {id: t.id, repeat: {...t?.repeat, rule: rruleSet.toString()}}
            updateTaskList(updatedTask)
            updateTaskListLocally(updatedTask)
            undoRecurringTasks = [...undoRecurringTasks, t]
          })
        }
        // Show undo button
        toast.custom(<Toaster undoRecurringTasks={undoRecurringTasks} fetchAddTaskData={undoRecurringTask} currentPage={currentPage} undoRecurringTask={true} deleteTaskData={false} completeTaskData={false} task={task} title={"Task Deleted"} />)
      }
      if(fetchMoreData){
        fetchMoreData()
      }
      setOnDeleteActive(false)
      // Reload project tasks
      reloadProjectTasks()
    } catch (error) {
      console.log('error', error)
    }
  }

  const setTaskToDateConfirm = async (task, date, type) => {
    if(task?.repeat?.rule && typeof task?.repeat?.value != 'undefined' && task?.repeat?.value != '1' && task?.date !== null){
      setChangeDateRecurringTask({date, type})
    }else{
      setTaskToDate(task, date);
    }
  }

  const setTaskToDate = async (task, date, recurring = null) => {
    setChangeDateRecurringTask({});
    let requestBody = {
      "id": task?.id,
      "title": task?.title,
      "date": date
    };
    
    try {
      if(recurring !== null && recurring !== 3){
        await updateRecurringTask(task, date, recurring)
      }else{
        await updateTaskList({...requestBody, repeat: null})
        updateTaskListLocally(requestBody)
      }
      fetchTotalCount()
      // Reload project tasks
      reloadProjectTasks()
    } catch ({ response }) {}
  }

  const updateTaskListLocally = (task) => {
    switch (currentPage) {
      case 'upComing':
        dispatch(updateGroupedList(task));
        break;

      case 'overDue':
        dispatch(updateOverDueTaskList(task))
        break;

      case 'allTask':
        dispatch(updateAllTaskList(task))
        break;

      case 'noDate':
        dispatch(updateNoDateTaskList(task))
        break;

      default:
        dispatch(updateSetTaskList(task));
    }
  }

  const createTaskLocally = (task) => {
    switch (currentPage) {
      case 'upComing':
        dispatch(pushGroupedList(task))
        break;
      case 'overDue':
        dispatch(pushOverDueTaskList(task))
        break;
      case 'allTask':
        dispatch(pushAllTaskList(task))
        break;
      case 'noDate':
        dispatch(pushNoDateTaskList(task))
        break;
      case 'completedList':
        dispatch(pushCompletedTaskList(task))
        break;
      default:
        dispatch(pushSetTaskList(task));
    }
  }

  const onDateChange = async (task, date, recurring = null) => {
    setChangeDateRecurringTask({});
    let requestBody = {
      "id": task?.id,
      "title": task?.title,
      "date": moment(date).startOf('date').toISOString()
    };

    try {
      if(recurring !== null && recurring !== 3){
        await updateRecurringTask(task, date, recurring)
      }else{
        let response = await updateTaskList(requestBody)

        if (currentPage === "upComing") {
          dispatch(updateGroupedList(requestBody))
          dispatch(updateSetTaskList(requestBody));
        } else if (currentPage === "home") {
          dispatch(updateSetTaskList(requestBody));
        } else if (currentPage === "overDue") {
          dispatch(updateOverDueTaskList(requestBody))
          dispatch(updateSetTaskList(requestBody));
        } else if (currentPage === "allTask") {
          dispatch(updateAllTaskList(requestBody))
          dispatch(updateSetTaskList(requestBody));
        } else if (currentPage === "noDate") {
          dispatch(updateNoDateTaskList(requestBody))
          fetchTotalCount()
        }
        setSelectedDate(moment(date).startOf('date').toISOString())
        setDateRange(moment(date).startOf('date').toISOString())
      }
      // Reload project tasks
    reloadProjectTasks()
    } catch (e) {
      console.log('error====', e)
    }
  }

  const updateRecurringTask = async (task, date, recurring) => {
    const newTaskDate = groupDate ?? dateChange;
    let rruleSet = rrulestr(task?.repeat?.rule, {
      forceset: true,
    })
    // If this is a recurring task and want to edit the current occurrence only
    if(recurring == 1){
      rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
      rruleSet.exdate(momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate())
    }else{
      
      if(recurring == 2){
        // If this is a recurring task and want to edit the current occurrence and all future occurrences of the task
        const rrule = new RRule({...(RRule.parseString(rruleSet._rrule.toString())), until: momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).add(-1, 'days').endOf('day').toDate()});
        rruleSet._rrule[0] = rrule;
        rruleSet.rrule(rrule);
      }
    }
    const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
    await updateTaskList(updatedTask)
    updateTaskListLocally(updatedTask)

    // Create new rule for new task
    rruleSet = rrulestr(task?.repeat?.rule, {
      forceset: true,
    })
    let rruleOptions = { dtstart: momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate()}
    if(recurring == 1){
      rruleOptions = {...rruleOptions, until: momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate()}
    }else{
      rruleOptions = {...(RRule.parseString(rruleSet._rrule.toString())), ...rruleOptions}
    }

    let newRuleSet = new RRuleSet();
    newRuleSet.rrule(new RRule(rruleOptions));
    const repeat = {...task?.repeat, rule: newRuleSet.toString()};
    // Extract relevant fields from task object
    const {
      title,
      tags,
      Project,
      time,
      week,
      timeZone,
      status,
      completedAt,
      isMostImportant
    } = task;

    const response = await createTask({
      title,
      tags,
      project: Project?.title ?? null,
      time,
      week,
      timeZone,
      date,
      status,
      completedAt,
      isMostImportant,
      repeat,
      origTaskId: task?.id,
      orgRepeatId: task?.orgRepeatId
    })
    createTaskLocally(response?.data?.responseData?.data);
  }

  const onEditClick = async (task) => {
    setEditTaskId(task.id);
  }

  const addToFocusList = (e) => {
    e.preventDefault();
    // const isTaskInFocus = focusTasksList.find(t => t.id === task.id);
    // if(!isTaskInFocus){
    //   const focusTasks = [...focusTasksList, {...task, focus: 1}];
    //   dispatch(setFocusTasksList(focusTasks));
    //   triggerWebSocket({ action: "focusTasksList", tasks: focusTasks })
    // }

    const focusTasks = [{...task, focus: 1}];
    dispatch(setFocusTasksList(focusTasks));
    //triggerWebSocket({ action: "focusTasksList", tasks: focusTasks })
    navigate('/focus-mode-settings#added');
  }

  const triggerWebSocket = (data) => {
    // Trigger timer in wepapp and other clients
    if (socket !== null){
        const user = decodeToken(localStorage.getItem('token'));
        socket.emit('focusTimer', {room: user['outseta:accountUid'], data});
    }
  }

  const removeDropdowns = () => {
    deleteRecurringTask && setDeleteRecurringTask(false);
    changeDateRecurringTask && setChangeDateRecurringTask({})
  }

  const overdue = (((currentPage === "allTask" || currentPage === "overDue") && taskDate != null && taskDate < currentDate) || moment(groupDate ?? dateChange).isBefore(moment(), 'day'));
  const background = theme == "dark" ? `linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #000 !important;` : `linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.80) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #FFF !important`;
  const isNowTask = isTodayPage && taskIndex === 0 && isNowGroup;
  let taskRowBg = isNowTask 
                  ?
                  {
                    background
                  }
                  : 
                  {
                    '&:hover': {
                      '.task-sections':{
                        background
                      }
                    }
                  };
  taskRowBg = disableHover ? {
                      '.task-sections':{
                        border: '0 !important'
                      }
                    } : taskRowBg;

  return (
    <>
      {editTaskId === task.id ? <>
        <EditTaskList
          setEditTaskId={setEditTaskId}
          setSelectedDate={setSelectedDate}
          item={task}
          projectId={projectId}
          groupDate={groupDate}
          currentPage={currentPage}
          setEditTaskListData={setEditTaskListData}
          fetchAddTaskData={fetchAddTaskData}
          hideActions={disableActions}
        /></> :
        <>
          <li onMouseLeave={removeDropdowns} className={`li-task-list hr-line ${isTodayPage ? 'today-page' : ''} ${isNowTask ? 'now-task-row' : ''} task-item${task.id} ${task.isMostImportant === 1 && task?.status == 1 ? 'most-important-task-completed' : ''} ${task?.status == 1 ? 'completed-task' : ''}`}
            css={{
              ...taskRowBg,
              '&:hover .ut-data': {
                color: theme == "dark" ? "white !important" : "gray !important",
              },
              '&:hover span.task-title': {
                color: theme == "dark" ? "white !important" : "black !important"
              },

            }}
            >
            {task?.status == 0 && !disableSort && <DragHandle />}
            <div className="task-sections" >
              {
                task?.status == 1 &&
                <>
                  <Checkbox defaultChecked="checked" name={task?.id} defaultValue={task?.id} onClick={e => onCompleteTask(task, 0)}  colorPicker={colorPicker}/>
                  <span ref={taskTitleRef} css={{
                      color: theme == "dark" ? "white !important" : "black important",
                    }}
                    className='task-title' data-tip data-for={`longText-${task?.id}`}>{task.title}</span>
                </>
              }
              {
                task?.status == 0 &&
                <>
                  <Checkbox overdue={overdue} name={task?.id} defaultValue={task?.id} onClick={() => {onCompleteTask(task)}} colorPicker={colorPicker}/>
                  <span
                    ref={taskTitleRef}
                    css={{
                      color: theme == "dark" ? "white !important" : "black important",
                    }}
                    className={`task-title ${isNowTask ? 'darkPrimaryColor' : ''}`} data-tip data-for={`longText-${task?.id}`} onClick={() => onEditClick(task)}>{task?.title}
                  </span>
                </>
              }
              {
                changeDateRecurringTask.type === 'datepicker' && <ConfirmRecurringTask iconComponent={<CalendarIcon />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => onDateChange(task, changeDateRecurringTask.date, recurring)} onHide={() => setChangeDateRecurringTask({})} />
              }
              <div className='task-meta-wrapper'>
                {
                  (currentPage === "allTask" || currentPage === "overDue") && task?.date !== null 
                  && 
                  <span className="task-deta">
                    <CalendarIconWithoutNumber color={overdue ? '#D95555' : 'black'} width={12} height={13} />
                    <label className="ut-data" css={{color: theme == "dark" ? "white !important" : "gray !important"}}>
                    { dateWithOverdueColor(moment(task?.date).startOf('day'), dateFormat) }
                    </label>
                  </span>
                }
                {
                  showDate  && task?.date && (
                    <span className={`${overdue ? 'overdueTaskColor' : 'darkPrimaryColor'}`}>{calendarFormat(task.date)}</span>
                  )
                }
                <TaskSourceIcon task={task} colorPicker={colorPicker} />
                <RecurringIcon task={task} overdue={overdue} colorPicker={colorPicker} />
                <ProjectTagIcon task={task} />
              </div>
            </div>
            {
            task.isMostImportant === 1 
            &&
            (
                task?.status === 1
                ?
                <span className="most-important-task" css={{ background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%), ${colorPicker}` : `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}` }}><span className='darkPrimaryColor line-height-normal f-weight-500 font-family rl'>A successful day!</span></span>
                :
                <span className="most-important-task" css={{ background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%), ${colorPicker}` : `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}` }}><MostImportant color={colorPicker} fill={colorPicker} /> <span className='darkPrimaryColor line-height-normal f-weight-500'>Task of the Day</span></span>
            )
            }
            {
              showTooltip && <ReactTooltip delayShow={500} id={`longText-${task?.id}`} place="right" effect="solid" className="reactTooltipCss"> {task?.title} </ReactTooltip>
            }
            {
              !disableActions
              &&
              <div className="on-hover-list">
                <ul className="hover-action">
                  <MostImportantTaskButton theme={theme} color={theme == "dark" ? "white" : colorPicker} task={task} colorPicker={colorPicker}  newTaskDate={task?.date ? new Date(moment(groupDate ?? dateChange).startOf('date')).toISOString() : null} currentPage={currentPage} />
                  
                  {
                    (overdue || !moment(currentDate).isSame(moment(groupDate ?? dateChange), 'day'))
                    && 
                    <li data-tip data-for="todayTaskTooltip" className="hover-action-li" style={{ cursor: "pointer" }} onClick={() => setTaskToDateConfirm(task, new Date(moment(currentDate).startOf()).toISOString(), 'today')}> 
                      <Tooltip text="Move to today" className='overDue-tooltip' color={colorPicker}>
                        <a className="hovr-btn tooltip-hover" style={{ cursor: "pointer", background: theme == 'dark' ? '#000000' : '#ffffff' }}><Clock color={theme == "dark" ? "white" : colorPicker} /></a>
                      </Tooltip>
                      {
                        changeDateRecurringTask.type === 'today' && <ConfirmRecurringTask iconComponent={<Clock />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => setTaskToDate(task, changeDateRecurringTask.date, recurring)} onHide={() => setChangeDateRecurringTask({})} />
                      }
                    </li>

                  }
                  {
                    !moment().startOf('date').add(1, 'days').isSame(moment(groupDate ?? dateChange), 'day') && task.status != '1' 
                    && 
                    <li data-tip data-for="tomorrowTaskTooltip" className="hover-action-li" onClick={() => setTaskToDateConfirm(task, moment().startOf('date').add(1, 'days').toISOString(), 'tomorrow')}>
                      <Tooltip text="Move to tomorrow" className='overDue-tooltip' color={colorPicker}>
                        <a className="hovr-btn tooltip-hover" style={{ cursor: "pointer", background: theme == 'dark' ? '#000000' : '#ffffff' }}><MoveTomorrowIcon color={theme == "dark" ? "white" : "black"} /></a>
                      </Tooltip>
                      {
                        changeDateRecurringTask.type === 'tomorrow' && <ConfirmRecurringTask iconComponent={<MoveTomorrowIcon />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => setTaskToDate(task, changeDateRecurringTask.date, recurring)} onHide={() => setChangeDateRecurringTask({})} />
                      }
                    </li>
                  }
                  <li className="hover-action-li">
                    <span className='date-picker-wrapper'>
                      <CalendarIcon color={theme == "dark" ? "white" : colorPicker} />
                      <Datepicker onChange={(date) => { setTaskToDateConfirm(task, date, 'datepicker') }} value={dateTime} />
                    </span>
                  </li>
                  {
                    task?.status == 0 && settings?.focus && moment(currentDate).isSame(moment(groupDate ?? dateChange), 'day')
                    &&
                    <li className="hover-action-li">
                      <Tooltip className='overDue-tooltip' text="Start focus session" color={colorPicker}>
                        <a style={{ background: theme == 'dark' ? '#000000' : '#ffffff' }} className={`hovr-btn ${onDeleteActive ? 'active' : ''}`} onClick={addToFocusList}>
                          <SetFocusIcon />
                        </a>
                      </Tooltip>
                    </li>
                  }
                  <li className="hover-action-li" data-tip data-for="deleteTaskTooltip" style={{ cursor: "pointer" }} onClick={onDeleteClick}> 
                      <Tooltip className='overDue-tooltip' text="Delete task" color={colorPicker}>
                        <a style={{ background: theme == 'dark' ? '#000000' : '#ffffff' }} className={`hovr-btn ${onDeleteActive ? 'active' : ''}`}>
                          <Trash color={theme == "dark" ? "white" : colorPicker} />
                        </a>
                      </Tooltip>
                      {
                        deleteRecurringTask && <ConfirmRecurringTask iconComponent={<Trash />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => onDeleteTask(task, recurring)} onHide={() => setDeleteRecurringTask(false)} />
                      }
                    {/* <Tooltips/> */}
                  </li>
                </ul>
              </div>
            }
          </li>
        </>
      }
    </>
  )
}

export default TaskList